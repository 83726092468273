import './ModalConfirmation.scss';

import { Button, Modal } from '../../atoms';

import { Action } from '../../../common/enum';
import { Color } from '../../../common/color';
import React from 'react';
import { useIntl } from 'react-intl';

export interface ModalConfirmationProps {
    action: Action;
    entity: string;
    name?: string;
    tick?: boolean;
    onClose: (open: boolean) => void;
    onSave: () => void;
}

export const ModalConfirmation: React.FunctionComponent<ModalConfirmationProps> = (props: ModalConfirmationProps) => {
    const intl = useIntl();
    const tick = props.tick ? '`' : '';
    const onClose = () => {
        props.onClose(false);
    };

    const onSave = () => {
        onClose();
        props.onSave();
    };

    return (
        <div className="modal-confirmation-container" onClick={() => props.onClose(false)}>
            <Modal title={`${intl.formatMessage({ id: `action.${props.action.toLowerCase()}` })} ${props.entity}`}>
                <div>
                    <div className="body">
                        <div>{intl.formatMessage({ id: 'message.confirmation.action' }, { action: intl.formatMessage({ id: props.action.toLowerCase() }).toLowerCase() })} {`${tick}${props.name ? props.name : props.entity}${tick}`} ?</div>
                    </div>
                    <div className="actions">
                        <div style={{ order: `${props.action === Action.Delete ? '1' : '2'}` }}>
                            <Button
                                text={intl.formatMessage({ id: 'confirm' })}
                                onClick={() => { onSave(); }}
                            />
                        </div>
                        <div style={{ order: `${props.action === Action.Delete ? '2' : '1'}` }}>
                            <Button
                                text={intl.formatMessage({ id: 'cancel' })}
                                color={Color.Red}
                                onClick={() => { onClose(); }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
