import { Card, Select, splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { Agent } from '../../../models/Agent';
import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const InterventionVehiclePage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const [ agents, setAgents ] = useState<Array<Agent>>([]);
    const [ vehiclesParkId, setVehiclesParkId ] = useState<Array<string>>([]);
    const [ selectedVehicle, setSelectedVehicle ] = useState<string>();
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'sequence.number' }), key: 'sequenceNumber' },
        { label: intl.formatMessage({ id: 'date' }), key: 'date' },
        { label: intl.formatMessage({ id: 'intervention.hour' }), key: 'hour' },
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'agent.code' }), key: 'agentCode' },
        { label: intl.formatMessage({ id: 'agent.name' }), key: 'agent' },
        { label: intl.formatMessage({ id: 'mileage' }), key: 'kilometers' },
    ];

    useEffect(() => {
        apiService.getAllAgents(undefined, !authorization).then(setAgents);
        apiService.getInterventionParkIds().then(setVehiclesParkId);
    }, []);

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'intervention.vehicle' })}
                    contentClass="p-3"
                >
                    <div className="col-3 mx-auto">
                        <Select
                            label={intl.formatMessage({ id: 'park.id' })}
                            options={vehiclesParkId.map(k => ({ label: k, value: k }))}
                            onChange={e => setSelectedVehicle(e[0] ? e[0].value.toString() : undefined)}
                            placeHolder={intl.formatMessage({ id: 'select.vehicle' })}
                            isClearable
                        />
                    </div>

                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getInterventionVehicles(pageIndex, pageSize, sorting, selectedVehicle).then(res => {
                            return { ...res, results: res.results.map(r => ({ ...r, agent: agents.find(a => a.code === r.agentCode) })) };
                        }),
                        formatData: (data) => ({
                            ...data,
                            date: splitDateTime(intl, data.date)[0],
                            hour: splitDateTime(intl, data.hour)[1],
                            agent: data.agent ? `${data.agent.firstName} ${data.agent.lastName}` : undefined,
                        }),
                        refreshTrigger: [ agents, selectedVehicle ],
                    }}
                />
            }
        />
    );
};
