import './Homepage.scss';

import MenuBundle from '../../MenuBundle/MenuBundle';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { routeGroups } from '../../../App';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const Homepage = () => {
    const intl = useIntl();

    const user = useSelector<RootState, boolean>(state => !!state.user.user);
    const userPermissionKeys = useSelector<RootState, Array<string>>(state => state.user.userPermissionKeys);

    const groups = user ?
        routeGroups.map(g => ({
            ...g,
            routes: g.routes.filter(r => !r.hideHomepage && (!r.permissionKey || userPermissionKeys.includes(r.permissionKey)))
        })).filter(g => g.routes.length)
        : [];

    return (
        <PageFillUnderHeader
            header={<div className="app-title text-center mb-5">Saphir II</div>}
            content={
                <div>
                    <div className="homepage d-flex h-100 align-items-center">
                        <div className="d-flex flex-wrap justify-content-around w-100 homepage-container">
                            {
                                groups.map(group =>
                                    <div key={`menu-bundle-${group.title}`} className="bundle">
                                        <MenuBundle icon={group.icon} label={ intl.formatMessage({ id: group.title }) } options={group.routes} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        />

    );
};
