export class PassengerInformation {
    constructor(
        public id: number,
        public startDate: Date,
        public endDate: Date,
        public informationType: string,
        public times: Array<Array<string>>,
        public activeDays: number,
        public targetType: PassengerInformationTargetType,
        public targets: Array<number>,
        public information: {[ key: string]: string},
        public pcMessage?: {
            image: string,
            fontWeight: string,
            fontSize: number,
            fontFamily: string,
            text: string,
        }
    ) {}
}

export enum PassengerInformationTargetType {
    Global = 'G',
    Vehicles = 'V',
    Lines = 'L',
    Stops = 'A',
    Panels = 'P',
}

export enum PassengerInformationType {
    Perturbation = 'P',
    Commercial = 'C',
    PI = '1',
    PIPriority = '2',
    PC = '3',
    PCPriority = '4',
}

export enum PassengerInformationKey {
    P = 'P',
    J = 'J',
    VehS1 = '1',
    VehS2 = '2',
    BivS1 = '3',
    I = 'I',
}
