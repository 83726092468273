import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import OLTileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';

export interface TileLayerProps {
    source: TileSource;
    zIndex: number;
}

const TileLayer = (props: TileLayerProps) => {
    const map = useContext(MapContext);
    useEffect(() => {
        if (!map) { return; }

        const tileLayer = new OLTileLayer({
            source: props.source,
            zIndex: props.zIndex,
        });
        map.addLayer(tileLayer);
        tileLayer.setZIndex(props.zIndex);

        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [ map, props.source ]);
    return null;
};
export default TileLayer;
