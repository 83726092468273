import './TableHeader.scss';

import { Button, Icon, IconType, Input, Modal } from '../../atoms';
import { ReactNode, useState } from 'react';
import { TableColumn, TableOptions, TableValueType } from '../Table/Table';
import { TableFilter, TableFilters } from '../TableFilters/TableFilters';

import { Action } from '../../../common/enum';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';

export interface TableHeaderProps {
    title?: string | ReactNode,
    data?: Array<{[key: string]: TableValueType}>,
    columns?: Array<TableColumn>,
    options?: TableOptions,
    filters?: { [column: string]: TableFilter | undefined },
    currentFilterKey?: string,
    hidedColumns?: Array<string>,
    onAction?: (kind: Action, data?: { [key: string]: TableValueType }) => void;
    onFiltersChange?: (filters: {[column: string]: TableFilter | undefined}) => void;
    onHiderReset?: () => void;
}

export const TableHeader = (props: TableHeaderProps) => {
    const intl = useIntl();
    const [ downloadModal, setDownloadModal ] = useState<boolean>(false);
    const [ fileName, setFileName ] = useState<string>(`Export_${intl.formatDate(Date.now()).replaceAll('/', '-')}.csv`);

    return (
        <>
            {
                downloadModal && props.options?.export && props.data &&
                <Modal title={intl.formatMessage({ id: 'export.file.title' })}>
                    <div className="d-flex flex-column gap-3">
                        <Input
                            label={intl.formatMessage({ id: 'export.file.label' })}
                            value={fileName}
                            onChange={setFileName}
                        />
                        <div className="d-flex gap-3 justify-content-center">
                            <CSVLink
                                data={props.data}
                                separator=";"
                                headers={props.columns}
                                filename={fileName}
                                onClick={() => setDownloadModal(false)}
                            >
                                <Button
                                    text={intl.formatMessage({ id: 'export' })}
                                    onClick={() => []}
                                />
                            </CSVLink>
                            <Button
                                text={intl.formatMessage({ id: 'cancel' })}
                                onClick={() => setDownloadModal(false)}
                            />
                        </div>
                    </div>
                </Modal>
            }
            {
                (props.title || props.options?.create || props.options?.export || props.filters) &&
                <div className="table-header">
                    <div className="d-flex justify-content-center">
                        {
                            typeof props.title === 'string' ?
                                <div className="title text-center">{props.title.toUpperCase()}</div> :
                                <div>{props.title}</div>
                        }
                        <div className={`action-buttons d-flex p-3 gap-3 top-0 end-0 ${props.title ? 'position-absolute' : 'ms-auto'}`}>
                            {
                                props.options?.create &&
                                <Icon
                                    iconType={IconType.Plus}
                                    onClick={() => { props.onAction && props.onAction(Action.Create); }}
                                />
                            }
                            {
                                props.options?.export && !!props.data?.length &&
                                <Icon
                                    iconType={IconType.Download}
                                    onClick={() => setDownloadModal(true)}
                                />
                            }
                        </div>
                    </div>
                    {
                        props.filters && props.currentFilterKey && props.columns &&
                        <div className="d-flex justify-content-center gap-2 mx-auto mb-4">
                            <TableFilters
                                columns={props.columns}
                                filters={props.filters}
                                currentColumn={props.currentFilterKey}
                                onFiltersChange={(e) => {
                                    if (props.onFiltersChange) {
                                        return props.onFiltersChange(e);
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        !!props.hidedColumns?.length &&
                        <div className="w-100 text-center">
                            <Button
                                text={intl.formatMessage({ id: 'show.all.columns' })}
                                onClick={() => props.onHiderReset && props.onHiderReset()}
                            />
                        </div>
                    }
                </div>
            }
        </>
    );
};
