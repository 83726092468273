import { Button, TableColumn } from '@ceccli/design-system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { CommonPageType } from '../../../common/enum';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { PaginatedResult } from '../../../common/interface';
import { RootState } from '../../../app/store';
import { SoftwareTable } from '../../SoftwareTable/SoftwareTable';
import { TableSort } from '@ceccli/design-system/src/components/molecules/Table/Table';
import { TerminalWithSoftware } from '../../../models/Terminal';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { VehicleHardware } from '../../../models/VehicleSoftware';

export const DeployedSoftwarePage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const { type } = useParams();

    const columns: { [key: string]: Array<TableColumn> } = {
        vehicles: [
            { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
            { label: intl.formatMessage({ id: 'depot' }), key: 'depot' },
            { label: intl.formatMessage({ id: 'last.update' }), key: 'lastConnection', subIndex: [ 1 ] },
            { label: intl.formatMessage({ id: 'rt.calculator' }), key: 'typeRT' },
            { label: intl.formatMessage({ id: 'rt.version' }), key: 'versionRT' },
            { label: intl.formatMessage({ id: 'rt.last.download' }), key: 'dateRT', subIndex: [ 1 ] },
            { label: intl.formatMessage({ id: 'tft.calculator' }), key: 'typeTFT' },
            { label: intl.formatMessage({ id: 'tft.version' }), key: 'versionTFT' },
            { label: intl.formatMessage({ id: 'tft.last.download' }), key: 'dateTFT', subIndex: [ 1 ] },
            { label: intl.formatMessage({ id: 'desk.calculator' }), key: 'typeDESK' },
            { label: intl.formatMessage({ id: 'desk.version' }), key: 'versionDESK' },
            { label: intl.formatMessage({ id: 'desk.last.download' }), key: 'dateDESK', subIndex: [ 1 ] },
        ],
        terminals: [
            { label: intl.formatMessage({ id: 'terminal.type' }), key: 'type' },
            { label: intl.formatMessage({ id: 'terminal.index' }), key: 'index' },
            { label: intl.formatMessage({ id: 'name' }), key: 'name' },
            { label: intl.formatMessage({ id: 'last.update' }), key: 'lastConnection', subIndex: [ 1 ] },
            { label: intl.formatMessage({ id: 'rt.calculator' }), key: 'typeRT' },
            { label: intl.formatMessage({ id: 'rt.version' }), key: 'versionRT' },
            { label: intl.formatMessage({ id: 'rt.last.download' }), key: 'dateRT', subIndex: [ 1 ] },
            { label: intl.formatMessage({ id: 'tft.version' }), key: 'versionTFT' },
            { label: intl.formatMessage({ id: 'tft.last.download' }), key: 'dateTFT', subIndex: [ 1 ] },
        ]
    };

    const getFunctions: {[key: string]: (pageIndex?: number, pageSize?: number, sorting?: TableSort) => Promise<PaginatedResult<VehicleHardware | TerminalWithSoftware>>} = {
        vehicles: apiService.getVehiclesWithSoftware,
        terminals: apiService.getTerminalsWithSoftware,
    };

    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const availableTypes = showTerminals ? [ CommonPageType.Vehicles, CommonPageType.Terminals ] : [ CommonPageType.Vehicles ];

    const navigateToType = (key?: string) => {
        navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/${key}`);
    };

    useEffect(() => {
        if (!type || !availableTypes.includes(type as CommonPageType)) {
            navigateToType(availableTypes[0]);
        }
    }, [ type ]);

    return (
        type ?
            <PageFillUnderHeader
                content={
                    <SoftwareTable
                        title={
                            <div className="text-center">
                                <div className="title">{intl.formatMessage({ id: `deployed.software.${type}` })}</div>
                                {
                                    showTerminals &&
                                    <Button
                                        text={intl.formatMessage({ id: 'switch.to' }, { value: intl.formatMessage({ id: availableTypes.find(t => t !== type) }) })}
                                        onClick={() => navigateToType(availableTypes.find(t => t !== type))}
                                    />
                                }
                            </div>
                        }
                        columns={columns[type]}
                        getFunction={getFunctions[type]}
                        type={type}
                    />
                }
            /> :
            null
    );
};
