import './GeneralNetworkStatePage.scss';

import { Button, LineIndicator, Table, TableColumn, TableValueType } from '@ceccli/design-system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { Line } from '../../../models/Line';
import { Point } from '../../../models/Point';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { VehicleCartography } from '../../../models/Cartography/VehicleCartography';

interface RowRenderData {
    vehicles: Array<VehicleCartography>,
    lineId?: number,
    pathId?: number,
}

export const GeneralNetworkStatePage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const location = useLocation();

    const { lineId, pathId } = useParams();
    const navigate = useNavigate();
    const navigateToPrevious = (key?: string) => navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf(`/${key}`))}`);

    const allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);

    const [ selectedLine, setSelectedLine ] = useState<Line>();
    const [ selectedPath, setSelectedPath ] = useState<number>();

    const [ pathData, setPathData ] = useState<Array<{ [key: string]: TableValueType }>>();
    const [ stopData, setStopData ] = useState<Array<{ [key: string]: TableValueType }>>();
    const [ pathStops, setPathStops ] = useState<Array<{ id: number, points: Array<Point>}>>([]);

    const lineColumns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'line.code' }), key: 'code', renderCell: (row) => renderLine(row) },
        { label: intl.formatMessage({ id: 'line.label' }), key: 'label' },
        { label: intl.formatMessage({ id: 'vehicle.count' }), key: 'count', renderCell: (row, renderData) => renderCount(row, renderData as RowRenderData, 'lineId') },
    ];

    const pathColumns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'path' }), key: 'code', renderCell: (row) => renderLine(row) },
        { label: intl.formatMessage({ id: 'vehicle.count' }), key: 'count', renderCell: (row, renderData) => renderCount(row, renderData as RowRenderData, 'pathId') },
    ];

    const pointColumns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'stop' }), key: 'label', renderCell: (row) => renderLabel(row) },
        { label: intl.formatMessage({ id: 'park.id' }), key: 'bodyId' },
        { label: intl.formatMessage({ id: 'mission' }), key: 'missionNumber' },
        { label: intl.formatMessage({ id: 'service.vehicle' }), key: 'sv' },
        { label: intl.formatMessage({ id: 'service.agent' }), key: 'sa' },
    ];

    useEffect(() => {
        const id = Number(lineId);
        if (!id) {
            if (lineId) {
                navigateToPrevious(lineId);
            }
            setSelectedLine(undefined);
            return;
        }
        if (!selectedLine || selectedLine.id !== id) {
            apiService.getLine(id).then(res => {
                setSelectedLine(res);
                if (!res) {
                    navigateToPrevious(lineId);
                }
            });
        }
    }, [ lineId ]);

    useEffect(() => {
        const id = Number(pathId);
        if (pathId && !id) {
            navigateToPrevious(pathId);
        } else {
            setSelectedPath(id);
        }
    }, [ pathId ]);

    useEffect(() => {
        if (selectedLine) {
            apiService.getLinePaths(selectedLine.id).then(res => {
                if (selectedPath && !res.includes(selectedPath)) {
                    navigateToPrevious(pathId);
                } else {
                    getPathData(res);
                }
            });
        }
    }, [ selectedLine, selectedPath ]);

    useEffect(() => {
        if (selectedPath) {
            if (pathData) {
                getStopsData();
            } else {
                getPathData([ selectedPath ]);
            }
        }
    }, [ selectedPath, pathData ]);

    const getPathData = async (paths: Array<number>) => {
        if (selectedLine) {
            const pathPoints = await Promise.all(paths.map(p => apiService.getPathPoints(p).then(res => res)));
            setPathData(pathPoints.map((points, index) => ({
                id: paths[index],
                code: paths[index],
                background: selectedLine.background,
                color: selectedLine.color,
                label: `${points[0].label} - ${points[points.length - 1].label}`,
                via: points.filter(p => p.via).map(p => p.label).join(' - '),
            })));
            setPathStops(pathPoints.map((points, index) => ({
                id: paths[index],
                points,
            })));
        }
    };

    const getStopsData = () => {
        if (selectedPath) {
            const stops = pathStops.find(s => s.id === selectedPath);
            if (stops) {
                const lineId = selectedLine?.id;
                const pathId = selectedPath;
                const vehicles = allVehicles.filter(v => !lineId || lineId === v.lineId).filter(v => !pathId || pathId === v.pathId);

                Promise.all(stops.points.map(async p => {
                    const pointVehicles = vehicles.filter(v => v.previousStop === p.id);
                    const services = await Promise.all(pointVehicles.map(v => (v.missionId ? apiService.getMissionServices(v.missionId) : null)));

                    return {
                        ...p,
                        bodyId: pointVehicles.map(v => v.bodyId),
                        missionNumber: pointVehicles.map(v => (v.missionNumber ? v.missionNumber.toString() : '-')),
                        sv: services.map(v => (v?.SV ? v.SV : '-')),
                        sa: services.map(v => (v?.SA ? v.SA : '-')),
                    };
                })).then(res => setStopData(res));
            }
        }
    };

    const renderTitle = () => {
        return (
            selectedLine ?
                <div className="pt-3 text-center">
                    <LineIndicator
                        { ...selectedLine}
                        classNameCode="p-3 mb-2"
                        label={(selectedPath && stopData) ? `${stopData[0].label} - ${stopData[stopData?.length - 1].label}` : selectedLine.label?.toString()}
                        height="auto"
                    />
                    <Button
                        text={intl.formatMessage({ id: 'back.to' }, { type: intl.formatMessage({ id: selectedPath ? 'paths' : 'lines' }).toLowerCase() })}
                        onClick={() => navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')))}
                    />
                </div> :
                null
        );
    };

    const renderCount = (
        row: { [key: string]: TableValueType },
        renderData: RowRenderData,
        checkInfo: 'lineId' | 'pathId'
    ) => {
        const lineId = renderData.lineId;
        const pathId = renderData.pathId;
        const filtered = renderData.vehicles
            .filter(v => !lineId || lineId === v.lineId)
            .filter(v => !pathId || pathId === v.pathId)
            .filter(v => v[checkInfo] === row.id);

        return (
            <div>{filtered.length || '-'}</div>
        );
    };

    const renderLine = (row: { [key: string]: TableValueType }) => {
        return (
            <div className="d-flex align-items-center gap-2">
                <LineIndicator { ...row } />
                {
                    row.via && <div className="fst-italic via">{`(${intl.formatMessage({ id: 'via' })}: ${row.via})`}</div>
                }
            </div>
        );
    };

    const renderData: RowRenderData = {
        vehicles: allVehicles.filter(v => v.lineId),
        lineId: selectedLine?.id,
        pathId: selectedPath,
    };

    const renderLabel = (row: { [key: string]: TableValueType }) => {
        return (
            <div
                className={
                    `
                        ${row.via ? 'fst-italic' : ''}
                        ${row.primary ? 'fw-bold' : ''}
                        ${row.reference ? 'text-decoration-underline' : ''}
                    `
                }
            >{row.label}</div>
        );
    };

    return (
        <div className="general-network-state p-4 h-100 overflow-auto">
            {
                (!selectedLine || !lineId) ?
                    <Table
                        title={intl.formatMessage({ id: 'general.network.state' })}
                        columns={lineColumns}
                        dynamicData={{
                            getData: (pageIndex, pageSize, sorting) => apiService.getLines(pageIndex, pageSize, sorting),
                        }}
                        onClick={data => navigate(`/general-network-state/${data.id}`)}
                        renderData={renderData}
                    /> :
                    !selectedPath ?
                        <>
                            <div className="d-none">{lineId}</div>
                            {
                                pathData &&
                                <Table
                                    title={renderTitle()}
                                    columns={pathColumns}
                                    staticData={{ data: pathData }}
                                    onClick={data => {
                                        setSelectedPath(Number(data.id));
                                        navigate(`/general-network-state/${selectedLine.id}/${data.id}`);
                                    }}
                                    renderData={renderData}
                                />
                            }
                        </> :
                        <>
                            {
                                stopData &&
                                <Table
                                    title={renderTitle()}
                                    columns={pointColumns}
                                    staticData={{ data: stopData }}
                                    renderData={renderData}
                                />
                            }
                        </>
            }
        </div>
    );
};
