import { Button, splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { CommonPageType } from '../../../common/enum';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { TableSort } from '@ceccli/design-system/src/components/molecules/Table/Table';
import { TerminalWithScenario } from '../../../models/Terminal';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { VehicleWithScenario } from '../../../models/Vehicle';

export const DeployedScenarioPage = () => {
    const apiService = new ApiService();
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl();
    const { type } = useParams();

    const columns: { [key: string]: Array<TableColumn> } = {
        vehicles: [
            { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
            { label: intl.formatMessage({ id: 'scenario.file' }), key: 'scenarioFile' },
            { label: intl.formatMessage({ id: 'scenario.version' }), key: 'scenarioVersion' },
            { label: intl.formatMessage({ id: 'deployed' }), key: 'deploymentDate' },
        ],
        terminals: [
            { label: intl.formatMessage({ id: 'type' }), key: 'type' },
            { label: intl.formatMessage({ id: 'terminal.index' }), key: 'index' },
            { label: intl.formatMessage({ id: 'name' }), key: 'name' },
            { label: intl.formatMessage({ id: 'scenario.file' }), key: 'scenarioFile' },
            { label: intl.formatMessage({ id: 'scenario.version' }), key: 'scenarioVersion' },
            { label: intl.formatMessage({ id: 'deployed' }), key: 'deploymentDate' },
            { label: intl.formatMessage({ id: 'diffused' }), key: 'diffusionDate' },
        ]
    };

    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const availableTypes = showTerminals ? [ CommonPageType.Vehicles, CommonPageType.Terminals ] : [ CommonPageType.Vehicles ];

    const getFunction = (pageIndex?: number, pageSize?: number, sorting?: TableSort) => (type === CommonPageType.Terminals ?
        apiService.getTerminalsWithScenario(pageIndex, pageSize, sorting) :
        apiService.getVehiclesWithScenario(pageIndex, pageSize, sorting));

    const navigateToType = (key?: string) => {
        navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/${key}`);
    };

    useEffect(() => {
        if (!type || !availableTypes.includes(type as CommonPageType)) {
            navigateToType(availableTypes[0]);
        }
    }, [ type ]);

    return (
        <PageFillUnderHeader
            content={
                <Table
                    title={
                        <div className="text-center">
                            <div className="title">{intl.formatMessage({ id: 'deployed.scenario.title' }, { value: intl.formatMessage({ id: type }) })}</div>
                            {
                                availableTypes.length > 1 &&
                                <Button
                                    text={intl.formatMessage({ id: 'switch.to' }, { value: intl.formatMessage({ id: availableTypes.find(t => t !== type) }) })}
                                    onClick={() => navigateToType(availableTypes.find(t => t !== type))}
                                />
                            }
                        </div>
                    }
                    columns={type ? columns[type] : []}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => getFunction(pageIndex, pageSize, sorting).then(res => res),
                        formatData: (data: TerminalWithScenario | VehicleWithScenario) => {
                            return ({
                                ...data,
                                deploymentDate: data.deploymentDate ? splitDateTime(intl, (data as any)?.deploymentDate) : undefined,
                                diffusionDate: data.diffusionDate ? splitDateTime(intl, (data as any)?.diffusionDate) : undefined,
                            });
                        },
                        refreshTrigger: [ type ],
                    }}
                    allSortable
                    options={{ export: true }}
                />
            }
        />
    );
};
