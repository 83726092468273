import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToastModel } from '@ceccli/design-system';

export interface ToastState {
    toasts: Array<ToastModel>;
}

export const initialState: ToastState = {
    toasts: [],
};

export const toastStore = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToasts: (state, action: PayloadAction<Array<ToastModel>>) => {
            state.toasts = action.payload;
        },
        pushToast: (state, action: PayloadAction<ToastModel>) => {
            state.toasts.push(action.payload);
        },
        deleteToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(e => e.id !== action.payload);
        },
    },
});

export const { setToasts, pushToast, deleteToast } = toastStore.actions;

export default toastStore.reducer;
