import axios, { } from 'axios';
import jwt from 'jwt-decode';
import { User } from '../models/User';
import { logoutUser } from '../app/userStore';
import store from '../app/store';

export class SecurityManager {
    // Intercept requests to add HEADER
    secureRequest = () => {
        axios.interceptors.request.use(conf => {
            const token = localStorage.getItem('token');
            if (token?.length && token.length > 0 && conf.headers) {
                const tokenInfo = this.decodeToken();
                if (new Date(tokenInfo.exp * 1000) < new Date()) {
                    store.dispatch(logoutUser());
                }
                (conf.headers as any)['x-access-token'] = token;
            }
            return conf;
        });
        return axios;
    };

    decodeToken = (): { exp: number, iat: number, user: User } => {
        const token = localStorage.getItem('token');
        const tokenInfo = token && token.length > 0 ? jwt(token) : undefined;
        return (tokenInfo as { exp: number, iat: number, user: User });
    };
}


