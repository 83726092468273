import './TextArea.scss';

import React from 'react';

export interface TextAreaProps {
    label?: string
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    className?: string;
    name?: string;
    onChange?: (value: string, name?: string) => void;
}

export const TextArea: React.FunctionComponent<TextAreaProps> = (props: TextAreaProps) => {
    return (
        <div className={`textarea-container ${props.className ?? ''}`}>
            { props.label && <div className="label">{props.label}</div> }
            <textarea
                name={props.name}
                value={props.value}
                className="me-2"
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e.currentTarget.value, e.currentTarget.name);
                    }
                }}
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
        </div>
    );
};

