export interface Journey {
    id: string,
    startStop: string,
    endStop: string,
    startTime: number,
    endTime: number,
    mode: JourneyMode,
    lineCode: string,
}

export enum JourneyMode {
    HLP = 1,
}
