import { CartographyKeys, CartographyObject } from './CartographyObject';

import { Icon } from 'ol/style';
import PortableIcon from '../../assets/portable.png';

export class PortableCartography extends CartographyObject {
    radioAddress: number;
    time: string;

    constructor(id: number, label: string, position: [number, number], radioAddress: number, time: string) {
        super(id, CartographyKeys.Portable, label, position, PortableCartography.icons[0]);
        this.radioAddress = radioAddress;
        this.time = time;
    }

    static icons = [
        new Icon({
            anchor: [ 0.5, 1 ],
            src: PortableIcon,
            scale: 0.1,
        }),
    ];
}

export interface PortableCartographyInit {
    id: number,
    label: string,
    radioAddress: number,
}
