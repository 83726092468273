import { Scenario } from './Scenario';
import { SoftwareInfo } from './VehicleSoftware';

export class TerminalSoftware {
    constructor(
        public tft: SoftwareInfo,
        public rt: SoftwareInfo,
        public lastConnection: string
    ) {}
}

export enum TerminalDisplayType {
    PANEL = 'PAN',
    SATELLITE = 'SAT',
}

export enum TerminalDBType {
    PAN = 'BRN',
    SAT = 'SAT'
}

export interface Terminal {
    id: number;
    type: TerminalDisplayType;
    name: string;
    index: number;
}

export interface TerminalWithScenario extends Terminal, Scenario {}

export interface TerminalWithSoftware extends Terminal {
    calculators?: TerminalSoftware,
}

export interface TerminalWithCycle extends Terminal {
    file?: boolean;
    scenario?: boolean;
    schedule?: boolean;
    ack?: boolean;
    sectorIndex?: number;
}
