import { Message, MessageProgram, MessageTargetType } from '../models/Message';
import { Mission, MissionJourney, MissionServices } from '../models/Mission';
import { PaginatedResult, Result } from '../common/interface';
import { SaphirParameter, SaphirParameterKeys } from '../models/SaphirParameter';
import { Services, ServicesType } from '../models/Services';
import { sortArrayNumberAndString, TableColumn, TableSort } from '@ceccli/design-system';
import { sqlTypes, TableFilter } from '@ceccli/design-system/src/components/molecules/TableFilters/TableFilters';
import { Terminal, TerminalWithCycle, TerminalWithScenario, TerminalWithSoftware } from '../models/Terminal';
import { Vehicle, VehicleWithCycleApi, VehicleWithInformationBank, VehicleWithScenario } from '../models/Vehicle';

import { Agent } from '../models/Agent';
import { Application } from '../models/Application';
import { AssignmentHistory } from '../models/AssignmentHistory';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { Calculator } from '../models/Calculator';
import { CommonPageType } from '../common/enum';
import { Communication } from '../models/Communication';
import { ConnectionHistory } from '../models/ConnectionHistory';
import { EmbeddedSoftware } from '../models/EmbeddedSoftware';
import { Equipement } from '../models/Equipement';
import { Hazard } from '../models/Hazard';
import { InformationBank } from '../models/InformationBank';
import { InterventionParameter } from '../models/InterventionParameter';
import { InterventionVehicle } from '../models/InterventionVehicle';
import { IntlShape } from 'react-intl';
import { Line } from '../models/Line';
import { PassingHistory } from '../models/PassingHistory';
import { PlatformFunction } from '../models/PlatformFunction';
import { Portable } from '../models/Portable';
import { Sector } from '../models/Sector';
import { SecurityManager } from '../security/security';
import { SoftwareDeployment } from '../models/SoftwareDeployment';
import { SoftwareDeploymentTerminal } from '../models/SoftwareDeploymentTerminal';
import { Stop } from '../models/Stop';
import { StopCartography } from '../models/Cartography/StopCartography';
import { TechnicalWarning } from '../models/TechnicalWarning';
import { TerminalCartography } from '../models/Cartography/TerminalCartography';
import { TerminalParameter } from '../models/TerminalParameter';
import { User } from '../models/User';
import { VehicleHardware } from '../models/VehicleSoftware';
import { Version } from '../models/Version';

const apiUrl = process.env.REACT_APP_API_URL;
const security = new SecurityManager();
const axios = security.secureRequest();
export class ApiService {
    // EMBEDDED SOFTWARES
    getEmbeddedSoftwares = (): Promise<Array<EmbeddedSoftware>> => {
        return axios.get(`${apiUrl}/embedded-software`).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => new EmbeddedSoftware(item.TypeLogiciel, item.AttTypeCalculateur, item.NumeroVersion, item.NomFichier, item.TailleFichier));
        });
    };

    getEmbeddedSoftwareDeployement = (): Promise<Array<EmbeddedSoftware>> => {
        return axios.get(`${apiUrl}/embedded-software`).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => new EmbeddedSoftware(item.TypeLogiciel, item.AttTypeCalculateur, item.NumeroVersion, item.NomFichier, item.TailleFichier));
        });
    };

    isEmbeddedSoftwareDeployed = (type: string, typeCalculator: number, version: number) => {
        return axios.get(`${apiUrl}/embedded-software/is-deployed`, { params: { type, typeCalculator, version } }).then(res => res.data);
    };

    isEmbeddedSoftwareDeploymentProgrammed = (type: string, typeCalculator: number, version: number) => {
        return axios.get(`${apiUrl}/embedded-software/is-deployment-programmed`, { params: { type, typeCalculator, version } }).then(res => res.data);
    };

    createEmbeddedSoftware = (data: FormData): Promise<EmbeddedSoftware> => {
        return axios.post(`${apiUrl}/embedded-software`, data).then((res: AxiosResponse<EmbeddedSoftware>) => {
            return res.data;
        });
    };

    deleteSoftware = (software: EmbeddedSoftware) => {
        return axios.delete(`${apiUrl}/embedded-software/${software.typeSoftware}/${software.version}/${software.typeCalculator}/${software.fileName}`);
    };

    // VEHICLES
    getVehicles = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<Vehicle>> => {
        return axios.get(`${apiUrl}/vehicle`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getVehicleParkId = (id: number): Promise<string> => {
        return axios.get(`${apiUrl}/vehicle/parkId/${id}`).then(res => res.data);
    };

    getAllVehicles = (sorting?: TableSort, calculatorType?: {typeCalculator: number, typeSoftware: string}, time?: number): Promise<Array<Vehicle>> => {
        return axios.get(`${apiUrl}/vehicle/all`, { params: { sorting, calculatorType } }).then(res => {
            return sortArrayNumberAndString(res.data as Array<Vehicle>, 'parkId');
        });
    };

    getVehiclesWithSoftware = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<VehicleHardware>> => {
        return axios.get(`${apiUrl}/vehicle/software`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getVehiclesWithInformationBank = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<VehicleWithInformationBank>> => {
        return axios.get(`${apiUrl}/vehicle/information-bank`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getVehiclesWithDeployedTable = (pageIndex?: number, pageSize?: number, sorting?: TableSort, dateKey?: string): Promise<PaginatedResult<any>> => {
        return axios.get(`${apiUrl}/vehicle/deployed-table`, { params: { pageIndex, pageSize, sorting, dateKey } }).then(res => res.data);
    };

    getVehiclesWithCycle = (): Promise<Array<VehicleWithCycleApi>> => {
        return axios.get(`${apiUrl}/vehicle/cycle`).then(res => res.data);
    };

    getVehiclesWithScenario = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<VehicleWithScenario>> => {
        return axios.get(`${apiUrl}/vehicle/scenario`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    // INTERVENTION VEHICLES
    getInterventionVehicles = (pageIndex?: number, pageSize?: number, sorting?: TableSort, parkId?: string): Promise<PaginatedResult<InterventionVehicle>> => {
        return axios.get(`${apiUrl}/intervention-vehicle`, { params: { pageIndex, pageSize, sorting, parkId } }).then(res => res.data);
    };

    getInterventionParkIds = (): Promise<Array<string>> => {
        return axios.get(`${apiUrl}/intervention-vehicle/parkId`).then(res => res.data);
    };

    // TERMINALS
    getTerminals = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<Terminal>> => {
        return axios.get(`${apiUrl}/terminal`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getTerminal = (id: number): Promise<Terminal> => {
        return axios.get(`${apiUrl}/terminal/${id}`).then(res => res.data);
    };

    getAllTerminals = (sorting?: TableSort): Promise<Array<Terminal>> => {
        return axios.get(`${apiUrl}/terminal/all`, { params: { sorting } }).then(res => res.data);
    };

    getTerminalsWithSoftware = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<TerminalWithSoftware>> => {
        return axios.get(`${apiUrl}/terminal/software`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getTerminalsWithScenario = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<TerminalWithScenario>> => {
        return axios.get(`${apiUrl}/terminal/scenario`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getTerminalsCartography = (projection?: number) => {
        return axios.get(`${apiUrl}/terminal/cartography`, { params: { projection } }).then(res => res.data.map((item: any) => new TerminalCartography(item.id, item.name, item.position)));
    };

    getTerminalsWithCycle = (
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        time?: number,
        sector?: string,
        line?: string,
        labelFilter?: string
    ): Promise<PaginatedResult<TerminalWithCycle>> => {
        return axios.get(`${apiUrl}/terminal/cycle`, { params: { pageIndex, pageSize, sorting, time, sector, line, labelFilter } }).then(res => res.data);
    };

    getTerminalsLines = (time?: number): Promise<{[terminalId: number]: Array<Line>}> => {
        return axios.get(`${apiUrl}/terminal/lines`, { params: { time } }).then(res => res.data);
    };

    // TERMINALS PARAMETERS
    getTerminalParams = (pageIndex?: number, pageSize?: number, sorting?: TableSort, terminalId?: number): Promise<PaginatedResult<TerminalParameter>> => {
        return axios.get(`${apiUrl}/terminal-param`, { params: { pageIndex, pageSize, sorting, terminalId } }).then(res => res.data);
    };

    updateTerminalParams = (params: {[ param: string]: string | number }, terminalId?: number,) => {
        return axios.patch(`${apiUrl}/terminal-param`, { params, terminalId }).then(res => res.status);
    };

    // TERMINAL MESSAGES
    getTerminalMessages = (
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        targetId?: number,
        targetType?: MessageTargetType,
        startDate?: Date,
        endDate?: Date,
    ): Promise<PaginatedResult<Message>> => {
        return axios.get(`${apiUrl}/terminal-messages`, { params: { pageIndex, pageSize, sorting, targetId, targetType, startDate, endDate } }).then(res => res.data);
    };

    getTerminalMessagesProgram = (
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        startDate?: Date,
        endDate?: Date,
    ): Promise<PaginatedResult<MessageProgram>> => {
        return axios.get(`${apiUrl}/terminal-messages-program`, { params: { pageIndex, pageSize, sorting, startDate, endDate } }).then(res => res.data);
    };

    // EXPORT
    getTableFromBase = (baseName: string, anonymize?: boolean) => {
        return axios.get(`${apiUrl}/export/tables`, { params: { baseName, anonymize } }).then(res => {
            return res.data;
        });
    };

    getTableDescription = (baseName: string, tableName: string): Promise<Array<TableColumn>> => {
        return axios.get(`${apiUrl}/export/table-description`, { params: { baseName, tableName } }).then(res => {
            return res.data.map((item: any) => ({ label: item.Field, key: item.Field, dbKey: item.Field, type: item.Type.split('(')[0] as sqlTypes }));
        });
    };

    getTableContent = (
        baseName: string,
        tableName: string,
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        filters?: { [colKey: string]: TableFilter | undefined },
        intl?: IntlShape,
    ): Promise<PaginatedResult<any>> => {
        return axios.get(`${apiUrl}/export/table-content`, { params: { pageIndex, pageSize, baseName, tableName, sorting, filters } }).then(res => {
            const datePattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

            return ({
                ...res.data,
                results: res.data.results.map((item: any) => {
                    const obj = { ...item };
                    if (intl) {
                        for (const k of Object.keys(obj)) {
                            if (typeof obj[k] === 'string' && datePattern.test(obj[k]) && intl) {
                                obj[k] = intl.formatDate(obj[k]);
                            }
                        }
                    }
                    return obj;
                })
            });
        });
    };

    // SOFTWARE DEPLOYMENTS
    getSofwareDeployments = () :Promise<Array<SoftwareDeployment>> => {
        return axios.get(`${apiUrl}/softwareDeployment`).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => new SoftwareDeployment(item.CleIdt, item.TypeLogiciel, item.NumeroVersion, item.AttTypeCalculateur, item.DateDeploiement, item.DateChargement));
        });
    };
    getSofwareDeploymentTerminals = () :Promise<Array<SoftwareDeploymentTerminal>> => {
        return axios.get(`${apiUrl}/softwareDeploymentTerminal`).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => new SoftwareDeploymentTerminal(item.CleIdt, item.TypeBorne, item.TypeLogiciel, item.NumeroVersion, item.AttTypeCalculateur, item.DateDeploiement, item.DateChargement));
        });
    };

    createSoftwareDeployment = (soft: Array<any>): Promise<SoftwareDeployment> => {
        return axios.post(`${apiUrl}/softwareDeployment`, { params: { soft } }).then((res: AxiosResponse<Result>) => {
            return res.data?.results?.map((item: any) => new SoftwareDeployment(item.CleIdt, item.TypeLogiciel, item.NumeroVersion, item.AttTypeCalculateur, item.DateDeploiement, item.DateChargement));
        });
    };

    createSoftwareDeploymentTerminal = (soft: Array<any>): Promise<SoftwareDeploymentTerminal> => {
        return axios.post(`${apiUrl}/softwareDeploymentTerminal`, { params: { soft } }).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => new SoftwareDeploymentTerminal(item.CleIdt, item.TypeBorne, item.TypeLogiciel, item.NumeroVersion, item.AttTypeCalculateur, item.DateDeploiement, item.DateChargement));
        });
    };

    deleteSoftwareDeployment = (software: SoftwareDeployment) => {
        return axios.delete(`${apiUrl}/softwareDeployment/${software.id}/${software.softwareType}/${software.version}/${software.calculator}`);
    };

    deleteSoftwareDeploymentTerminal = (software: SoftwareDeploymentTerminal) => {
        return axios.delete(`${apiUrl}/softwareDeploymentTerminal/${software.id}/${software.displayType}/${software.softwareType}/${software.version}/${software.calculator}`);
    };

    // USER
    getUsers = (
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        userId?: number,
        userName?: string,
        password?: string
    ): Promise<PaginatedResult<User>> => {
        return axios.get(`${apiUrl}/user`, { params: { pageIndex, pageSize, sorting, userId, userName, password } }).then(res => res.data);
    };

    getUserByAttribute = (id?: string, userName?: string): Promise<User | undefined> => {
        return axios.get(`${apiUrl}/user/by-attribute`, { params: { id, userName } }).then(res => res.data);
    };

    createUser = (user: User, password: string) => {
        return axios.post(`${apiUrl}/user`, { params: { user, password } }).then(res => res.status);
    };

    updateUser = (user: User, password?: string) => {
        return axios.patch(`${apiUrl}/user`, { params: { user, password } }).then(res => res.status);
    };

    deleteUser = (userId: number) => {
        return axios.delete(`${apiUrl}/user/${userId}`);
    };

    login = (username: string, password: string) => {
        return axios.post(`${apiUrl}/user/login`, { username, password }).then((res: AxiosResponse<User>) => {
            return res.data;
        });
    };

    // PLATFORM FUNCTIONS
    getAllPlatformFunctions = (): Promise<Array<PlatformFunction>> => {
        return axios.get(`${apiUrl}/platform-function`).then(res => res.data);
    };

    getVersion = (identifier: string): Promise<Version> => {
        return axios.get(`${apiUrl}/version/${identifier}`).then(res => res.data);
    };

    getUnloadingInfo = (pageIndex?: number, pageSize?: number, sorting?: TableSort, date?: Date, vehicleId?: number, fileType?: string) => {
        return axios.get(`${apiUrl}/unloading`, { params: { pageIndex, pageSize, sorting, date, vehicleId, fileType } }).then(res => res.data);
    };

    getAssignmentHistory = (
        pageIndex?: number,
        pageSize?: number,
        sorting?: TableSort,
        date?: Date,
        vehicleId?: Array<number>,
        serviceAgent?: Array<string>,
        serviceVehicle?: Array<string>,
        lineCode?: Array<string>,
        anonymize?: boolean,
    ): Promise<PaginatedResult<AssignmentHistory>> => {
        return axios.get(`${apiUrl}/assignment-history`, { params: { pageIndex, pageSize, sorting, date, vehicleId, serviceAgent, serviceVehicle, lineCode, anonymize } }).then(res => res.data);
    };

    getVehicleLastAssignment = (id: number): Promise<AssignmentHistory> => {
        return axios.get(`${apiUrl}/assignment-history/last-assignment/${id}`).then(res => res.data);
    };

    getServices = (type: ServicesType): Promise<Array<Services>> => {
        return axios.get(`${apiUrl}/services/${type}`).then(res => res.data);
    };

    // LINES
    getLines = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<Line>> => {
        return axios.get(`${apiUrl}/line`, { params: { pageIndex, pageSize, sorting } }).then(res => {
            return {
                ...res.data,
                results: sortArrayNumberAndString(res.data.results as Array<Line>, 'code'),
            };
        });
    };

    getAllLines = (): Promise<Array<Line>> => {
        return axios.get(`${apiUrl}/line/all`).then(res => res.data);
    };

    getLine = (lineId: number): Promise<Line | undefined> => {
        return axios.get(`${apiUrl}/line/${lineId}`).then(res => res.data);
    };

    getLinePaths = (lineId: number): Promise<Array<number>> => {
        return axios.get(`${apiUrl}/line/paths/${lineId}`).then(res => res.data);
    };

    getPathPoints = (pathId: number): Promise<Array<any>> => {
        return axios.get(`${apiUrl}/line/paths/points/${pathId}`).then(res => res.data);
    };

    // APPLICATIONS
    getApplications = (): Promise<Array<Application>> => {
        return axios.get(`${apiUrl}/applications`).then(res => res.data);
    };

    // INTERVENTION PARAMS
    getInterventionParams = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<InterventionParameter>> => {
        return axios.get(`${apiUrl}/intervention-param`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    updateInterventionParam = (interventionParam: InterventionParameter) => {
        return axios.patch(`${apiUrl}/intervention-param`, { params: { interventionParam } }).then(res => {
            return res.status;
        });
    };

    // SAPHIR PARAMETERS
    getSaphirParams = (pageIndex?: number, pageSize?: number, sorting?: TableSort): Promise<PaginatedResult<SaphirParameter>> => {
        return axios.get(`${apiUrl}/saphir-param`, { params: { pageIndex, pageSize, sorting } }).then(res => res.data);
    };

    getSaphirParam = (key: SaphirParameterKeys): Promise<SaphirParameter> => {
        return axios.get(`${apiUrl}/saphir-param/${key}`).then(res => res.data);
    };

    // SAPHIR FUNCTIONS
    getSaphirFunction = (name: string) => {
        return axios.get(`${apiUrl}/saphir-function`, { params: { name } }).then(res => res.data);
    };

    updateSaphirParam = (saphirParameter: SaphirParameter) => {
        return axios.patch(`${apiUrl}/saphir-param`, { params: { saphirParameter } }).then(res => res.status);
    };

    // MISSIONS
    getMissionServices = (missionId: number): Promise<MissionServices> => {
        return axios.get(`${apiUrl}/missions/services/${missionId}`).then(res => res.data);
    };

    getMission = (missionId: number): Promise<Mission> => {
        return axios.get(`${apiUrl}/missions/${missionId}`).then(res => res.data);
    };

    getMissionsCourses = (time: number): Promise<Array<MissionJourney>> => {
        return axios.get(`${apiUrl}/mission-journey`, { params: { time } }).then(res => res.data);
    };

    getPassingHistory = (
        date?: Date,
        parkId?: string
    ): Promise<Array<PassingHistory>> => {
        return axios.get(`${apiUrl}/passing-history`, { params: { date, parkId } }).then(res => res.data);
    };

    // AGENTS
    getAllAgents = (time?: number, anonymize?: boolean): Promise<Array<Agent>> => {
        return axios.get(`${apiUrl}/agent`, { params: { time, anonymize } }).then(res => res.data);
    };

    // PORTABLES
    getAllPortables = (): Promise<Array<Portable>> => {
        return axios.get(`${apiUrl}/portable`).then(res => res.data);
    };

    getPortable = (id: number): Promise<Portable> => {
        return axios.get(`${apiUrl}/portable/${id}`).then(res => res.data);
    };

    // TECHNICAL WARNINGS
    getTechnicalWarnings = (pageIndex?: number, pageSize?: number, sorting?: TableSort, date?: Date, vehicleId?: number): Promise<PaginatedResult<TechnicalWarning>> => {
        return axios.get(`${apiUrl}/technical-warning`, { params: { pageIndex, pageSize, sorting, date, vehicleId } }).then(res => res.data);
    };

    // INFORMATION BANK
    getInformationBank = (vehicleId: number): Promise<InformationBank> => {
        return axios.get(`${apiUrl}/information-bank/${vehicleId}`).then(res => res.data);
    };

    // COMMUNICATIONS
    getCommunications = (type: CommonPageType, pageIndex?: number, pageSize?: number, sorting?: TableSort, date?: Date, vehicleId?: number): Promise<PaginatedResult<Communication>> => {
        return axios.get(`${apiUrl}/communication`, { params: { type, pageIndex, pageSize, sorting, date, vehicleId } }).then(res => res.data);
    };

    // CONNECTION HISTORY
    getConnectionHistory = (pageIndex?: number, pageSize?: number, sorting?: TableSort, parkId?: string): Promise<PaginatedResult<ConnectionHistory>> => {
        return axios.get(`${apiUrl}/connection-history`, { params: { pageIndex, pageSize, sorting, parkId } }).then(res => res.data);
    };

    // EQUIPMENTS
    getEquipements = (calculator?: number) :Promise<Array<Equipement>> => {
        return axios.get(`${apiUrl}/equipements/${calculator}`,).then((res: AxiosResponse<Result>) => {
            return res.data.results.map((item: any) => {
                return {
                    id: item.CleIdt,
                    CleDat: item.CleDat,
                    bodyId: 'NumeroSatellite' in item ? item.NumeroSatellite : item.NumeroPanneau,
                    typeOfTerminal: 'NumeroSatellite' in item ? 'SAT' : 'BRN',
                    calculator: item.AttTypeCalculateur
                };
            });
        });
    };

    // CALCULATORS
    getCalculators = (): Promise<Array<Calculator>> => {
        return axios.get(`${apiUrl}/calculator`).then(res => res.data);
    };

    // HAZARD
    getHazards = (pageIndex?: number, pageSize?: number, sorting?: TableSort, past?: boolean): Promise<PaginatedResult<Hazard>> => {
        return axios.get(`${apiUrl}/hazard`, { params: { pageIndex, pageSize, sorting, past } }).then(res => res.data);
    };

    getHazard = (applicationDate: string): Promise<Hazard> => {
        return axios.get(`${apiUrl}/hazard/${applicationDate}`).then(res => res.data);
    };

    deleteHazard = (applicationDate: string): Promise<void> => {
        return axios.delete(`${apiUrl}/hazard/${applicationDate}`).then(res => res.data);
    };

    createHazard = (hazard: Hazard): Promise<number> => {
        return axios.post(`${apiUrl}/hazard`, { params: { hazard } }).then(res => res.status);
    };

    updateHazard = (hazard: Hazard): Promise<number> => {
        return axios.patch(`${apiUrl}/hazard`, { params: { hazard } }).then(res => res.status);
    };

    // STOP
    getStops = (projection: number): Promise<Array<StopCartography>> => {
        return axios.get(`${apiUrl}/stop`, { params: { projection } }).then(res => res.data.map((s: Stop) => new StopCartography(s.id, s.label, s.position, s.positionLambert)));
    };

    // SECTOR
    getSectors = (time?: number): Promise<Array<Sector>> => {
        return axios.get(`${apiUrl}/sector`, { params: { time } }).then(res => res.data);
    };
}
