import 'ol/ol.css';
import './CartographyPage.scss';

import { Button, Checkbox, CheckElementOptionType, ExpandPanel, Select } from '@ceccli/design-system';
import { CartographyKeys, CartographyObject } from '../../../models/Cartography/CartographyObject';
import { localStorageKey, LocalStorageService } from '../../../services/localStorage.service';
import Map, { MapHandle } from '../../Map/Map';
import { setAgents, setStops } from '../../../app/drawableDataStore';
import { Style, Text } from 'ol/style';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { VehicleCartography, vehicleIconSize } from '../../../models/Cartography/VehicleCartography';

import { Agent } from '../../../models/Agent';
import { ApiService } from '../../../services/apiService';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import Layers from '../../Map/Layers/Layers';
import OverlayCard from '../../Map/OverlayCard/OverlayCard';
import OverlayLayer from '../../Map/Layers/OverlayLayer';
import { PortableCartography } from '../../../models/Cartography/PortableCartography';
import PortableIcon from '../../../assets/portable.png';
import { RootState } from '../../../app/store';
import { StopCartography } from '../../../models/Cartography/StopCartography';
import StopIcon from '../../../assets/flag.svg';
import { TerminalCartography } from '../../../models/Cartography/TerminalCartography';
import TerminalIcon from '../../../assets/terminal.png';
import TileLayer from '../../Map/Layers/TileLayer';
import TileSource from 'ol/source/Tile';
import { useIntl } from 'react-intl';
import { UtilsService } from '../../../services/utilsService';
import VectorLayer from '../../Map/Layers/VectorLayer';
import VectorSource from 'ol/source/Vector';
import VehicleIcon from '../../../assets/automobile.svg';
import XYZ from 'ol/source/XYZ';

interface CartographyFilter {
    options: Array<ExtendedOptions>,
    label?: string;
    emptyParam?: string;
    getValue?: (options: Array<CheckElementOptionType>) => Array<CheckElementOptionType>;
    getHide?: (lines: Array<number>) => number[];
}

export interface ExtendedOptions extends CheckElementOptionType {
    agentId?: number,
    lineId?: number,
}

const icons = {
    [CartographyKeys.Stop]: StopIcon,
    [CartographyKeys.Vehicle]: VehicleIcon,
    [CartographyKeys.Portable]: PortableIcon,
    [CartographyKeys.Terminal]: TerminalIcon,
};

const apiService = new ApiService();
const utilsService = new UtilsService();

export const CartographyPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const mapData = useSelector((state: RootState) => state.mapData);
    const allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);
    const allStops = useSelector<RootState, Array<StopCartography>>(state => state.drawableData.stops);
    const allPortables = useSelector<RootState, Array<PortableCartography>>(state => state.drawableData.portables);
    const allAgents = useSelector<RootState, Array<Agent>>(state => state.drawableData.agents);
    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const [ allTerminal, setAllTerminal ] = useState<Array<TerminalCartography>>([]);
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);

    /*
     * TODO: Improve this when handle env management
     * const [ tileSource ] = useState<TileSource>(new OlSourceOSM());
     * const [ tileSource ] = useState<TileSource>(new XYZ({ url: `${process.env.REACT_APP_OSM_SERVER_TEST}/{z}/{x}/{y}.png` }));
     */
    const [ tileSource ] = useState<TileSource>(new XYZ({ url: `${process.env.REACT_APP_OSM_SERVER}/{z}/{x}/{y}.png` }));
    const [ hover, setHover ] = useState<string>();
    const mapRef = useRef<MapHandle>(null);

    const overlayRef = useRef<HTMLDivElement>(null);
    const [ overlayFeature, setOverlayFeature ] = useState<FeatureLike>();
    const [ overlayPosition, setOverlayPosition ] = useState<[number, number]>();

    const [ hiddenVehicles, setHiddenVehicles ] = useState<Array<number>>([]);
    const [ hiddenStops, setHiddenStops ] = useState<Array<number>>([]);
    const [ hiddenPortables, setHiddenPortables ] = useState<Array<number>>([]);
    const [ hiddenTerminals, setHiddenTerminals ] = useState<Array<number>>([]);
    const [ hideUnassigned, setHideUnassigned ] = useState<boolean>(false);

    const maxVehicleId = Math.max(...allVehicles.map(v => v.id));
    const displayedVehicles = allVehicles.filter(v => !hideUnassigned || v.missionNumber);

    const displayedElements = [
        {
            elements: displayedVehicles,
            label: intl.formatMessage({ id: 'vehicles' }),
            hidden: hiddenVehicles,
            setHidden: setHiddenVehicles,
            icon: VehicleIcon,
            filters: [
                {
                    label: intl.formatMessage({ id: 'vehicles' }),
                    options: displayedVehicles.map(v => ({ label: v.bodyId, value: v.id.toString(), color: v.line?.color, background: v.line?.background, agentId: v.agentId, lineId: v.lineId })),
                },
                {
                    label: intl.formatMessage({ id: 'agents' }),
                    options: allAgents.map(a => {
                        const vehicle = displayedVehicles.find(v => v.agentId === a.id);
                        return ({
                            label: `${a.code}${authorization ? `- ${a?.lastName} ${a?.firstName}` : ''}`,
                            value: vehicle?.id.toString(),
                            color: vehicle?.line?.color,
                            background: vehicle?.line?.background
                        });
                    }).filter(a => a.value),
                    emptyParam: 'agentId',
                },
                {
                    label: intl.formatMessage({ id: 'line' }),
                    options: utilsService.getUniqueByAttribute(
                        displayedVehicles.filter(v => v.line?.code).map(v => ({ label: v.line?.code, value: v.line?.id.toString(), color: v.line?.color, background: v.line?.background })),
                        'value'
                    ),
                    getValue: (options: Array<CheckElementOptionType>) => {
                        const displayedLines = displayedVehicles.filter(v => v.line?.code && !hiddenVehicles.includes(v.id)).map(v => v.line?.id);
                        return options.filter(o => displayedLines.includes(Number(o.value)));
                    },
                    getHide: (lines: Array<number>) => displayedVehicles.filter(v => !v.line?.id || !lines.includes(v.line.id)).map(v => v.id),
                    emptyParam: 'lineId',
                }
            ] as Array<CartographyFilter>,
            checkbox: {
                label: intl.formatMessage({ id: 'hide.unassigned.vehicles' }),
                checked: hideUnassigned,
                onChange: setHideUnassigned
            },
            key: CartographyKeys.Vehicle,
        },
        {
            elements: allStops,
            label: intl.formatMessage({ id: 'stops' }),
            hidden: hiddenStops,
            setHidden: setHiddenStops,
            icon: StopIcon,
            filters: [{
                options: allStops.map(s => ({ label: s.label, value: s.id.toString() }))
            }],
            key: CartographyKeys.Stop,
        },
        {
            elements: allPortables,
            label: intl.formatMessage({ id: 'portables' }),
            hidden: hiddenPortables,
            setHidden: setHiddenPortables,
            icon: PortableIcon,
            filters: [{
                options: allPortables.map(p => ({ label: p.label, value: p.id.toString() }))
            }],
            key: CartographyKeys.Portable,
        },
    ];

    if (showTerminals) {
        displayedElements.push(
            {
                elements: allTerminal,
                label: intl.formatMessage({ id: 'terminals' }),
                hidden: hiddenTerminals,
                setHidden: setHiddenTerminals,
                icon: TerminalIcon,
                filters: [{
                    options: allTerminal.map(t => ({ label: t.label, value: t.id.toString() }))
                }],
                key: CartographyKeys.Terminal,
            }
        );
    }

    useEffect(() => {
        if (!allAgents.length) {
            apiService.getAllAgents(undefined, !authorization).then(res => dispatch(setAgents(res)));
        }
    }, []);

    useEffect(() => {
        if (mapData.projection) {
            if (!allStops.length) {
                apiService.getStops(mapData.projection).then(data => dispatch(setStops(data)));
            }

            if (showTerminals) {
                apiService.getTerminalsCartography(mapData.projection).then(data => setAllTerminal(data));
            }
        }
    }, [ mapData.projection ]);

    const getFeatures = (datas: Array<StopCartography | VehicleCartography | PortableCartography | TerminalCartography>) => {
        return datas.map(data => new Feature(data));
    };

    const styleFunction = (feature: FeatureLike) => {
        const styles = [];
        const hovered = hover && hover === feature.get('key');
        const bodyId = feature.get('bodyId');
        const zIndex = hovered ?
            (maxVehicleId + 1) :
            bodyId ?
                feature.get('id') :
                undefined;

        const mapAngle = mapRef.current?.getRotation() ?? 0;
        const angle = feature.get('icon').rotation_ + mapAngle;
        const length = (vehicleIconSize.width * vehicleIconSize.scale) / 2;
        const offsetX = length * Math.cos(angle);
        const offsetY = length * Math.sin(angle);
        const textGap = 6;

        styles.push(new Style({
            image: feature.get('icon'),
            text: bodyId ? new Text({
                text: bodyId.toString(),
                offsetX,
                offsetY: offsetY - textGap,
            }) : undefined,
            zIndex,
        }));

        let text = '';
        let scale = 1;
        if (mapData.switchCartography?.text) {
            const delayFull = utilsService.formatDelay(feature.get('advanceDelay'));
            text = `${delayFull[0]}${delayFull.slice(4, 6)}'${delayFull.slice(7, delayFull.length)}`;
            scale = 0.8;
        } else {
            let missionNumber = feature.get('missionNumber');
            if (missionNumber && missionNumber.length > 5) {
                missionNumber = `${missionNumber.slice(0, 3)}..`;
            }
            text = missionNumber;
        }
        if (bodyId) {
            styles.push(new Style({
                text: new Text({
                    text: (text || '?').toString(),
                    offsetX,
                    offsetY: offsetY + textGap,
                    scale,
                }),
                zIndex,
            }));
        }

        return styles;
    };

    const updators = {
        [CartographyKeys.Stop]: (payload: Array<StopCartography>) => dispatch(setStops(payload)),
        [CartographyKeys.Vehicle]: undefined,
        [CartographyKeys.Portable]: undefined,
        [CartographyKeys.Terminal]: undefined,
    };

    const models = {
        [CartographyKeys.Stop]: StopCartography,
        [CartographyKeys.Vehicle]: VehicleCartography,
        [CartographyKeys.Portable]: PortableCartography,
        [CartographyKeys.Terminal]: TerminalCartography,
    };

    const [ iconSelector, setIconSelector ] = useState<CartographyKeys>();
    const [ selectedIcons, setSelectedIcons ] = useState<{[key: string]: number}>(LocalStorageService.getLocalStorage(localStorageKey.cartographyIcons));

    useEffect(() => {
        localStorage.setItem(localStorageKey.cartographyIcons, JSON.stringify(selectedIcons))
    }, [ selectedIcons ]);

    const renderIconSelector = (key: CartographyKeys, label: string, elements: Array<CartographyObject>) => {
        const availableIcons = models[key].icons;
        const iconIndex = selectedIcons[key] ?? 0;
        const updateFct = updators[key];
        const canUpdate = availableIcons.length && updateFct;

        return (
            <>
                <img
                    className={`icon me-2 ${canUpdate ? 'cursor-pointer' : ''}`}
                    alt={label}
                    src={models[key].icons[iconIndex].getSrc()}
                    onClick={() => setIconSelector(key)}
                />
                <div>{label}</div>
                {
                    iconSelector === key && canUpdate &&
                    <div className="icon-selector position-absolute d-flex p-2 top-100">
                        {
                            availableIcons.map((icon, index) => (
                                <div className={`icon-container ${selectedIcons[key] === index ? 'selected' : ''}`}>
                                    <img
                                        className="icon"
                                        src={icon.getSrc()}
                                        onClick={() => {
                                            setSelectedIcons({ ...selectedIcons, [key]: index });
                                            updateFct(elements.map(e => ({ ...e, icon: availableIcons[index] })));
                                            setIconSelector(undefined);
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                }
            </>
        );
    };

    return (
        <div className="cartography-page w-100 h-100">
            {
                mapData?.center && mapData?.minRange && mapData?.maxRange &&
                <Map
                    center={mapData.center}
                    minRange={mapData.minRange}
                    maxRange={mapData.maxRange}
                    zoom={mapData.onOpenZoom}
                    onHover={(feature) => {
                        setHover(feature?.get('key'));

                        let position;
                        if (feature) {
                            position = fromLonLat([ ...feature?.get('position') ].reverse()) as [number, number];
                        }
                        setOverlayFeature(feature);
                        setOverlayPosition(position);
                    }}
                    onClick={() => {}}
                    ref={mapRef}
                >
                    <Layers>
                        <TileLayer
                            source={tileSource}
                            zIndex={0}
                        />
                        <VectorLayer
                            source={new VectorSource({ features: getFeatures(displayedElements.map(item => [ ...item.elements ].filter(o => !item.hidden.includes(o.id))).flat()) })}
                            style={styleFunction}
                        />
                    </Layers>
                    <OverlayLayer
                        position={overlayPosition}
                        contentRef={overlayRef}
                        isVehicle={overlayFeature?.get('type') === CartographyKeys.Vehicle}
                    />
                    <OverlayCard
                        icons={icons}
                        feature={overlayFeature}
                        ref={overlayRef}
                    />
                </Map>
            }
            <ExpandPanel minPanelWidth={384}>
                <div className="h-100 p-4 overflow-auto" style={{ minWidth: '20vw' }}>
                    {
                        displayedElements.map((item, index) => {
                            return (
                                <div className="my-4" key={`item-${index}`}>
                                    <div className="d-flex justify-content-between position-relative">
                                        <div className="d-flex align-items-center me-2">
                                            { renderIconSelector(item.key, item.label, item.elements) }
                                        </div>
                                        <div className="d-flex gap-2 button-container align-items-center justify-content-end flex-wrap">
                                            {
                                                item.checkbox &&
                                                <Checkbox {...item.checkbox} />
                                            }
                                            <div className="d-flex gap-2">
                                                <Button
                                                    text={intl.formatMessage({ id: 'show.all' })}
                                                    onClick={() => item.setHidden([])}
                                                />
                                                <Button
                                                    text={intl.formatMessage({ id: 'hide.all' })}
                                                    onClick={() => item.setHidden(item.filters[0].options.map(o => Number(o.value)))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        item.filters.map((f: CartographyFilter, index) => {
                                            return (
                                                <div key={`filter-${index}`}>
                                                    <Select
                                                        label={f.label ? intl.formatMessage({ id: 'filter.by' }, { value: f.label.toLowerCase() }) : undefined}
                                                        value={f.getValue ?
                                                            f.getValue(f.options) :
                                                            f.options.filter(o => !item.hidden.includes(Number(o.value)))
                                                        }
                                                        options={f.options.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))}
                                                        isMulti
                                                        onChange={(e: Array<CheckElementOptionType>) => {
                                                            if (f.getHide) {
                                                                item.setHidden(f.getHide(e.map(v => Number(v.value))));
                                                            } else {
                                                                const nonEmptyList = (item.filters[0].options as Array<ExtendedOptions>).filter(o => !f.emptyParam || (o as any)[f.emptyParam]);
                                                                item.setHidden(nonEmptyList.map(o => Number(o.value)).filter(v => !e.map(v => v.value).includes(v.toString())));
                                                            }
                                                        }}
                                                        isDisabled={!f.options.length}
                                                        isClearable={false}
                                                        onClick={(val: string) => {
                                                            const element = item.elements.find(e => e.id === Number(val));
                                                            setHover(element?.key);
                                                            mapRef.current?.setView(element?.position, 18);
                                                        }}
                                                        className="mt-2"
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </ExpandPanel>
        </div>
    );
};
