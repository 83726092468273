import './OverlayCard.scss';

import { getDelayColor, VehicleCartography } from '../../../models/Cartography/VehicleCartography';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { Agent } from '../../../models/Agent';
import { CartographyKeys } from '../../../models/Cartography/CartographyObject';
import { DelayColors } from '../../../app/mapDataStore';
import { FeatureLike } from 'ol/Feature';
import { PortableCartography } from '../../../models/Cartography/PortableCartography';
import { RootState } from '../../../app/store';
import { StopCartography } from '../../../models/Cartography/StopCartography';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UtilsService } from '../../../services/utilsService';

export interface OverlayCardProps {
    icons: { [key in CartographyKeys]?: string }
    feature?: FeatureLike;
}

const OverlayCard = React.forwardRef((props: OverlayCardProps, ref: React.Ref<HTMLDivElement>) => {
    const intl = useIntl();
    const utilsService = new UtilsService();
    const allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);
    const allPortables = useSelector<RootState, Array<PortableCartography>>(state => state.drawableData.portables);
    const allStops = useSelector<RootState, Array<StopCartography>>(state => state.drawableData.stops);
    const allAgents = useSelector<RootState, Array<Agent>>(state => state.drawableData.agents);
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);
    const delayColors = useSelector<RootState, DelayColors | undefined>(state => state.mapData.delayColors);
    const emptySymbol = '-';

    const rtElements = useMemo(() => [ ...allVehicles, ...allPortables ], [ allVehicles, allPortables ]);
    const featureKey = props.feature?.get('key');
    const [ rtElement, setRtElement ] = useState<VehicleCartography | PortableCartography>();

    useEffect(() => {
        setRtElement(rtElements.find(e => e.key === featureKey));
    }, [ featureKey, rtElements ]);

    const renderContent = () => {
        if (!props.feature) {
            return null;
        }

        const obj = rtElement ?? { ...props.feature?.getProperties() };

        let content;
        switch (obj.type) {
            case CartographyKeys.Vehicle:
                content = renderVehicleInfo(obj as VehicleCartography);
                break;
            case CartographyKeys.Portable:
                content = renderPortableInfo(obj as PortableCartography);
                break;
            default:
                break;
        }

        return (
            <>
                <div className="d-flex">
                    <img alt={obj.type} src={props.icons[obj.type as CartographyKeys]} className="icon me-2" />
                    <div className="title">{ obj.label }</div>
                </div>
                { content }
            </>
        );
    };

    const renderVehicleInfo = (obj: VehicleCartography) => {
        const stopKeys = [ 'startStop', 'endStop', 'nextStop', 'previousStop' ];
        const [ startStop, endStop, nextStop, previousStop ] = stopKeys.map(k => allStops.find(s => s.id === obj[k as keyof typeof obj]));
        const agent = allAgents.find(a => a.id === obj.agentId);

        const renderInfoRow = (label: string, info?: string | number, indicator?: ReactNode) => {
            return (
                <>
                    <div className="label">{intl.formatMessage({ id: label })}</div>
                    <div className="d-flex ms-auto">
                        {indicator}
                        <div>{info ? info : emptySymbol}</div>
                    </div>
                </>
            );
        };

        const renderSeparatorRow = () => {
            return (
                <>
                    <div className="separator"></div>
                    <div></div>
                </>
            );
        };

        return (
            <div className="content">
                {
                    obj.line &&
                    <div className="d-flex">
                        <div className="px-2 me-1" style={{ background: obj.line?.background, color: obj.line?.color }}>
                            {obj.line.code}
                        </div>
                        <div>{obj.line?.label}</div>
                    </div>
                }
                <div className="content-grid">
                    { renderSeparatorRow() }
                    { renderInfoRow(
                        'agent',
                        agent ?
                            `${agent.code}${authorization ? `- ${agent?.lastName} ${agent?.firstName}` : ''}` :
                            undefined
                    )
                    }
                    { renderInfoRow('mission', obj.missionNumber) }
                    { renderSeparatorRow() }
                    { renderInfoRow('departure', startStop?.label) }
                    { renderInfoRow('destination', endStop?.label) }
                    { renderSeparatorRow() }
                    { renderInfoRow('stop.previous', previousStop?.label) }
                    { renderInfoRow('stop.next', nextStop?.label) }
                    { renderSeparatorRow() }
                    { renderInfoRow('last.info.time', obj.time) }
                    { renderInfoRow(
                        'earlier.late',
                        utilsService.formatDelay(obj.advanceDelay),
                        <div className="delay-indicator my-auto me-2" style={{ background: getDelayColor(obj, delayColors) }}/>
                    )}
                </div>
            </div>
        );
    };

    const renderPortableInfo = (obj: PortableCartography) => {
        return (
            <div className="content">
                <div>{intl.formatMessage({ id: 'last.info.time' })}: {obj.time}</div>
                <div>{intl.formatMessage({ id: 'radio' })}: : {obj.radioAddress}</div>
            </div>
        );
    };

    return (
        <div
            ref={ref}
            className={`overlay-card p-3 ${props.feature ? '' : 'd-none'}`}
        >
            { renderContent() }
        </div>
    );
});

export default OverlayCard;
