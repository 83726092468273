import './MenuBundle.scss';

import { ColorVariable, Icon, IconType } from '@ceccli/design-system';

import React from 'react';
import { RouteSettings } from '../../App';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export interface MenuBundleProps {
    options: Array<RouteSettings>;
    label?: string,
    icon?: IconType
}

export const MenuBundle: React.FunctionComponent<MenuBundleProps> = (props: MenuBundleProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const displayedOptions = props.options.filter(o => o.title).map(o => ({ ...o, title: intl.formatMessage({ id: o.title }) })).sort((a, b) => a.title.localeCompare(b.title));

    return (
        <div className="menu-bundle">
            <div className="d-flex justify-content-between header pb-2 pe-1 gap-4">
                <div className="title">{props.label?.toUpperCase()}</div>
                <Icon
                    iconType={props.icon}
                    color={ColorVariable.Primary}
                    size={23}
                />
            </div>
            <div className="d-flex flex-column gap-1 mt-2">
                {
                    displayedOptions.map(option => {
                        return (
                            option.title &&
                             <div
                                 key={option.path}
                                 className="d-flex cursor-pointer"
                                 onClick={() => navigate(option.path)}
                             >
                                 {option.title}
                             </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default MenuBundle;
