import './PhonyIndicator.scss';

import { ColorVariable, groupBy, groupValueBy, Icon, IconType, TableValueType } from '@ceccli/design-system';
import { Phony, PhonyFlag, PhonyPriority } from '../../models/Phony';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../app/store';
import { setOpenDraggable } from '../../app/phonyStore';

export const getRowBackground = (priority?: TableValueType, opacity: number = 90) => {
    switch (priority) {
        case PhonyPriority.Distress:
            return `${ColorVariable.PhonyDistress}${opacity}`;
        case PhonyPriority.Urgent:
            return `${ColorVariable.PhonyUrgent}${opacity}`;
        case PhonyPriority.Simple:
            return `${ColorVariable.PhonySimple}${opacity}`;
        default:
            return undefined;
    }
};

export interface PhonyIndicatorProps {
    size?: number;
    color?: string;
}

export const PhonyIndicator = (props: PhonyIndicatorProps) => {
    const dispatch = useDispatch();
    const phonyInfos = useSelector<RootState, {[key: number]: Phony}>(state => state.phony.phonyInfos);
    const isPhonyOpened = useSelector<RootState, boolean>(state => state.phony.openDraggable);
    const prioritiesCount = groupValueBy(
        groupBy(Object.values(phonyInfos).filter(p => p.flag !== PhonyFlag.Ended), 'priority'),
        'priority',
        'priority'
    );
    const maxPriority = Math.max(...Object.keys(prioritiesCount).map(Number));

    const size = props.size ?? 18;
    const chipOffset = 2 * size / 3;

    const renderCount = (priority: PhonyPriority) => {
        const currentCount = prioritiesCount[priority];
        if (!currentCount?.length) {
            return;
        }

        let offset = 0;
        for (let i = priority + 1; i <= maxPriority; i++) {
            if (prioritiesCount[i]?.length) {
                offset += chipOffset;
            }
        }
        return (
            <div
                className="phony-chip position-absolute fw-bold"
                style={{ left: offset }}
            >
                <div
                    className="d-flex w-100 h-100 justify-content-center align-items-center rounded-circle"
                    style={{ background: getRowBackground(priority), fontSize: size / 2 }}
                >
                    {currentCount.length}
                </div>
            </div>
        );
    };

    return (
        <div
            className="phony-indicator position-relative mb-3"
            onClick={() => dispatch(setOpenDraggable(!isPhonyOpened))}
            style={{ marginBottom: size * 0.75 }}
        >
            <div className="chip-container">
                { renderCount(PhonyPriority.Simple) }
                { renderCount(PhonyPriority.Urgent) }
                { renderCount(PhonyPriority.Distress) }
            </div>
            <Icon iconType={IconType.Phone} size={size} color={ColorVariable.Secondary} />
        </div>
    );
};
