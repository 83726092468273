export enum Database {
    Collect = 'SaphColl',
    Modelization = 'SaphModl',
    RealTime = 'SaphTmpR',
    Reference = 'SaphRefr',
}

export enum SoftwareType {
    BNT = 'BNT',
    BTD = 'BTD',
    NTR = 'NTR',
    PUP = 'PUP',
    TFT = 'TFT',
}

export enum EquipementType {
    VEH = 'VEH',
    PUP = 'PUP',
    BRN = 'BRN',
}

export enum SoftwareDeploymentColumn {
    EQUIPEMENT_NUMBER = 'equipementNumber',
    SOFTWARE_TYPE = 'softwareType',
    CALCULATOR = 'calculatorName',
    VERSION = 'version',
    DEPLOIMENT_DATE = 'deploymentDate',
}

export enum TerminalDisplay {
    SAT= '-SAT',
    PAN= '-PAN'
}

export enum CommonPageType {
    Terminals = 'terminals',
    Vehicles = 'vehicles',
}

export enum SocketInitKey {
    Cartography = '101',
    Phony = '103',
    Assignment = '104',
    PassengerInformation = '106',
    Cycle = '107',
}
