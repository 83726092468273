export class SoftwareDeploymentTerminal {
    constructor(
        public id: number,
        public displayType: string,
        public softwareType: string,
        public version: number,
        public calculator: number,
        public deploymentDate: Date,
        public loadingDate: Date| null
    ) {}
}
