import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DelayColors, SwitchCartography } from './mapDataStore';
import { generateVehicleIcon, VehicleCartography } from '../models/Cartography/VehicleCartography';

import { Agent } from '../models/Agent';
import { Line } from '../models/Line';
import { Mission } from '../models/Mission';
import { PortableCartography } from '../models/Cartography/PortableCartography';
import { StopCartography } from '../models/Cartography/StopCartography';

interface drawableDataState {
    agents: Array<Agent>,
    stops: Array<StopCartography>,
    vehicles: Array<VehicleCartography>,
    lines: Array<Line>,
    portables: Array<PortableCartography>,
    missions: Array<Mission>,
}

const initialState: drawableDataState = {
    agents: [],
    stops: [],
    vehicles: [],
    lines: [],
    portables: [],
    missions: [],
};

export const drawableDataStore = createSlice({
    name: 'drawableData',
    initialState,
    reducers: {
        setAgents: (state, action: PayloadAction<Array<Agent>>) => {
            state.agents = action.payload;
        },
        addMissions: (state, action: PayloadAction<Array<Mission>>) => {
            state.missions = [ ...state.missions, ...action.payload ];
        },
        addLines: (state, action: PayloadAction<Array<Line>>) => {
            state.lines = [ ...state.lines, ...action.payload ];
        },
        setStops: (state, action: PayloadAction<Array<StopCartography>>) => {
            state.stops = action.payload as any;
        },
        addPortables: (state, action: PayloadAction<Array<PortableCartography>>) => {
            state.portables = [ ...state.portables, ...action.payload as any ];
        },
        updatePortable: (state, action: PayloadAction<PortableCartography>) => {
            state.portables = [ ...state.portables.map(v => {
                if (v.id === action.payload.id) {
                    return action.payload as any;
                }
                return v;
            }) ];
        },
        addVehicles: (state, action: PayloadAction<Array<VehicleCartography>>) => {
            state.vehicles = [ ...state.vehicles, ...action.payload as any ];
        },
        updateVehicle: (state, action: PayloadAction<{ vehicle: VehicleCartography, delayColors?: DelayColors, switchCartography?: SwitchCartography }>) => {
            state.vehicles = [ ...state.vehicles.map(v => {
                if (v.id === action.payload.vehicle.id) {
                    action.payload.vehicle.icon = generateVehicleIcon(action.payload.vehicle, action.payload.delayColors, action.payload.switchCartography);
                    return action.payload.vehicle as any;
                }
                return v;
            }) ];
        },
    },
});

export const { setAgents, addLines, addMissions, setStops, addPortables, updatePortable, addVehicles, updateVehicle } = drawableDataStore.actions;

export default drawableDataStore.reducer;
