import { ReactNode, useEffect, useRef, useState } from 'react';

export interface PageFillUnderHeaderProps {
    content: ReactNode,
    header?: ReactNode,
    footer?: ReactNode,
    trigger?: Array<unknown>,
    className?: string,
}

export const PageFillUnderHeader = (props: PageFillUnderHeaderProps) => {
    const [ headerFooterHeight, setHeaderFooterHeight ] = useState<number>();
    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setHeaderFooterHeight((headerRef.current?.clientHeight ?? 0) + (footerRef.current?.clientHeight ?? 0));
    }, [ headerRef.current, footerRef.current, props.trigger ]);

    return (
        <div className={`p-3 p-md-4 h-100 w-100 overflow-auto ${props.className}`}>
            {
                props.header &&
                <div ref={headerRef} className="pb-3">
                    { props.header }
                </div>
            }
            <div style={{ height: `calc(100% - ${headerFooterHeight}px)` }}>
                { props.content }
            </div>
            {
                props.footer &&
                <div ref={footerRef} className="pt-3">
                    { props.footer }
                </div>
            }
        </div>
    );
};
