import { splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { useIntl } from 'react-intl';
import { VersionIdentifier } from '../../../models/Version';

export const InformationBanksPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const [ currentVersion, setCurrentVersion ] = useState<number>();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'type' }), key: 'type' },
        { label: intl.formatMessage({ id: 'version' }), key: 'version' },
        { label: intl.formatMessage({ id: 'deployed.at' }), key: 'endDate', subIndex: [ 1 ] }
    ];

    useEffect(() => {
        apiService.getVersion(VersionIdentifier.InformationBank).then(res => setCurrentVersion(res.value));
    }, []);

    return (
        <div className="information-banks-page p-4 h-100">
            <Table
                columns={columns}
                dynamicData={{
                    getData: apiService.getVehiclesWithInformationBank,
                    formatData: item => ({
                        ...item,
                        endDate: splitDateTime(intl, item.endDate),
                    })
                }}
                title={
                    <div>
                        <div className="title"> {intl.formatMessage({ id: 'deployed.information.bank' })}</div>
                        <div className="text-center fst-italic mb-2">{intl.formatMessage({ id: 'current.information.bank' }, { value: currentVersion })}</div>
                    </div>
                }
                options={{ export: true }}
                allSortable
                emptySymbol={intl.formatMessage({ id: 'unknown.symbol' })}
            />
        </div>
    );
};
