import './ToastContainer.scss';

import React, { useEffect, useState } from 'react';
import { ToastModel, ToastPosition } from '../../../models/Toast';

import { Toast } from '../../atoms/Toast/Toast';

export interface ToastContainerProps {
    toasts: Array<ToastModel>;
    position?: ToastPosition;
    duration?: number;
    onDeleteToast: (toastId: number) => void;
}

export const ToastContainer: React.FunctionComponent<ToastContainerProps> = (props: ToastContainerProps) => {
    const [ list, setList ] = useState<Array<ToastModel>>();

    useEffect(() => {
        setList(props.toasts);
    }, [ props.toasts ]);

    const deleteToast = (id: number) => props.onDeleteToast(id);

    return (
        <div className={`toast-container-container ${props.position || ToastPosition.TopRight}`}>
            {list?.map((toast: ToastModel) => {
                return (
                    <Toast
                        key={toast.id}
                        toast={toast}
                        duration={props.duration || 7000}
                        onDeleteToast={deleteToast}
                    />
                );
            })}
        </div>
    );
};
