import { CartographyKeys } from "../models/Cartography/CartographyObject";

export enum localStorageKey {
    cartographyIcons = 'cartography-icons',
}

export class LocalStorageService {
    static getLocalStorage = (storageKey: localStorageKey) => {
        return JSON.parse(localStorage.getItem(storageKey) ?? '{}');
    };

    static getLocalIcon = (cartographyKey: CartographyKeys) => {
        return this.getLocalStorage(localStorageKey.cartographyIcons)[cartographyKey] ?? 0;
    };
}
