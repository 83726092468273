import './Input.scss';

import { ChangeEvent, HTMLInputTypeAttribute, useState } from 'react';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

export interface InputProps {
    label?: string
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    className?: string;
    name?: string;
    type?: HTMLInputTypeAttribute;
    min?: number;
    max?: number;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
    onChange?: (value: string, name?: string, event?: ChangeEvent<HTMLInputElement>) => void;
}

export const Input = (props: InputProps) => {
    const [ showText, setShowText ] = useState<boolean>(false);
    const iconSize = 16;
    const iconMargin = 6;

    return (
        <div className={`input-container ${props.className ?? ''}`} data-testid="input">
            { props.label && <div className="label">{props.label}</div> }
            <div className="position-relative">
                <input
                    type={
                        props.type === 'password' ?
                            showText ? 'text' : 'password' :
                            props.type
                    }
                    name={props.name}
                    value={props.value}
                    className={`me-2 ${props.error ? 'error' : ''}`}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e.currentTarget.value, e.currentTarget.name, e);
                        }
                    }}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    min={props.min}
                    max={props.max}
                    style={{
                        paddingRight: props.type === 'password' ?
                            2 * iconMargin + iconSize :
                            undefined
                    }}
                    required={props.required}
                />
                <div className="error-msg">{props.errorMessage}</div>
                {
                    props.type === 'password' &&
                    <div
                        className="position-absolute top-0 end-0 icon"
                        onClick={() => setShowText(!showText)}
                        style={{ marginLeft: iconMargin, marginRight: iconMargin }}
                    >
                        {
                            showText ?
                                <EyeSlash /> : <Eye />
                        }
                    </div>
                }
            </div>
        </div>
    );
};

