import './TableFooter.scss';

import { Paginator, Select } from '../../atoms';

import { useIntl } from 'react-intl';

export interface TableFooterProps {
    count: number,
    pageIndex: number,
    pageSize: number,
    availablePageSizes: Array<number>,
    onPageIndexChange: (pageIndex: number) => void,
    onPageSizeChange: (pageSize: number) => void,
}

export const TableFooter = (props: TableFooterProps) => {
    const intl = useIntl();
    const pageCount = props.pageSize ? Math.ceil(props.count / props.pageSize) : 0;

    return (
        <div className="table-footer d-flex justify-content-end align-items-center p-2 flex-wrap">
            <div className="d-flex align-items-center gap-1">
                <div>{intl.formatMessage({ id: 'table.pageSize' })} </div>
                <Select
                    value={[{ label: props.pageSize.toString(), value: props.pageSize.toString() }]}
                    options={props.availablePageSizes.map(s => ({ label: s.toString(), value: s.toString() }))}
                    onChange={(e) => props.onPageSizeChange(Number(e[0].value))}
                />
            </div>
            <div>{Math.min(props.pageSize * props.pageIndex + 1, props.count)} - {Math.min(props.pageSize * (props.pageIndex + 1), props.count)} {intl.formatMessage({ id: 'table.page.of' })} {props.count}</div>
            {
                !!pageCount &&
                <Paginator
                    pageIndex={props.pageIndex}
                    pageCount={pageCount}
                    onChange={props.onPageIndexChange}
                />
            }
        </div>
    );
};
