import './ExpandPanel.scss';

import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { ColorVariable } from '../../../common/color';
import Draggable from 'react-draggable';
import { ExpandArrow } from '../../atoms/ExpandArrow/ExpandArrow';

export interface ExpandPanelProps {
    children: ReactElement;
    minPanelWidth?: number;
}

export const ExpandPanel: React.FunctionComponent<ExpandPanelProps> = (props: ExpandPanelProps) => {
    const [ expanded, setExpanded ] = useState<boolean>(false);
    const [ contentWidth, setContentWidth ] = useState<number>(0);
    const [ dragging, setDragging ] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const minLeftSpace = 100;
    const minWidth = props.minPanelWidth ?? 200;
    const maxWidth = window.innerWidth - minLeftSpace;

    useEffect(() => {
        setContentWidth(contentRef.current?.clientWidth ?? 0);
    }, [ contentRef.current ]);

    return (
        <React.Fragment>
            <div
                className={`expand-panel d-flex ${expanded ? 'expanded' : ''} ${dragging ? 'dragging' : ''}`}
                style={{ width: expanded ? Math.max(contentWidth, minWidth) : 0 }}
            >
                <div className="trigger p-1 pe-2">
                    <ExpandArrow
                        angle={90}
                        onClick={() => setExpanded(!expanded)}
                        color={ColorVariable.Primary}
                    />
                </div>
                <Draggable
                    axis="x"
                    onDrag={(_, { x }) => {
                        const width = contentWidth - x;
                        setContentWidth(
                            (width > maxWidth) ?
                                maxWidth : (width < minWidth) ?
                                    minWidth : width
                        );
                    }}
                    onStart={() => setDragging(true)}
                    onStop={() => setDragging(false)}
                    position={{ x: 0, y: 0 }}
                >
                    <div className="resizer"></div>
                </Draggable>
                <div
                    className="expand-content"
                    ref={contentRef}
                >{props.children}</div>
            </div>
        </React.Fragment>
    );
};
