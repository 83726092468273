import { Journey } from './Journey';

export interface Mission {
    id: number;
    missionNumber: number;
}

export interface MissionServices {
    SA: string,
    SV: string,
}

export enum MissionType {
    NOMINAL = 1,
    NOMINAL_IDLE = -1,
    REINFORCEMENT = 2,
    REINFORCEMENT_IDLE = -2,
    DUPLICATION = 0,
}
export interface MissionJourney {
    id: number;
    serviceAgent: number;
    serviceVehicle: number;
    missionNumber: number;
    type: MissionType;
    journeys: Array<Journey>;
}
