import './ColorPalette.scss';

import { Color, ColorVariable } from '../../../common/color';

import { ColorInfo } from '../../atoms';
import React from 'react';

export enum PaletteType {
    Color = 0,
    Variable = 1,
}

export interface ColorPaletteProps {
    palette: PaletteType;
}

export const ColorPalette: React.FunctionComponent<ColorPaletteProps> = (props: ColorPaletteProps) => {
    const palettes: { keys: Array<string>, values: Array<string> } = { keys: [], values: [] };
    const getPalette = (): void => {
        switch (props.palette) {
            case PaletteType.Variable:
                palettes.keys = Object.keys(ColorVariable).filter(key => !key.startsWith('#'));
                palettes.values = (Object.values(ColorVariable) as Array<string>).filter(value => value.startsWith('#'));
                break;
            case PaletteType.Color:
            default:
                palettes.keys = Object.keys(Color);
                palettes.values = Object.values(Color);
                break;
        }
    };
    getPalette();

    return (
        <div className="color-palette-container">
            {palettes.keys.map((color, index) => <ColorInfo key={index} color={palettes.values[index]} name={color} />)}
        </div>
    );
};
