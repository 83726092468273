import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisplayCycle } from '../models/DisplayCycle';

export interface cycleState {
    terminals: {[id: number]: DisplayCycle};
    vehicles: {[id: number]: DisplayCycle};
}

const initialState: cycleState = {
    terminals: {},
    vehicles: {},
};

export const cycleStore = createSlice({
    name: 'cycle',
    initialState,
    reducers: {
        addTerminal: (state, action: PayloadAction<{id: number, element: DisplayCycle}>) => {
            state.terminals = { ...state.terminals, [action.payload.id]: action.payload.element };
        },
        addVehicle: (state, action: PayloadAction<{id: number, element: DisplayCycle}>) => {
            state.vehicles = { ...state.vehicles, [action.payload.id]: action.payload.element };
        },
        removeTerminal: (state, action: PayloadAction<number>) => {
            const terminals = { ...state.terminals };
            delete terminals[action.payload];
            state.terminals = { ...terminals };
        },
        removeVehicle: (state, action: PayloadAction<number>) => {
            const vehicles = { ...state.vehicles };
            delete vehicles[action.payload];
            state.vehicles = { ...vehicles };
        },
    },
});

export const { addTerminal, addVehicle, removeTerminal, removeVehicle } = cycleStore.actions;

export default cycleStore.reducer;
