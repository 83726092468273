import './SaphirSettingsPage.scss';

import { Action, Button, Input, InputProps, ModalConfirmation, Table, TableColumn, TableValueType, TextArea, ToastModel, ToastType } from '@ceccli/design-system';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { pushToast } from '../../../app/ToastStore';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

export const SaphirSettingsPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const dispatch = useDispatch();

    const [ updatedValue, setUpdateValue ] = useState<{ [key: string]: string }>({});
    const [ updatedComment, setUpdateComment ] = useState<{ [key: string]: string }>({});
    const [ columns, setColumns ] = useState<Array<TableColumn>>([]);
    const [ triggerChange, setTriggerChange ] = useState<boolean>(false);
    const [ triggerUpdate, setTriggerUpdate ] = useState<boolean>(false);
    const [ confirmationData, setConfirmationData ] = useState<{ row: { [key: string]: TableValueType }, key: string, updatedObj: { [key: string]: string }}>();

    const renderCell = (
        row: { [key: string]: TableValueType },
        key: string,
        updatedObj: { [key: string]: string },
        setUpdateObj: Dispatch<SetStateAction<{ [key: string]: string }>>,
        component: React.FunctionComponent<InputProps>,
    ) => {
        const param = String(row.param);

        return (
            <div className="text-center d-flex flex-column gap-2">
                {
                    React.createElement(component, {
                        value: updatedObj[param] ?? row[key],
                        onChange: (e: string) => {
                            if (e !== row[key]) {
                                updatedObj[param] = e;
                            } else {
                                delete updatedObj[param];
                            }
                            setUpdateObj({ ...updatedObj });
                            setTriggerChange(!triggerChange);
                        }
                    })
                }
                {
                    typeof updatedObj[param] !== 'undefined' && updatedObj[param] !== row[key] &&
                    <Button
                        text={intl.formatMessage({ id: 'update' })}
                        onClick={() => setConfirmationData({ key, row, updatedObj })}
                    />
                }
            </div>
        );
    };

    useEffect(() => {
        setColumns([
            { label: intl.formatMessage({ id: 'parameter' }), key: 'param', dbKey: 'CleParam' },
            { label: intl.formatMessage({ id: 'value' }), key: 'value', dbKey: 'ValeurParam', renderCell: (row) => renderCell(row, 'value', updatedValue, setUpdateValue, Input) },
            { label: intl.formatMessage({ id: 'comment' }), key: 'comment', dbKey: 'Commentaire', renderCell: (row) => renderCell(row, 'comment', updatedComment, setUpdateComment, TextArea) },
        ]);
    }, [ triggerChange ]);

    const renderConfirmationModal = () => {
        if (!confirmationData) {
            return;
        }

        const { key, row, updatedObj } = confirmationData;
        const param = String(row.param);

        return (
            <ModalConfirmation
                action={Action.Update}
                entity={intl.formatMessage({ id: 'settings.saphir' })}
                name={`${intl.formatMessage({ id: 'the.parameter', }).toLowerCase()} ${param}`}
                onClose={() => setConfirmationData(undefined)}
                onSave={async() => {
                    let error = false;
                    try {
                        await apiService.updateSaphirParam({
                            param,
                            value: (key === 'value' ? updatedValue[param] ?? row.value : row.value) as string,
                            comment: (key === 'comment' ? updatedComment[param] ?? row.comment : row.comment) as string,
                        });
                        delete updatedObj[param];
                        setTriggerChange(!triggerChange);
                        setTriggerUpdate(!triggerUpdate);
                    } catch {
                        error = true;
                    }
                    dispatch(pushToast(
                        new ToastModel(
                            intl.formatMessage(
                                { id: `saphir.settings.${error ? 'error' : 'success'}` },
                                { param }
                            ),
                            error ? ToastType.Error : ToastType.Success,
                        )
                    ));
                }}
            />
        );
    };

    return (
        <>
            { renderConfirmationModal() }
            <PageFillUnderHeader
                content={
                    <div className="saphir-settings-table">
                        <Table
                            title={intl.formatMessage({ id: 'settings.saphir' })}
                            columns={columns}
                            dynamicData={{
                                getData: (pageIndex, pageSize, sorting) => apiService.getSaphirParams(pageIndex, pageSize, sorting),
                                refreshTrigger: [ triggerUpdate ]
                            }}
                            options={{ export: true }}
                            allSortable
                        />
                    </div>
                }
            />
        </>
    );
};
