import './Map.scss';

import * as ol from 'ol';

import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { defaults } from 'ol/control';
import { FeatureLike } from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import MapContext from './MapContext';

export interface MapProps {
    children: ReactNode;
    zoom: number;
    center: [number, number];
    minRange: [number, number];
    maxRange: [number, number];
    onHover: (feature?: FeatureLike) => void;
    onClick: (feature?: FeatureLike, position?: [ number, number ]) => void;
}

export interface MapHandle {
    setView: (position?: [ number, number ], zoom?: number) => void;
    getRotation: () => number | undefined;
}

const Map = forwardRef<MapHandle, MapProps>((props: MapProps, ref: React.Ref<MapHandle>) => {
    const mapRef = useRef(null);
    const [ map, setMap ] = useState<ol.Map>();

    useEffect(() => {
        const options = {
            view: new ol.View({
                center: fromLonLat([ ...props.center ].reverse()),
                extent: [
                    ...fromLonLat([ ...props.minRange ].reverse()),
                    ...fromLonLat([ ...props.maxRange ].reverse()),
                ],
                zoom: props.zoom,
            }),
            layers: [],
            controls: defaults(),
            overlays: []
        };
        const mapObject = new ol.Map(options);
        mapObject.setTarget(mapRef.current ?? undefined);
        setMap(mapObject);
        return () => mapObject.setTarget(undefined);
    }, []);

    useEffect(() => {
        if (!map) {
            return;
        }
        map.on('click', (event) => {
            const feature = map.forEachFeatureAtPixel(event.pixel, f => f);
            if (feature) {
                const coordinate = map.getCoordinateFromPixel(event.pixel);
                props.onClick(feature, coordinate as [ number, number ]);
            } else {
                props.onClick();
            }
        });
        map.on('pointermove', (event) => {
            const feature = map.forEachFeatureAtPixel(event.pixel, f => f);
            map.getTargetElement().style.cursor = feature ? 'pointer' : '';
            props.onHover(feature);
        });

        map.on('movestart', () => {
            document.body.style.cursor = 'grabbing';
        });
        map.on('moveend', () => {
            document.body.style.cursor = 'grab';
        });
    }, [ map ]);

    useEffect(() => {
        if (!map) {
            return;
        }

        map.getView().setZoom(props.zoom);
    }, [ props.zoom ]);

    useEffect(() => {
        if (!map) {
            return;
        }

        map.getView().setCenter(fromLonLat([ ...props.center ].reverse()));
    }, [ map, props.center ]);

    useImperativeHandle(ref, () => ({
        setView(position, zoom) {
            if (!position && !zoom) {
                return;
            }

            const view = map?.getView();
            if (position) {
                view?.setCenter(fromLonLat([ ...position ].reverse()));
            }
            if (zoom) {
                view?.setZoom(zoom);
            }
        },
        getRotation() {
            return map?.getView().getRotation();
        }
    }));

    return (
        <MapContext.Provider value={ map }>
            <div ref={mapRef} className="map-container w-100 h-100">
                {props.children}
            </div>
        </MapContext.Provider>
    );
});

export default Map;
