import './ParkTrackingPage.scss';

import { Button, Card, CheckElementOptionType, LineIndicator, Select, Table, TableColumn, TableValueType } from '@ceccli/design-system';
import { getDelayColor, LocationSituation, SituationDelay, SituationDelayDeparture, VehicleCartography } from '../../../models/Cartography/VehicleCartography';
import { setAgents, setStops } from '../../../app/drawableDataStore';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Agent } from '../../../models/Agent';
import { ApiService } from '../../../services/apiService';
import { DelayColors } from '../../../app/mapDataStore';
import { Line } from '../../../models/Line';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { StopCartography } from '../../../models/Cartography/StopCartography';
import { useIntl } from 'react-intl';
import { UtilsService } from '../../../services/utilsService';

export const ParkTrackingPage = () => {
    const apiService = new ApiService();
    const utilsService = new UtilsService();
    const intl = useIntl();
    const dispatch = useDispatch();
    const projection = useSelector<RootState, number | undefined>(state => state.mapData.projection);
    const delayColors = useSelector<RootState, DelayColors | undefined>(state => state.mapData.delayColors);
    const allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);
    const allAgents = useSelector<RootState, Array<Agent>>(state => state.drawableData.agents);
    const allStops = useSelector<RootState, Array<StopCartography>>(state => state.drawableData.stops);
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);

    const lineOptions = utilsService.getUniqueByAttribute(
        allVehicles.filter(v => v.line)
            .map(v => v.line) as Array<Line>, 'id')
        .map(l => ({ ...l, value: l.id.toString() }))
        .sort((a, b) => a.code.localeCompare(b.code, intl.locale, { numeric: true }));
    const [ selectedLine, setSelectedLine ] = useState<number>();
    const [ matrixDisplay, setMatrixDisplay ] = useState<boolean>(false);
    const [ showParkId, setShowParkId ] = useState<boolean>(false);
    const maxRangeMeter = 100;

    const formatMatrix = (line: Line) => {
        const vehicles = allVehicles.filter(v => v.lineId === line.id);

        const getSize = (condition: (v: VehicleCartography) => boolean, subVehicles?: Array<VehicleCartography>) => {
            const l = (subVehicles ?? vehicles).filter(v => condition(v));
            return l.length > 0 ? l.map(v => v.bodyId) : undefined;
        };

        const isOutRange = (v: VehicleCartography) => {
            const stopPos = allStops.find(s => s.id === v.startStop)?.positionLambert;
            const vehiclePos = v.positionLambert;
            if (stopPos && vehiclePos) {
                return vehiclePos.map((p, i) => Math.pow(p - stopPos[i], 2)).reduce((a, b) => Math.sqrt(a + b)) > maxRangeMeter;
            }
            return false;
        };

        const vehiclesInRace = vehicles.filter(v => v.locationSituation === LocationSituation.InRace || v.locationSituation === LocationSituation.InRaceStopped);
        const vehiclesDeparture = vehicles.filter(v => v.locationSituation === LocationSituation.TerminusDeparture);

        return ({
            ...line,
            largeAdvance: getSize(v => v.situationDelay === SituationDelay.largeAdvance, vehiclesInRace),
            smallAdvance: getSize(v => v.situationDelay === SituationDelay.smallAdvance, vehiclesInRace),
            onTime: getSize(v => v.situationDelay === SituationDelay.onTime, vehiclesInRace),
            smallDelay: getSize(v => v.situationDelay === SituationDelay.smallDelay, vehiclesInRace),
            largeDelay: getSize(v => v.situationDelay === SituationDelay.largeDelay, vehiclesInRace),
            terminalArrival: getSize(v => v.locationSituation === LocationSituation.TerminusArrival),
            terminalDeparture: getSize(v => v.situationDelay === SituationDelayDeparture.onTime || v.situationDelay === SituationDelayDeparture.smallAdvance, vehiclesDeparture),
            departureSmallDelay: getSize(v => v.situationDelay === SituationDelayDeparture.smallDelay, vehiclesDeparture),
            departureLargeDelay: getSize(v => v.situationDelay === SituationDelayDeparture.largeDelay, vehiclesDeparture),
            departureOutArea: getSize(v => isOutRange(v), vehiclesDeparture),
            relocated: getSize(v => v.situationDelay === SituationDelay.relocated, vehiclesInRace),
            total: vehicles.length,
        });
    };

    const tableInfo: {[key: string]: {
        columns: Array<TableColumn>,
        data: Array<{ [key: string]: TableValueType }>,
    }} = {
        default: {
            columns: [
                { label: intl.formatMessage({ id: 'park.id' }), key: 'bodyId' },
                { label: intl.formatMessage({ id: 'time' }), key: 'time' },
                { label: intl.formatMessage({ id: 'agent' }), key: 'agent' },
                { label: intl.formatMessage({ id: 'mission' }), key: 'missionNumber' },
                { label: intl.formatMessage({ id: 'line' }), key: 'code', renderCell: row => <LineIndicator {...row} /> },
                { label: intl.formatMessage({ id: 'destination' }), key: 'endStop' },
                { label: intl.formatMessage({ id: 'stop.previous' }), key: 'previousStop' },
                { label: intl.formatMessage({ id: 'stop.next' }), key: 'nextStop' },
                { label: intl.formatMessage({ id: 'earlier.late' }), key: 'advanceDelay', renderCell: row => renderDelay(row, row.advanceDelay) },
            ],
            data: allVehicles
                .filter(v => !selectedLine || v.lineId === selectedLine)
                .map(v => ({
                    ...v.line,
                    id: v.id,
                    bodyId: v.bodyId,
                    time: v.time,
                    agent: allAgents.find(a => a.id === v.agentId)?.lastName,
                    missionNumber: v.missionNumber,
                    endStop: allStops.find(s => s.id === v.endStop)?.label,
                    previousStop: allStops.find(s => s.id === v.previousStop)?.label,
                    nextStop: allStops.find(s => s.id === v.nextStop)?.label,
                    advanceDelay: v.advanceDelay,
                    delayColor: getDelayColor(v, delayColors),
                })),
        },
        matrix: {
            columns: [
                { label: intl.formatMessage({ id: 'line' }), key: 'code', renderCell: row => <LineIndicator {...row} /> },
                { label: intl.formatMessage({ id: 'matrix.arrival' }), key: 'terminalArrival', renderCell: (row, renderData) => renderMatrixCell(row.terminalArrival, !!renderData, 'arrival') },
                { label: intl.formatMessage({ id: 'matrix.departure.out' }), key: 'departureOutArea', renderCell: (row, renderData) => renderMatrixCell(row.departureOutArea, !!renderData) },
                { label: intl.formatMessage({ id: 'matrix.departure' }), key: 'terminalDeparture', renderCell: (row, renderData) => renderMatrixCell(row.terminalDeparture, !!renderData, 'departure') },
                { label: intl.formatMessage({ id: 'matrix.departure.small.delay' }), key: 'departureSmallDelay', renderCell: (row, renderData) => renderMatrixCell(row.departureSmallDelay, !!renderData, 'departure-small-delay') },
                { label: intl.formatMessage({ id: 'matrix.departure.large.delay' }), key: 'departureLargeDelay', renderCell: (row, renderData) => renderMatrixCell(row.departureLargeDelay, !!renderData, 'departure-large-delay') },
                { label: intl.formatMessage({ id: 'matrix.large.delay' }), key: 'largeDelay', renderCell: (row, renderData) => renderMatrixCell(row.largeDelay, !!renderData, 'large-delay') },
                { label: intl.formatMessage({ id: 'matrix.small.delay' }), key: 'smallDelay', renderCell: (row, renderData) => renderMatrixCell(row.smallDelay, !!renderData, 'small-delay') },
                { label: intl.formatMessage({ id: 'matrix.on.time' }), key: 'onTime', renderCell: (row, renderData) => renderMatrixCell(row.onTime, !!renderData, 'on-time') },
                { label: intl.formatMessage({ id: 'matrix.small.advance' }), key: 'smallAdvance', renderCell: (row, renderData) => renderMatrixCell(row.smallAdvance, !!renderData, 'small-advance') },
                { label: intl.formatMessage({ id: 'matrix.large.advance' }), key: 'largeAdvance', renderCell: (row, renderData) => renderMatrixCell(row.largeAdvance, !!renderData, 'large-advance') },
                { label: intl.formatMessage({ id: 'matrix.relocated' }), key: 'relocated', renderCell: (row, renderData) => renderMatrixCell(row.relocated, !!renderData) },
                { label: intl.formatMessage({ id: 'total' }), key: 'total' },
            ],
            data: lineOptions.filter(l => !selectedLine || l.id === selectedLine).map(formatMatrix)
        },
    };

    useEffect(() => {
        if (!allAgents.length) {
            apiService.getAllAgents(undefined, !authorization).then(res => dispatch(setAgents(res)));
        }
    }, []);

    useEffect(() => {
        if (!allStops.length && projection) {
            apiService.getStops(projection).then(data => dispatch(setStops(data)));
        }
    }, [ projection ]);

    useEffect(() => {
        if (!matrixDisplay) {
            setShowParkId(false);
        }
    }, [ matrixDisplay ]);

    const renderMatrixCell = (value: TableValueType, showId: boolean, className?: string,) => {
        const val = value ? (value as Array<string>) : undefined;
        const displayVal = val ?
            showId ?
                val.reduce((a, b) => `${a} - ${b}`) :
                val.length :
            undefined;

        return (
            val ?
                <div className={`matrix-cell ${showId ? 'show-parkId' : ''} ${className ? 'colorized' : ''} ${className}`}>{displayVal}</div> :
                <>-</>
        );
    };

    const renderDelay = (lineInfo: {[key: string]: TableValueType}, value: TableValueType) => {
        return (
            <div className="d-flex gap-2">
                <div>{ utilsService.formatDelay(Number(value)) }</div>
                <div
                    style={{ background: lineInfo.delayColor?.toString() }}
                    className="delay-indicator my-auto"
                ></div>
            </div>
        );
    };

    const renderOption = (data: CheckElementOptionType) => {
        return (
            data.value ?
                <LineIndicator {...data} /> :
                <div className="ms-2">{data.label}</div>
        );
    };

    return (
        <PageFillUnderHeader
            className={`park-tracking-page ${matrixDisplay ? 'matrix' : ''} ${showParkId ? 'show-parkId' : ''}`}
            header={
                <Card title={intl.formatMessage({ id: 'park.tracking' })} contentClass="p-3">
                    <div className="d-flex justify-content-center gap-3">
                        <div className="col-3">
                            <Select
                                options={lineOptions}
                                onChange={(e) => setSelectedLine(e[0] ? Number(e[0].value) : undefined)}
                                renderOption={renderOption}
                                renderSingleValue={renderOption}
                                isDisabled={lineOptions.length < 1}
                                placeHolder={intl.formatMessage({ id: 'select.line' })}
                                isClearable
                            />
                        </div>
                        <Button
                            text={intl.formatMessage(
                                { id: 'display' },
                                { type: intl.formatMessage({ id: matrixDisplay ? 'classic' : 'matrix' }).toLowerCase() }
                            )}
                            onClick={() => setMatrixDisplay(!matrixDisplay)}
                        />
                        {
                            matrixDisplay &&
                            <Button
                                text={intl.formatMessage({ id: showParkId ? 'count' : 'park.id' })}
                                onClick={() => setShowParkId(!showParkId)}
                            />
                        }
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={tableInfo[matrixDisplay ? 'matrix' : 'default'].columns}
                    staticData={{ data: tableInfo[matrixDisplay ? 'matrix' : 'default'].data }}
                    renderData={showParkId}
                />
            }
        />
    );
};
