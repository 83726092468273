import './PhonyPage.scss';

import { Collapsible, ColorVariable } from '@ceccli/design-system';
import { Phony, PhonyFlag } from '../../../models/Phony';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { PhonyTable } from '../../PhonyTable/PhonyTable';
import { RootState } from '../../../app/store';
import { setOpenDraggable } from '../../../app/phonyStore';
import { useIntl } from 'react-intl';

export const PhonyPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const phonyInfos = useSelector<RootState, {[key: number]: Phony}>(state => state.phony.phonyInfos);
    const [ historyOpen, setHistoryOpen ] = useState<boolean>(false);

    const emptyHistory = !Object.values(phonyInfos).find(p => p.flag === PhonyFlag.Ended);

    useEffect(() => {
        dispatch(setOpenDraggable(false));
    }, []);

    return (
        <PageFillUnderHeader
            content={
                <div className="phony-page">
                    <PhonyTable />
                    <div className="my-4 pb-4">
                        <Collapsible
                            title={emptyHistory ?
                                intl.formatMessage({ id: 'phony.empty.history' }) :
                                `${intl.formatMessage({ id: historyOpen ? 'hide' : 'show' })} ${intl.formatMessage({ id: 'phony.history.show' })}`.toUpperCase()
                            }
                            color={ColorVariable.AccentText}
                            onChange={setHistoryOpen}
                            className={`phony-collapsible ${emptyHistory ? 'disabled' : ''}`}
                            headerClassName="justify-content-center gap-4 my-4 phony-collapsible-header cursor-pointer"
                            content={<PhonyTable history />}
                            disabled={emptyHistory}
                        />
                    </div>
                </div>
            }
        />
    );
};
