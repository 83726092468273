import './HazardGesturePage.scss';

import { Action, Button, Collapsible, ColorVariable, DatePicker, formatTimeFromMinutes, Modal, Table, TableColumn, TextArea, TimePicker, ToastModel, ToastType } from '@ceccli/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { formatExploitationTime } from '../../../common/helper';
import { Hazard } from '../../../models/Hazard';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { pushToast } from '../../../app/ToastStore';
import { RootState } from '../../../app/store';
import { SaphirParameterKeys } from '../../../models/SaphirParameter';
import { useIntl } from 'react-intl';

export const HazardGesturePage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [ historyOpen, setHistoryOpen ] = useState<boolean>(false);
    const [ modalType, setModalType ] = useState<Action>();
    const [ selectedHazard, setSelectedHazard ] = useState<Hazard>();
    const [ saveHazard, setSaveHazard ] = useState<Hazard>();
    const [ refreshIncoming, setRefreshIncoming ] = useState<boolean>(false);
    const userName = useSelector<RootState, string | undefined>(state => state.user.user?.userName);
    const [ startExploitation, setStartExploitation ] = useState<number>();
    const [ endExploitation, setEndExploitation ] = useState<number>();
    const [ confirmation, setConfirmation ] = useState<boolean>(false);
    const [ errors, setErrors ] = useState<{[key: string]: boolean}>({});

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'application.date' }), key: 'date' },
        { label: intl.formatMessage({ id: 'time.slot' }, { n: 1 }), key: 'timeSlot1' },
        { label: intl.formatMessage({ id: 'time.slot' }, { n: 2 }), key: 'timeSlot2' },
        { label: intl.formatMessage({ id: 'time.slot' }, { n: 3 }), key: 'timeSlot3' },
        { label: intl.formatMessage({ id: 'info' }), key: 'info' },
        { label: intl.formatMessage({ id: 'user' }), key: 'userName' },
    ];

    const formatTimeSlot = (startTime?: number, endTime?: number) => [
        startTime ? formatTimeFromMinutes(intl, startTime) : undefined,
        endTime ? formatTimeFromMinutes(intl, endTime) : undefined
    ];

    const formatHazard = (data: Hazard) => ({
        ...data,
        date: intl.formatDate(data.applicationDate),
        timeSlot1: formatTimeSlot(data.startTime1, data.endTime1),
        timeSlot2: formatTimeSlot(data.startTime2, data.endTime2),
        timeSlot3: formatTimeSlot(data.startTime3, data.endTime3),
    });

    const onAction = (kind: Action, data?: Object) => {
        setModalType(kind);
        switch (kind) {
            case Action.Create:
                setSelectedHazard({ applicationDate: (new Date()).toISOString(), info: '', userName: userName ?? '' });
                break;
            case Action.Update:
                setSelectedHazard(data as Hazard);
                setSaveHazard(data as Hazard);
                break;
            case Action.Delete:
                setSelectedHazard(data as Hazard);
                setConfirmation(true);
                break;
            default:
                break;
        }
    };

    const handleConflict = () => {
        if (!selectedHazard) {
            return;
        }

        apiService.getHazard(selectedHazard.applicationDate).then(res => {
            if (res) {
                dispatch(pushToast(
                    new ToastModel(
                        intl.formatMessage({ id: 'hazard.gesture.conflict' }),
                        ToastType.Error,
                    )
                ));
                return;
            }
            setConfirmation(true);
        });
    };

    const handleConfirm = () => {
        if (!selectedHazard) {
            return;
        }
        switch (modalType) {
            case Action.Create:
                return handleUpdate(apiService.createHazard(selectedHazard));
            case Action.Update:
                return handleUpdate(apiService.updateHazard(selectedHazard));
            case Action.Delete:
                return handleUpdate(apiService.deleteHazard(selectedHazard.applicationDate));
            default:
                return;
        }
    };

    const handleUpdate = async (promise: Promise<unknown>) => {
        let error = false;
        try {
            await promise;
        } catch {
            error = true;
        }

        dispatch(pushToast(
            new ToastModel(
                intl.formatMessage(
                    { id: `hazard.gesture.${error ? 'error' : 'success'}` },
                    { type: intl.formatMessage({ id: `action.${modalType?.toLowerCase()}` }).toLowerCase() }
                ),
                error ? ToastType.Error : ToastType.Success,
            )
        ));
        setRefreshIncoming(!refreshIncoming);
        setModalType(undefined);
        setConfirmation(false);
    };

    const isTimeSlotError = (startKey: keyof Hazard, endKey: keyof Hazard) => {
        const start = selectedHazard ? Number(selectedHazard[startKey]) : undefined;
        const end = selectedHazard ? Number(selectedHazard[endKey]) : undefined;
        const error = start ?
            !end || end < start :
            !!end;
        return error;
    };

    const isEmpty = (a: unknown) => a === null || typeof a === 'undefined';
    const emptyTimeSlots = isEmpty(selectedHazard?.startTime1) || isEmpty(selectedHazard?.endTime1);

    useEffect(() => {
        apiService.getSaphirParam(SaphirParameterKeys.ExploitationStart).then(res => setStartExploitation(formatExploitationTime(res)));
        apiService.getSaphirParam(SaphirParameterKeys.ExploitationEnd).then(res => setEndExploitation(formatExploitationTime(res)));
    }, []);

    useEffect(() => {
        if (selectedHazard) {
            setErrors({
                1: !selectedHazard.startTime1 || isTimeSlotError('startTime1', 'endTime1'),
                2: isTimeSlotError('startTime2', 'endTime2'),
                3: isTimeSlotError('startTime3', 'endTime3'),
            });
        } else {
            setErrors({});
        }
    }, [ selectedHazard ]);

    const renderModal = () => {
        let content;

        if (confirmation) {
            content = renderConfirmationModal();
        } else {
            switch (modalType) {
                case Action.Create:
                case Action.Update:
                    content = <>
                        <DatePicker
                            label={intl.formatMessage({ id: 'application.date' })}
                            defaultDate={selectedHazard?.applicationDate ? new Date(selectedHazard.applicationDate) : undefined}
                            minDate={new Date()}
                            onChange={e => setSelectedHazard(selectedHazard ? { ...selectedHazard, applicationDate: e ? e.toISOString() : '' } : undefined)}
                            disabled={modalType === Action.Update}
                        />
                        <div className="d-flex gap-4">
                            {renderTimeSlotInput(1, 'startTime1', 'endTime1')}
                            {renderTimeSlotInput(2, 'startTime2', 'endTime2', emptyTimeSlots)}
                            {renderTimeSlotInput(3, 'startTime3', 'endTime3', isEmpty(selectedHazard?.startTime2) || isEmpty(selectedHazard?.endTime2))}
                        </div>
                        <TextArea
                            label={intl.formatMessage({ id: 'info' })}
                            className="d-block"
                            value={selectedHazard?.info}
                            onChange={e => setSelectedHazard(selectedHazard ? { ...selectedHazard, info: e } : undefined)}
                        />
                        <div className="d-flex justify-content-center gap-2">
                            <Button
                                text={intl.formatMessage({ id: 'save' })}
                                onClick={() => (modalType === Action.Create ? handleConflict() : setConfirmation(true))}
                                disabled={
                                    (modalType === Action.Update && JSON.stringify(selectedHazard) === JSON.stringify(saveHazard)) ||
                                    emptyTimeSlots ||
                                    Object.values(errors).find(e => e)
                                }
                            />
                            <Button
                                text={intl.formatMessage({ id: 'close' })}
                                onClick={() => {
                                    setSelectedHazard(undefined);
                                    setModalType(undefined);
                                }}
                            />
                        </div>
                    </>;
                    break;
                case Action.Delete:
                    setSelectedHazard(undefined);
                    setModalType(undefined);
                    break;
                default:
                    break;
            }
        }

        return (
            <div className="hazard-modal d-flex flex-column gap-4">
                {content}
            </div>
        );
    };

    const renderTimeSlotInput = (n: number, startKey: keyof Hazard, endKey: keyof Hazard, disabled?: boolean) => {
        return (
            <div className={`d-flex flex-column gap-2 ${errors[n] ? 'error' : ''}`}>
                <div>{intl.formatMessage({ id: 'time.slot' }, { n })}</div>
                <TimePicker
                    min={startExploitation}
                    max={endExploitation}
                    minutes={selectedHazard ? Number(selectedHazard[startKey]) : undefined}
                    onChange={(val) => setSelectedHazard(selectedHazard ? { ...selectedHazard, [startKey]: val } : undefined)}
                    disabled={disabled}
                />
                <TimePicker
                    min={startExploitation}
                    max={endExploitation}
                    minutes={selectedHazard ? Number(selectedHazard[endKey]) : undefined}
                    onChange={(val) => setSelectedHazard(selectedHazard ? { ...selectedHazard, [endKey]: val } : undefined)}
                    disabled={disabled}
                />
            </div>
        );
    };

    const renderConfirmationModal = () => {
        if (!modalType) {
            return;
        }

        return (
            <>
                <div className="text-center">
                    {intl.formatMessage(
                        { id: 'hazard.gesture.confirm' },
                        { type: intl.formatMessage({ id: `action.${modalType.toLowerCase()}` }).toLowerCase() }
                    )}
                </div>
                <div>
                    {
                        selectedHazard &&
                        <Table
                            columns={columns}
                            staticData={{ data: [ formatHazard(selectedHazard) ] }}
                        />
                    }
                </div>
                <div className="d-flex justify-content-center gap-3">
                    <Button
                        text={intl.formatMessage({ id: 'confirm' })}
                        onClick={() => selectedHazard && handleConfirm()}
                    />
                    <Button
                        text={intl.formatMessage({ id: 'cancel' })}
                        onClick={() => setConfirmation(false)}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <PageFillUnderHeader
                content={
                    <div className="hazard-page">
                        <Table
                            title={intl.formatMessage({ id: 'hazard.gesture' })}
                            columns={columns}
                            dynamicData={{
                                getData: (pageIndex, pageSize, sort) => apiService.getHazards(pageIndex, pageSize, sort),
                                formatData: formatHazard,
                                refreshTrigger: [ refreshIncoming ]
                            }}
                            onAction={onAction}
                            options={{ export: true, delete: true, update: true, create: true }}
                        />
                        <div className="my-4">
                            <Collapsible
                                title={`${intl.formatMessage({ id: historyOpen ? 'hide' : 'show' })} ${intl.formatMessage({ id: 'hazard.gesture.history.show' })}`.toUpperCase()}
                                color={ColorVariable.AccentText}
                                onChange={setHistoryOpen}
                                className="hazard-collapsible"
                                headerClassName="justify-content-center gap-4 my-4 hazard-collapsible-header cursor-pointer"
                                content={
                                    <div className="mb-4">
                                        <Table
                                            title={intl.formatMessage({ id: 'hazard.gesture.history' })}
                                            columns={columns}
                                            options={{ export: true }}
                                            dynamicData={{
                                                getData: (pageIndex, pageSize, sort) => apiService.getHazards(pageIndex, pageSize, sort, true),
                                                formatData: formatHazard,
                                            }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                }
            />
            {
                modalType &&
                <Modal
                    title={intl.formatMessage({ id: 'hazard.gesture.title' }, { type: intl.formatMessage({ id: `action.${modalType.toLowerCase()}` }) })}
                    onClose={() => {
                        setModalType(undefined);
                        setConfirmation(false);
                    }}
                >
                    {renderModal()}
                </Modal>
            }
        </>
    );
};
