export enum MessageType {
    Perturbation = 'P',
    Commercial = 'C',
}

export enum MessageGroup {
    Lines = 'L',
    Panel = 'P',
    General = 'G',
    Stop = 'A',
}

export enum MessageAction {
    Delete = 0,
    AddUpdate = 1,
}

export enum MessageTargetType {
    Panel = 'P',
    Satellite = 'S',
}

export enum TypeTranslation {
    P = 'perturbation',
    C = 'commercial',
}

export enum GroupTranslation {
    'L' = 'lines',
    'P' = 'panels',
    'G' = 'general',
    'A' = 'stops'
}

export enum ActionTranslation {
    'message.delete',
    'message.add.update',
}

export interface Message {
    startDate: Date;
    endDate: Date;
    exploitationDate: Date;
    id: number,
    messageText: string,
    messageNumber: number,
    messageType: MessageType | null,
    messageTargetType: MessageTargetType,
    messageTargetId: number,
}

export interface MessageProgram {
    messageText: string,
    id: number,
    messageNumber: number,
    type: MessageType | null,
    activeDays: number,
    group: MessageGroup | null,
    action: MessageAction,
    startDate: Date;
    endDate: Date;
    actionDate: Date;
    timeSlots: Array<Date>;
    targets: Array<string>;
}
