import { Card, CheckElementOptionType, DatePicker, Select, splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { UnloadingFileType, UnloadingInfo } from '../../../models/UnloadingInfo';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { useIntl } from 'react-intl';

export const UnloadedFilesPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();

    const [ date, setDate ] = useState<Date | undefined>(new Date());
    const [ vehicleOptions, setVehicleOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ selectedVehicleId, setSelectedVehicleId ] = useState<number>();
    const fileTypeOptions = Object.values(UnloadingFileType).slice(0, Object.values(UnloadingFileType).length / 2).map(t => ({ label: t.toString(), value: t.toString() }));
    const [ selectedFileType, setSelectedFileType ] = useState<string>();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'unloading.date' }), key: 'unloadingDate', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'file.type' }), key: 'fileType' },
        { label: intl.formatMessage({ id: 'file.name' }), key: 'fileName' },
        { label: intl.formatMessage({ id: 'file.size' }), key: 'fileSize' },
        { label: intl.formatMessage({ id: 'processed' }), key: 'processed' },
    ];

    useEffect(() => {
        apiService.getAllVehicles().then(res => setVehicleOptions(res.map(v => ({ label: v.parkId, value: v.id.toString() })).sort((a, b) => Number(a.label) - Number(b.label))));
    }, []);

    return (
        <PageFillUnderHeader
            header={
                <Card title={intl.formatMessage({ id: 'unloaded.files' })} contentClass="p-3">
                    <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-0">
                        <div className="col-12 col-lg-4 p-0 pe-lg-2">
                            <DatePicker
                                label={intl.formatMessage({ id: 'unloading.date' })}
                                onChange={setDate}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                defaultDate={new Date()}
                                maxDate={new Date()}
                                options={{ today: true, all: true }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 p-0 px-lg-2">
                            <Select
                                label={intl.formatMessage({ id: 'park.id' })}
                                options={vehicleOptions}
                                onChange={e => setSelectedVehicleId(e[0] ? Number(e[0].value) : undefined)}
                                placeHolder={intl.formatMessage({ id: 'select.vehicle' })}
                                isClearable
                            />
                        </div>
                        <div className="col-12 col-lg-4 p-0 ps-lg-2">
                            <Select
                                label={intl.formatMessage({ id: 'file.type' })}
                                options={fileTypeOptions}
                                onChange={e => setSelectedFileType(e[0] ? e[0].value.toString() : undefined)}
                                placeHolder={intl.formatMessage({ id: 'select.file.type' })}
                                isClearable
                            />
                        </div>
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getUnloadingInfo(pageIndex, pageSize, sorting, date, selectedVehicleId, selectedFileType),
                        formatData: (data: UnloadingInfo) => {
                            return ({
                                ...data,
                                unloadingDate: splitDateTime(intl, data.unloadingDate),
                                processed: data.processed ? intl.formatMessage({ id: 'ok' }) : undefined,
                            });
                        },
                        refreshTrigger: [ date, selectedVehicleId, selectedFileType, vehicleOptions ]
                    }}
                    options={{ export: true }}
                    boldRow={(data) => !data.processed}
                    allSortable
                    emptyMessage={`${intl.formatMessage({ id: 'empty.table.info' }, {
                        baseMessage: intl.formatMessage({ id: 'empty.unloading' }),
                        date: date ? intl.formatDate(date) : undefined,
                        vehicle: vehicleOptions.find(o => Number(o.value) === selectedVehicleId)?.label
                    })}`}
                />
            }
        />
    );
};
