import './PassengerInformationPage.scss';

import { Action, CheckElementOptionType, CheckList, CheckListType, convertNumberToBinary, ModalConfirmation, Table, TableColumn, TableValueType, ToastModel, ToastType } from '@ceccli/design-system';
import { PassengerInformation, PassengerInformationKey, PassengerInformationTargetType, PassengerInformationType } from '../../../models/PassengerInformation/PassengerInformation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { formatExploitationTime } from '../../../common/helper';
import moment from 'moment';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { PassengerInformationModal } from '../../modals/PassengerInformationModal';
import { PassengerInformationSocketService } from '../../../services/passengerInformationSocket.service';
import { pushToast } from '../../../app/ToastStore';
import { RootState } from '../../../app/store';
import { SaphirParameterKeys } from '../../../models/SaphirParameter';
import { useIntl } from 'react-intl';

export const PassengerInformationPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const passengerInformationSocketService = new PassengerInformationSocketService();

    const informationList = useSelector<RootState, Array<PassengerInformation>>(state => state.passengerInformation.passengerInformation);
    const apiService = new ApiService();
    const [ passengerInformation, setPassengerInformation ] = useState<PassengerInformation>(new PassengerInformation(-1, new Date(), new Date(), '', [], 0, PassengerInformationTargetType.Vehicles, [], {}));
    const [ openModalDelete, setOpenModalDelete ] = useState<boolean>(false);
    const [ openModalPassengerInformation, setOpenModalPassengerInformation ] = useState<boolean>(false);
    const [ vehicles, setVehicles ] = useState<Array<CheckElementOptionType>>([]);
    const [ lines, setLines ] = useState<Array<CheckElementOptionType>>([]);
    const [ stops, setStops ] = useState<Array<CheckElementOptionType>>([]);
    const [ startExploitation, setStartExploitation ] = useState<number>();
    const [ endExploitation, setEndExploitation ] = useState<number>();
    const [ modalType, setModalType ] = useState<Action>();
    const [ webSocket, setWebSocket ] = useState<WebSocket>();

    useEffect(() => {
        const ws = passengerInformationSocketService.initWebsocket();
        setWebSocket(ws);
        apiService.getAllVehicles().then(res => setVehicles(res.map(v => ({ label: v.parkId, value: v.id }))));
        apiService.getAllLines().then(res => setLines(res.map(l => ({ label: l.label, value: l.id }))));
        apiService.getStops(0).then(res => setStops(res.map(s => ({ label: s.label, value: s.id }))));

        return () => {
            ws?.close();
        };
    }, []);

    useEffect(() => {
        apiService.getSaphirParam(SaphirParameterKeys.ExploitationStart).then(res => setStartExploitation(formatExploitationTime(res)));
        apiService.getSaphirParam(SaphirParameterKeys.ExploitationEnd).then(res => setEndExploitation(formatExploitationTime(res)));
    }, []);

    const renderApplication = (data: {[key: string]: TableValueType }) => {
        return (
            <div>
                <div>{data.startDate}</div>
                <div>{data.endDate}</div>
                <div className="sub-value">{renderInfoType(data.informationType)}</div>
                <CheckList title={intl.formatMessage({ id: 'authorization.days' })} type={CheckListType.Weekday} active={convertNumberToBinary(Number(data.activeDays))} />
            </div>
        );
    };

    const renderInfoType = (type: TableValueType) => {
        switch (type) {
            case PassengerInformationType.Commercial:
                return 'Commercial';
            case PassengerInformationType.Perturbation:
                return 'Perturbation';
            case PassengerInformationType.PC:
                return 'PC';
            case PassengerInformationType.PCPriority:
                return 'PC priority';
            case PassengerInformationType.PI:
                return 'PI';
            case PassengerInformationType.PIPriority:
                return 'PI priority';
            default:
                return type;
        }
    };

    const renderTarget = (data: {[key: string]: TableValueType }) => {
        switch (data.targetType) {
            case PassengerInformationTargetType.Global:
                return intl.formatMessage({ id: 'park.all' });
            case PassengerInformationTargetType.Vehicles:
                return renderTargetsTypes('vehicles', data.targets);
            case PassengerInformationTargetType.Lines:
                return renderTargetsTypes('lines', data.targets);
            case PassengerInformationTargetType.Stops:
                return renderTargetsTypes('stops', data.targets);
            case PassengerInformationTargetType.Panels:
                return renderTargetsTypes('panels', data.targets);
            default:
                console.log('Target not found', data.targetType, data.targets);
                return undefined;
        }
    };

    const renderTargetsTypes = (type: string, targets: TableValueType) => {
        return (
            <div>
                <div>{intl.formatMessage({ id: type })}</div>
                <div className="sub-value d-flex flex-column">
                    {targets && typeof targets === 'string' && targets.split('|').map(t => <div>{t}</div>)}
                </div>
            </div>
        );
    };

    const renderInformation = (data: {[key: string]: TableValueType }) => {
        return data.pcMessage ?
            <div className="d-flex gap-3 align-items-center">
                <div className="mock-image">{data.image}</div>
                <div style={{
                    fontFamily: data.fontFamily as string,
                    fontWeight: data.fontWeight === 'N' ? 'regular' : 'bold',
                    fontSize: data.fontSize as number,
                }}>{data.text}</div>
            </div> :
            <div>
                { (Array.isArray(data.information) ? data.information : [ data.information ]).map(i => <div>{i}</div>) }
            </div>;
    };

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'application' }), key: 'startDate', renderCell: renderApplication },
        { label: intl.formatMessage({ id: 'hours' }), key: 'timestamp' },
        { label: intl.formatMessage({ id: 'key' }), key: 'informationKey' },
        { label: intl.formatMessage({ id: 'passenger.information' }), key: 'information', renderCell: renderInformation },
        { label: intl.formatMessage({ id: 'target' }), key: 'targets', renderCell: renderTarget },
        { label: intl.formatMessage({ id: 'state' }), key: 'state' },
    ];

    const getInformation = (informations: Array<string>, keys: Array<string>): {[ key: string]: string} => {
        if (!informations || !keys || informations.length !== keys.length) {
            return {};
        }
        return Object.assign({}, ...keys.map((key, i) => ({ [key]: informations[i] })));
    };

    const onAction = (kind: Action, data?: any) => {
        let information = new PassengerInformation(-1, new Date(), new Date(), '', [], 0, PassengerInformationTargetType.Vehicles, [], {});
        if (data) {
            information = new PassengerInformation(data.id, new Date(data.startDateString), new Date(data.endDateString), data.informationType, data.times.map((time: string) => time.split(' - ')), data.activeDays, data.targetType, data.targetsValues, getInformation(data.information, data.keys));
        }
        switch (kind) {
            case Action.Create:
                setOpenModalPassengerInformation(true);
                setPassengerInformation(information);
                setModalType(Action.Create);
                break;
            case Action.Update:
                setOpenModalPassengerInformation(true);
                setPassengerInformation(information);
                setModalType(Action.Update);
                break;
            case Action.Duplicate:
                setOpenModalPassengerInformation(true);
                setPassengerInformation(information);
                setModalType(Action.Duplicate);
                break;
            case Action.Delete:
                setOpenModalDelete(true);
                setPassengerInformation(information);
                break;
            default:
                break;
        }
    };

    const handleEvent = (func: (ws: WebSocket, data: PassengerInformation) => void) => {
        if (webSocket && passengerInformation) {
            func(webSocket, passengerInformation);
        }
    };

    const createInformation = () => {
        handleEvent(passengerInformationSocketService.createPassengerInformation);
    };

    const updateInformation = () => {
        handleEvent(passengerInformationSocketService.updatePassengerInformation);
    };

    const handleInformation = () => {
        switch (modalType) {
            case Action.Create:
            case Action.Duplicate:
                createInformation();
                break;
            case Action.Update:
                updateInformation();
                break;
            default:
                break;
        }
    };

    const deleteInformation = () => {
        handleEvent(passengerInformationSocketService.deletePassengerInformation);
        dispatch(pushToast(
            new ToastModel(intl.formatMessage({ id: 'message.success.action' },
                { object: intl.formatMessage({ id: 'passenger.information' }), action: intl.formatMessage({ id: `${Action.Delete.toLowerCase()}d` }).toLowerCase() },
            ), ToastType.Success)
        ));
    };

    const getObjectId = (list: Array<CheckElementOptionType>, target: number): CheckElementOptionType => {
        const value = list.find(v => Number(v.value) === Number(target));
        if (!value) {
            return { label: '', value: '' };
        }
        return value;
    };

    const getTargetsLabel = (targets: Array<number>, broadcasts: Array<CheckElementOptionType>, value: boolean) => {
        return targets
            .map(t => getObjectId(broadcasts, t))
            .map(t => (value ? `${t.value} - ${t.label}` : t.label))
            .reduce((a, b) => `${a} | ${b}`);
    };

    const getTargets = (targets: Array<number>, targetType: PassengerInformationTargetType) => {
        if (!targets.length) {
            return undefined;
        }
        switch (targetType) {
            case PassengerInformationTargetType.Vehicles:
                return getTargetsLabel(targets, vehicles, false);
            case PassengerInformationTargetType.Lines:
                return getTargetsLabel(targets, lines, true);
            case PassengerInformationTargetType.Stops:
                return getTargetsLabel(targets, stops, true);
            default:
                return getTargetsLabel(targets, vehicles, false);
        }
    };

    const getTime = (time: string) => {
        return moment(Number(time) * 1000).format('mm:ss');
    };

    const getInformationKey = (informations: Array<string>): Array<string> => {
        const array: Array<string> = [];
        informations.forEach(information => {
            switch (information) {
                case PassengerInformationKey.VehS1:
                    array.push('VEH S1');
                    break;
                case PassengerInformationKey.VehS2:
                    array.push('VEH S2');
                    break;
                case PassengerInformationKey.BivS1:
                    array.push('BIV S1');
                    break;
                default:
                    array.push(information);
                    break;
            }
        });
        return array;
    };

    return (
        <>
            <PageFillUnderHeader
                content={
                    <Table
                        title={intl.formatMessage({ id: 'passenger.information' })}
                        columns={columns}
                        staticData={{
                            data: [ ...informationList ].map(i => ({
                                ...i,
                                times: i.times.map(t => (t.length ? t.reduce((a, b) => `${a} - ${b}`) : '')),
                                timestamp: i.times.map(t => (t.length ? t.reduce((a, b) => `${getTime(a)} - ${getTime(b)}`) : '')),
                                keys: Object.keys(i.information),
                                information: Object.values(i.information),
                                informationKey: getInformationKey(Object.keys(i.information)),
                                targets: getTargets(i.targets, i.targetType),
                                targetsValues: i.targets,
                                state: i.endDate.getTime() < Date.now() ?
                                    intl.formatMessage({ id: 'finished' }) :
                                    i.startDate.getTime() > Date.now() ? intl.formatMessage({ id: 'scheduled' }) : intl.formatMessage({ id: 'on.going' }),
                                startDate: intl.formatDate(i.startDate),
                                endDate: intl.formatDate(i.endDate),
                                startDateString: i.startDate.toString(),
                                endDateString: i.endDate.toString(),
                                image: i.pcMessage?.image,
                                fontWeight: i.pcMessage?.fontWeight,
                                fontSize: i.pcMessage?.fontSize,
                                fontFamily: i.pcMessage?.fontFamily,
                                text: i.pcMessage?.text,
                                pcMessage: !!i.pcMessage,
                            }))
                        }}
                        options={{ create: true, update: true, duplicate: true, delete: true }}
                        onAction={onAction}
                    />
                }
            />
            {
                passengerInformation && openModalDelete &&
                <ModalConfirmation action={Action.Delete} entity={intl.formatMessage({ id: 'delete.modal.message' })} name={intl.formatMessage({ id: 'delete.modal.message.this' })} onClose={() => setOpenModalDelete(false)} onSave={deleteInformation} />
            }
            {
                passengerInformation && openModalPassengerInformation && modalType && startExploitation && endExploitation &&
                <PassengerInformationModal action={modalType} data={passengerInformation} vehicles={vehicles} lines={lines} stops={stops} startExploitation={startExploitation} endExploitation={endExploitation} setData={setPassengerInformation} onClose={() => setOpenModalPassengerInformation(false)} onSave={handleInformation} />
            }
        </>
    );
};
