import { CartographyKeys, CartographyObject } from './CartographyObject';
import { DelayColors, SwitchCartography } from '../../app/mapDataStore';

import AutomobileIcon from '../../assets/automobile.svg';
import { Color } from '@ceccli/design-system';
import { Icon } from 'ol/style';
import { Line } from '../Line';

const defaultBackground = 'grey';

export const vehicleIconSize = {
    width: 108,
    height: 102,
    scale: 0.5,
};

export enum LocationSituation {
    TerminusDeparture = 11,
    InRace = 12,
    InRaceStopped = 13,
    TerminusArrival = 14,
}

export enum SituationDelay {
    largeAdvance = -2,
    smallAdvance = -1,
    onTime = 0,
    smallDelay = 1,
    largeDelay = 2,
    relocated = 0xD,
}

export enum SituationDelayDeparture {
    smallAdvance = -1,
    onTime = 0,
    smallDelay = 1,
    largeDelay = 2,
}

export class VehicleCartography extends CartographyObject {
    heading: number;
    time: string;
    onClick: () => void;
    icon: Icon;
    bodyId: string;
    advanceDelay: number;
    agentId?: number;
    missionId?: number;
    missionNumber?: number;
    line?: Line;
    startStop?: number;
    endStop?: number;
    previousStop?: number;
    nextStop?: number;
    lineId?: number;
    pathId?: number;
    locationSituation?: LocationSituation;
    situationDelay?: SituationDelay | SituationDelayDeparture;

    constructor(
        id: number,
        position: [number, number],
        positionLambert: [number, number],
        heading: number,
        time: string,
        onClick: () => void,
        bodyId: string,
        advanceDelay: number,
        label: string,
        agentId?: number,
        missionId?: number,
        missionNumber?: number,
        line?: Line,
        startStop?: number,
        endStop?: number,
        previousStop?: number,
        nextStop?: number,
        lineId?: number,
        pathId?: number,
        locationSituation?: LocationSituation,
        situationDelay?: SituationDelay | SituationDelayDeparture,
        delayColors?: DelayColors,
        switchCartography?: SwitchCartography,
    ) {
        super(id, CartographyKeys.Vehicle, label, position, undefined, positionLambert);
        this.heading = heading;
        this.agentId = agentId;
        this.advanceDelay = advanceDelay;
        this.missionId = missionId;
        this.missionNumber = missionNumber;
        this.bodyId = bodyId;
        this.time = time;
        this.onClick = onClick;
        this.line = line;
        this.icon = generateVehicleIcon(this, delayColors, switchCartography);
        this.startStop = startStop;
        this.endStop = endStop;
        this.previousStop = previousStop;
        this.nextStop = nextStop;
        this.lineId = lineId;
        this.pathId = pathId;
        this.locationSituation = locationSituation;
        this.situationDelay = situationDelay;
    }

    static icons = [
        new Icon({
            src: AutomobileIcon,
        })
    ];
}

export const getDelayColor = (vehicle: VehicleCartography, colors?: DelayColors) => {
    const value = vehicle.advanceDelay;
    const line = vehicle.line;

    return line ?
        value > -line.smallAdvance && value < line.smallDelay ? (colors?.onTime ?? Color.OntimeColor) :
            value <= -line.smallAdvance && value > -line.largeAdvance ? (colors?.smallAdvance ?? Color.SmallAdvanceColor) :
                value <= -line.largeAdvance ? (colors?.largeAdvance ?? Color.LargeAdvanceColor) :
                    value >= line.smallDelay && value < line.largeAdvance ? (colors?.smallDelay ?? Color.SmallDelayColor) :
                        (colors?.largeDelay ?? Color.LargeDelayColor) :
        undefined;
};

export const generateVehicleIcon = (vehicle: VehicleCartography, delayColors?: DelayColors, switchCartography?: SwitchCartography) => {
    const delayColor = getDelayColor(vehicle, delayColors);
    const lineColor = vehicle.line?.background ?? defaultBackground;

    const background = switchCartography?.color ? delayColor : lineColor;
    const dot = switchCartography?.color ? lineColor : delayColor;

    const inlineSvg = `
        <svg width="${vehicleIconSize.width}" height="${vehicleIconSize.height}" viewBox="0 0 108 102" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M86.9545 101.409H19.9091L19.9091 27.5454C30.138 10.321 38.1804 5.25352 55.1364 1.40906H86.9545V101.409Z" fill="${background}" stroke="black"/>
            <path d="M-1.0455 50.2727L19.409 36.495L19.409 64.0504L-1.0455 50.2727Z" fill="red"/>
            <circle cx="54" cy="51.4091" r="30.6818" transform="rotate(-90 54 51.4091)" fill="white"/>
            <circle cx="74.4545" cy="13.9091" r="7.45455" transform="rotate(-90 74.4545 13.9091)" fill="${dot}" stroke="black"/>
        </svg>

    `;
    const blob = new Blob([ inlineSvg ], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    return (
        new Icon({
            src: url,
            scale: vehicleIconSize.scale,
            anchor: [ 0, 0.5 ],
            rotateWithView: true,
            rotation: (vehicle.heading) * Math.PI / 180,
        })
    );
};
