import { Card, CheckElementOptionType, DatePicker, SelectFilter, splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { TechnicalWarning } from '../../../models/TechnicalWarning';
import { useIntl } from 'react-intl';

export const TechnicalWarningPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();

    const [ date, setDate ] = useState<Date | undefined>(new Date());
    const [ vehicleOptions, setVehicleOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ selectedVehicleId, setSelectedVehicleId ] = useState<number>();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'date' }), key: 'date', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId', },
        { label: intl.formatMessage({ id: 'warning.label' }), key: 'label' },
        { label: intl.formatMessage({ id: 'additional.text' }), key: 'additionalText' },
        { label: intl.formatMessage({ id: 'action' }), key: 'action' },
    ];

    useEffect(() => {
        apiService.getAllVehicles().then(res => setVehicleOptions(res.map(v => ({ label: v.parkId, value: v.id.toString() }))));
    }, []);

    return (
        <PageFillUnderHeader
            header={
                <Card title={intl.formatMessage({ id: 'technical.warnings' })} contentClass="p-3">
                    <div className="d-block d-md-flex justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 pe-0 pe-md-4 mb-2 mb-md-0">
                            <DatePicker
                                label={intl.formatMessage({ id: 'exploitation.date' })}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                onChange={setDate}
                                defaultDate={new Date()}
                                options={{ all: true, today: true }}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 pe-1">
                            <SelectFilter
                                label={intl.formatMessage({ id: 'park.id' })}
                                options={vehicleOptions}
                                buttonOptions={{ all: true }}
                                placeHolder={intl.formatMessage({ id: 'select.vehicle' })}
                                onChange={e => setSelectedVehicleId(e[0] ? Number(e[0].value) : undefined)}
                                masculine={true}
                            />
                        </div>
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getTechnicalWarnings(pageIndex, pageSize, sorting, date, selectedVehicleId),
                        formatData: (data: TechnicalWarning) => {
                            return ({
                                ...data,
                                date: splitDateTime(intl, data.date),
                            });
                        },
                        refreshTrigger: [ date, selectedVehicleId ],
                    }}
                    emptyMessage={`${intl.formatMessage({ id: 'empty.table.info' }, {
                        baseMessage: intl.formatMessage({ id: 'empty.technical.warning' }),
                        date: date ? intl.formatDate(date) : undefined,
                        vehicle: vehicleOptions.find(o => Number(o.value) === selectedVehicleId)?.label
                    })}`}
                    options={{ export: true }}
                    allSortable
                />
            }
        />
    );
};
