import './ExpandArrow.scss';

import { ColorVariable } from '../../../common/color';
import { useState } from 'react';

export interface ExpandArrowProps {
    angle?: number;
    size?: number;
    expanded?: boolean;
    color?: ColorVariable | string;
    noAnimation?: boolean;
    strokeSize?: number;
    onClick?: (expanded: boolean) => void;
}

export const ExpandArrow = (props: ExpandArrowProps) => {
    const angle = props.angle ?? 0;
    const size = props.size ? `${props.size}px` : '100%';

    const [ expanded, setExpanded ] = useState<boolean>(props.expanded ?? false);

    return (
        <div
            className={`arrow ${(!props.noAnimation && expanded) ? 'expanded ' : ''}`}
            style={{
                transform: `rotate(${angle}deg)`,
                width: size,
                height: size,
            }}
            onClick={() => {
                if (props.onClick) {
                    props.onClick(!expanded);
                }
                setExpanded(!expanded);
            }}
            data-testid="expand-arrow"
        >
            <div className="left" style={{ height: props.strokeSize, background: props.color }}></div>
            <div className="right" style={{ height: props.strokeSize, background: props.color }}></div>
        </div>
    );
};
