import moment from 'moment';

export class UtilsService {
    getUnique = <T, >(arr: Array<T>): Array<T> => {
        return arr.filter((value, index, self) => self.indexOf(value) === index);
    };

    getUniqueByAttribute = <T, K extends keyof T>(arr: Array<T>, attribute: K): Array<T> => {
        return arr.filter((val, index, self) => {
            const i = self.findIndex(v => v[attribute] === val[attribute]);
            return i === index;
        });
    };

    groupBy = (array: any[], key: string | number) => {
        return array.reduce((result: any, currentValue: { [x: string]: string | number; }) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    formatVersion = (version: number): string => {
        const versionStr = `v${version}`;
        return versionStr.match(/.{1,2}/g)?.join('.') ?? '';
    };

    formatDelay = (advanceDelay: number) => {
        return ((Math.sign(advanceDelay) >= 0 ? '+' : '-') + moment.utc(Math.abs(advanceDelay) * 1000).format('HH:mm:ss'));
    };

    splitDateTime = (dateStr: string) => {
        const date = new Date(String(dateStr));
        return [ date.toLocaleDateString(), date.toLocaleTimeString() ];
    };

    convertFormatDate = (date: Date) => {
        return moment(date).format('YYYY-MM-DD');
    };
}
