import { Card, CheckElementOptionType, Select, splitDateTime, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { ConnectionHistory } from '../../../models/ConnectionHistory';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { useIntl } from 'react-intl';

export const ConnectionHistoryPage = () => {
    const intl = useIntl();
    const apiService = new ApiService();
    const [ vehicleOptions, setVehicleOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ selectedVehicle, setSelectedVehicle ] = useState<string>();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'connection.time' }), key: 'time', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'entity' }), key: 'entity' },
    ];

    useEffect(() => {
        apiService.getAllVehicles().then(res => setVehicleOptions(res.map(v => ({ label: v.parkId, value: v.parkId }))));
    }, []);

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'connection.history' })}
                    contentClass="p-3"
                >
                    <Select
                        label={intl.formatMessage({ id: 'park.id' })}
                        placeHolder={intl.formatMessage({ id: 'select.vehicle' })}
                        options={vehicleOptions}
                        onChange={(e) => setSelectedVehicle(e[0] ? String(e[0].value) : undefined)}
                        isClearable
                    />
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getConnectionHistory(pageIndex, pageSize, sorting, selectedVehicle),
                        formatData: (data: ConnectionHistory) => {
                            return ({
                                ...data,
                                time: splitDateTime(intl, data.time),
                                entity: intl.formatMessage({ id: `connection.entity.${data.entity.toLowerCase()}` })
                            });
                        },
                        refreshTrigger: [ selectedVehicle ],
                    }}
                    options={{ export: true }}
                    allSortable
                    emptyMessage={`${intl.formatMessage({ id: 'empty.table.info' }, {
                        baseMessage: intl.formatMessage({ id: 'empty.unloading' }),
                        date: undefined,
                        vehicle: selectedVehicle,
                    })}`}
                />
            }
        />
    );
};
