import { ColorVariable } from '../../../common/color';

export interface LoaderProps {
    size?: number;
    color?: ColorVariable | string;
}

export const Loader = (props: LoaderProps) => {
    const size = props.size ?? 64;
    const color = props.color ?? ColorVariable.Primary;

    return (
        <div
            className="d-flex align-items-center justify-content-center loader-container"
            style={{
                width: size,
                height: size,
            }}
            data-testid="loader"
        >
            <div
                className="loader"
                style={{
                    color: color as string,
                    fontSize: size / 2,
                }}
            />
        </div>
    );
};
