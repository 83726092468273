import { Action, Button, Card, CheckElementOptionType, Input, Modal, ModalConfirmation, Select, Table, TableColumn, ToastModel, ToastType } from '@ceccli/design-system';
import { EquipementType, SoftwareType } from '../../../common/enum';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { Calculator } from '../../../models/Calculator';
import { EmbeddedSoftware } from '../../../models/EmbeddedSoftware';
import { pushToast } from '../../../app/ToastStore';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { UtilsService } from '../../../services/utilsService';

const utilsService = new UtilsService();

export const EmbeddedSoftwarePage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const [ tablesData, setTablesData ] = useState<{ [key: string]: Array<{ [key: string]: number | string }> }>({});
    const [ calculators, setCalculators ] = useState<Array<Calculator>>([]);
    const [ software, setSoftware ] = useState<EmbeddedSoftware>();
    const [ refresh, setRefresh ] = useState<boolean>(false);
    const [ openDeleteModal, setOpenDeleteModal ] = useState<boolean>(false);
    const [ infoModalText, setInfoModalText ] = useState<string>();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'version' }), key: 'formattedVersion' },
        { label: intl.formatMessage({ id: 'calculator' }), key: 'calculator' },
        { label: intl.formatMessage({ id: 'file.name' }), key: 'fileName' },
        { label: intl.formatMessage({ id: 'file.size' }), key: 'fileSize' },
    ];

    const equipmentOptions = [
        { label: intl.formatMessage({ id: 'vehicle' }), value: 'VEH' },
        { label: intl.formatMessage({ id: 'desk' }), value: 'PUP' },
        { label: intl.formatMessage({ id: 'panel.satellite' }), value: 'BRN' },
    ];
    const [ selectedEquipment, setSelectedEquipment ] = useState<CheckElementOptionType>(equipmentOptions[0]);
    const [ file, setFile ] = useState<File>();
    const calculatorOptions = calculators.filter(c => c.target === selectedEquipment.value).map(c => ({ label: c.name, value: c.id.toString() }));
    const [ selectedCalculator, setSelectedCalculator ] = useState<CheckElementOptionType>(calculatorOptions[0]);
    const [ availableSoftwares, setAvailableSoftwares ] = useState<Array<any>>();

    const noyauTr = 'NoyauTR';
    const tftDiaporama = 'TFTDiaporama';
    const noyauPupitre = 'NoyauPupitre';
    const allowedExtensions = [ 'lod', 'exe' ];
    const regex = `^(${noyauTr}|${tftDiaporama}|${noyauPupitre})_[\\w.-]+(\\.(${allowedExtensions.map(e => e).join('|')}))?$`;

    const dispatch = useDispatch();

    useEffect(() => {
        Promise.all([
            apiService.getCalculators(),
            apiService.getEmbeddedSoftwares(),
        ]).then(([ calculators, softwares ]) => {
            const tmpData: any = utilsService.groupBy(softwares.map(d => ({
                ...d,
                calculator: calculators.find(c => c.id === d.typeCalculator)?.name ?? '',
                fileSize: `${d.fileSize} bytes`,
                formattedVersion: utilsService.formatVersion(d.version),
                typeSoftwareExpanded: expandTypeSoftware(d.typeSoftware)
            })), 'typeSoftwareExpanded');

            setTablesData(tmpData);
            setAvailableSoftwares(softwares);
            setCalculators(calculators);
        });
    }, [ refresh ]);

    useEffect(() => {
        setSelectedCalculator(calculatorOptions[0]);
    }, [ selectedEquipment, calculators ]);
    const [ fileNameError, setFileNameError ] = useState<boolean>(true);

    useEffect(() => {
        if (file && new RegExp(regex, 'g').test(file.name)) {
            setFileNameError(false);
        }
    }, [ file ]);

    const expandTypeSoftware = (typeSoftware: string): string => {
        switch (typeSoftware) {
            case SoftwareType.BNT:
                return intl.formatMessage({ id: 'software.bnt.expanded' });
            case SoftwareType.BTD:
                return intl.formatMessage({ id: 'software.btd.expanded' });
            case SoftwareType.NTR:
                return intl.formatMessage({ id: 'software.ntr.expanded' });
            case SoftwareType.PUP:
                return intl.formatMessage({ id: 'software.pupitre.expanded' });
            case SoftwareType.TFT:
                return intl.formatMessage({ id: 'software.tft.expanded' });
            default:
                return 'typeSoftware';
        }
    };

    const onAction = (action: Action, data?: Object) => {
        let embbededSoftware: EmbeddedSoftware | undefined;
        if (data) {
            embbededSoftware = data as EmbeddedSoftware;
        }
        switch (action) {
            case Action.Delete:
                if (embbededSoftware) {
                    const soft = embbededSoftware;
                    apiService.isEmbeddedSoftwareDeployed(soft.typeSoftware, soft.typeCalculator, soft.version).then(res => {
                        if (res) {
                            setInfoModalText('embedded.software.deployed');
                        } else {
                            apiService.isEmbeddedSoftwareDeploymentProgrammed(soft.typeSoftware, soft.typeCalculator, soft.version).then(res => {
                                if (res) {
                                    setInfoModalText('embedded.software.programmed');
                                } else {
                                    setOpenDeleteModal(true);
                                    setSoftware(soft);
                                }
                            });
                        }
                    });
                }
                break;
            default:
                break;
        }
    };

    const deleteSoftware = async () => {
        if (software) {
            await apiService.deleteSoftware(software);
            setRefresh(!refresh);
        }
    };

    const extractVersion = (filename: string) => {
        const extension = filename.endsWith('.exe') ? '.exe' : '.lod';
        const siteIncluded: boolean = filename.includes('-');
        return siteIncluded ? filename.split('_')[1].split('-')[0].split('.').map((c, index) => (index === 0 || c.length === 2 ? c : `0${c}`)).join('') :
            filename.split('_')[1].split(extension)[0].split('.').map((c, index) => (index === 0 || c.length === 2 ? c : `0${c}`)).join('');
    };
    const createEmbeddedSoftware = () => {
        if (file) {
            let typeLogiciel = '';
            const version = extractVersion(file.name);
            if (new RegExp(`^${noyauTr}_.*(\.lod|\.exe)$`).test(file.name)) {
                switch (selectedEquipment.value) {
                    case EquipementType.VEH:
                        typeLogiciel = SoftwareType.NTR;
                        break;
                    case EquipementType.BRN:
                        typeLogiciel = SoftwareType.BNT;
                        break;
                    default:
                        dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'embeddded.software.toast.functional.error' }), ToastType.Error)));
                        return;
                }
            } else if (new RegExp(`^${tftDiaporama}_.*(\.exe)$`).test(file.name)) {
                switch (selectedEquipment.value) {
                    case EquipementType.VEH:
                        typeLogiciel = SoftwareType.TFT;
                        break;
                    case EquipementType.BRN:
                        typeLogiciel = SoftwareType.BTD;
                        break;
                    default:
                        dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'embeddded.software.toast.functional.error' }), ToastType.Error)));
                        return;
                }
            } else if (new RegExp(`^${noyauPupitre}_.*(?<!\.lod|\.exe)$`).test(file.name)) {
                typeLogiciel = SoftwareType.PUP;
            } else {
                dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'embeddded.software.toast.functional.error' }), ToastType.Error)));
                return;
            }
            if (availableSoftwares?.find(c => c.typeSoftware === typeLogiciel
                && c.typeCalculator === parseInt(String(selectedCalculator?.value))
                    && c.fileName === file.name)) {
                dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'embeddded.software.toast.info' }), ToastType.Error)));
                return;
            }

            const data = new FormData();
            data.append('file', file);
            data.append('soft', JSON.stringify(
                { typeSoftware: typeLogiciel,
                    typeCalculator: parseInt(String(selectedCalculator?.value)),
                    version: parseInt(version),
                    fileName: file.name,
                    fileSize: file.size })
            );
            apiService.createEmbeddedSoftware(data).then(() => {
                dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'embeddded.software.toast.success' }), ToastType.Success)));
                setRefresh(!refresh);
            });
        }
    };

    return (
        <div className="p-4 h-100 overflow-auto">
            <div className="col-sm-6 mb-5 mx-auto">
                <Card title={intl.formatMessage({ id: 'add.embedded.software' })} contentClass="p-4">
                    <>
                        <Input
                            label={intl.formatMessage({ id: 'select.software.file' })}
                            type="file"
                            onChange={(v, n, e) => e?.target.files && setFile(e.target.files[0])}
                            required
                            error={fileNameError}
                            errorMessage={file ? intl.formatMessage({ id: 'embeddded.software.toast.format.error' }) : undefined }
                        />
                        <Select
                            label={intl.formatMessage({ id: 'select.type.equipment' })}
                            value={[ selectedEquipment ]}
                            options={equipmentOptions}
                            onChange={(e) => setSelectedEquipment(e[0])}
                            className="mt-2"
                        />
                        <Select
                            label={intl.formatMessage({ id: 'select.type.calculator' })}
                            value={[ selectedCalculator ]}
                            options={calculators.filter(c => c.target === selectedEquipment.value).map(c => ({ label: c.name, value: c.id.toString() }))}
                            onChange={(e) => setSelectedCalculator({ label: e[0].label, value: String(e[0].value) })}
                            className="mt-2"
                        />
                        <div className="form-button">
                            <Button
                                text={intl.formatMessage({ id: 'software.send' })}
                                onClick={() => { createEmbeddedSoftware(); }}
                                disabled={fileNameError || !selectedCalculator }
                            />
                        </div>
                    </>
                </Card>
            </div>
            {
                Object.keys(tablesData).map(k => {
                    return (
                        <div className="mb-5">
                            <Table
                                title={k}
                                columns={columns}
                                staticData={{ data: tablesData[k] }}
                                options={{ delete: true }}
                                onAction={onAction}
                            />
                        </div>
                    );
                })
            }

            {
                openDeleteModal &&
                <ModalConfirmation action={Action.Delete} entity={intl.formatMessage({ id: 'delete.modal.software' })} name={intl.formatMessage({ id: 'delete.modal.software.this' })} onClose={() => setOpenDeleteModal(false)} onSave={() => deleteSoftware()} />
            }

            {
                infoModalText &&
                <Modal
                    title={`${intl.formatMessage({ id: 'modal.delete' })} ${intl.formatMessage({ id: 'delete.modal.software' })}`}
                    onClose={() => setInfoModalText(undefined)}
                >
                    <div className="text-center">
                        <div className="mb-3">
                            {intl.formatMessage(
                                { id: 'embedded.software.deletion.issue' },
                                { explanation: intl.formatMessage({ id: infoModalText }) })
                            }
                        </div>
                        <Button
                            text={intl.formatMessage({ id: 'close' })}
                            onClick={() => setInfoModalText(undefined)}
                        />
                    </div>
                </Modal>
            }
        </div>
    );
};
