import './Collapsible.scss';

import { Icon, IconType } from '../../atoms';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { ColorVariable } from '../../../common/color';

export interface CollapsibleProps {
    title: string | ReactNode,
    content: string | ReactNode,
    className?: string,
    headerClassName?: string,
    color?: string,
    disabled?: boolean,
    onChange?: (open: boolean) => void,
}

export const Collapsible = (props: CollapsibleProps) => {
    const [ open, setOpen ] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(open);
        }
    }, [ props.onChange, open ]);

    return (
        <div className={`collapsible overflow-hidden ${open ? 'open' : ''} ${props.className}`}>
            <div
                className={`d-flex ${props.headerClassName ?? 'justify-content-between'}`}
                onClick={() => setOpen(props.disabled ? false : !open)}
            >
                <div>{props.title}</div>
                {
                    !props.disabled &&
                    <Icon
                        iconType={IconType.Angle}
                        color={props.color ?? ColorVariable.Secondary}
                        className="collapsible-icon my-auto"
                    />
                }
            </div>
            <div
                className="collapsible-collapse"
                style={{ maxHeight: contentRef.current?.clientHeight }}
            >
                <div ref={contentRef}>{props.content}</div>
            </div>
        </div>
    );
};
