import './Icon.scss';

import { useEffect, useState } from 'react';

import { ColorVariable } from '../../../common/color';

const DownloadIcon = require('./src/assets/download.svg').default;
const PlusIcon = require('./src/assets/plus.svg').default;
const HomeIcon = require('./src/assets/home.svg').default;
const SettingsIcon = require('./src/assets/settings.svg').default;
const CartographyIcon = require('./src/assets/cartography.svg').default;
const DiskIcon = require('./src/assets/disk.svg').default;
const WifiIcon = require('./src/assets/wifi.svg').default;
const ArrowLeftIcon = require('./src/assets/arrow-left.svg').default;
const AudioIcon = require('./src/assets/audio.svg').default;
const ClockArrowIcon = require('./src/assets/clock-arrow.svg').default;
const CalendarClockIcon = require('./src/assets/calendar-clock.svg').default;
const BusCarIcon = require('./src/assets/bus-car.svg').default;
const NetworkIcon = require('./src/assets/network.svg').default;
const PreviousIcon = require('./src/assets/previous.svg').default;
const UsersIcon = require('./src/assets/users.svg').default;
const AngleIcon = require('./src/assets/angle.svg').default;
const CheckIcon = require('./src/assets/check.svg').default;
const FilmIcon = require('./src/assets/film.svg').default;
const ClockIcon = require('./src/assets/clock.svg').default;
const FileIcon = require('./src/assets/file.svg').default;
const DotIcon = require('./src/assets/dot.svg').default;
const TrashIcon = require('./src/assets/trash.svg').default;
const PencilIcon = require('./src/assets/pencil.svg').default;
const DuplicateIcon = require('./src/assets/duplicate.svg').default;
const AngleLeftIcon = require('./src/assets/angle-left.svg').default;
const AngleRightIcon = require('./src/assets/angle-right.svg').default;
const OutIcon = require('./src/assets/out.svg').default;
const BusIcon = require('./src/assets/bus.svg').default;
const UserIcon = require('./src/assets/user.svg').default;
const EyeIcon = require('./src/assets/eye.svg').default;
const EyeCrossIcon = require('./src/assets/eye-cross.svg').default;
const WrenchIcon = require('./src/assets/wrench.svg').default;
const EmptyFolderIcon = require('./src/assets/empty-folder.svg').default;
const CrossCircleIcon = require('./src/assets/cross-circle.svg').default;
const PhoneIcon = require('./src/assets/phone.svg').default;
const ResizeIcon = require('./src/assets/resize.svg').default;

export enum IconType {
    Angle = AngleIcon,
    ArrowLeft = ArrowLeftIcon,
    Audio = AudioIcon,
    BusCar = BusCarIcon,
    CalendarClock = CalendarClockIcon,
    Cartography = CartographyIcon,
    Check = CheckIcon,
    Clock = ClockIcon,
    ClockArrow = ClockArrowIcon,
    Disk = DiskIcon,
    Dot = DotIcon,
    Download = DownloadIcon,
    File = FileIcon,
    Film = FilmIcon,
    Home = HomeIcon,
    Network = NetworkIcon,
    Out = OutIcon,
    Plus = PlusIcon,
    Previous = PreviousIcon,
    Settings = SettingsIcon,
    Users = UsersIcon,
    Wifi = WifiIcon,
    Trash = TrashIcon,
    Pencil = PencilIcon,
    Duplicate = DuplicateIcon,
    AngleLeft = AngleLeftIcon,
    AngleRight = AngleRightIcon,
    Bus = BusIcon,
    User = UserIcon,
    Eye = EyeIcon,
    EyeCross = EyeCrossIcon,
    Wrench = WrenchIcon,
    EmptyFolder = EmptyFolderIcon,
    CrossCircle = CrossCircleIcon,
    Phone = PhoneIcon,
    Resize = ResizeIcon,
}

export interface IconProps {
    size?: number;
    color?: string | ColorVariable;
    src?: string;
    iconType?: IconType;
    className?: string;
    onClick?: () => void;
}

export const Icon = (props: IconProps) => {
    const size = props.size ?? 18;
    const color = props.color ?? ColorVariable.TextLight;
    const [ url, setUrl ] = useState<string | IconType>();

    useEffect(() => {
        if (props.src) {
            setUrl(props.src);
        } else if (props.iconType) {
            setUrl(props.iconType);
        }
    }, [ props.iconType, props.src ]);

    return (
        <div
            className={`icon ${props.className} ${props.onClick ? 'interactive-icon' : ''}`}
            style={{
                height: size,
                width: size,
                background: color,
                maskImage: `url(${url})`,
                WebkitMaskImage: `url(${url})`,
            }}
            onClick={props.onClick}
        />
    );
};
