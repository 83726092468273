import { Button, Card, CheckElementOptionType, Input, Select, Table, TableColumn, TableValueType, ToastModel, ToastType } from '@ceccli/design-system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { pushToast } from '../../../app/ToastStore';
import { Terminal } from '../../../models/Terminal';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

interface RenderCellData {
    updatedValue?: { [key: string]: string | number },
    impactedTerminals?: Array<CheckElementOptionType>,
    terminalId?: string,
}

export const TerminalSettingsPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const location = useLocation();
    const dispatch = useDispatch();

    const [ selectedTerminal, setSelectedTerminal ] = useState<{ [key: string]: TableValueType }>();
    const [ updatedValue, setUpdateValue ] = useState<{ [key: string]: string | number }>({});
    const [ triggerChange, setTriggerChange ] = useState<boolean>(false);
    const [ triggerUpdate, setTriggerUpdate ] = useState<boolean>(false);

    const { terminalId } = useParams();
    const navigate = useNavigate();

    const [ terminalColumns, setTerminalColumns ] = useState<Array<TableColumn>>([]);

    const [ terminalOptions, setTerminalOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ impactedTerminals, setImpactedTerminals ] = useState<Array<CheckElementOptionType>>([]);

    const columns = [{ label: intl.formatMessage({ id: 'terminal' }), key: 'label' }];

    const handleChange = (updateFunction: () => Promise<number>, label: string) => {
        let error = false;
        try {
            updateFunction();
        } catch {
            error = true;
        }
        dispatch(
            pushToast(
                new ToastModel(
                    intl.formatMessage({ id: `terminal.settings.${error ? 'error' : 'success'}` }, { label }),
                    error ? ToastType.Error : ToastType.Success,
                )
            )
        );
    };

    const renderCell = (row: { [key: string]: TableValueType }, data: RenderCellData) => {
        const param = String(row.param);
        const currentUpdates = data.updatedValue ?? {};
        const impactedList = data.impactedTerminals ?? [];

        return (
            <div className="d-flex gap-2">
                <Input
                    value={currentUpdates[param] ?? row.value ?? row.defaultValue}
                    onChange={e => {
                        if (e !== row.value) {
                            currentUpdates[param] = e;
                        } else {
                            delete currentUpdates[param];
                        }
                        setUpdateValue({ ...currentUpdates });
                        setTriggerChange(!triggerChange);
                    }}
                />
                T
                {
                    data.terminalId === 'all' &&
                    <Button
                        text={intl.formatMessage({ id: 'update' })}
                        disabled={!impactedList.length || typeof currentUpdates[param] === 'undefined'}
                        onClick={() => impactedList.map(o => handleChange(
                            () => apiService.updateTerminalParams({ [param]: currentUpdates[param] }, o.value ? Number(o.value) : undefined),
                            o.label,
                        ))}
                    />
                }
            </div>
        );
    };

    useEffect(() => {
        if (terminalId === 'all' && !terminalOptions.length) {
            apiService.getAllTerminals().then(res => setTerminalOptions(res.map(p => ({ label: `${p.type} - ${p.index} - ${p.name}`, value: p.id.toString() }))));
        }
    }, [ terminalId ]);

    useEffect(() => {
        if (terminalId) {
            switch (terminalId) {
                case 'all':
                    setSelectedTerminal({ label: intl.formatMessage({ id: 'multiple.configuration' }) });
                    break;
                default:
                    if (Number(terminalId)) {
                        apiService.getTerminal(Number(terminalId)).then(res => {
                            setSelectedTerminal({ id: res.id, label: `${res.type} - ${res.index} - ${res.name}` });
                        });
                    } else {
                        navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}`);
                    }
            }
        }
    }, [ terminalId ]);

    useEffect(() => {
        setTerminalColumns([
            { label: intl.formatMessage({ id: 'parameter' }), key: 'param' },
            { label: intl.formatMessage({ id: 'comment' }), key: 'comment' },
            { label: intl.formatMessage({ id: 'value' }), key: 'value', renderCell: (row, renderData) => renderCell(row, renderData as RenderCellData) }
        ]);
    }, [ triggerChange, impactedTerminals ]);

    return (
        <PageFillUnderHeader
            header={
                terminalId === 'all' &&
                <Card title={intl.formatMessage({ id: 'multiple.configuration.terminal' })} contentClass="p-3">
                    <Select
                        label={intl.formatMessage({ id: 'terminals.impacted' })}
                        options={terminalOptions}
                        placeHolder={intl.formatMessage({ id: 'select.terminal' })}
                        onChange={e => setImpactedTerminals(e)}
                        isClearable
                        isMulti
                    />
                </Card>
            }
            content={
                !selectedTerminal || !terminalId ?
                    <Table
                        title={intl.formatMessage({ id: 'configuration.terminal' })}
                        columns={columns}
                        dynamicData={{
                            getData: (pageIndex, pageSize) => apiService.getTerminals(pageIndex, pageSize),
                            formatData: (data: Terminal) => ({
                                id: data.id,
                                label: `${data.type} - ${data.index} - ${data.name}`
                            })
                        }}
                        options={{ export: true, update: true }}
                        onAction={(_, data) => {
                            if (data) {
                                setSelectedTerminal(data);
                                navigate(`/terminal-settings/${data.id}`);
                            }
                        }}
                    /> :
                    <Table
                        title={selectedTerminal.label}
                        columns={terminalColumns}
                        dynamicData={{
                            getData: (pageIndex, pageSize, sorting) => apiService.getTerminalParams(pageIndex, pageSize, sorting, selectedTerminal?.id ? Number(selectedTerminal?.id) : undefined),
                            refreshTrigger: [ selectedTerminal.id, triggerUpdate ]
                        }}
                        options={{ export: true }}
                        renderData={{ updatedValue, impactedTerminals, terminalId }}
                    />
            }
            footer={
                ((selectedTerminal && terminalId !== 'all') || !terminalId) &&
                <div className="text-center">
                    <Button
                        text={terminalId ? intl.formatMessage({ id: 'save.changes' }) : intl.formatMessage({ id: 'multiple.configuration' }) }
                        onClick={() => {
                            terminalId ?
                                handleChange(
                                    () => apiService.updateTerminalParams(updatedValue, selectedTerminal ? Number(selectedTerminal.id) : undefined).then(res => {
                                        setUpdateValue({});
                                        setTriggerUpdate(!triggerUpdate);
                                        return res;
                                    }),
                                    String(`${selectedTerminal?.label}`),
                                ) :
                                navigate('/terminal-settings/all');
                        }}
                        disabled={!!terminalId && !Object.keys(updatedValue).length}
                    />
                </div>
            }
            trigger={[ selectedTerminal ]}
        />
    );
};
