import './UsersManagementPage.scss';

import { Action, Button, Card, Checkbox, Input, Modal, Table, TableColumn, ToastModel, ToastType } from '@ceccli/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import bcrypt from 'bcryptjs';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { PlatformFunction } from '../../../models/PlatformFunction';
import { pushToast } from '../../../app/ToastStore';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { User } from '../../../models/User';

export const UsersManagementPage = () => {
    const apiService = new ApiService();

    const intl = useIntl();
    const location = useLocation();
    const { userId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ refresh, setRefresh ] = useState<boolean>(false);
    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'username' }), key: 'userName' },
        { label: intl.formatMessage({ id: 'lastname' }), key: 'lastName' },
        { label: intl.formatMessage({ id: 'firstname' }), key: 'firstName' },
    ];

    const [ selectedUser, setSelectedUser ] = useState<User>();
    const [ saveUser, setSaveUser ] = useState<User>();
    const [ permissionBinary, setPermissionBinary ] = useState<string>();

    const [ password, setPassword ] = useState<string>();
    const [ confirmPassword, setConfirmPassword ] = useState<string>();
    const [ confirmationModalType, setConfirmationModalType ] = useState<Action>();
    const [ userAlreadyUsed, setUserAlreadyUsed ] = useState<boolean>(false);
    const permissionList = useSelector<RootState, Array<PlatformFunction>>(state => state.user.permissionList);

    useEffect(() => {
        if (!userId) {
            resetChange();
        } else {
            const id = Number(userId);
            if (id && selectedUser?.id !== id) {
                apiService.getUsers(undefined, undefined, undefined, id).then(res => {
                    const user = res.results[0];
                    if (user) {
                        setSelectedUser(res.results[0]);
                        setSaveUser(res.results[0]);
                    } else {
                        navigate('/users-management/');
                    }
                });
            } else if (selectedUser?.id !== id) {
                navigate('/users-management/');
            }
        }
    }, [ userId ]);

    useEffect(() => {
        setPermissionBinary(selectedUser?.permission);
    }, [ selectedUser ]);

    useEffect(() => {
        if (selectedUser?.userName) {
            apiService.getUserByAttribute(undefined, selectedUser?.userName).then(res => setUserAlreadyUsed(!!(res?.userName && (res?.userName !== saveUser?.userName))));
        } else {
            setUserAlreadyUsed(false);
        }
    }, [ selectedUser?.userName, saveUser?.userName ]);

    const resetChange = () => {
        setSelectedUser(undefined);
        setSaveUser(undefined);
        setConfirmationModalType(undefined);
        setPassword(undefined);
        setConfirmPassword(undefined);
        navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/')));
    };

    const handleChanges = async (actionType: Action) => {
        let error = false;
        try {
            switch (actionType) {
                case Action.Create:
                    selectedUser && password && await apiService.createUser(selectedUser, bcrypt.hashSync(password, String(process.env.REACT_APP_SECURITY_PWD_SALT)));
                    break;
                case Action.Update:
                    selectedUser && await apiService.updateUser(selectedUser, password ? bcrypt.hashSync(password, String(process.env.REACT_APP_SECURITY_PWD_SALT)) : password);
                    break;
                case Action.Delete:
                    selectedUser && await apiService.deleteUser(selectedUser.id);
                    setRefresh(!refresh);
                    break;
                default:
                    break;
            }
        } catch {
            error = true;
        }
        dispatch(
            pushToast(
                new ToastModel(
                    intl.formatMessage({ id: `user.${error ? 'error' : 'success'}.${actionType.toLowerCase()}` }, { user: selectedUser?.userName }),
                    error ? ToastType.Error : ToastType.Success
                )
            )
        );

        resetChange();
    };

    const onAction = (kind: Action, data?: Object) => {
        const user = data ? data as User : undefined;
        switch (kind) {
            case Action.Update:
                setSelectedUser(user);
                setSaveUser(user);
                navigate(`/users-management/${user?.id}`);
                break;
            case Action.Create:
                const newUser = new User(-1, '', '', '', '', 0);
                setSelectedUser(newUser);
                navigate(`/users-management/${newUser?.id}`);
                break;
            case Action.Delete:
                setSelectedUser(user);
                setConfirmationModalType(Action.Delete);
                break;
            default:
                break;
        }
    };

    const updatePermissionBinary = (infoBit: number, checked: boolean) => {
        let perm = selectedUser?.permission.length ? selectedUser?.permission : ''.padStart(32, '0');

        const index = perm.length - infoBit;
        const val = (checked ? '1' : '0');

        perm = `${perm.substring(0, index)}${val}${perm?.substring(index + 1)}`;

        setPermissionBinary(perm);
        selectedUser && setSelectedUser({ ...selectedUser, permission: perm });
    };

    const updateAllPermission = (select?: boolean) => {
        const val = (select ? '1' : '0');
        const permission = val.repeat(permissionList.length);
        setPermissionBinary(permission);
        selectedUser && setSelectedUser({ ...selectedUser, permission });
    };

    const isCreating = userId === '-1';

    return (
        <>
            <PageFillUnderHeader
                content={
                    !userId ?
                        <Table
                            title={intl.formatMessage({ id: 'users.saphir' })}
                            columns={columns}
                            dynamicData={{
                                getData: (pageIndex, pageSize, sorting) => apiService.getUsers(pageIndex, pageSize, sorting),
                                refreshTrigger: [ refresh ]
                            }}
                            onAction={onAction}
                            options={{ update: true, delete: true, create: true }}
                            allSortable
                        /> :
                        selectedUser ?
                            <Card
                                title={intl.formatMessage({ id: `user.title.${(isCreating ? Action.Create : Action.Update).toLowerCase()}` })}
                                contentClass="p-4"
                            >
                                <div>
                                    <div className="d-flex flex-column gap-3 col-6 mx-auto">
                                        <Input
                                            label={intl.formatMessage({ id: 'username' })}
                                            value={selectedUser.userName}
                                            onChange={e => setSelectedUser({ ...selectedUser, userName: e })}
                                            errorMessage={
                                                userAlreadyUsed ?
                                                    intl.formatMessage({ id: 'username.already.used' }) :
                                                    intl.formatMessage({ id: 'error.empty.apostrophe' }, { fieldName: intl.formatMessage({ id: 'username' }).toLowerCase() })
                                            }
                                            error={userAlreadyUsed}
                                            required
                                        />
                                        <Input
                                            label={intl.formatMessage({ id: 'lastname' })}
                                            value={selectedUser.lastName}
                                            onChange={e => setSelectedUser({ ...selectedUser, lastName: e })}
                                            errorMessage={intl.formatMessage({ id: 'error.empty' }, { fieldName: intl.formatMessage({ id: 'lastname' }).toLowerCase() })}
                                            required
                                        />
                                        <Input
                                            label={intl.formatMessage({ id: 'firstname' })}
                                            value={selectedUser.firstName}
                                            onChange={e => setSelectedUser({ ...selectedUser, firstName: e })}
                                            errorMessage={intl.formatMessage({ id: 'error.empty' }, { fieldName: intl.formatMessage({ id: 'firstname' }).toLowerCase() })}
                                            required
                                        />
                                        <Input
                                            label={intl.formatMessage({ id: 'password' })}
                                            type="password"
                                            value={password}
                                            onChange={e => setPassword(e)}
                                            required={userId === '-1'}
                                            errorMessage={intl.formatMessage({ id: 'error.empty' }, { fieldName: intl.formatMessage({ id: 'password' }).toLowerCase() })}
                                        />
                                        <Input
                                            label={intl.formatMessage({ id: 'confirm.password' })}
                                            type="password"
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e)}
                                            errorMessage={intl.formatMessage({ id: 'error.not.equal' }, {
                                                fieldName1: intl.formatMessage({ id: 'password' }).toLowerCase(),
                                                fieldName2: intl.formatMessage({ id: 'confirm.password' }).toLowerCase()
                                            })}
                                            error={password !== confirmPassword}
                                            required={userId === '-1'}
                                        />
                                    </div>
                                    <div className="d-flex flex-column gap-3 mt-5 align-items-center">
                                        <h6 className="text-center">{intl.formatMessage({ id: 'user.authorization' })}</h6>
                                        <Checkbox
                                            label={intl.formatMessage({ id: 'authorization.agent' })}
                                            checked={!!selectedUser.authorization}
                                            onChange={(e) => setSelectedUser({ ...selectedUser, authorization: e ? 1 : 0 })}
                                        />
                                    </div>
                                    <div className="d-flex flex-column gap-3 mx-auto mt-5">
                                        <h6 className="text-center">{intl.formatMessage({ id: 'user.permissions' })}</h6>
                                        <div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <Checkbox
                                                    label={intl.formatMessage({ id: 'select.all' })}
                                                    checked={permissionBinary === '1'.repeat(permissionList.length)}
                                                    onChange={updateAllPermission}
                                                />
                                            </div>
                                            <div className="functions-grid gap-2">
                                                {
                                                    permissionList.filter(p => p.active).map(f => (
                                                        <div className="d-flex gap-4">
                                                            <Checkbox
                                                                label={f.comment}
                                                                checked={permissionBinary ? permissionBinary[permissionBinary?.length - f.infoBit] === '1' : false}
                                                                onChange={e => updatePermissionBinary(f.infoBit, e)}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <Button
                                            text={isCreating ? intl.formatMessage({ id: 'create.user' }) : intl.formatMessage({ id: 'update' })}
                                            onClick={() => setConfirmationModalType(isCreating ? Action.Create : Action.Update)}
                                            disabled={
                                                (!permissionBinary || !parseFloat(permissionBinary)) ??
                                                isCreating ?
                                                    (!selectedUser.userName || !selectedUser.firstName || !selectedUser.lastName || !password || password !== confirmPassword) :
                                                    (JSON.stringify(saveUser) === JSON.stringify(selectedUser) && !password)
                                            }
                                        />
                                    </div>
                                </div>
                            </Card> :
                            null
                }
            />
            {
                confirmationModalType && selectedUser &&
                <Modal
                    title={intl.formatMessage({ id: `user.title.${confirmationModalType.toLowerCase()}` })}
                    onClose={() => setConfirmationModalType(undefined)}
                >
                    <div>
                        {
                            intl.formatMessage(
                                { id: `user.confirmation.${confirmationModalType.toLowerCase()}` },
                                { user: `${selectedUser.userName}` }
                            )
                        }
                    </div>
                    <div className="d-flex justify-content-center gap-2 mt-3">
                        <Button
                            text={intl.formatMessage({ id: 'confirm' })}
                            onClick={() => handleChanges(confirmationModalType)}
                        />
                        <Button
                            text={intl.formatMessage({ id: 'cancel' })}
                            onClick={() => setConfirmationModalType(undefined)}
                        />
                    </div>
                </Modal>
            }
        </>
    );
};
