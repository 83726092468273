import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PassengerInformation } from '../models/PassengerInformation/PassengerInformation';

interface passengerInformationState {
    passengerInformation: Array<PassengerInformation>;
}

const initialState: passengerInformationState = {
    passengerInformation: [],
};

export const passengerInformationStore = createSlice({
    name: 'passengerInformation',
    initialState,
    reducers: {
        addInformation: (state, action: PayloadAction<PassengerInformation>) => {
            const index = state.passengerInformation.findIndex(info => info.id === action.payload.id && info.informationType === action.payload.informationType);
            if (index !== -1) {
                state.passengerInformation[index] = action.payload;
            } else {
                state.passengerInformation.push(action.payload);
            }
        },
        updateInformation: (state, action: PayloadAction<PassengerInformation>) => {
            const index = state.passengerInformation.findIndex(info => info.id === action.payload.id && info.informationType === action.payload.informationType);
            state.passengerInformation[index] = action.payload;
        },
        deleteInformation: (state, action: PayloadAction<number>) => {
            state.passengerInformation = state.passengerInformation.filter(e => e.id !== action.payload);
        },
    },
});

export const { addInformation, updateInformation, deleteInformation } = passengerInformationStore.actions;

export default passengerInformationStore.reducer;
