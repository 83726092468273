import { Button, Card, Checkbox, ColorVariable, Icon, IconType, Input, LineIndicator, Modal, Select, Table } from '@ceccli/design-system';
import { TableColumn, TableProps, TableValueType } from '@ceccli/design-system/src/components/molecules/Table/Table';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { AssignmentHistory } from '../../../models/AssignmentHistory';
import { CommonPageType } from '../../../common/enum';
import { CycleSocketService } from '../../../services/cycleSocket.service';
import { cycleState } from '../../../app/cycleStore';
import { DisplayCycle } from '../../../models/DisplayCycle';
import { Line } from '../../../models/Line';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { Sector } from '../../../models/Sector';
import { TerminalWithCycle } from '../../../models/Terminal';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { VehicleCartography } from '../../../models/Cartography/VehicleCartography';

export interface CycleRenderData {
    vehiclesRt: Array<VehicleCartography>,
    lastAssignments: {[id: number]: AssignmentHistory},
    cyclesRt: {[id: number]: DisplayCycle},
}

const renderLinesCell = (row: {[key: string]: TableValueType}) => {
    const lines = row.lines as Array<any>;
    if (!lines?.length) {
        return;
    }

    return (
        <div className="d-flex gap-2">
            {
                lines.map(l => <LineIndicator {...l} />)
            }
        </div>

    );
};

const renderCycleCell = (active?: TableValueType) => {
    return (
        <Icon
            iconType={IconType.Dot}
            color={typeof active === 'undefined' ?
                ColorVariable.DisabledBorder :
                active ?
                    ColorVariable.Success : ColorVariable.Error
            }
        ></Icon>
    );
};

export const CycleManagementPage: React.FunctionComponent = () => {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const apiService = new ApiService();
    const cycleSocketService = new CycleSocketService();
    const [ webSocket, setWebSocket ] = useState<WebSocket>();
    const { type } = useParams();

    const ackCycles = useSelector<RootState, cycleState>(state => state.cycles);
    const allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);
    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const availableTypes = showTerminals ? [ CommonPageType.Vehicles, CommonPageType.Terminals ] : [ CommonPageType.Vehicles ];

    const [ panelsLines, setPanelsLines ] = useState<{[panelId: number]: Array<Line> }>({});
    const [ sectors, setSectors ] = useState<Array<Sector>>([]);
    const [ lines, setLines ] = useState<Array<Line>>([]);

    const [ selectedSector, setSelectedSector ] = useState<string>();
    const [ selectedLine, setSelectedLine ] = useState<string>();
    const [ labelFilter, setLabelFilter ] = useState<string>();
    const [ lastAssignments, setLastAssignments ] = useState<{[vehicleId: number]: AssignmentHistory}>({});

    useEffect(() => {
        setWebSocket(cycleSocketService.initWebsocket());

        return () => {
            webSocket?.close();
            setWebSocket(undefined);
        };
    }, []);

    useEffect(() => {
        if (!type || !availableTypes.includes(type as CommonPageType)) {
            onNavigation();
        }

        if (showTerminals) {
            apiService.getTerminalsLines().then(setPanelsLines);
            apiService.getSectors().then(setSectors);
        }
        apiService.getAllLines().then(setLines);
    }, [ type ]);

    const onNavigation = () => {
        const param = availableTypes.find(t => t !== type);
        navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/${param}`);
    };

    const getTerminals = (pageIndex?: number, pageSize?: number) => {
        return apiService.getTerminalsWithCycle(
            pageIndex,
            pageSize,
            undefined,
            undefined,
            selectedSector,
            selectedLine,
            labelFilter
        ).then(res => {
            return ({
                ...res,
                results: res.results.map((item: TerminalWithCycle) => ({
                    ...item,
                    lines: panelsLines[item.id],
                    sector: sectors.find(s => s.id === item.sectorIndex)?.label,
                }))
            });
        });
    };

    const renderAckCell = (row: {[key: string]: TableValueType}, renderData: CycleRenderData) => {
        const cycles = renderData.cyclesRt[Number(row.id)];
        const ack = cycles && !cycles.schedule === !updatedValue.schedule && !cycles.file === !updatedValue.file && !cycles.scenario === !updatedValue.scenario ?
            cycles : false;

        return (
            ack ?
                <Icon
                    iconType={IconType.Check}
                    color={ColorVariable.Success}
                /> :
                <></>
        );
    };

    const currentCycles = type === CommonPageType.Terminals ?
        ackCycles.terminals : ackCycles.vehicles;

    const cycleColumns: Array<TableColumn> = [
        { label: 'Schedule', key: 'schedule', renderCell: (row) => renderCycleCell(currentCycles[Number(row.id)]?.schedule ?? row.schedule), icon: IconType.Clock },
        { label: 'Scenario', key: 'scenario', renderCell: (row) => renderCycleCell(currentCycles[Number(row.id)]?.scenario ?? row.scenario), icon: IconType.Film },
    ];

    if (type === 'vehicles') {
        cycleColumns.splice(1, 0, { label: 'File', key: 'file', renderCell: (row) => renderCycleCell(currentCycles[Number(row.id)]?.file ?? row.file), icon: IconType.File });
    }

    const renderLastAssignment = (row: {[key: string]: TableValueType}, renderData: CycleRenderData) => {
        const list = renderData.vehiclesRt;
        const vehicle = list?.find(v => v.id === row.id);
        const line = vehicle?.line;
        const lastAssignment = renderData.lastAssignments[Number(row.id)];

        return line ?
            <LineIndicator { ...line} /> :
            lastAssignment ?
                <div>
                    <div>{`${intl.formatMessage({ id: 'sv' })} ${lastAssignment.serviceVehicle} - ${intl.formatMessage({ id: 'sa' })} ${lastAssignment.serviceAgent}`}</div>
                    <div className="sub-value">{intl.formatDate(lastAssignment.date)}</div>
                </div> :
                emptySymbol;
    };

    const renderReception = (row: {[key: string]: TableValueType}, renderData: CycleRenderData) => {
        const list = renderData.vehiclesRt;
        const vehicle = list?.find(v => v.id === row.id);
        return vehicle?.time ?? emptySymbol;
    };

    const [ vehicles, setVehicles ] = useState<Array<any>>([]);

    useEffect(() => {
        if (type === CommonPageType.Vehicles && !vehicles.length) {
            apiService.getVehiclesWithCycle().then(setVehicles);
        }
    }, [ type, vehicles ]);

    useEffect(() => {
        if (ackCycles.vehicles) {
            setVehicles(vehicles.map(v => ({ ...v, ...ackCycles.vehicles[v.id] })));
        }
    }, [ ackCycles.vehicles ]);

    useEffect(() => {
        vehicles.map(v => apiService.getVehicleLastAssignment(v.id).then(res => {
            lastAssignments[v.id] = res;
            setLastAssignments({ ...lastAssignments });
        }));
    }, [ vehicles.length ]);

    const tableInfos: { [key: string]: TableProps<any>} = {
        terminals: {
            columns: [
                ...[
                    { label: intl.formatMessage({ id: 'terminal.index' }), key: 'index' },
                    { label: intl.formatMessage({ id: 'label' }), key: 'name' },
                    { label: intl.formatMessage({ id: 'sector' }), key: 'sector' },
                    { label: intl.formatMessage({ id: 'served.lines' }), key: 'lines', renderCell: renderLinesCell },
                ],
                ...cycleColumns,
            ],
            dynamicData: {
                getData: getTerminals,
                refreshTrigger: [ selectedSector, selectedLine, labelFilter, type ],
            },
        },
        vehicles: {
            columns: [
                ...[
                    { label: intl.formatMessage({ id: 'reception' }), key: 'reception', renderCell: (row, renderData) => renderReception(row, renderData as CycleRenderData) },
                    { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
                    { label: intl.formatMessage({ id: 'last.assignment' }), key: 'lastAssignment', subIndex: [ 1 ], renderCell: (row, renderData) => renderLastAssignment(row, renderData as CycleRenderData) },
                ] as Array<TableColumn>,
                ...cycleColumns,
            ],
            staticData: {
                data: selectedLine ?
                    vehicles.filter(v => allVehicles.filter(vRt => vRt.lineId?.toString() === selectedLine).map(vRt => vRt.bodyId).includes(v.parkId))
                    : vehicles
            },

        }
    };

    const tableInfo = type ? tableInfos[type] : undefined;

    const [ selected, setSelected ] = useState<Array<any>>([]);
    const [ selectAll, setSelectAll ] = useState<boolean>();
    const [ updatedValue, setUpdatedValue ] = useState<{ scenario?: boolean, file?: boolean, schedule?: boolean}>({ scenario: false, file: false, schedule: false });
    const [ updateModal, setUpdateModal ] = useState<boolean>(false);
    const [ updatedRows, setUpdatedRows ] = useState<Array<{[key: string]: TableValueType}>>([]);
    const [ updateSent, setUpdateSent ] = useState<boolean>(false);

    const emptySymbol = '-';

    const renderData: CycleRenderData = {
        vehiclesRt: allVehicles,
        lastAssignments,
        cyclesRt: currentCycles,
    };

    const renderOption = (icon: IconType, text: string, key: 'schedule' | 'scenario' | 'file') => {
        if (!updatedValue) {
            return;
        }

        const val = updatedValue[key];
        return (
            <div
                className={`cycle-option d-flex gap-2 align-items-center px-4 ${val ? 'active' : ''}`}
            >
                <Checkbox
                    checked={val}
                    onChange={() => setUpdatedValue({ ...updatedValue, [key]: !val })}
                    disabled={!selected.length}
                />
                <Icon iconType={icon} />
                <div>{text}</div>
                { renderCycleCell(val) }
            </div>
        );
    };

    const updateCycles = () => {
        if (!webSocket) {
            return;
        }
        selected.map(s => cycleSocketService.sendEvent(webSocket, s.id, type === 'vehicles' ? 'V' : 'P', updatedValue));
        setUpdateSent(true);
        setSelected([]);
    };

    return (
        <>
            {
                updateModal &&
                <Modal
                    onClose={() => setUpdateModal(false)}
                >
                    <Table
                        title={intl.formatMessage({ id: 'cycle.update.confirmation' })}
                        columns={
                            (tableInfo?.columns ?
                                [
                                    ...tableInfo?.columns,
                                    { label: 'ACK', key: 'ack', renderCell: (row, renderData) => renderAckCell(row, renderData as CycleRenderData) } as TableColumn
                                ] : []
                            ).map(c => {
                                const test = { ...c };
                                switch (c.key) {
                                    case 'schedule':
                                        test.renderCell = () => renderCycleCell(updatedValue.schedule);
                                        break;
                                    case 'scenario':
                                        test.renderCell = () => renderCycleCell(updatedValue.scenario);
                                        break;
                                    case 'file':
                                        test.renderCell = () => renderCycleCell(updatedValue.file);
                                        break;
                                    default:
                                        break;
                                }
                                return test;
                            })
                        }
                        staticData={{ data: updatedRows }}
                        renderData={renderData}
                    />
                    <div className="d-flex justify-content-center gap-2 mt-3">
                        <Button
                            text={intl.formatMessage({ id: updateSent ? 'close' : 'confirm' })}
                            onClick={() => {
                                if (updateSent) {
                                    setUpdateSent(false);
                                    setUpdateModal(false);
                                } else {
                                    updateCycles();
                                }
                            }}
                        />
                        {
                            !updateSent &&
                            <Button
                                text={intl.formatMessage({ id: 'cancel' })}
                                onClick={() => setUpdateModal(false)}
                            />
                        }
                    </div>
                </Modal>
            }
            <PageFillUnderHeader
                header={
                    <Card
                        title={
                            intl.formatMessage(
                                { id: 'cycle.management.title' },
                                { type: intl.formatMessage({ id: type }) }
                            )
                        }
                    >
                        <div className="d-flex p-4">
                            <div className="d-flex w-100 gap-3 justify-content-center flex-wrap align-items-end">
                                {
                                    type === CommonPageType.Terminals &&
                                    <Select
                                        label={intl.formatMessage({ id: 'select.sector' })}
                                        options={sectors.map(s => ({ ...s, value: s.id.toString() }))}
                                        onChange={e => setSelectedSector(e[0] ? String(e[0].value) : undefined)}
                                        className="col-12 col-md-3"
                                        isClearable
                                    />
                                }
                                <Select
                                    label={intl.formatMessage({ id: 'select.line' })}
                                    options={lines.map(l => ({ ...l, value: l.id.toString() }))}
                                    renderOption={(data) => <LineIndicator {...data} />}
                                    renderSingleValue={(data) => <LineIndicator {...data} />}
                                    onChange={e => setSelectedLine(e[0] ? String(e[0].value) : undefined)}
                                    className="col-12 col-md-3"
                                    isClearable
                                />
                                {
                                    type === CommonPageType.Terminals &&
                                    <Input
                                        label={intl.formatMessage({ id: 'label.filter' })}
                                        value={labelFilter}
                                        onChange={e => setLabelFilter(e)}
                                        className="col-12 col-md-3"
                                    />
                                }
                                {
                                    availableTypes.length > 1 &&
                                    <Button
                                        text={intl.formatMessage({ id: 'see.data' }, { name: intl.formatMessage({ id: availableTypes.find(t => t !== type) }) })}
                                        onClick={() => onNavigation()}
                                    />
                                }
                            </div>
                        </div>
                    </Card>
                }
                content={
                    tableInfo && (type === CommonPageType.Vehicles || (!!Object.keys(panelsLines).length && !!sectors.length)) &&
                        <Table
                            title={
                                <div className="d-flex gap-4 justify-content-center my-3">
                                    <Button
                                        text={intl.formatMessage({ id: `${selectAll ? 'unselect' : 'select'}.all` })}
                                        onClick={() => setSelectAll(!selectAll)}
                                    />
                                    { renderOption(IconType.Clock, intl.formatMessage({ id: 'cycle.schedule' }), 'schedule')}
                                    { type === 'vehicles' && renderOption(IconType.File, intl.formatMessage({ id: 'cycle.file' }), 'file')}
                                    { renderOption(IconType.Film, intl.formatMessage({ id: 'cycle.scenario' }), 'scenario')}
                                    {
                                        updatedValue &&
                                        <Button
                                            text={intl.formatMessage({ id: 'save' })}
                                            onClick={() => {
                                                setUpdatedRows(
                                                    selected
                                                        .filter(s => {
                                                            const val = currentCycles[s.id] ?? s;
                                                            return !val.schedule !== !updatedValue.schedule || !val.file !== !updatedValue.file || !val.scenario !== !updatedValue.scenario;
                                                        })
                                                        .map(s => ({ ...s, ...updatedValue, ack: Object.keys(ackCycles.terminals).find(t => Number(t) === s.id) }))
                                                );
                                                setUpdateModal(true);
                                            }}
                                            disabled={
                                                !selected.length ||
                                                !selected.find(s => !s.schedule !== !updatedValue.schedule || !s.file !== !updatedValue.file || !s.scenario !== !updatedValue.scenario) &&
                                                !selected.filter(s => currentCycles[s.id]).find(s => !currentCycles[s.id].schedule !== !updatedValue.schedule || !currentCycles[s.id].file !== !updatedValue.file || !currentCycles[s.id].scenario !== !updatedValue.scenario)
                                            }
                                        />
                                    }
                                </div>
                            }
                            columns={tableInfo.columns}
                            dynamicData={tableInfo.dynamicData}
                            staticData={tableInfo.staticData}
                            multiSelect
                            selectAll={selectAll}
                            onSelectChange={s => {
                                setSelected(s);
                                setUpdatedValue(s.length ? { scenario: !!s[0].scenario, file: !!s[0].file, schedule: !!s[0].schedule } : {});
                            }}
                            renderData={renderData}
                            refreshColRender={allVehicles}
                            selected={selected}
                        />
                }
            />
        </>
    );
};
