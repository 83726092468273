export enum UnloadingFileType {
    'BL',
    'DL',
}

export interface UnloadingInfo {
    vehicleId: number;
    unloadingDate: string;
    fileName: string;
    fileType: UnloadingFileType;
    fileSize: number;
    processed: boolean;
    totalCount: number;
}
