import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DelayColors {
    onTime?: string,
    smallAdvance?: string,
    largeAdvance?: string,
    smallDelay?: string,
    largeDelay?: string,
}

export interface SwitchCartography {
    color: boolean,
    text: boolean,
}

interface mapDataState {
    center?: [ number, number ],
    minRange?: [ number, number ],
    maxRange?: [ number, number ],
    minZoom: number,
    maxZoom: number,
    onOpenZoom: number,
    projection?: number,
    delayColors?: DelayColors,
    switchCartography?: SwitchCartography,
}

const initialState: mapDataState = {
    center: undefined,
    minRange: undefined,
    maxRange: undefined,
    minZoom: 15,
    maxZoom: 19,
    onOpenZoom: 15,
    projection: undefined,
};

export const mapDataStore = createSlice({
    name: 'mapData',
    initialState,
    reducers: {
        setMapData: (state, action) => {
            state.center = action.payload.center;
            state.minRange = action.payload.minRange;
            state.maxRange = action.payload.maxRange;
        },
        setProjection: (state, action: PayloadAction<number>) => {
            state.projection = action.payload;
        },
        setDelayColors: (state, action: PayloadAction<DelayColors>) => {
            state.delayColors = action.payload;
        },
        setSwitch: (state, action: PayloadAction<SwitchCartography>) => {
            state.switchCartography = action.payload;
        },
    },
});

export const { setDelayColors, setMapData, setProjection, setSwitch } = mapDataStore.actions;

export default mapDataStore.reducer;
