import './ArrowButton.scss';

import { Card } from '../Card/Card';
import { ColorVariable } from '../../../common/color';
import { ExpandArrow } from '../ExpandArrow/ExpandArrow';

export interface ArrowButtonProps {
    angle?: number;
    size?: number;
    color?: ColorVariable | string;
    disabled?: boolean;
    strokeSize?: number;
    onClick?: (click: boolean) => void;
}

export const ArrowButton = (props: ArrowButtonProps) => {
    const size = props.size ?? 14;
    const color = props.color ?? ColorVariable.TextDark;
    const strokeSize = props.strokeSize ?? 1;

    return (
        <div
            className={`arrow-button ${props.disabled ? 'disabled' : ''}`}
        >
            <Card>
                <ExpandArrow
                    angle={props.angle}
                    size={size}
                    color={color}
                    strokeSize={strokeSize}
                    onClick={(e) => {
                        if (!props.disabled) {
                            props.onClick && props.onClick(e);
                        }
                    }}
                    noAnimation
                />
            </Card>
        </div>
    );
};
