import { RefObject, useContext, useEffect, useState } from 'react';
import MapContext from '../MapContext';
import OLOverlay from 'ol/Overlay';

export interface OverlayLayerProps {
    contentRef: RefObject<HTMLDivElement>;
    position?: [ number, number ];
    isVehicle?: boolean;
}

const OverlayLayer = (props: OverlayLayerProps) => {
    const map = useContext(MapContext);
    const [ overlay, setOverlay ] = useState<OLOverlay>();

    useEffect(() => {
        if (!map) { return; }

        const initOverlayLayer = new OLOverlay({
            element: props.contentRef.current ?? undefined,
            position: props.position,
            positioning: 'top-center',
            offset: [ 0, props.isVehicle ? 40 : 10 ],
            autoPan: true,
        });

        map.addOverlay(initOverlayLayer);

        setOverlay(initOverlayLayer);

        return () => {
            if (map) {
                map.removeOverlay(initOverlayLayer);
                setOverlay(undefined);
            }
        };
    }, [ map, props.isVehicle ]);

    useEffect(() => {
        overlay?.setPosition(props.position);
    }, [ props.position ]);

    return null;
};
export default OverlayLayer;
