export interface LineIndicatorProps {
    code?: string;
    color?: string;
    background?: string;
    label?: string;
    classNameCode?: string;
    width?: number;
    height?: number | string;
}

export const LineIndicator = (props: LineIndicatorProps) => {
    const width = props.width ?? 60;
    const height = props.height ?? 30;

    const renderCode = () => {
        return (
            <div
                className={`line-indicator p-1 ${props.classNameCode} text-center`}
                style={{
                    backgroundColor: props.background,
                    color: props.color,
                    width,
                    height
                }}
            >
                {props.code}
            </div>
        );
    };

    return (
        <div className="line-indicator-container d-flex gap-3" data-testid="line-indicator">
            { renderCode() }
            { props.label && <div className="d-flex align-items-center">{props.label}</div>}
        </div>
    );
};
