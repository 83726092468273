import { Card, Select, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { Database } from '../../../common/enum';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const ExportPage = () => {
    const intl = useIntl();
    const apiService = new ApiService();
    const baseOptions = ([ Database.Collect, Database.Modelization ] ?? [])
        .map(base => ({ value: base, label: base }));

    const [ tables, setTables ] = useState<Array<string>>([]);
    const [ selectedBase, setSelectedBase ] = useState<string>();
    const [ selectedTable, setSelectedTable ] = useState<string>();
    const [ columns, setColumns ] = useState<Array<TableColumn>>([]);

    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);

    useEffect(() => {
        if (selectedBase) {
            apiService.getTableFromBase(selectedBase, !authorization).then(res => {
                setTables(res);
            });
        } else {
            setTables([]);
        }
        setSelectedTable(undefined);
    }, [ selectedBase ]);

    useEffect(() => {
        if (selectedBase && selectedTable) {
            apiService.getTableDescription(selectedBase, selectedTable).then(res => setColumns(res.map(c => {
                delete c.type;
                return c;
            })));
        }
    }, [ selectedTable ]);

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'data.export' })}
                    contentClass="p-4"
                >
                    <>
                        <div className="d-flex gap-3">
                            <div className="w-100">
                                <Select
                                    options={baseOptions}
                                    placeHolder={intl.formatMessage({ id: 'select.base' })}
                                    onChange={(e) => setSelectedBase(e[0] ? e[0].value.toString() : undefined)}
                                    label={intl.formatMessage({ id: 'base.name' })}
                                />
                            </div>
                            <div className="w-100">
                                <Select
                                    options={tables?.map(t => ({ value: t, label: t }))}
                                    placeHolder={intl.formatMessage({ id: 'select.table' })}
                                    value={selectedTable ? [{ value: selectedTable, label: selectedTable }] : []}
                                    onChange={(e) => setSelectedTable(e[0] ? e[0].value.toString() : undefined)}
                                    label={intl.formatMessage({ id: 'table.name' })}

                                />
                            </div>
                        </div>
                    </>
                </Card>
            }
            content={
                selectedTable && selectedBase &&
                <Table
                    title={`${selectedBase} - ${selectedTable}`}
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting, filters) => apiService.getTableContent(selectedBase, selectedTable, pageIndex, pageSize, columns.find(c => c.dbKey === sorting?.column) ? sorting : undefined, filters, intl),
                        refreshTrigger: [ columns ],
                    }}
                    allSortable
                    allHidable
                    options={{ export: true }}
                />
            }
        />
    );
};
