import { Action, Button, Card, DatePicker, Modal, ModalConfirmation, Select, Table, TableColumn, TableValueType, ToastModel, ToastType } from '@ceccli/design-system';
import { EquipementType, SoftwareDeploymentColumn, SoftwareType, TerminalDisplay } from '../../../common/enum';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { Calculator } from '../../../models/Calculator';
import { EmbeddedSoftware } from '../../../models/EmbeddedSoftware';
import { Equipement } from '../../../models/Equipement';
import { pushToast } from '../../../app/ToastStore';
import { RootState } from '../../../app/store';
import { SoftwareDeployment } from '../../../models/SoftwareDeployment';
import { SoftwareDeploymentTerminal } from '../../../models/SoftwareDeploymentTerminal';
import { useIntl } from 'react-intl';
import { UtilsService } from '../../../services/utilsService';
import { Vehicle } from '../../../models/Vehicle';

export const EmbeddedSoftwareDeployementPage = () => {
    const apiService = new ApiService();
    const utilsService = new UtilsService();
    const intl = useIntl();
    const dispatch = useDispatch();
    const equipmentOptions = [
        { label: intl.formatMessage({ id: 'vehicle' }), value: EquipementType.VEH },
        { label: intl.formatMessage({ id: 'desk' }), value: EquipementType.PUP },
    ];

    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    if (showTerminals) {
        equipmentOptions.push({ label: intl.formatMessage({ id: 'panel.satellite' }), value: EquipementType.BRN });
    }

    const typeSoftwareVEH = [
        { label: intl.formatMessage({ id: 'software.ntr.expanded' }), value: SoftwareType.NTR },
        { label: intl.formatMessage({ id: 'software.tft.expanded' }), value: SoftwareType.TFT },
    ];
    const typeSoftwarePUP = [
        { label: intl.formatMessage({ id: 'software.pupitre.expanded' }), value: SoftwareType.PUP },
    ];
    const typeSoftwareBRN = [
        { label: intl.formatMessage({ id: 'software.bnt.expanded' }), value: SoftwareType.BNT },
        { label: intl.formatMessage({ id: 'software.btd.expanded' }), value: SoftwareType.BTD },
    ];
    const [ selectedEquipment, setSelectedEquipment ] = useState<{label: string, value: string}>();
    const [ softwares, setSoftwares ] = useState<Array<EmbeddedSoftware>>([]);
    const [ equipements, setEquipements ] = useState<Array<Equipement | Vehicle>>([]);
    const [ softwareTypeOptions, setSoftwareTypeOptions ] = useState<Array<{label: string, value: string}>>(typeSoftwareVEH);
    const [ selectedTypeSoftware, setSelectedTypeSoftware ] = useState<{label: string, value: string}>();
    const [ refresh, setRefresh ] = useState<boolean>(false);
    const [ refreshDeployment, setRefreshDeployment ] = useState<boolean>(false);
    const [ versions, setVersions ] = useState<Array<EmbeddedSoftware>>([]);
    const [ selectedVersion, setSelectedVersion ] = useState<{label: string, value: string}>();
    const [ selectedEquipements, setSelectedEquipements ] = useState<Array<{label: string, value: string}>>([]);
    const [ date, setDate ] = useState<Date>();
    const [ tablesData, setTablesData ] = useState<Array<{ [key: string]: TableValueType }>>([]);
    const [ openDeleteModal, setOpenDeleteModal ] = useState<boolean>(false);
    const [ softwareDeployments, setSoftwareDeployments ] = useState<Array<SoftwareDeployment>>([]);
    const [ availableEquipements, setAvailableEquipements ] = useState<Array<Equipement | Vehicle>>([]);
    const [ calculators, setCalculators ] = useState<Array<Calculator>>([]);
    const [ softwareDeployment, setSoftwareDeployment ] = useState<SoftwareDeployment | SoftwareDeploymentTerminal>();
    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'software.deployment.tableColumn.equipement' }), key: SoftwareDeploymentColumn.EQUIPEMENT_NUMBER },
        { label: intl.formatMessage({ id: 'software.deployment.tableColumn.software' }), key: SoftwareDeploymentColumn.SOFTWARE_TYPE },
        { label: intl.formatMessage({ id: 'software.deployment.tableColumn.calculator' }), key: SoftwareDeploymentColumn.CALCULATOR },
        { label: intl.formatMessage({ id: 'software.deployment.tableColumn.numeroVersion' }), key: SoftwareDeploymentColumn.VERSION },
        { label: intl.formatMessage({ id: 'software.deployment.tableColumn.deploymentDate' }), key: SoftwareDeploymentColumn.DEPLOIMENT_DATE }
    ];
    const [ openConfirmModal, setOpenConfirmModal ] = useState<boolean>(false);
    const loadSoftwares = () => {
        switch (selectedEquipment?.value) {
            case EquipementType.VEH:
                setSoftwareTypeOptions(typeSoftwareVEH);
                break;
            case EquipementType.PUP:
                setSoftwareTypeOptions(typeSoftwarePUP);
                break;
            case EquipementType.BRN:
                setSoftwareTypeOptions(typeSoftwareBRN);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        Promise.all([
            apiService.getEmbeddedSoftwares(),
            apiService.getCalculators(),
            apiService.getEquipements(),
            apiService.getAllVehicles()
        ]).then(([ softwares, calculators, equipementList, vehicleList ]) => {
            setSoftwares(softwares);
            setAvailableEquipements([ ...equipementList, ...vehicleList ]);
            setCalculators(calculators);
        });
    }, [ refresh ]);

    useEffect(() => {
        Promise.all([
            apiService.getSofwareDeployments(),
            apiService.getSofwareDeploymentTerminals(),
        ]).then(([ softwareDeployments, softwareDeploymentTerminals ]) => {
            setSoftwareDeployments([ ...softwareDeployments, ...softwareDeploymentTerminals ]);
        });
    }, [ refreshDeployment ]);

    useEffect(() => {
        loadSoftwares();
    }, [ selectedEquipment ]);

    useEffect(() => {
        setSelectedTypeSoftware(undefined);
        setRefresh(!refresh);
    }, softwareTypeOptions);

    useEffect(() => {
        setSelectedVersion(undefined);
    }, [ versions ]);

    useEffect(() => {
        setVersions(softwares.filter(soft => soft.typeSoftware === selectedTypeSoftware?.value));
    }, [ selectedTypeSoftware, softwares ]);

    useEffect(() => {
        setSelectedEquipements([]);
    }, [ equipements ]);

    const filterEquipements = (list: Array<Equipement | Vehicle>, version: number) => {
        return list.filter(equipment => !softwareDeployments.find(s =>
            s.softwareType === selectedTypeSoftware?.value &&
            s.id === equipment.id &&
            s.version === version
        ));
    };

    useEffect(() => {
        if (selectedVersion && selectedVersion.value?.length > 0) {
            const version = JSON.parse(selectedVersion.value);
            const calculator = version?.typeCalculator;
            if (calculator) {
                if (selectedEquipment?.value === 'BRN') {
                    if (equipements) { setSelectedEquipements([]); }
                    apiService.getEquipements(calculator).then(e => setEquipements(filterEquipements(e, version.version)));
                } else {
                    apiService.getAllVehicles(
                        undefined,
                        ({
                            typeCalculator: version.typeCalculator,
                            typeSoftware: version.typeSoftware
                        })
                    ).then(e => {
                        if (equipements) { setSelectedEquipements([]); }
                        setEquipements(filterEquipements(e, version.version));
                    });
                }
            }
        } else {
            setEquipements([]);
        }
    }, [ selectedVersion ]);

    const getId = (equipement: Equipement | Vehicle) => {
        const obj = Object.create({ ...equipement });
        return obj.parkId ?? obj.bodyId;
    };

    useEffect(() => {
        setSelectedEquipements([]);

        availableEquipements.length > 0 && setTablesData(softwareDeployments.map(s => {
            const filteredEqui = availableEquipements.filter(e => {
                let predicat;
                if ('calculator' in e) {
                    predicat = e.calculator === s.calculator;
                } else if ('typeCalculatorRT' in e && s.softwareType === SoftwareType.NTR) {
                    predicat = e.typeCalculatorRT === s.calculator;
                } else if ('typeCalculatorTFT' in e && s.softwareType === SoftwareType.TFT) {
                    predicat = e.typeCalculatorTFT === s.calculator;
                } else if ('typeCalculatorDesk' in e) {
                    predicat = e.typeCalculatorDesk === s.calculator;
                }
                return e.id === s.id && predicat;
            });
            return ({
                ...s,
                equipementNumber: filteredEqui.length ? getId(filteredEqui[0]) + ('typeOfTerminal' in filteredEqui[0] ? (filteredEqui[0].typeOfTerminal === EquipementType.BRN ? TerminalDisplay.PAN : TerminalDisplay.SAT) : '') : undefined,
                calculatorName: calculators.find(c => c.id === s.calculator)?.name ?? '',
                version: utilsService.formatVersion(s.version),
                deploymentDate: intl.formatDate(s.deploymentDate),
                loadingDate: String(s.loadingDate)
            });
        }));
    }, [ equipements, softwareDeployments, availableEquipements ]);

    const ableToDeploy = (isTerminal: boolean): Array<any> => {
        if (!selectedTypeSoftware || !selectedVersion) {
            return [];
        }
        // This method works for both SoftwareDeployments and SoftwareDeploimentTerminals
        let arr = [];
        arr = selectedEquipements.map(v => {
            const value = JSON.parse(v.value);
            let toInsertSoft: SoftwareDeployment | SoftwareDeploymentTerminal;
            if (isTerminal) {
                toInsertSoft = new SoftwareDeploymentTerminal(
                    value.id, value.typeOfTerminal, selectedTypeSoftware.value, parseInt(JSON.parse(selectedVersion.value).version), JSON.parse(selectedVersion.value).typeCalculator, date ? date : new Date(), new Date()
                );
            } else {
                toInsertSoft = new SoftwareDeployment(
                    value.id, selectedTypeSoftware.value, parseInt(JSON.parse(selectedVersion.value).version), JSON.parse(selectedVersion.value).typeCalculator, date ? date : new Date(), new Date()
                );
            }

            // Check if it exists already in the table
            const ifExist = tablesData.find(s => s.id === toInsertSoft.id &&
                s.softwareType === toInsertSoft.softwareType &&
                s.version === utilsService.formatVersion(toInsertSoft.version) &&
                s.calculator === toInsertSoft.calculator);
            if (!ifExist) {
                return toInsertSoft;
            }
            dispatch(pushToast(new ToastModel(`${intl.formatMessage({ id: 'software.deployment.warn' })}${v.label}`, ToastType.Warning)));
        });
        const cleanArr = arr.filter(subArr => subArr !== undefined);

        return cleanArr;
    };

    const deploySoftware = () => {
        if (selectedEquipment?.value !== EquipementType.BRN) {
            const cleanArr = ableToDeploy(false);
            cleanArr.length > 0 && apiService.createSoftwareDeployment(cleanArr);
        } else {
            const cleanArr = ableToDeploy(true);
            cleanArr.length > 0 && apiService.createSoftwareDeploymentTerminal(cleanArr);
        }
        setOpenConfirmModal(false);
        setRefreshDeployment(!refreshDeployment);
    };

    const onAction = (action: Action, data?: Object) => {
        let softwareDeployment;
        if (data) {
            softwareDeployment = data as SoftwareDeployment | SoftwareDeploymentTerminal;
        }
        switch (action) {
            case Action.Delete:
                setOpenDeleteModal(true);
                setSoftwareDeployment(softwareDeployment);
                break;
            default:
                break;
        }
    };

    const cancelDeployment = () => {
        if (softwareDeployment) {
            if ('displayType' in softwareDeployment) {
                apiService.deleteSoftwareDeploymentTerminal(softwareDeployment);
            } else {
                apiService.deleteSoftwareDeployment(softwareDeployment);
            }

            setRefreshDeployment(!refreshDeployment);
        }
    };
    return (

        <div className="p-4 h-100 overflow-auto">
            <div className="row">
                <div className="col-12 col-sm-6 mb-5 mx-auto">
                    <Card title={intl.formatMessage({ id: 'software.deployment' })} contentClass="p-4">
                        <>
                            <DatePicker
                                label={intl.formatMessage({ id: 'exploitation.date' })}
                                onChange={e => setDate(e)}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                minDate={new Date()}
                                className="mt-2 "
                            />
                            <Select
                                label={intl.formatMessage({ id: 'select.type.equipment' })}
                                value={selectedEquipment ? [ selectedEquipment ] : undefined}
                                options={equipmentOptions}
                                onChange={(e) => setSelectedEquipment({ label: e[0].label, value: String(e[0].value) })}
                                className="mt-2"

                            />

                            <Select
                                label={intl.formatMessage({ id: 'select.type.software' })}
                                value={selectedTypeSoftware ? [ selectedTypeSoftware ] : []}
                                options={selectedEquipment ? softwareTypeOptions : []}
                                onChange={(e) => setSelectedTypeSoftware({ label: e[0].label, value: String(e[0].value) })}
                                className="mt-2"

                            />

                            <Select
                                label={intl.formatMessage({ id: 'version' })}
                                value={selectedVersion ? [ selectedVersion ] : []}
                                options={versions.map(c => ({
                                    label: utilsService.formatVersion(c.version).concat(' (').concat(calculators.find(s => s.id === c.typeCalculator)?.name ?? '').concat(')'), value: JSON.stringify(c)
                                }))}
                                onChange={(e) => setSelectedVersion({ label: e[0].label, value: String(e[0].value) })}
                                className="mt-2"
                            />

                            <div className="mb-3">
                                <div className="d-flex w-100 align-items-end gap-2">
                                    <Select
                                        label={intl.formatMessage({
                                            id: selectedEquipment ?
                                                [EquipementType.PUP, EquipementType.VEH ].map(String).includes(selectedEquipment.value) ?
                                                    'vehicles' : 'terminals' :
                                                'equipments'
                                        })}
                                        value={selectedEquipements}
                                        options={equipements.map(c => (
                                            {
                                                label: `${getId(c)} ${
                                                    'typeOfTerminal' in c ? (c.typeOfTerminal === EquipementType.BRN ? TerminalDisplay.PAN : TerminalDisplay.SAT) : ''}`
                                                , value: JSON.stringify(c)
                                            }))}
                                        onChange={(e) => {
                                            setSelectedEquipements(e.map(q => ({ label: q.label, value: String(q.value) })));
                                        }}
                                        className="mt-2"
                                        isMulti
                                        isClearable
                                    />
                                    <Button
                                        text={intl.formatMessage({ id: 'all' })}
                                        onClick={() => setSelectedEquipements(equipements.map(e => ({ label: getId(e), value: JSON.stringify(e) })))}
                                        disabled={!equipements.length}
                                    />
                                </div>
                                {
                                    selectedEquipment && selectedTypeSoftware && selectedVersion && !equipements.length &&
                                    <div className="text-center fw-light fst-italic">{intl.formatMessage({ id: 'empty.equipments' })}</div>
                                }
                            </div>
                            <div className="w-100 text-center">
                                <Button
                                    text={intl.formatMessage({ id: 'software.deployment.action.deploy' })}
                                    onClick={() => { setOpenConfirmModal(true); }}
                                    disabled={!selectedEquipements || equipements.length === 0 || selectedEquipements.length === 0 || !date}
                                />
                            </div>
                        </>
                    </Card>
                </div>
            </div>
            <div className="mb-5">
                <Table
                    title={intl.formatMessage({ id: 'software.deployment.table.title' })}
                    columns={columns}
                    staticData={{ data: tablesData }}
                    options={{ delete: true }}
                    onAction={onAction}
                    allSortable
                />
            </div>

            {
                openDeleteModal &&
                <ModalConfirmation action={Action.Delete} entity={intl.formatMessage({ id: 'delete.modal.software.deployment' })} name={intl.formatMessage({ id: 'delete.modal.software.deployment.this' })} onClose={() => setOpenDeleteModal(false)} onSave={() => cancelDeployment()} />
            }
            {
                openConfirmModal &&
                <Modal title={intl.formatMessage({ id: 'software.deployment.table.title' })}>
                    <div>
                        {
                            intl.formatMessage({ id: 'software.deployment.modal.confirm' })
                        }
                    </div>
                    <div className="d-flex justify-content-center gap-2 mt-3">
                        <Button
                            text={intl.formatMessage({ id: 'confirm' }).toUpperCase()}
                            onClick={() => deploySoftware()}
                        />
                        <Button
                            text={intl.formatMessage({ id: 'cancel' }).toUpperCase()}
                            onClick={() => setOpenConfirmModal(false)}
                        />
                    </div>
                </Modal>
            }
        </div>

    );
};
