import './Modal.scss';

import React, { ReactElement } from 'react';

import { Card } from '../Card/Card';

export interface ModalProps {
    children: Array<ReactElement> | ReactElement;
    title?: string;
    closeBackground?: boolean;
    onClose?: (open: boolean) => void;
}

export const Modal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
    return (
        <div className="modal-container" onClick={() => props.closeBackground && props.onClose && props.onClose(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <Card title={props.title} onClose={props.onClose}>
                    <div className="p-4">
                        {
                            props.children
                        }
                    </div>
                </Card>
            </div>
        </div>
    );
};
