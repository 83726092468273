import './Button.scss';

import { Color, ColorVariable } from '../../../common/color';
import React, { MouseEvent } from 'react';

export interface ButtonProps {
    text: string;
    color?: ColorVariable | Color;
    border?: ColorVariable | Color;
    textColor?: ColorVariable | Color;
    disabled?: boolean;
    type?: 'submit' | 'reset';
    onClick: (event: MouseEvent) => void;
}

export const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
    return (
        <div className="button-container" data-testid="button">
            <button
                type={props.type}
                className="button"
                style={{
                    color: `${!props.disabled && (props?.textColor || ColorVariable.Secondary)}`,
                    border: `1px solid ${!props.disabled && (props?.border || ColorVariable.Primary)}`,
                    background: props?.color || ColorVariable.Primary,
                }}
                onClick={props.onClick}
                disabled={props.disabled}
            >
                <span>{props.text}</span>
            </button>
        </div>
    );
};
