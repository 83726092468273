import { UtilsService } from '../services/utilsService';

const utilsService = new UtilsService();

export class EmbeddedSoftware {
    formattedVersion: string;

    constructor(
        public typeSoftware: string,
        public typeCalculator: number,
        public version: number,
        public fileName: string,
        public fileSize: number,
    ) {
        this.formattedVersion = utilsService.formatVersion(version);
    }
}
