import { addLines, addMissions, addPortables, addVehicles, updatePortable, updateVehicle } from '../app/drawableDataStore';
import { DelayColors, setDelayColors, setMapData, setProjection, setSwitch, SwitchCartography } from '../app/mapDataStore';
import { useDispatch, useSelector } from 'react-redux';

import { ApiService } from './apiService';
import { Line } from '../models/Line';
import { Mission } from '../models/Mission';
import { PortableCartography } from '../models/Cartography/PortableCartography';
import { RootState } from '../app/store';
import { SaphirParameterKeys } from '../models/SaphirParameter';
import { SocketInitKey } from '../common/enum';
import { useIntl } from 'react-intl';
import { VehicleCartography } from '../models/Cartography/VehicleCartography';

export enum WebSocketKey {
    MapInit = 1,
    VehicleData = 2,
    PortableData = 3,
}

const apiService = new ApiService();

export class WebSocketService {
    dispatch = useDispatch();
    intl = useIntl();
    allVehicles = useSelector<RootState, Array<VehicleCartography>>(state => state.drawableData.vehicles);
    allPortables = useSelector<RootState, Array<PortableCartography>>(state => state.drawableData.portables);

    allMissions = useSelector<RootState, Array<Mission>>(state => state.drawableData.missions);
    allLines = useSelector<RootState, Array<Line>>(state => state.drawableData.lines);

    projection = useSelector<RootState, number | undefined>(state => state.mapData.projection);

    getSettings = async () => {
        const apiService = new ApiService();

        const [ proj, switchColor, switchText, ...colorRes ] = await Promise.all([
            apiService.getSaphirParam(SaphirParameterKeys.Projection),
            apiService.getSaphirParam(SaphirParameterKeys.SwitchColor),
            apiService.getSaphirParam(SaphirParameterKeys.SwitchText),
            apiService.getSaphirParam(SaphirParameterKeys.OnTime),
            apiService.getSaphirParam(SaphirParameterKeys.SmallAdvance),
            apiService.getSaphirParam(SaphirParameterKeys.LargeAdvance),
            apiService.getSaphirParam(SaphirParameterKeys.SmallDelay),
            apiService.getSaphirParam(SaphirParameterKeys.LargeDelay)
        ]);
        const switchCartography: SwitchCartography = { color: Boolean(Number(switchColor.value)), text: Boolean(Number(switchText.value)) };

        const colorSet = colorRes.map(item => { return item.value ? `#${item.value.split(';')[0]}` : undefined; });
        const colors: DelayColors = {};
        colors.onTime = colorSet[0];
        colors.smallAdvance = colorSet[1];
        colors.largeAdvance = colorSet[2];
        colors.smallDelay = colorSet[3];
        colors.largeDelay = colorSet[4];

        this.dispatch(setProjection(Number(proj.value)));
        this.dispatch(setSwitch(switchCartography));
        this.dispatch(setDelayColors(colors));

        const result: [ DelayColors, SwitchCartography] = [ colors, switchCartography ];
        return result;
    };

    initWebsocket = async () => {
        const [ delayColors, switchCartography ] = await this.getSettings();

        const ws = new WebSocket(String(process.env.REACT_APP_WEBSOCKET_URL));

        ws.onopen = () => {
            ws.send(SocketInitKey.Cartography);
        };

        ws.onmessage = (msg) => {
            const msgSplit = msg.data.split('\n');
            const key = Number(msgSplit[0]);

            switch (key) {
                case WebSocketKey.MapInit:
                    this.initMapData(msgSplit);
                    break;
                case WebSocketKey.VehicleData:
                    this.initVehicles(msgSplit.slice(1), delayColors, switchCartography);
                    break;
                case WebSocketKey.PortableData:
                    this.initPortables(msgSplit.slice(1));
                    break;
                default:
                    break;
            }
        };

        ws.onerror = function(event) {
            console.log('Error on WebSocket', event);
        };

        return ws;
    };

    initMapData = (msgSplit: Array<string>) => {
        const mapData = msgSplit[1].split('|').map((v: string) => Number(v));
        const center = mapData.slice(0, 2);
        const minRange = mapData.slice(2, 4);
        const maxRange = mapData.slice(4, 6);
        this.dispatch(setMapData({ center, minRange, maxRange }));
    };

    initVehicles = async (arr: Array<string>, delayColors?: DelayColors, switchCartography?: SwitchCartography) => {
        const notEmpty = arr.filter(a => a.length);
        const vehicles = await Promise.all(
            notEmpty.map(data => {
                const info = data.split('|');

                const lineId = this.convertInfo(info[5]);
                const vehicleId = Number(info[0]);
                const assignmentId = Number(info[7]);

                return Promise.all([
                    lineId ? this.getLine(lineId) : undefined,
                    this.getAssignment(assignmentId),
                    this.getBodyId(vehicleId)
                ]).then(([ line, assignment, bodyId ]) => {
                    return new VehicleCartography(
                        vehicleId,
                        [ Number(info[2]), Number(info[3]) ],
                        [ Number(info[15]), Number(info[16]) ],
                        -Number(info[4]) + 90,
                        info[1],
                        () => {},
                        bodyId,
                        Number(info[8]),
                        `${this.intl.formatMessage({ id: 'vehicle' })} ${bodyId}`,
                        this.convertInfo(info[6]),
                        assignmentId,
                        assignment?.missionNumber,
                        line,
                        this.convertInfo(info[9]),
                        this.convertInfo(info[10]),
                        this.convertInfo(info[11]),
                        this.convertInfo(info[12]),
                        lineId,
                        this.convertInfo(info[17]),
                        Number(info[13]),
                        Number(info[14]),
                        delayColors,
                        switchCartography,
                    );
                });
            })
        );

        const newVehicles = vehicles.filter(v => {
            const i = this.allVehicles.findIndex((e: VehicleCartography) => e.id === v.id);
            if (i > -1) {
                this.allVehicles[i] = v;
                this.dispatch(updateVehicle({ vehicle: v, delayColors, switchCartography }));
                return false;
            }
            return true;
        }
        );

        if (newVehicles.length) {
            this.allVehicles = [ ...this.allVehicles, ...newVehicles ];
            this.dispatch(addVehicles(newVehicles));
        }
    };

    initPortables = async (arr: Array<string>) => {
        const portables = await Promise.all(arr.filter(a => a.length).map(data => {
            const info = data.split('|');

            return apiService.getPortable(Number(info[0])).then(portable => {
                return new PortableCartography(
                    portable.id,
                    portable.label,
                    [ Number(info[1]), Number(info[2]) ],
                    portable.radioAddress,
                    info[3],
                );
            });
        }));

        const filteredPortables = portables.filter(p => {
            const i = this.allPortables.findIndex((e: PortableCartography) => e.id === p.id);
            if (i > -1) {
                this.allPortables[i] = p;
                this.dispatch(updatePortable(p));
                return false;
            }
            return true;
        });

        if (filteredPortables.length) {
            this.allPortables = [ ...this.allPortables, ...filteredPortables ];
            this.dispatch(addPortables(filteredPortables));
        }
    };

    getAssignment = (assignmentId: number) => {
        if (!assignmentId) {
            return;
        }

        let assignment = this.allMissions.find(a => a.id === assignmentId);
        if (!assignment) {
            return apiService.getMission(assignmentId).then(data => {
                if (data) {
                    this.dispatch(addMissions([ data ]));
                    assignment = data;
                }
                return assignment;
            });
        }
        return assignment;
    };

    getLine = (lineId: number) => {
        if (!lineId) {
            return undefined;
        }

        const line = this.allLines.find(l => l.id === lineId);
        if (!line) {
            return apiService.getLine(lineId).then(data => {
                if (data) {
                    this.dispatch(addLines([ data ]));
                }
                return data;
            });
        }
        return line;
    };

    getBodyId = (vehicleId: number) => {
        const bodyId = this.allVehicles.find(v => v.id === vehicleId)?.bodyId;
        if (!bodyId) {
            return apiService.getVehicleParkId(vehicleId);
        }
        return bodyId;
    };

    convertInfo = (val?: string) => {
        return Number(val) ? Number(val) : undefined;
    };
}
