export interface SaphirParameter {
    param: string,
    value: string,
    comment: string,
}

export enum SaphirParameterKeys {
    Projection = 'ProjLb',
    ExploitationEnd = 'FinExpl',
    ExploitationStart = 'DebExpl',
    SmallAdvance = 'PetiteAvance',
    LargeAdvance = 'GrandeAvance',
    SmallDelay = 'PetitRetard',
    LargeDelay = 'GrandRetard',
    OnTime ='ALHeure',
    SwitchColor = 'CouleursPicto',
    SwitchText = 'InfosPicto'
}
