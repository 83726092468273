import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface saphirFunctionsState {
    showTerminals?: boolean,
}

const initialState: saphirFunctionsState = {
    showTerminals: undefined
};

export const saphirFunctionsStore = createSlice({
    name: 'saphirFunctions',
    initialState,
    reducers: {
        setShowTerminals: (state, action: PayloadAction<boolean>) => {
            state.showTerminals = action.payload;
        },
    },
});

export const { setShowTerminals } = saphirFunctionsStore.actions;

export default saphirFunctionsStore.reducer;
