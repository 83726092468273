import './Text.scss';
import React from 'react';

export enum VariantType {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    Span = 'span',
}

export enum TextStyle {
    TableLabel = 'table-label',
    TableValue = 'table-value'
}

export interface TextProps {
    variant?: VariantType;
    textStyle?: TextStyle;
}

export const Text: React.FunctionComponent<TextProps & React.HTMLAttributes<HTMLDivElement>> = ({ children, variant, textStyle, ...other }) => {
    return React.createElement(
        variant?.toString() || VariantType.Span.toString(),
        {
            ...other,
            className: `${other.className} ${textStyle}`
        },
        children
    );
};
