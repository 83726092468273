import './index.scss';

import App from './App';
import { IntlProvider } from 'react-intl';
import { Lang } from '@ceccli/design-system/dist';
import messagesEN from './translations/en.json';
import messagesFR from './translations/fr.json';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store from './app/store';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const getLang = (lang: Lang) => {
    switch (lang) {
        case Lang.EN: return messagesEN;
        case Lang.FR: return messagesFR;
        default: return messagesFR;
    }
};

const lang = Lang.FR;
const messages = getLang(lang);

root.render(
    <Provider store={store}>
        <IntlProvider locale={lang} messages={messages} defaultLocale={lang}>
            <App />
        </IntlProvider>
    </Provider>
);
