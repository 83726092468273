import './Select.scss';

import React, { ReactNode } from 'react';
import ReactSelect, { components, GroupBase, OptionProps, SingleValueProps, StylesConfig } from 'react-select';

import { CheckElementOptionType } from '../../../common/type';

export interface SelectProps {
    options: Array<CheckElementOptionType>;
    value?: Array<CheckElementOptionType>;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isMulti?: boolean;
    defaultValue?: Array<CheckElementOptionType>;
    className?: string;
    placeHolder?: string;
    label?: string;
    styleConfig?: StylesConfig<CheckElementOptionType>;
    addAllOption?: boolean;
    selectAllOptions?: boolean;
    renderOption?: (data: CheckElementOptionType) => ReactNode;
    renderSingleValue?: (data: CheckElementOptionType) => ReactNode;
    onChange?: (value: Array<CheckElementOptionType>) => void;
    onClick?: (value: string) => void;
}

export const Select: React.FunctionComponent<SelectProps> = (props: SelectProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const MultiValueLabel = (multiProps: any) =>
        <components.MultiValueLabel
            {...multiProps}
            innerProps={{
                ...multiProps.innerProps,
                onClick: () => {
                    props.onClick ?
                        props.onClick(multiProps.data.value) :
                        undefined;
                }
            }}
        />;

    const defaultStyle: StylesConfig<CheckElementOptionType> = {
        multiValue: (styles, { data }) => ({
            ...styles,
            background: data.background,
            color: data.color,
            cursor: props.onClick ? 'pointer' : 'default',
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        })
    };

    const CustomOption = (optionProps: OptionProps<CheckElementOptionType, boolean, GroupBase<CheckElementOptionType>>) => {
        return (
            <components.Option {...optionProps}>
                {props.renderOption && props.renderOption(optionProps.data)}
            </components.Option>
        );
    };

    const CustomSingleValue = (singleValueProps: SingleValueProps<CheckElementOptionType, boolean, GroupBase<CheckElementOptionType>>) => {
        return (
            <components.SingleValue {...singleValueProps}>
                {props.renderSingleValue && props.renderSingleValue(singleValueProps.data)}
            </components.SingleValue>
        );
    };

    const options = props.addAllOption ? [{ value: '', label: 'All' }, ...props.options ] : props.options;

    return (
        <div className={`select-container ${props.className ?? ''}`}>
            { props.label && <div className="label">{props.label}</div> }
            <ReactSelect
                classNamePrefix="react-select"
                value={props.selectAllOptions ? options : props.value }
                isClearable={props.isClearable}
                isSearchable={props.isSearchable}
                isDisabled={props.isDisabled || !options.length}
                isLoading={props.isLoading}
                isMulti={props.isMulti}
                defaultValue={props.defaultValue}
                options={options}
                closeMenuOnSelect={!props.isMulti}
                styles={props.styleConfig ?? defaultStyle}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(Array.isArray(e) ? e : [ e ]);
                    }
                }}
                placeholder={props.placeHolder}
                components={
                    {
                        ...{ MultiValueLabel },
                        ...(props.renderOption ? { Option: CustomOption } : {}),
                        ...(props.renderSingleValue ? { SingleValue: CustomSingleValue } : {})
                    }
                }
            />
        </div>
    );
};
