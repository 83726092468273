import { CartographyKeys, CartographyObject } from './CartographyObject';

import DotIcon from '../../assets/dot.svg';
import FlagIcon from '../../assets/flag.svg';
import { Icon } from 'ol/style';
import { LocalStorageService } from '../../services/localStorage.service';

export class StopCartography extends CartographyObject {
    constructor(id: number, name: string, position: [number, number], positionLambert?: [number, number]) {
        super(id, CartographyKeys.Stop, name, position, StopCartography.icons[LocalStorageService.getLocalIcon(CartographyKeys.Stop)], positionLambert);
    }

    static icons = [
        new Icon({
            anchor: [ 0.2, 1 ],
            src: FlagIcon,
            scale: 0.07
        }),
        new Icon({
            anchor: [ 0.5, 0.5 ],
            src: DotIcon,
            scale: 0.07
        })
    ];
}
