import { addTerminal, addVehicle, removeTerminal, removeVehicle } from '../app/cycleStore';

import { DisplayCycle } from '../models/DisplayCycle';
import { SocketInitKey } from '../common/enum';
import { useDispatch } from 'react-redux';

export enum ModificationReceptionKey {
    Panel = 51,
    Vehicle = 50,
}

export enum ModificationSendKey {
    P = 'MCYB',
    V = 'MCY'
}

export class CycleSocketService {
    dispatch = useDispatch();

    initWebsocket = () => {
        const ws = new WebSocket(String(process.env.REACT_APP_WEBSOCKET_URL));

        ws.onopen = () => {
            ws.send(SocketInitKey.Cycle);
        };

        ws.onmessage = (msg) => {
            const msgSplit = msg.data.split('\n');
            const key = Number(msgSplit[0]);
            const msgContent = msgSplit[1].split('|');
            const id = Number(msgContent[1]);
            const val = Number(msgContent[2]);

            switch (key) {
                case ModificationReceptionKey.Panel:
                    this.dispatch(addTerminal({ id, element: new DisplayCycle(true, val) }));
                    break;
                case ModificationReceptionKey.Vehicle:
                    this.dispatch(addVehicle({ id, element: new DisplayCycle(true, val) }));
                    break;
                default:
                    break;
            }
        };

        ws.onerror = function(event) {
            console.log('Error on WebSocket', event);
        };

        return ws;
    };

    sendEvent = (ws: WebSocket, id: number, type: 'P' | 'V', value: DisplayCycle) => {
        const i = `${Number(!value.scenario)}${Number(!value.file)}${Number(!value.schedule)}`;
        const msg = `${type};${id};${parseInt(i, 2)};`;

        switch (type) {
            case 'P':
                this.dispatch(removeTerminal(id));
                break;
            case 'V':
                this.dispatch(removeVehicle(id));
                break;
            default:
                break;
        }
        ws.send(`<${ModificationSendKey[type]}:${msg.length}>${msg}`);
    };
}
