import { Base, Lang } from './enum';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import messagesEN from '../translations/en.json';
import messagesFR from '../translations/fr.json';

const anyCase = (text: string, char: string, caps: boolean = false): string => {
    const regex = '[-_ ]';
    if (text.match(new RegExp(regex, 'g'))) {
        text = capitalize(text);
        return text.replace(new RegExp(`(${regex}[a-z])`, 'gi'), ($1) => {
            return $1.toUpperCase()
                .replace(new RegExp(regex, 'g'), char);
        });
    }
    const caseText = text.replace(/([A-Z])/g, $1 => `${char}${$1}`).replace(char, '').toLowerCase();
    if (caps) {
        caseText.toUpperCase();
    }
    return caseText;
};

export const capitalize = (text: string, pascalCase: boolean = false): string => {
    return text.charAt(0).toUpperCase() + (pascalCase ? text : text.toLowerCase()).slice(1);
};

export const snakeCase = (text: string): string => {
    return anyCase(text, '_', true);
};

export const kebabCase = (text: string): string => {
    return anyCase(text, '-');
};

export const spaceCase = (text: string): string => {
    return anyCase(text, ' ');
};

export const camelCase = (text: string): string => {
    if (text.split('_').length > 1) {
        text = text.split('_').slice(1).join('_');
    }
    return text.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

export const pascalCase = (text: string): string => {
    return capitalize(camelCase(text), false);
};

export const getLang = (lang: Lang) => {
    switch (lang) {
        case Lang.EN: return messagesEN;
        case Lang.FR: return messagesFR;
        default: return messagesFR;
    }
};

export const getUnique = <T, >(arr: Array<T>): Array<T> => {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
};

export const getUniqueByAttribute = <T, K extends keyof T>(arr: Array<T>, attribute: K): Array<T> => {
    return arr.filter((val, index, self) => {
        const i: number | undefined = self.findIndex(v => v[attribute] === val[attribute]);
        return i === index;
    });
};

export const groupBy = <T, K extends keyof T>(arr: Array<T>, property: K): Array<Array<T>> => {
    const hash = Object.create(null);
    arr.map(obj => {
        if (hash[obj[property]]) {
            hash[obj[property]].push(obj);
        } else {
            hash[obj[property]] = [ obj ];
        }
    });
    return Object.values(hash);
};

export const groupValueBy = <T, K extends keyof T>(arr: Array<Array<T>>, groupingKey: K, valueKey: K) => {
    const obj = Object.create({});
    arr.map(item => {
        const a = item[0];
        const b = a[groupingKey];
        obj[b] = item.map(v => v[valueKey]);
    });
    return obj;
};

export const formatVersion = (version: number): string => {
    const versionStr = `v${version}`;
    return versionStr.match(/.{1,2}/g)?.join('.') ?? '';
};

export const formatDelay = (advanceDelay: number) => {
    return ((Math.sign(advanceDelay) >= 0 ? '+' : '-') + moment.utc(Math.abs(advanceDelay) * 1000).format('HH:mm:ss'));
};

const formatNumber = (n: number) => {
    return `0${n}`.slice(-2);
};

export const formatTimeFromSeconds = (intl: IntlShape, seconds: number, hideSeconds?: boolean) => {
    if (seconds < 0) {
        return undefined;
    }
    const integerSeconds = Math.floor(seconds);
    const min = Math.floor((integerSeconds / 60) % 60);
    const hours = Math.floor((integerSeconds / 60 / 60));
    const sec = integerSeconds % 60;
    const daysAfter = Math.floor(hours / 24);

    return `
        ${formatNumber(hours % 24)}:${formatNumber(min)}${!hideSeconds ? `:${formatNumber(sec)}` : ''}
        ${daysAfter ? ` (${intl?.formatMessage({ id: 'days.short' })}+${daysAfter})` : ''}
    `;
};

export const formatTimeFromMinutes = (intl: IntlShape, minutes: number) => formatTimeFromSeconds(intl, minutes * 60, true);

export const splitDateTime = (intl: IntlShape, dateStr: string) => {
    return [ intl.formatDate(dateStr), intl.formatTime(dateStr, { timeStyle: 'medium' }) ];
};

export const convertDecimalToBase = (n: number, base: Base): string => {
    return n.toString(base).toUpperCase();
};

export const convertBaseToDecimal = (n: string, base: Base): number => {
    return parseInt(n, base);
};

export const convertNumberToBinary = (n: number): Array<boolean> => {
    return convertDecimalToBase(n, Base.Binary)
        .split('')
        .reverse()
        .map(Number)
        .map(Boolean);
};

export const convertBinaryToNumber = (array: Array<boolean>): number => {
    return convertBaseToDecimal(Array.from(array, value => value || false)
        .reverse()
        .map(Number)
        .join(''), Base.Binary);
};

export const websocketMessageFormat = (array: Array<string | number | { [key: string]: string }>) => {
    return array.map(String).join(';');
};

export const websocketFormat = (code: string, message: string) => {
    return `<${code}:${message.length}>${message}`;
};

export const arrayDigit = (length: number, mulitplier?: number, digit?: number): Array<number | string> => {
    let array: Array<number | string> = Array.from({ length }).map((_, i) => i * (mulitplier ?? 1));
    if (digit) {
        array = array.map(String).map(e => e.padStart(digit, '0'));
    }
    return array;
};

export const roundNearX = (value: number, x: number) => {
    return Math.round(value / x) * x;
};

export const sortArrayNumberAndString = <T>(array: Array<T>, key: keyof T): Array<T> => {
    if (!array) {
        return [];
    }
    const sortedNumbers = array.filter(x => !isNaN(parseInt(x[key] as string, 10))).sort((a, b) => parseInt(a[key] as string, 10) - parseInt(b[key] as string, 10));
    const sortedStrings = array.filter(x => isNaN(parseInt(x[key] as string, 10))).sort((a, b) => String(a[key]).localeCompare(String(b[key])));
    return [ ...sortedNumbers, ...sortedStrings ];
};
