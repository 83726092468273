import { Button, Card, CheckElementOptionType, DatePicker, Select, Table, TableColumn } from '@ceccli/design-system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import { CommonPageType } from '../../../common/enum';
import { Communication } from '../../../models/Communication';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const CommunicationPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const { type } = useParams();
    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const availableTypes = showTerminals ? [ CommonPageType.Vehicles, CommonPageType.Terminals ] : [ CommonPageType.Vehicles ];

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'date' }), key: 'date', sortable: true },
        type === CommonPageType.Vehicles ?
            { label: intl.formatMessage({ id: 'park.id' }), key: 'info', dbKey: 'vehicleId', sortable: true } :
            { label: intl.formatMessage({ id: 'terminal' }), key: 'info', dbKey: 'terminalId', sortable: true },
        { label: intl.formatMessage({ id: 'service' }), key: 'service', sortable: true },
        { label: intl.formatMessage({ id: 'emission' }), key: 'emission', sortable: true },
        { label: intl.formatMessage({ id: 'reception' }), key: 'reception', sortable: true },
        { label: intl.formatMessage({ id: 'total.er' }), key: 'total' },
        { label: intl.formatMessage({ id: 'emission.percentage' }), key: 'emissionPct' },
        { label: intl.formatMessage({ id: 'reception.percentage' }), key: 'receptionPct' },
    ];

    const [ date, setDate ] = useState<Date>();
    const [ title, setTitle ] = useState<string>();
    const [ selectLabel, setSelectLabel ] = useState<string>();
    const [ selectOptions, setSelectOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ selectedId, setSelectedId ] = useState<number>();

    const navigateToType = (key?: string) => {
        navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/${key}`);
    };

    useEffect(() => {
        if (!type || !availableTypes.includes(type as CommonPageType)) {
            navigateToType(availableTypes[0]);
        } else {
            switch (type) {
                case CommonPageType.Vehicles:
                    setSelectLabel(intl.formatMessage({ id: 'park.id' }));
                    setTitle(intl.formatMessage({ id: 'communications.equipments.vehicle' }));
                    apiService.getAllVehicles().then(res => setSelectOptions(res.map(v => ({ label: v.parkId, value: v.id.toString() }))));
                    break;
                case CommonPageType.Terminals:
                    setSelectLabel(intl.formatMessage({ id: 'terminal' }));
                    setTitle(intl.formatMessage({ id: 'communications.equipments.terminal' }));
                    apiService.getAllTerminals().then(res => setSelectOptions(res.map(p => ({ label: `${p.type} - ${p.index} - ${p.name}`, value: p.id.toString() }))));
                    break;
                default:
                    navigateToType(availableTypes[0]);
                    break;
            }
        }
    }, [ type ]);

    return (
        <PageFillUnderHeader
            header={
                <Card title={title} contentClass="p-3">
                    <div className="d-flex flex-column flex-md-row justify-content-center gap-4 align-items-end flex-wrap">
                        <div className="col-12 col-md-6 col-lg-4">
                            <DatePicker
                                label={intl.formatMessage({ id: 'exploitation.date' })}
                                onChange={setDate}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                options={{ reset: true }}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Select
                                label={selectLabel}
                                options={selectOptions}
                                onChange={e => setSelectedId(e[0] ? Number(e[0].value) : undefined)}
                                placeHolder={intl.formatMessage({ id: `select.${type?.slice(0, -1)}` })}
                                isClearable
                            />
                        </div>
                        {
                            availableTypes.length > 1 &&
                            <Button
                                text={intl.formatMessage({ id: 'switch.to' }, { value: intl.formatMessage({ id: availableTypes.find(t => t !== type) }) })}
                                onClick={() => navigateToType(availableTypes.find(t => t !== type))}
                            />
                        }
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getCommunications(type as CommonPageType, pageIndex, pageSize, sorting, date, selectedId),
                        formatData: (data: Communication) => {
                            const option = selectOptions.find(o => Number(o.value) === data.targetId);
                            const total = data.emission + data.reception;
                            return ({
                                ...data,
                                date: intl.formatDate(data.date),
                                info: option?.label,
                                emissionPct: `${(data.emission / total * 100).toFixed(2)}%`,
                                receptionPct: `${(data.reception / total * 100).toFixed(2)}%`,
                                total,
                            });
                        },
                        refreshTrigger: [ date, selectedId, type, selectOptions ],
                    }}
                    options={{ export: true }}
                />
            }
        />
    );
};
