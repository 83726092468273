import './ColorInfo.scss';

import React from 'react';

export enum ColorShape {
    Circle = 'circle',
    Square = 'square',
}

export interface ColorInfoProps {
    color: string;
    name: string;
    variable?: string;
    shape?: ColorShape;
}

export const ColorInfo: React.FunctionComponent<ColorInfoProps> = (props: ColorInfoProps) => {
    return (
        <div className="color-info-container">
            <div className={`shape ${props.shape || ColorShape.Square}`} style={{ backgroundColor: props.color }}></div>
            <div className="info">
                <span>{props.color.toUpperCase()}</span>
                <span>{props.name}</span>
                <span>{props.variable}</span>
            </div>
        </div>
    );
};
