import './Menu.scss';

import { BurgerIcon, Collapsible, Icon, IconType } from '../../atoms';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { ColorVariable } from '../../../common/color';
import { useIntl } from 'react-intl';

export interface RouteSettings {
    path: string,
    title?: string,
    icon?: IconType,
    hideHomepage?: boolean,
    wip?: boolean,
    permissionKey?: string,
}

export interface RouteGroup {
    title: string,
    routes: Array<RouteSettings>,
    icon: IconType,
}

export interface MenuProps {
    routeGroups: Array<RouteGroup>,
    onNavigation: (path: string | number) => void,
    onLogout: () => void,
    phony?: ReactNode,
}

export const Menu = (props: MenuProps) => {
    const intl = useIntl();
    const [ selectedGroup, setSelectedGroup ] = useState<RouteGroup>();
    const [ expanded, setExpanded ] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const routeGroups = props.routeGroups.map(g => ({
        ...g,
        title: intl.formatMessage({ id: g.title }),
        routes: g.routes
            .map(r => ({
                ...r,
                title: r.title ? intl.formatMessage({ id: r.title }) : '',
            }))
            .sort((a, b) => a.title.localeCompare(b.title))
    }));

    const headerRef = useRef<HTMLDivElement>(null);
    const mobileGroupContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);

        function handleClick(e: MouseEvent) {
            if (!menuRef.current?.contains(e.target as Node)) {
                setExpanded(false);
            }
        }
    }, []);

    const renderExpandPanel = () => {
        if (!selectedGroup) {
            return null;
        }

        return (
            <div className="expanded-content position-relative p-3">
                <div className="group-title p-3">{intl.formatMessage({ id: selectedGroup.title })}</div>
                { renderRoutes(selectedGroup) }
            </div>
        );
    };

    const renderRoutes = (group: RouteGroup, className?: string) => (
        group.routes.filter(r => !r.hideHomepage).map(r => <div key={r.path} className={className}>{renderMenuItem(r)}</div>)
    );

    const renderMenuItem = (
        item: { icon?: IconType, title?: string, path?: string },
        noTitle?: boolean,
        selected?: boolean,
        onClick?: () => void,
        className: string = 'p-3',
    ) => (
        <div
            key={`${item.title} ${item.path}`}
            onClick={() => {
                if (item.path) {
                    props.onNavigation(item.path);
                    setExpanded(false);
                } else if (onClick) {
                    onClick();
                }
            }}
        >
            <div className={`menu-item cursor-pointer d-flex align-items-center gap-3 ${selected ? 'selected' : ''} ${className}`}>
                {
                    item.icon &&
                    <Icon
                        iconType={item.icon}
                        color={ColorVariable.Secondary}
                    />
                }
                {
                    !noTitle &&
                    <div className="item-title">{item.title}</div>
                }
            </div>
        </div>
    );

    const renderGroupsDesktop = () => {
        return (
            routeGroups.map(g => renderMenuItem(
                g,
                true,
                selectedGroup?.title === g.title,
                () => {
                    setSelectedGroup(g);
                    setExpanded(true);
                },
            ))
        );
    };

    const renderGroupsMobile = () => {
        return (
            routeGroups.map(g =>
                <div key={g.title}>
                    <Collapsible
                        title={renderMenuItem(g, false, false)}
                        content={renderRoutes(g, 'ps-5')}
                        className="pe-3"
                    />
                </div>
            )
        );
    };

    return (
        <div
            ref={menuRef}
            className="menu p-2 position-relative d-flex flex-row flex-md-column gap-2">
            <div
                ref={headerRef}
                className="d-flex flex-row flex-md-column w-100 justify-content-between gap-2 mb-0 mb-md-2"
            >
                {
                    renderMenuItem(
                        { icon: IconType.Home, title: 'home' },
                        true,
                        false,
                        () => {
                            props.onNavigation('/');
                            setSelectedGroup(undefined);
                            setExpanded(false);
                        },
                        'p-2 p-md-3'
                    )
                }
                {
                    props.phony &&
                    <div className="menu-item cursor-pointer">{props.phony}</div>
                }
                {
                    renderMenuItem(
                        { icon: IconType.Previous, title: 'previous' },
                        true,
                        false,
                        () => {
                            props.onNavigation(-1);
                            setSelectedGroup(undefined);
                            setExpanded(false);
                        },
                        'p-2 p-md-3'
                    )
                }
                <BurgerIcon
                    open={expanded}
                    color={ColorVariable.Secondary}
                    className="p-2 d-block d-md-none"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            </div>
            <div className="d-none d-md-flex flex-column gap-2">
                { renderGroupsDesktop() }
            </div>
            <div className={`menu-expand-container h-100 ${expanded ? 'expanded' : ''}`}>
                <div>
                    <div
                        ref={mobileGroupContainer}
                        className="d-block d-md-none"
                    >
                        { renderGroupsMobile() }
                    </div>
                </div>
                { renderExpandPanel() }
            </div>
            <div className="mt-md-auto">
                {
                    renderMenuItem(
                        { icon: IconType.Out, title: 'logout' },
                        true,
                        false,
                        props.onLogout,
                        'p-2 p-md-3'
                    )
                }
            </div>
        </div>
    );
};
