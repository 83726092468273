import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Phony, PhonyFlag } from '../models/Phony';

export interface cycleState {
    phonyInfos: {[phonyId: number]: Phony};
    openDraggable: boolean;
}

const initialState: cycleState = {
    phonyInfos: {},
    openDraggable: false,
};

export const phonyStore = createSlice({
    name: 'phony',
    initialState,
    reducers: {
        addPhony: (state, action: PayloadAction<{id: number, content: Phony}>) => {
            state.phonyInfos = {
                ...state.phonyInfos,
                [action.payload.id]: action.payload.content,
            };
        },
        updatePhony: (state, action: PayloadAction<{id: number, content: {updateTime: string, post: string, login: string, label: string, flag: PhonyFlag}}>) => {
            state.phonyInfos = {
                ...state.phonyInfos,
                [action.payload.id]: {
                    ...state.phonyInfos[action.payload.id],
                    ...action.payload.content,
                },
            };
        },
        setOpenDraggable: (state, action: PayloadAction<boolean>) => {
            state.openDraggable = action.payload;
        },
    },
});

export const { addPhony, updatePhony, setOpenDraggable } = phonyStore.actions;

export default phonyStore.reducer;
