import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignmentInfo } from '../services/assignmentsSocket.service';

interface assignmentsState {
    assignments: Array<AssignmentInfo>;
}

const initialState: assignmentsState = {
    assignments: [],
};

export const assignmentsStore = createSlice({
    name: 'assignments',
    initialState,
    reducers: {
        addAssignment: (state, action: PayloadAction<AssignmentInfo>) => {
            state.assignments = [ ...state.assignments.filter(i =>
                i.missionId !== action.payload.missionId || i.duplicationIndex !== action.payload.duplicationIndex
            ), action.payload ];
        },
        removeAssignment: (state, action: PayloadAction<{missionId: number, duplicationIndex: number}>) => {
            state.assignments = state.assignments.filter(i => i.missionId !== action.payload.missionId || i.duplicationIndex !== action.payload.duplicationIndex);
        },
        removeVehicleAssignments: (state, action: PayloadAction<number>) => {
            state.assignments = state.assignments.map(s => {
                if (s.vehicleId === action.payload) {
                    return { ...s, vehicleId: 0 };
                }
                return s;
            });
        },
        removeAgentAssignments: (state, action: PayloadAction<number>) => {
            state.assignments = state.assignments.map(s => {
                if (s.agentId === action.payload) {
                    return { ...s, agentId: 0 };
                }
                return s;
            });
        },
        clearAllAssignments: (state) => {
            state.assignments = [];
        },
    },
});

export const { addAssignment, removeAssignment, removeAgentAssignments, removeVehicleAssignments, clearAllAssignments } = assignmentsStore.actions;

export default assignmentsStore.reducer;
