import './DatePicker.scss';

import { Icon, IconType } from '../Icon/Icon';
import { useEffect, useRef, useState } from 'react';

import { Button } from '../Button/Button';
import { Calendar } from '../Calendar/Calendar';
import { useIntl } from 'react-intl';

export interface DatePickerOptions {
    reset?: boolean,
    all?: boolean,
    today?: boolean,
}

export interface DatePickerProps {
    defaultDate?: Date;
    label?: string;
    name?: string;
    className?: string;
    placeHolder?: string;
    minDate?: Date;
    maxDate?: Date;
    options?: DatePickerOptions;
    disabled?: boolean;
    onChange?: (date?: Date, name?: string) => void;
}

export const DatePicker: React.FunctionComponent<DatePickerProps> = (props: DatePickerProps) => {
    const [ calendarOpen, setCalendarOpen ] = useState<boolean>(false);
    const [ date, setDate ] = useState<Date | undefined>(props.defaultDate ?? undefined);
    const calendarRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ calendarRef.current ]);

    const handleClick = (event: MouseEvent) => {
        if (!!calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
            setCalendarOpen(false);
        }
    };

    const onChange = (value?: Date) => {
        if (props.onChange) {
            props.onChange(value, props.name);
        }
    };

    return (
        <div
            ref={calendarRef}
            className={`date-picker-container ${props.className} ${props.disabled ? 'disabled' : ''}`}
        >
            { props.label && <div className="label">{props.label}</div> }
            <div className="d-flex gap-1">
                <div
                    className="date-picker d-flex align-items-center justify-content-between"
                    onClick={() => setCalendarOpen(props.disabled ? false : !calendarOpen)}
                >
                    <div className={`${date ? '' : 'place-holder'}`}>{date ? intl.formatDate(date) : props.placeHolder}</div>
                    <Icon iconType={IconType.CalendarClock} />
                </div>
                {
                    props.options?.reset &&
                    <Button
                        text={intl.formatMessage({ id: 'reset' })}
                        onClick={() => {
                            setDate(props.defaultDate);
                            setCalendarOpen(false);
                            onChange(props.defaultDate);
                        }}
                    />
                }
                {
                    props.options?.all &&
                    <Button
                        text={intl.formatMessage({ id: 'all.gendered' }, { masculine: false })}
                        onClick={() => {
                            setDate(undefined);
                            setCalendarOpen(false);
                            onChange(undefined);
                        }}
                    />
                }
                {
                    props.options?.today &&
                    <Button
                        text={intl.formatMessage({ id: 'today' })}
                        onClick={() => {
                            setDate(new Date());
                            setCalendarOpen(false);
                            onChange(new Date());
                        }}
                    />
                }
            </div>
            {
                calendarOpen &&
                <div className="calendar">
                    <Calendar
                        value={date}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        onChange={(e: Date) => {
                            e.setTime(e.getTime() - (e.getTimezoneOffset() * 60 * 1000));
                            setDate(e);
                            setCalendarOpen(false);
                            onChange(e);
                        }}
                    />
                </div>
            }
        </div>
    );
};
