import './LoginPage.scss';

import { Button, Input, Modal } from '@ceccli/design-system';
import { FormEvent, useEffect, useState } from 'react';
import { updatePermissionList, updateUser } from '../../../app/userStore';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import bcrypt from 'bcryptjs';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { User } from '../../../models/User';

export const LoginPage = () => {
    const intl = useIntl();
    const apiService = new ApiService();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector<RootState, User | undefined | null>(state => state.user.user);
    const [ userName, setUserName ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ userError, setUserError ] = useState<boolean>(false);

    const redirectUrl = new URLSearchParams(useLocation().search).get('redirectUrl');

    useEffect(() => {
        if (user) {
            navigate(redirectUrl ?? '/');
        }
    }, [ user ]);

    const connectUser = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        localStorage.setItem('token', '');
        apiService.login(userName, bcrypt.hashSync(password, String(process.env.REACT_APP_SECURITY_PWD_SALT))).then(data => {
            if (data) {
                apiService.getAllPlatformFunctions().then(res => dispatch(updatePermissionList(res)));
                dispatch(updateUser(new User(data.id, data.userName, data.firstName, data.lastName, data.permission, data.authorization, data.token)));
                setUserError(false);
                navigate(redirectUrl ?? '/');
            }
        }).catch(() => {
            setUserError(true);
        });
    };

    return (
        <div className="d-flex align-items-center vh-100 login-page">
            <Modal>
                <form
                    className="login-form d-flex flex-column align-items-center gap-4"
                    onSubmit={connectUser}
                >
                    <div className="title">Saphir II</div>
                    <h6 className="my-2">{intl.formatMessage({ id: 'login.title' })}</h6>
                    <Input
                        label={intl.formatMessage({ id: 'user' })}
                        value={userName}
                        onChange={setUserName}
                        className="w-100"
                        error={userError}
                    />
                    <Input
                        type="password"
                        label={intl.formatMessage({ id: 'password' })}
                        value={password}
                        onChange={setPassword}
                        className="w-100"
                        error={userError}
                        errorMessage={intl.formatMessage({ id: 'incorrect.credentials' })}
                    />
                    <Button
                        type="submit"
                        text={intl.formatMessage({ id: 'login' }).toUpperCase()}
                        onClick={() => {}}
                        disabled={!userName.length || !password.length}
                    />
                </form>
            </Modal>
        </div>
    );
};
