
export interface Phony {
    startTime: string;
    priority: PhonyPriority;
    callType: number;
    hardwareType: PhonyHardwareType;
    hardwareKey: number;
    gssi: number;
    agentId: number;
    lineId: number;
    flag: PhonyFlag;
    updateTime?: string;
    login?: string;
    label?: string;
    post?: string;
}

export enum PhonyPriority {
    Simple = 1,
    Urgent = 2,
    Distress = 3,
}

export enum PhonyFlag {
    Ongoing = 'C',
    Ended = 'T',
    Request = 'D',
}

export enum PhonyHardwareType {
    Vehicle = 1,
    Portable = 2,
}
