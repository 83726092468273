import { CheckElementOptionType, Select } from '@ceccli/design-system';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface DynamicSelectProps<T> {
    selectedValue?: Array<string | number | boolean>,
    label?: string,
    placeholder?: string,
    className?: string,
    isMulti?: boolean,
    renderOption?: (data: CheckElementOptionType) => ReactNode;
    getFunction: () => Promise<Array<T>>,
    toOption: (item: T) => CheckElementOptionType,
    onChange: (items: Array<T>) => void,
}

export const DynamicSelect = <T, >(props: DynamicSelectProps<T>) => {
    const intl = useIntl();
    const [ data, setData ] = useState<Array<T>>([]);
    const [ options, setOptions ] = useState<Array<CheckElementOptionType>>([]);

    useEffect(() => {
        props.getFunction().then(res => {
            setData(res);
            setOptions(res.map(item => props.toOption(item)).sort((a, b) => a.label.localeCompare(b.label, intl.locale, { numeric: true })));
        });
    }, []);

    return (
        <Select
            value={options.filter(o => props.selectedValue?.includes(o.value))}
            options={options}
            label={props.label}
            placeHolder={props.placeholder}
            className={props.className}
            onChange={(e) => {
                props.onChange(data.filter(item => e.find(o => o && o.value === props.toOption(item).value)));
            }}
            renderOption={props.renderOption}
            isClearable
            isMulti={props.isMulti}
        />
    );
};

export default DynamicSelect;
