import './Card.scss';

import { Icon, IconType } from '../Icon/Icon';
import React, { ReactElement, ReactNode } from 'react';

export interface CardProps {
    title?: string | ReactNode;
    children?: ReactElement;
    fullHeight?: boolean;
    contentClass?: string;
    onClose?: (open: boolean) => void;
}

export const Card: React.FunctionComponent<CardProps> = (props: CardProps) => {
    return (
        <div className={`card-container ${props.fullHeight ? 'full-height' : ''}`}>
            { props.title &&
                <div className={`header text-center d-flex ${props?.onClose ? 'justify-content-between' : 'justify-content-center'}`}>
                    <div className="w-100">
                        { typeof props.title === 'string' ? props.title.toUpperCase() : props.title }
                    </div>
                    <div>
                        {
                            props?.onClose &&
                            <Icon iconType={IconType.CrossCircle} onClick={() => props.onClose && props.onClose(false)} />
                        }
                    </div>
                </div>
            }
            <div className={`content ${props.contentClass}`}>
                { props.children }
            </div>
        </div>
    );
};
