export class User {
    constructor(
        public id: number,
        public userName: string,
        public firstName: string,
        public lastName: string,
        public permission: string,
        public authorization: number,
        public token?: string
    ) {
    }
}

export enum PermissionKeys {
    Phony = 'PHONIE'
}
