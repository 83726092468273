import './BurgerIcon.scss';

import { ColorVariable } from '../../../common/color';

export interface BurgerIconProps {
    open?: boolean,
    size?: number,
    color?: string | ColorVariable;
    className?: string;
    onClick?: () => void;
}

export const BurgerIcon = (props: BurgerIconProps) => {
    const size = props.size ?? 18;
    const color = props.color ?? ColorVariable.TextLight;

    const renderLine = (className: string) => {
        return (
            <div
                className={`line ${className}`}
                style={{ background: color }}
            />
        );
    };

    return (
        <div
            className={props.className}
            onClick={props.onClick}
            data-testid="burger-icon"
        >
            <div
                className={`burger-icon ${props.open ? 'open' : ''}`}
                style={{ width: size, height: size }}
            >
                { renderLine('top') }
                { renderLine('mid') }
                { renderLine('bot') }
            </div>
        </div>
    );
};
