import { ColorVariable, Table, TableColumn, TableValueType } from '@ceccli/design-system';
import { Phony, PhonyFlag, PhonyHardwareType } from '../../models/Phony';
import { useEffect, useState } from 'react';

import { Agent } from '../../models/Agent';
import { ApiService } from '../../services/apiService';
import { getRowBackground } from '../PhonyIndicator/PhonyIndicator';
import { Portable } from '../../models/Portable';
import { RootState } from '../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Vehicle } from '../../models/Vehicle';

export interface PhonyTableProps {
    history?: boolean;
    noTitle?: boolean;
}

export const PhonyTable = (props: PhonyTableProps) => {
    const intl = useIntl();
    const apiService = new ApiService();
    const phonyInfos = useSelector<RootState, {[key: number]: Phony}>(state => state.phony.phonyInfos);
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);
    const [ agents, setAgents ] = useState<Array<Agent>>([]);
    const [ vehicles, setVehicles ] = useState<Array<Vehicle>>([]);
    const [ portables, setPortables ] = useState<Array<Portable>>([]);

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'phony.start' }), key: 'startTime' },
        { label: intl.formatMessage({ id: 'gssi' }), key: 'gssi' },
        { label: intl.formatMessage({ id: 'hardware' }), key: 'hardware' },
        { label: intl.formatMessage({ id: 'agent' }), key: 'agent' },
        { label: intl.formatMessage({ id: 'post' }), key: 'post' },
    ];

    const updatePhonyInfos = Object.values(phonyInfos)
        .filter(phony => (props.history ? phony.flag === PhonyFlag.Ended : phony.flag !== PhonyFlag.Ended))
        .map(phony => {
            const agent = agents.find(a => Number(a.code) === phony.agentId);
            let hardwareLabel;
            switch (phony.hardwareType) {
                case PhonyHardwareType.Vehicle:
                    const vehicle = vehicles.find(v => v.id === phony.hardwareKey);
                    hardwareLabel = `${intl.formatMessage({ id: 'vehicle' })} ${vehicle ? `: ${vehicle.parkId}` : intl.formatMessage({ id: 'unknown' })}`;
                    break;
                case PhonyHardwareType.Portable:
                    const portable = portables.find(p => p.id === phony.hardwareKey);
                    hardwareLabel = `${intl.formatMessage({ id: 'portable' })} ${portable ? `: ${portable.label}` : intl.formatMessage({ id: 'unknown' })}`;
                    break;
                default:
                    break;
            }

            return ({
                ...phony,
                agent: agent ? `${agent.code} - ${agent.firstName} ${agent.lastName}` : undefined,
                hardware: hardwareLabel,
            });
        })
        .sort((a, b) => b.priority - a.priority);

    useEffect(() => {
        apiService.getAllAgents(undefined, !authorization).then(setAgents);
        apiService.getAllVehicles().then(setVehicles);
        apiService.getAllPortables().then(setPortables);
    }, []);

    const getRowColor = (flag?: TableValueType) => {
        switch (flag) {
            case PhonyFlag.Ongoing:
                return `${ColorVariable.Primary}`;
            default:
                return undefined;
        }
    };

    return (
        <Table
            title={props.noTitle ? undefined : intl.formatMessage({ id: 'phony.ongoing' })}
            columns={columns}
            staticData={{ data: updatePhonyInfos }}
            colorizedRow={row => (props.history ? undefined : getRowColor(row.flag))}
            backgroundRow={row => (props.history ? undefined : getRowBackground(row.priority, 40))}
            emptyMessage={intl.formatMessage({ id: 'phony.empty.ongoing' })}
        />
    );
};
