import { addAssignment, clearAllAssignments, removeAgentAssignments, removeAssignment, removeVehicleAssignments } from '../app/assignmentsStore';
import { ToastModel, ToastType } from '@ceccli/design-system';

import { pushToast } from '../app/ToastStore';
import { SocketInitKey } from '../common/enum';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

export enum AssignmentMessageType {
    Change = '7',
    ChangeError = '8',
    Remove = '9',
    RemoveError = '10',
    RemoveVehicle = '11',
    RemoveVehicleError = '12',
    RemoveAgent = '13',
    RemoveAgentError = '14',
}

export enum AssignmentEventCode {
    Remove = 'RM',
    AddModify = 'AM',
    Agents = 'AA',
    Vehicles = 'AV',
}

export class TestService {
    dispatch = useDispatch();
    intl = useIntl();

    initWebsocket = (daysAfterToday: number) => {
        const ws = new WebSocket(String(process.env.REACT_APP_WEBSOCKET_URL));

        const addToast = (info: Array<string>, errorMessage?: string) => {
            this.dispatch(pushToast(
                new ToastModel(
                    errorMessage ?? info[info.length - 1],
                    ToastType.Error,
                    this.intl.formatMessage({ id: 'assignment.error' }, { mission: `${info[1]} (${info[2]})` }),
                )
            ));
        };

        ws.onopen = () => {
            this.dispatch(clearAllAssignments());
            ws.send(SocketInitKey.Assignment);
            ws.send(`<RAJ:2>${daysAfterToday};`);
        };

        ws.onmessage = (msg) => {
            const msgSplit = msg.data.split('\n');
            const msgContent = msgSplit.slice(1, msgSplit.length - 1).map((msg: string) => msg.split('|'));
            const messageType = msgSplit[0];

            msgContent.map((info: Array<string>) => {
                switch (messageType) {
                    case AssignmentMessageType.Change:
                        this.dispatch(addAssignment(
                            new AssignmentInfo(
                                Number(info[1]),
                                Number(info[2]),
                                Number(info[3]),
                                Number(info[4]),
                                Number(info[5]),
                                Number(info[6]),
                                Number(info[7])
                            )
                        ));
                        break;
                    case AssignmentMessageType.ChangeError:
                    case AssignmentMessageType.RemoveError:
                        addToast(info);
                        break;
                    case AssignmentMessageType.Remove:
                        this.dispatch(removeAssignment({
                            missionId: Number(info[1]),
                            duplicationIndex: Number(info[2]),
                        }));
                        break;
                    case AssignmentMessageType.RemoveVehicle:
                        this.dispatch(removeVehicleAssignments(Number(info[2])));
                        break;
                    case AssignmentMessageType.RemoveVehicleError:
                        addToast(info, this.intl.formatMessage({ id: 'assignment.issue.vehicle' }, { vehicle: info[3] }));
                        break;
                    case AssignmentMessageType.RemoveAgent:
                        this.dispatch(removeAgentAssignments(Number(info[2])));
                        break;
                    case AssignmentMessageType.RemoveAgentError:
                        addToast(info, this.intl.formatMessage({ id: 'assignment.issue.agent' }, { agent: info[3] }));
                        break;
                    default:
                        break;
                }
            });
        };

        return ws;
    };

    modifyAssignment = (ws: WebSocket, eventData: AssignmentEventData) => {
        this.sendModifyDuplicate(ws, eventData);
    };

    duplicateAssignment = (ws: WebSocket, eventData: AssignmentEventData) => {
        const data = eventData;
        data.duplicationIndex = -1;
        this.sendModifyDuplicate(ws, data);
    };

    removeAssignment = (ws: WebSocket, eventData: AssignmentEventData) => {
        const eventArr = [
            eventData.dateIndex,
            eventData.missionId,
            eventData.duplicationIndex,
        ];
        this.sendEvent(ws, eventArr, AssignmentEventCode.Remove);
    };

    removeAgent = (ws: WebSocket, eventData: AssignmentEventData) => {
        const eventArr = [
            eventData.dateIndex,
            -1,
            eventData.agentId,
        ];
        this.sendEvent(ws, eventArr, AssignmentEventCode.Agents);
    };

    removeVehicle = (ws: WebSocket, eventData: AssignmentEventData) => {
        const eventArr = [
            eventData.dateIndex,
            -1,
            eventData.vehicleId,
        ];
        this.sendEvent(ws, eventArr, AssignmentEventCode.Vehicles);
    };

    sendModifyDuplicate = (ws: WebSocket, eventData: AssignmentEventData) => {
        const eventArr = [
            eventData.dateIndex,
            eventData.missionId,
            eventData.duplicationIndex,
            eventData.vehicleId,
            eventData.agentId,
            eventData.startJourneyIndex,
            eventData.endJourneyIndex,
            eventData.shiftInMinutes ?? 0,
            -1,
        ];
        this.sendEvent(ws, eventArr, AssignmentEventCode.AddModify);
    };

    sendEvent = (ws: WebSocket, eventArr: Array<number>, code: AssignmentEventCode) => {
        const msg = [
            ...eventArr,
            '14=SERVWEBSOCKETS',
            '12=CLIENT LEGER'
        ].reduce((a, b) => `${a}; ${b}`) as string;
        ws.send(`<${code}:${msg.length}>${msg}`);
    };
}

export class AssignmentInfo {
    constructor (
        public missionId: number,
        public duplicationIndex: number,
        public vehicleId: number,
        public agentId: number,
        public startJourneyIndex: number,
        public endJourneyIndex: number,
        public shiftInMinutes?: number,
    ) {}
}

export class AssignmentEventData extends AssignmentInfo {
    constructor(
        public dateIndex: number = 0,
        public missionId: number = 0,
        public duplicationIndex: number = -1,
        public vehicleId: number = 0,
        public agentId: number = 0,
        public startJourneyIndex: number = 0,
        public endJourneyIndex: number = 0,
        public shiftInMinutes?: number,
    ) {
        super(
            missionId,
            duplicationIndex,
            vehicleId,
            agentId,
            startJourneyIndex,
            endJourneyIndex,
            shiftInMinutes,
        );
    }
}

