import { ReactNode, useEffect, useState } from 'react';
import { splitDateTime, Table, TableColumn, TableSort } from '@ceccli/design-system';

import { ApiService } from '../../services/apiService';
import { Calculator } from '../../models/Calculator';
import { PaginatedResult } from '../../common/interface';
import { TerminalWithSoftware } from '../../models/Terminal';
import { useIntl } from 'react-intl';
import { UtilsService } from '../../services/utilsService';
import { VehicleHardware } from '../../models/VehicleSoftware';

export interface SoftwareTableProps {
    title: string | ReactNode;
    columns: Array<TableColumn>;
    type: string;
    getFunction: (pageIndex?: number, pageSize?: number, sorting?: TableSort) => Promise<PaginatedResult<VehicleHardware | TerminalWithSoftware>>;
}

export const SoftwareTable = (props: SoftwareTableProps) => {
    const apiService = new ApiService();
    const intl = useIntl();
    const utilsService = new UtilsService();

    const [ calculators, setCalculators ] = useState<Array<Calculator>>();

    useEffect(() => {
        apiService.getCalculators().then(setCalculators);
    }, []);

    const getContent = (val: string | number, obj: {[key: string]: string | number | undefined | Array<string>}, calculatorKey: string, softwareKey: string, calculators: Array<Calculator>) => {
        if (val) {
            const key = `${softwareKey}${calculatorKey.toUpperCase()}`;
            switch (softwareKey) {
                case 'type':
                    obj[key] = calculators.find(c => c.id === val)?.name;
                    break;
                case 'version':
                    obj[key] = utilsService.formatVersion(Number(val));
                    break;
                case 'date':
                    obj[key] = utilsService.splitDateTime(val.toString());
                    break;
                default:
                    obj[key] = val;
                    break;
            }
        }

        return obj;
    };

    return (
        <>
            {
                calculators &&
                <Table
                    title={props.title}
                    columns={props.columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => props.getFunction(pageIndex, pageSize, sorting).then(res => ({
                            ...res,
                            results: res.results.map(item => {
                                let obj = Object.create({});
                                if (item.calculators) {
                                    for (const key of Object.keys(item.calculators)) {
                                        const calculator = item.calculators[key as keyof typeof item.calculators];
                                        if (typeof calculator !== 'string') {
                                            for (const k of Object.keys(calculator)) {
                                                const val = calculator[k as keyof typeof calculator];
                                                obj = getContent(val, obj, key, k, calculators);
                                            }
                                        }
                                    }
                                }
                                return ({
                                    ...item,
                                    lastConnection: item.calculators?.lastConnection ? splitDateTime(intl, item.calculators?.lastConnection) : undefined,
                                    ...obj
                                });
                            }),
                        })),
                    }}
                    emptyMessage={intl.formatMessage({ id: 'empty.deployed.software' }, { target: intl.formatMessage({ id: props.type }).toLowerCase() })}
                    options={{ export: true }}
                    allSortable
                />
            }
        </>
    );
};
