import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { IconType, Loader, ToastContainer, ToastModel } from '@ceccli/design-system';
import { logoutUser, updatePermissionList, updateUser } from './app/userStore';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ApiService } from './services/apiService';
import { AssignmentPage } from './components/pages/AssignmentPage/AssignmentPage';
import { CartographyPage } from './components/pages/CartographyPage/CartographyPage';
import { CommunicationPage } from './components/pages/CommunicationPage/CommunicationPage';
import { ConnectionHistoryPage } from './components/pages/ConnectionHistoryPage/ConnectionHistoryPage';
import { CycleManagementPage } from './components/pages/CycleManagementPage/CycleManagementPage';
import { deleteToast } from './app/ToastStore';
import { DeployedEmbeddedTablesPage } from './components/pages/DeployedEmbeddedTables/DeployedEmbeddedTables';
import { DeployedScenarioPage } from './components/pages/DeployedScenarioPage/DeployedScenarioPage';
import { DeployedSoftwarePage } from './components/pages/DeployedSoftwarePage/DeployedSoftwarePage';
import { EmbeddedSoftwareDeployementPage } from './components/pages/EmbeddedSoftwareDeployementPage/EmbeddedSoftwareDeployementPage';
import { EmbeddedSoftwarePage } from './components/pages/EmbeddedSoftwarePage/EmbeddedSoftwarePage';
import { ExportPage } from './components/pages/ExportPage/ExportPage';
import { GeneralLayout } from './components/layouts/GeneralLayout/GeneralLayout';
import { GeneralNetworkStatePage } from './components/pages/GeneralNetworkStatePage/GeneralNetworkStatePage';
import { HazardGesturePage } from './components/pages/HazardGesturePage/HazardGesturePage';
import { Homepage } from './components/pages/Homepage/Homepage';
import { InformationBanksPage } from './components/pages/InformationBanksPage/InformationBanksPage';
import { InterventionSettingsPage } from './components/pages/InterventionSettingsPage/InterventionSettingsPage';
import { InterventionVehiclePage } from './components/pages/InterventionVehiclePage/InterventionVehiclePage';
import { LineAttachmentPage } from './components/pages/LineAttachmentPage/LineAttachmentPage';
import { LoginPage } from './components/pages/LoginPage/LoginPage';
import { MessagePage } from './components/pages/MessagePage/MessagePage';
import { MessageProgramPage } from './components/pages/MessageProgramPage/MessageProgramPage';
import { ParkTrackingPage } from './components/pages/ParkTrackingPage/ParkTrackingPage';
import { PassengerInformationPage } from './components/pages/PassengerInformationPage/PassengerInformationPage';
import { PhonyPage } from './components/pages/PhonyPage/PhonyPage';
import { PhonySocketService } from './services/phonySocket.service';
import { PlatformFunction } from './models/PlatformFunction';
import { RootState } from './app/store';
import { SaphirFunctionNames } from './models/SaphirFunction';
import { SaphirSettingsPage } from './components/pages/SaphirSettingsPage/SaphirSettingsPage';
import { SecurityManager } from './security/security';
import { ServiceAssignmentFollowUpPage } from './components/pages/ServiceAssignmentFollowUpPage/ServiceAssignmentFollowUpPage';
import { setShowTerminals } from './app/saphirFunctionsStore';
import { TechnicalWarningPage } from './components/pages/TechnicalWarningPage/TechnicalWarningPage';
import { TerminalSettingsPage } from './components/pages/TerminalSettingsPage/TerminalSettingsPage';
import { UnloadedFilesPage } from './components/pages/UnloadedFilesPage/UnloadedFilesPage';
import { User } from './models/User';
import { UsersManagementPage } from './components/pages/UsersManagementPage/UsersManagementPage';
import { WebSocketService } from './services/websocketService';

export interface RouteSettings {
    path: string,
    element: React.ReactNode,
    title?: string,
    pathBase?: string,
    hideHomepage?: boolean,
    permissionKey?: string,
    icon?: IconType
}

export interface RouteGroup {
    title: string,
    routes: Array<RouteSettings>;
    icon: IconType,
}

export const routeGroups: Array<RouteGroup> = [
    {
        title: 'rt.sae',
        routes: [
            {
                path: '/cartography',
                element: <CartographyPage />,
                title: 'cartography',
                icon: IconType.Cartography,
                permissionKey: 'CARTOGRAPHIE',
            },
            {
                path: '/assignment',
                element: <AssignmentPage />,
                title: 'assignments.gesture',
                icon: IconType.CalendarClock,
                permissionKey: 'GESTIONAFF',
            },
            {
                path: '/park-tracking',
                element: <ParkTrackingPage />,
                title: 'park.tracking',
                icon: IconType.BusCar,
                permissionKey: 'SUIVI_PARC',
            },
            {
                path: '/general-network-state',
                element: <GeneralNetworkStatePage />,
                title: 'general.network.state',
                icon: IconType.Network,
                permissionKey: 'ETAT_GENERAL',
            },
            {
                path: '/general-network-state/:lineId',
                element: <GeneralNetworkStatePage />,
                permissionKey: 'ETAT_GENERAL',
                hideHomepage: true
            },
            {
                path: '/general-network-state/:lineId/:pathId',
                element: <GeneralNetworkStatePage />,
                permissionKey: 'ETAT_GENERAL',
                hideHomepage: true
            },
            {
                path: '/passenger-information',
                element: <PassengerInformationPage />,
                title: 'passenger.information',
                permissionKey: 'IV',
                icon: IconType.Audio,
            },
            {
                path: '/cycle-management/',
                element: <CycleManagementPage />,
                permissionKey: 'GESTION_CYCLE_AFF',
                hideHomepage: true,
            },
            {
                path: '/cycle-management/:type',
                element: <CycleManagementPage />,
                title: 'cycle.management',
                permissionKey: 'GESTION_CYCLE_AFF',
                icon: IconType.ClockArrow,
            },
            {
                path: '/phony',
                element: <PhonyPage />,
                title: 'phony',
                permissionKey: 'PHONIE',
                icon: IconType.Phone,
            },
        ],
        icon: IconType.Wifi,
    },
    {
        title: 'dt.sae',
        routes: [
            {
                path: '/line-attachment',
                element: <LineAttachmentPage />,
                permissionKey: 'ANALYSE_LIGNE',
                title: 'line.attachment'
            },
            {
                path: '/service-assignment-followup',
                element: <ServiceAssignmentFollowUpPage />,
                permissionKey: 'AFFECTATIONS_SERVICES',
                title: 'services.assignments'
            },
            {
                path: '/technical-warning',
                element: <TechnicalWarningPage />,
                permissionKey: 'VISU_ALARMES_TECH',
                title: 'technical.warnings'
            },
            {
                path: '/intervention-vehicle',
                element: <InterventionVehiclePage />,
                permissionKey: 'VISU_VEHICULES_INTERV',
                title: 'intervention.vehicle'
            },
        ],
        icon: IconType.Disk,
    },
    {
        title: 'maintenance',
        routes: [
            {
                path: '/deployed-software/:type',
                pathBase: '/deployed-software',
                element: <DeployedSoftwarePage />,
                permissionKey: 'VISU_SOFT_EMBARQUE',
                title: 'deployed.software'
            },
            {
                path: '/deployed-embedded-tables',
                element: <DeployedEmbeddedTablesPage />,
                permissionKey: 'VISU_TABLES_EMBARQUEES',
                title: 'deployed.embedded.tables'
            },
            {
                path: '/information-bank',
                element: <InformationBanksPage />,
                permissionKey: 'VISU_BANQUE_INFO_LOCALE',
                title: 'deployed.information.bank'
            },
            {
                path: '/deployed-scenario/:type',
                element: <DeployedScenarioPage />,
                permissionKey: 'VISU_SCENARIOS',
                title: 'deployed.scenario'
            },
            {
                path: '/embedded-software',
                element: <EmbeddedSoftwarePage />,
                title: 'embedded.software',
                permissionKey: 'GESTION_SOFT_EMBARQUE',
            },
            {
                path: '/embedded-software-deployement',
                element: <EmbeddedSoftwareDeployementPage/>,
                title: 'embedded.software.deployement',
                permissionKey: 'DEPLOIEMENT_LOGICIEL',
            },
            {
                path: '/unloaded-file',
                element: <UnloadedFilesPage />,
                permissionKey: 'VISU_FICHIERS_DECHARGES',
                title: 'unloaded.files'
            },
            {
                path: '/connection-history',
                element: <ConnectionHistoryPage />,
                permissionKey: 'VISU_HISTORIQUE_CONNEXIONS',
                title: 'connection.history'
            },
            {
                path: '/vehicle-communication/:type',
                pathBase: '/vehicle-communication',
                element: <CommunicationPage />,
                permissionKey: 'VISU_COMMUNICATIONS',
                title: 'communications.equipments'
            },
            {
                path: '/messages',
                element: <MessagePage />,
                title: 'message.title',
                permissionKey: 'HISTO_MESSAGES_BIV',
            },
            {
                path: '/messages-program',
                element: <MessageProgramPage />,
                title: 'message.program.title',
                permissionKey: 'HISTO_PROG_BIV',
            },
        ],
        icon: IconType.Wrench,
    },
    {
        title: 'data.export',
        routes: [
            {
                path: '/export',
                element: <ExportPage />,
                permissionKey: 'EXPORT_DONNEES',
                title: 'data.export'
            }
        ],
        icon: IconType.Download,
    },
    {
        title: 'settings',
        routes: [
            {
                path: '/saphir-settings',
                element: <SaphirSettingsPage />,
                permissionKey: 'PARAMETRAGE_SAPHIR',
                title: 'settings.saphir'
            },
            {
                path: '/terminal-settings',
                element: <TerminalSettingsPage />,
                permissionKey: 'PARAMETRAGE_BORNES',
                title: 'settings.terminal'
            },
            {
                path: '/terminal-settings/:terminalId',
                element: <TerminalSettingsPage />,
                permissionKey: 'PARAMETRAGE_BORNES',
                hideHomepage: true
            },
            {
                path: '/vehicle-intervention-settings',
                element: <InterventionSettingsPage />,
                title: 'intervention.settings.title',
                permissionKey: 'PARAMETRAGE_VI'
            },
            {
                path: '/hazard-gesture',
                element: <HazardGesturePage />,
                title: 'hazard.gesture',
                permissionKey: 'PARAMETRAGE_ALEAS'
            },
        ],
        icon: IconType.Settings,
    },
    {
        title: 'profile',
        routes: [
            {
                path: '/users-management',
                element: <UsersManagementPage />,
                title: 'user.management',
                permissionKey: 'GESTION_USERS',
            },
            {
                path: '/users-management/:userId',
                element: <UsersManagementPage />,
                title: 'user.management',
                permissionKey: 'GESTION_USERS',
                hideHomepage: true
            },
            {
                path: '/login',
                element: <LoginPage />,
                title: 'login',
                hideHomepage: true,
            }
        ],
        icon: IconType.Users,
    },
];

function App() {
    const apiService = new ApiService();
    const webSocketService = new WebSocketService();
    const phonySocketService = new PhonySocketService();

    const dispatch = useDispatch();
    const user = useSelector<RootState, User | undefined | null>(state => state.user.user);
    const toasts = useSelector<RootState, Array<ToastModel>>(state => state.toasts.toasts);
    const permissionList = useSelector<RootState, Array<PlatformFunction>>(state => state.user.permissionList);
    const showTerminals = useSelector<RootState, boolean | undefined>(state => state.saphirFunction.showTerminals);
    const [ webSocket, setWebSocket ] = useState<WebSocket>();
    const [ socketPhony, setSocketPhony ] = useState<WebSocket>();
    const seconds = 7;

    useEffect(() => {
        const tokenInfo = new SecurityManager().decodeToken();
        const isLogged = tokenInfo && new Date(tokenInfo.exp * 1000) >= new Date();

        if (isLogged) {
            apiService.getAllPlatformFunctions().then(res => dispatch(updatePermissionList(res)));
            dispatch(updateUser(tokenInfo.user));
        } else {
            dispatch(logoutUser());
        }

        const appCount = localStorage.getItem('appCount');
        localStorage.setItem('appCount', `${Number(appCount) + 1}`);

        window.addEventListener('unload', () => {
            const appCount = Number(localStorage.getItem('appCount') ?? 1) - 1;
            localStorage.setItem('appCount', appCount.toString());
            if (!appCount) {
                dispatch(logoutUser());
            }
        });
    }, []);

    useEffect(() => {
        if (user && typeof showTerminals === 'undefined') {
            apiService.getSaphirFunction(SaphirFunctionNames.TerminalDisplay).then(res => dispatch(setShowTerminals(res.active)));
        }

        if (user) {
            webSocketService.initWebsocket().then(setWebSocket);
            setSocketPhony(phonySocketService.initWebsocket());
        }

        return () => {
            webSocket?.close();
            socketPhony?.close();
        };
    }, [ user ]);

    const onDeleteToast = (id: number) => {
        dispatch(deleteToast(id));
    };

    const renderRoute = (route: RouteSettings) => {
        const isOnLoginPage = route.path === '/login';
        let canAccess = isOnLoginPage;
        if (!canAccess) {
            const routePermission = permissionList.find(p => p.key === route.permissionKey);
            if ((!routePermission && user) || (routePermission?.active && (user?.permission[user.permission.length - routePermission?.infoBit] === '1'))) {
                canAccess = true;
            }
        }

        return (
            <GeneralLayout noMenu={isOnLoginPage}>
                {
                    canAccess ?
                        route.element :
                        typeof user !== 'undefined' ?
                            <Navigate to={
                                user ?
                                    '/' :
                                    '/login'
                            } /> :
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                <Loader />
                            </div>
                }
            </GeneralLayout>
        );
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
                <Route
                    path=""
                    element={renderRoute({
                        path: '/',
                        element: <Homepage />,
                    })}
                />
                {
                    routeGroups.map(g => g.routes.map(r =>
                        <Route
                            key={r.path}
                            path={r.path}
                            element={renderRoute(r)}
                        />
                    ))
                }
            </Routes>
            <ToastContainer toasts={toasts} onDeleteToast={onDeleteToast} duration={seconds * 1000} />
        </BrowserRouter>
    );
}

export default App;
