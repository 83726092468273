import { CartographyKeys, CartographyObject } from './CartographyObject';

import { Icon } from 'ol/style';
import TerminalIcon from '../../assets/terminal.png';

export class TerminalCartography extends CartographyObject {
    constructor(id: number, label: string, position: [number, number]) {
        super(id, CartographyKeys.Terminal, label, position, TerminalCartography.icons[0]);
    }

    static icons = [
        new Icon({
            anchor: [ 0.5, 1 ],
            src: TerminalIcon,
            scale: 0.07
        }),
    ];
}
