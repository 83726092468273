
export interface Version {
    date: string;
    identifier: VersionIdentifier;
    value: number;
}

export enum VersionIdentifier {
    InformationBank = 'BNQ_ILOC',
    Configuration = 'EMB_CFG',
    Capability = 'EMB_MOY',
    Schedule = 'EMB_HOR',
    Geography = 'EMB_GEO',
    GeographyPlus = 'EMB_GE+'
}
