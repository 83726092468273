import { Card, CheckElementOptionType, DatePicker, Select, Table, TableColumn } from '@ceccli/design-system';
import { MessageTargetType, TypeTranslation } from '../../../models/Message';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { TerminalDisplayType } from '../../../models/Terminal';
import { useIntl } from 'react-intl';

export const MessagePage = () => {
    const intl = useIntl();
    const apiService = new ApiService();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'ordinal.number' }), key: 'id' },
        { label: intl.formatMessage({ id: 'terminal' }), key: 'terminal', dbKey: 'messageTargetId' },
        { label: intl.formatMessage({ id: 'message.number' }), key: 'messageNumber' },
        { label: intl.formatMessage({ id: 'message.text' }), key: 'messageText' },
        { label: intl.formatMessage({ id: 'exploitation.date' }), key: 'exploitationDate' },
        { label: intl.formatMessage({ id: 'start.date' }), key: 'startDate', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'end.date' }), key: 'endDate', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'message.type' }), key: 'messageType' },
    ];

    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date>();
    const [ terminalsOptions, setTerminalsOptions ] = useState<Array<CheckElementOptionType>>([]);
    const [ selectedTerminal, setSelectedTerminal ] = useState<{type: MessageTargetType, id: number}>();

    useEffect(() => {
        apiService.getAllTerminals().then(res => setTerminalsOptions(res.map(t => ({ label: `${t.type} - ${t.id.toString()}`, value: t.id.toString() }))));
    }, []);

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'message.title' })}
                    contentClass="p-4"
                >
                    <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-0">
                        <div className="col-12 col-lg-4 p-0 pe-lg-2">
                            <DatePicker
                                label={intl.formatMessage({ id: 'start.date' })}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                onChange={setStartDate}
                                options={{ reset: true }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 p-0 px-lg-2">
                            <DatePicker
                                label={intl.formatMessage({ id: 'end.date' })}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                onChange={setEndDate}
                                options={{ reset: true }}
                            />
                        </div>
                        <div className="col-12 col-lg-4 p-0 ps-lg-2">
                            <Select
                                label={intl.formatMessage({ id: 'select.terminal' })}
                                options={terminalsOptions}
                                onChange={(e) => setSelectedTerminal(
                                    e[0] ?
                                        { type: e[0].label[0] as MessageTargetType, id: Number(e[0].label.split(' - ')[1]) }
                                        : undefined
                                )}
                                isClearable
                            />
                        </div>
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getTerminalMessages(pageIndex, pageSize, sorting, selectedTerminal?.id, selectedTerminal?.type, startDate, endDate),
                        formatData: (data) => ({
                            ...data,
                            startDate: [ intl.formatDate(data.startDate), intl.formatTime(data.startDate) ],
                            endDate: [ intl.formatDate(data.endDate), intl.formatTime(data.endDate) ],
                            exploitationDate: intl.formatDate(data.exploitationDate),
                            terminal: `${TerminalDisplayType[data.messageTargetType === MessageTargetType.Panel ? 'PANEL' : 'SATELLITE']} - ${data.messageTargetId}`,
                            messageType: data.messageType ? intl.formatMessage({ id: TypeTranslation[data.messageType] }) : undefined,
                        }),
                        refreshTrigger: [ selectedTerminal, startDate, endDate ],
                    }}
                    allSortable
                />
            }
        />
    );
};
