import './TableFilters.scss';

import { Button, DatePicker, Input, Select } from '../../atoms';
import { useEffect, useState } from 'react';

import { TableColumn } from '../Table/Table';
import { useIntl } from 'react-intl';

export type sqlTypes = 'text' | 'varchar' | 'int' | 'tinyint' | 'date';

const typesNum = [ 'int', 'tinyint' ];

export enum FilterType {
    Equal = 'equal',
    Diff = 'diff',
    Between = 'between',
    StartBy = 'startBy',
    Contain = 'contains',
    EndsBy = 'endsBy',
}

export interface TableFiltersProps {
    columns: Array<TableColumn>;
    filters: {[column: string]: TableFilter | undefined};
    currentColumn: string;
    onFiltersChange: (filters: {[column: string]: TableFilter | undefined}) => void;
}

export interface TableFilter {
    type: FilterType,
    value: string,
}

export const TableFilters = (props: TableFiltersProps) => {
    const intl = useIntl();
    const typeToOption = (type: FilterType) => ({ label: intl.formatMessage({ id: `filter.type.${type}` }), value: type });
    const colToOption = (col: TableColumn) => ({ label: col.label, value: col.key });

    const [ availableFilterType, setAvailableFilterType ] = useState<Array<FilterType>>([]);

    const convertDateToString = (date: Date) => date.toISOString().split('T')[0];

    const [ currentColKey, setCurrentColKey ] = useState<string>(props.currentColumn);
    const column = props.columns.find(c => (c.dbKey ?? c.key) === currentColKey);
    const isNum = column?.type && typesNum.includes(column.type);
    const [ filter, setFilter ] = useState<TableFilter>({ type: availableFilterType[0], value: '' });

    const [ dates, setDates ] = useState<Array<Date>>(filter?.value ? filter?.value.split('').map(v => new Date(v)) : [ new Date(), new Date() ]);

    useEffect(() => {
        setCurrentColKey(props.currentColumn);
    }, [ props.currentColumn ]);

    useEffect(() => {
        const filterTypes = getAvailableFilterType(column?.type);
        setAvailableFilterType(filterTypes);
        const type = props.filters[props.currentColumn]?.type ?? filterTypes[0];
        const value = !props.filters[props.currentColumn]?.value.length && isNum ? '0' : '';
        setFilter({ ...filter, value, type });
    }, [ column ]);

    const getAvailableFilterType = (columnType?: sqlTypes) => {
        switch (columnType) {
            case 'int':
            case 'tinyint':
            case 'date':
                return [ FilterType.Equal, FilterType.Diff, FilterType.Between ];
            case 'text':
            case 'varchar':
                return [ FilterType.StartBy, FilterType.Contain, FilterType.EndsBy ];
            default:
                return [];
        }
    };

    const renderInput = (index: number, doubleValue?: boolean) => {
        switch (column?.type) {
            case 'date':
                return (
                    <DatePicker
                        label={intl.formatMessage({ id: `filter.${doubleValue ? index > 0 ? 'max' : 'min' : 'value'}` })}
                        className="fw-normal"
                        defaultDate={dates[index]}
                        onChange={date => {
                            if (date) {
                                const newDates = index > 0 ? [ dates[0], date ] : [ date, dates[1] ];
                                setDates(newDates);
                                setFilter({ ...filter, value: doubleValue ? newDates.map(d => convertDateToString(d)).join(' ') : date.toLocaleDateString() });
                            }
                        }}
                    />
                );
            default:
                const splitVal = doubleValue ?
                    filter.value.split(' ') :
                    [ filter.value ];

                return (
                    <Input
                        label={intl.formatMessage({ id: `filter.${doubleValue ? index > 0 ? 'max' : 'min' : 'value'}` })}
                        className="filter-input"
                        value={splitVal[index]}
                        onChange={val => setFilter({ ...filter, value:
                            doubleValue ?
                                index > 0 ? `${splitVal[0]} ${val}` : `${val} ${splitVal[1]}` :
                                val
                        })}
                        type={isNum ? 'number' : undefined}
                    />
                );
        }
    };

    const renderFilterInput = () => {
        switch (filter.type ?? availableFilterType[0]) {
            case FilterType.Equal:
            case FilterType.Diff:
            case FilterType.StartBy:
            case FilterType.EndsBy:
            case FilterType.Contain:
                return renderInput(0);
            case FilterType.Between:
                return (
                    <>
                        { renderInput(0, true) }
                        { renderInput(1, true) }
                    </>
                );
            default:
                return;
        }
    };

    const renderFilter = () => {
        return (
            <>
                <div className="d-flex gap-2 mx-auto">
                    <Select
                        label={intl.formatMessage({ id: 'filter.column' })}
                        className="type-select fw-normal"
                        options={props.columns.filter(c => c.type).map(c => colToOption(c))}
                        value={column ? [ colToOption(column) ] : []}
                        onChange={e => {
                            setCurrentColKey(e[0].value.toString());
                        }}
                    />

                    <Select
                        label={intl.formatMessage({ id: 'filter.type' })}
                        className="type-select fw-normal"
                        options={availableFilterType.map(o => typeToOption(o))}
                        value={filter?.type ? [ typeToOption(filter.type) ] : []}
                        onChange={e => {
                            if (filter) {
                                setFilter({ ...filter, type: e[0].value as FilterType });
                            }
                        }}
                    />
                    { renderFilterInput() }
                </div>
                <div className="d-flex gap-2 mx-auto">
                    <Button
                        text={'Save'}
                        onClick={() => {
                            let currentFilter = filter;
                            if (column?.type === 'date' && !filter?.value) {
                                currentFilter = { ...filter, value: filter?.type === 'between' ? dates.map(d => convertDateToString(d)).join(' ') : convertDateToString(dates[0]) };
                                setFilter(currentFilter);
                            }
                            props.onFiltersChange({ ...props.filters, [currentColKey]: currentFilter });
                        }}
                    />
                    <Button
                        text={'Remove'}
                        onClick={() => {
                            props.onFiltersChange({ ...props.filters, [currentColKey]: undefined });
                        }}
                    />
                </div>
            </>
        );
    };

    return (
        <div className="table-filters d-flex gap-2 align-items-end flex-wrap">
            {renderFilter()}
        </div>
    );
};
