import './Calendar.scss';

import { Calendar as ReactCalendar } from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';

export interface CalendarProps {
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    onChange?: (date: Date) => void;
}

export const Calendar: React.FunctionComponent<CalendarProps> = (props: CalendarProps) => {
    return (
        <div data-testid="calendar">
            <ReactCalendar
                value={props.value}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onChange={(value: Value) => {
                    if (props.onChange) {
                        props.onChange(value as Date);
                    }
                }}
            />
        </div>
    );
};
