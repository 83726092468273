import './Toast.scss';

import React, { useEffect } from 'react';

import { ToastModel } from '../../../models/Toast';
import { X } from 'react-bootstrap-icons';

export interface ToastProps {
    toast: ToastModel;
    duration?: number;
    onDeleteToast: (toastId: number) => void;
}

export const Toast: React.FunctionComponent<ToastProps> = (props: ToastProps) => {
    console.log('props', props);
    useEffect(() => {
        if (props.duration) {
            const interval = setInterval(() => {
                props.onDeleteToast(props.toast.id);
            }, props.duration);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);

    return (
        <div className={`toast-atom-container ${props.toast.type} ${props.toast.style}`}>
            <div className={'d-flex flex-row justify-content-between'}>
                <div className="d-flex flex-row align-items-center">
                    {props.toast.titleIcon && props.toast.titleIcon}
                    <p className="toast-title my-auto">{props.toast.title}</p>
                </div>
                {
                    !!props.duration &&
                    <X
                        size={12}
                        className="cursor-pointer"
                        onClick={() => props.onDeleteToast(props.toast.id)}
                    />
                }
            </div>
            <div className="toast-content">
                <p>{props.toast.content}</p>
            </div>
        </div>
    );
};
