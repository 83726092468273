const cycleBitCount = 3;

export class DisplayCycle {
    public file?: boolean;
    public scenario?: boolean;
    public schedule?: boolean;

    constructor(
        public ack?: boolean,
        cycleValue?: number,
    ) {
        [ this.scenario, this.file, this.schedule ] = getBinaryCycle(cycleValue);
    }
}

const getBinaryCycle = (cycleValue?: number) => {
    if (cycleValue === null) {
        return Array.from(Array(cycleBitCount).keys()).map(i => undefined);
    }
    const binary = (cycleValue ?? 0).toString(2).padStart(cycleBitCount, '0');
    return Array.from(Array(cycleBitCount).keys()).map(i => (!Number(binary[i])));
};
