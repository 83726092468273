import './TableColumnHeader.scss';

import { FilterType, TableFilter } from '../TableFilters/TableFilters';
import { FunnelFill, XLg } from 'react-bootstrap-icons';
import { Icon, IconType, SortIndicator } from '../../atoms';
import { TableColumn, TableSort } from '../Table/Table';

import { Color } from '../../../common/color';
import { useIntl } from 'react-intl';

export interface TableColumnHeaderProps {
    column: TableColumn,
    allSortable?: boolean,
    allHidable?: boolean,
    sorting?: TableSort,
    filters?: { [column: string]: TableFilter | undefined },
    currentFilterKey?: string,
    onSortingChange?: (sort: TableSort) => void,
    onFiltersChange?: (filters: { [column: string]: TableFilter | undefined }) => void,
    onCurrentFilterKeyChange?: (currentFilterKey?: string) => void,
    onHideChange?: (key: string) => void,
}

export const TableColumnHeader = (props: TableColumnHeaderProps) => {
    const intl = useIntl();
    const columnKey = props.column.dbKey ?? props.column.key;
    const columnFilter = props.filters ? props.filters[columnKey] : undefined;
    const filterVal = columnFilter ?
        columnFilter?.type === 'between' ?
            columnFilter.value.split(' ') :
            [ columnFilter.value ] :
        [];

    return (
        <>
            <div className="d-flex align-items-center gap-1">
                <div className="me-2">
                    {
                        props.column.icon ?
                            <Icon iconType={props.column.icon} /> :
                            props.column.label
                    }
                </div>
                {
                    (props.allSortable || props.column.sortable || props.column.sortType) && props.column.key !== 'actions' &&
                    <SortIndicator
                        order={props.sorting?.column === columnKey ? props.sorting.order : 0}
                        onChange={(e) => {
                            if (props.onSortingChange) {
                                props.onSortingChange({ column: columnKey, order: e });
                            }
                        }}
                    />
                }
                {
                    props.column.type &&
                    <FunnelFill
                        color={Color.Grey3}
                        size={12}
                        className={`interactive-icon filter-icon ${props.filters && props.filters[columnKey] ? 'filtering' : ''}`}
                        onClick={() => {
                            if (props.onCurrentFilterKeyChange) {
                                props.onCurrentFilterKeyChange(props.currentFilterKey === columnKey ? undefined : columnKey);
                            }
                        }}
                    />
                }
                {
                    (props.allHidable || props.column.hidable) &&
                    <Icon
                        iconType={IconType.EyeCross}
                        color={Color.Grey3}
                        size={12}
                        className="interactive-icon filter-icon"
                        onClick={() => props.onHideChange && props.onHideChange(props.column.key)}
                    />
                }
            </div>
            {
                columnFilter?.type &&
                <div className="d-flex align-items-center gap-2">
                    <div className="filter-info">
                        {`${intl.formatMessage({ id: `filter.type.${columnFilter.type}` })} '${filterVal[0]}'`}
                        {
                            columnFilter.type === FilterType.Between &&
                            ` ${intl.formatMessage({ id: 'and' })} ${filterVal[1]}`
                        }
                    </div>
                    <XLg
                        color={Color.Grey3}
                        size={12}
                        className="interactive-icon"
                        onClick={() => {
                            if (props.onFiltersChange) {
                                props.onFiltersChange({ ...props.filters, [columnKey]: undefined });
                            }
                        }}
                    />
                </div>
            }
        </>
    );
};
