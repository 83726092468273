import './Stepper.scss';

import { Stepper as ReactStepper, Step } from 'react-form-stepper';

export interface StepperProps {
    activeStep: number;
    steps: Array<string>;
}

export const Stepper: React.FunctionComponent<StepperProps> = (props: StepperProps) => {
    return (
        <div className="stepper-container" data-testid="stepper">
            <ReactStepper activeStep={props.activeStep}>
                {
                    props.steps.map((step, index) => (
                        <Step key={index} label={step} />
                    ))
                }
            </ReactStepper>
        </div>
    );
};
