import { Card, DatePicker, formatTimeFromSeconds, LineIndicator, Table, TableColumn, TableValueType } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import DynamicSelect from '../../DynamicSelect/DynamicSelect';
import { Line } from '../../../models/Line';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { ServicesType } from '../../../models/Services';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const ServiceAssignmentFollowUpPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();

    const [ lines, setLines ] = useState<Array<Line>>([]);
    const [ selectedDate, setSelectedDate ] = useState<Date | undefined>(new Date());
    const [ selectedVehicle, setSelectedVehicle ] = useState<Array<number>>([]);
    const [ selectedServiceAgent, setSelectedServiceAgent ] = useState<Array<string>>([]);
    const [ selectedServiceVehicle, setSelectedServiceVehicle ] = useState<Array<string>>([]);
    const [ selectedLine, setSelectedLine ] = useState<Array<string>>([]);
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);

    const columns: Array<TableColumn> = lines.length ? [
        { label: intl.formatMessage({ id: 'sequence.number' }), key: 'sequenceNumber' },
        { label: intl.formatMessage({ id: 'vehicle' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'agent' }), key: 'agent', dbKey: 'code' },
        { label: intl.formatMessage({ id: 'service.agent' }), key: 'serviceAgent' },
        { label: intl.formatMessage({ id: 'service.vehicle' }), key: 'serviceVehicle' },
        { label: intl.formatMessage({ id: 'line' }), key: 'lineCode', renderCell: row => renderLine(row.lineCode) },
        { label: intl.formatMessage({ id: 'time.start' }), key: 'startTime' },
        { label: intl.formatMessage({ id: 'time.end' }), key: 'endTime' },
        { label: intl.formatMessage({ id: 'dubbing.level' }), key: 'duplicationIndex' },
    ] : [];

    useEffect(() => {
        apiService.getAllLines().then(res => setLines(res));
    }, []);

    const renderLine = (value: TableValueType) => {
        const line = lines.find(l => l.code === value);
        return (
            line ?
                <LineIndicator {...line} /> :
                <div>-</div>
        );
    };

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'services.assignments' })}
                    contentClass="p-3"
                >
                    <div className="d-flex gap-4 flex-wrap justify-content-center">
                        <DatePicker
                            label={intl.formatMessage({ id: 'exploitation.date' })}
                            className="col-12 col-lg-3 col-xxl-2"
                            defaultDate={selectedDate}
                            maxDate={new Date()}
                            onChange={setSelectedDate}
                        />
                        <DynamicSelect
                            selectedValue={selectedVehicle}
                            className="col-12 col-lg-3 col-xxl-2"
                            getFunction={apiService.getAllVehicles}
                            toOption={vehicle => ({ label: vehicle.parkId, value: vehicle.id })}
                            onChange={e => setSelectedVehicle(e.map(v => v.id))}
                            label={intl.formatMessage({ id: 'park.id' })}
                            placeholder={intl.formatMessage({ id: 'select.vehicle' })}
                            isMulti
                        />
                        <DynamicSelect
                            selectedValue={selectedServiceVehicle}
                            className="col-12 col-lg-3 col-xxl-2"
                            getFunction={() => apiService.getServices(ServicesType.Vehicles)}
                            toOption={service => ({ label: service.serviceNumber, value: service.serviceNumber })}
                            onChange={e => setSelectedServiceVehicle(e.map(s => s.serviceNumber))}
                            label={intl.formatMessage({ id: 'service.vehicle' })}
                            placeholder={intl.formatMessage({ id: 'select.service.vehicle' })}
                            isMulti
                        />
                        <DynamicSelect
                            selectedValue={selectedServiceAgent}
                            className="col-12 col-lg-3 col-xxl-2"
                            getFunction={() => apiService.getServices(ServicesType.Agents)}
                            toOption={service => ({ label: service.serviceNumber, value: service.serviceNumber })}
                            onChange={e => setSelectedServiceAgent(e.map(s => s.serviceNumber))}
                            label={intl.formatMessage({ id: 'service.agent' })}
                            placeholder={intl.formatMessage({ id: 'select.service.agent' })}
                            isMulti
                        />
                        <DynamicSelect
                            selectedValue={selectedLine}
                            className="col-12 col-lg-3"
                            getFunction={() => apiService.getAllLines()}
                            toOption={line => ({ ...line, value: line.code })}
                            onChange={e => setSelectedLine(e.map(l => l.code))}
                            label={intl.formatMessage({ id: 'line' })}
                            placeholder={intl.formatMessage({ id: 'select.line' })}
                            renderOption={data => <LineIndicator {...data } />}
                            isMulti
                        />
                    </div>
                </Card>
            }
            content={
                <Table
                    title=""
                    columns={columns}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getAssignmentHistory(pageIndex, pageSize, sorting, selectedDate, selectedVehicle, selectedServiceAgent, selectedServiceVehicle, selectedLine, !authorization),
                        formatData: (data) => ({
                            ...data,
                            startTime: formatTimeFromSeconds(intl, data.startTime),
                            endTime: formatTimeFromSeconds(intl, data.endTime),
                            date: undefined,
                        }),
                        refreshTrigger: [ selectedDate, selectedVehicle, selectedServiceAgent, selectedServiceVehicle, selectedLine ]
                    }}
                    emptyMessage={intl.formatMessage({ id: 'empty.table.common' }, { date: intl.formatDate(selectedDate) })}
                    options={{ export: true }}
                    allSortable
                />
            }
        />
    );
};
