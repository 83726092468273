import './CheckList.scss';

import { Text, VariantType } from '../Text/Text';
import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

export enum CheckListType {
    Custom,
    Weekday,
}

export interface CheckListProps {
    type: CheckListType;
    title?: string;
    list?: Array<string>;
    active: Array<boolean>;
    onChange?: (value: Array<boolean>) => void
}

export const CheckList: React.FunctionComponent<CheckListProps> = (props: CheckListProps) => {
    const intl = useIntl();
    const [ selectedOptions, setSelectedOptions ] = useState<Array<boolean>>(props.active || []);
    const list = props.list || [];

    switch (props.type) {
        case CheckListType.Weekday:
            const monday = '02/01/2023';
            const date = new Date(monday);
            date.setDate(date.getDate() - date.getDay());
            const initialDate = new Date(monday).getDate();
            for (let i = 1; i < 8; i++) {
                date.setDate(initialDate + i);
                list.push(intl.formatDate(date, { weekday: 'narrow' }));
            }
            list.push(intl.formatMessage({ id: 'week.holiday.code' }));
            break;
        default:
            break;
    }

    useEffect(() => {
        setSelectedOptions(props.active ?? []);
    }, [ props.active ]);

    const activeSelect = (index: number) => {
        if (props.onChange) {
            const active = selectedOptions;
            active[index] = !active[index];
            setSelectedOptions([ ...active ]);
            props.onChange(active);
        }
    };

    return (
        <div className="check-list-container" data-testid="check-list">
            {
                props.title &&
                <Text variant={VariantType.H5}>{props.title}</Text>
            }
            <div className="check-list">
                { list.map((c, i) => (
                    <div key={i} className={`check ${selectedOptions[i] ? 'enabled' : ''} ${i === list.length ? 'ms-2' : ''} ${props.onChange ? 'cursor-pointer' : ''}`} onClick={() => activeSelect(i)}>
                        {c}
                    </div>
                ))}
            </div>
        </div>
    );
};
