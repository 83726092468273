import './LineAttachmentPage.scss';

import { Card, DatePicker, formatTimeFromSeconds, LineIndicator, Table, TableColumn, TableValueType } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import DynamicSelect from '../../DynamicSelect/DynamicSelect';
import { Line } from '../../../models/Line';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { useIntl } from 'react-intl';

export const LineAttachmentPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();

    const [ lines, setLines ] = useState<Array<Line>>([]);
    const [ selectedDate, setSelectedDate ] = useState<Date | undefined>(new Date());
    const [ selectedVehicle, setSelectedVehicle ] = useState<string>();
    const maxDate = new Date();

    const columns: Array<TableColumn> = lines.length ? [
        { label: intl.formatMessage({ id: 'date' }), key: 'date' },
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'line' }), key: 'lineId', renderCell: row => renderLine(row.lineId) },
        { label: intl.formatMessage({ id: 'stop' }), key: 'stopName' },
        { label: intl.formatMessage({ id: 'time.theorical' }), key: 'theoryTime' },
        { label: intl.formatMessage({ id: 'time.real' }), key: 'time' },
    ] : [];

    useEffect(() => {
        apiService.getAllLines().then(res => setLines(res));
    }, []);

    const renderLine = (value: TableValueType) => {
        const line = lines.find(l => l.id === value);

        return (
            line ?
                <LineIndicator {...line} /> :
                <div>-</div>
        );
    };

    const [ passingHistory, setPassingHistory ] = useState<Array<{[key: string]: TableValueType}>>([]);

    useEffect(() => {
        apiService.getPassingHistory(selectedDate, selectedVehicle).then(res => setPassingHistory(
            res.filter(item => {
                const timeArr = res.filter(r => r.parkId === item.parkId && r.lineId === item.lineId).map(r => r.time);
                const min = Math.min(...timeArr);
                const max = Math.max(...timeArr);
                return item.time === min || item.time === max;
            }).map(item => ({
                ...item,
                date: intl.formatDate(new Date(item.date)),
                theoryTime: item.theoryTime > 0 ? formatTimeFromSeconds(intl, item.theoryTime) : undefined,
                time: item.time > 0 ? formatTimeFromSeconds(intl, item.time) : undefined,
            }))));
    }, [ selectedDate, selectedVehicle ]);
    return (
        <PageFillUnderHeader
            className="line-attachment-page"
            header={
                <Card
                    title={intl.formatMessage({ id: 'line.attachment' })}
                    contentClass="p-3"
                >
                    <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center">
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                            <DatePicker
                                label={intl.formatMessage({ id: 'exploitation.date' })}
                                className="w-100"
                                defaultDate={selectedDate}
                                maxDate={maxDate}
                                onChange={setSelectedDate}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                            <DynamicSelect
                                selectedValue={selectedVehicle ? [ selectedVehicle ] : undefined}
                                className="w-100"
                                getFunction={apiService.getAllVehicles}
                                toOption={vehicle => ({ label: vehicle.parkId, value: vehicle.parkId })}
                                onChange={e => setSelectedVehicle(e[0]?.parkId)}
                                label={intl.formatMessage({ id: 'park.id' })}
                                placeholder={intl.formatMessage({ id: 'select.vehicle' })}
                            />
                        </div>
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    staticData={{ data: passingHistory }}
                    emptyMessage={intl.formatMessage({ id: 'empty.line.attachment' }, { date: intl.formatDate(selectedDate), vehicle: selectedVehicle })}
                />
            }
        />
    );
};
