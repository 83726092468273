export enum Lang {
    EN = 'en',
    FR = 'fr',
}

export enum Action {
    Add = 'Ajout',
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
    Duplicate = 'Duplicate',
}

export enum Base {
    Binary = 2,
    Decimal = 10,
    Hexa = 16,
}
