import './Table.scss';

import { Icon, IconType, Loader } from '../../atoms';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { sqlTypes, TableFilter } from '../TableFilters/TableFilters';

import { Action } from '../../../common/enum';
import { sortOrder } from '../../atoms/SortIndicator/SortIndicator';
import { TableColumnHeader } from '../TableColumnHeader/TableColumnHeader';
import { TableFooter } from '../TableFooter/TableFooter';
import { TableHeader } from '../TableHeader/TableHeader';
import { useIntl } from 'react-intl';

export type TableValueType = string | number | boolean | Array<string> | Array<number> | Array<boolean> | Array<string | undefined> | undefined;

export interface TableSort {
    column: string,
    order: sortOrder,
}

export interface TableColumn {
    label: string,
    key: string,
    dbKey?: string,
    sortType?: 'alphanumeric' | 'date';
    subIndex?: Array<number>;
    type?: sqlTypes;
    sortable?: boolean;
    hidable?: boolean;
    icon?: IconType;
    renderCell?: (data: { [key: string]: TableValueType }, renderData?: unknown) => ReactElement | TableValueType;
}

export interface TableOptions {
    export?: boolean;
    create?: boolean;
    update?: boolean;
    delete?: boolean;
    duplicate?: boolean;
}

export interface TableProps<T> {
    columns?: Array<TableColumn>;
    staticData?: {
        data: Array<{ [key: string]: TableValueType }>,
        isLoading?: boolean,
    }
    dynamicData?: {
        getData: (
            pageIndex?: number,
            pageSize?: number,
            sorting?: TableSort,
            filters?: { [column: string]: TableFilter | undefined },
        ) => Promise<{ results: Array<T>, count: number }>,
        formatData?: (data: T) => { [key: string]: TableValueType };
        refreshTrigger?: Array<unknown>;
    }
    title?: string | ReactNode;
    allSortable?: boolean;
    allHidable?: boolean;
    options?: TableOptions;
    emptySymbol?: string;
    multiSelect?: boolean;
    selectAll?: boolean;
    selected?: Array<{ [key: string]: TableValueType}>;
    availablePageSizes?: Array<number>;
    emptyMessage?: string | ReactNode;
    refreshColRender?: unknown;
    renderData?: unknown;
    colorizedRow?: (data: { [key: string]: TableValueType }) => string | undefined,
    backgroundRow?: (data: { [key: string]: TableValueType }) => string | undefined,
    boldRow?: (data: { [key: string]: TableValueType }) => boolean,
    onClick?: (data: { [key: string]: TableValueType }) => void;
    onAction?: (kind: Action, data?: { [key: string]: TableValueType }) => void;
    onSelectChange?: (selectedRows: Array<{ [key: string]: TableValueType}>) => void;
}

export const Table = <T extends object>(props: TableProps<T>) => {
    const intl = useIntl();

    const [ data, setData ] = useState<Array<{[key: string]: TableValueType}>>(props.staticData?.data ?? []);

    const availablePageSizes = props.availablePageSizes ?? [ 25, 50, 100, 250, 500 ];
    const [ pageSize, setPageSize ] = useState<number>(availablePageSizes[0]);
    const [ pageIndex, setPageIndex ] = useState<number>(0);
    const [ count, setCount ] = useState<number>(0);

    const [ sorting, setSorting ] = useState<TableSort>();
    const [ filters, setFilters ] = useState<{[column: string]: TableFilter | undefined}>();
    const [ currentFilterKey, setCurrentFilterKey ] = useState<string>();
    const [ selectedRows, setSelectedRows ] = useState<Array<{ [key: string]: TableValueType }>>(props.selected ?? []);

    const emptySymbol = props.emptySymbol ?? '-';
    const renderActionIcon = (icon: IconType, table: Action, row: { [key: string]: TableValueType }) => {
        return (
            <Icon
                iconType={icon}
                onClick={() => {
                    props.onAction && props.onAction(table, row);
                }}
            />
        );
    };

    const renderActionsButton = (row: { [key: string]: TableValueType }) => {
        return (
            <div className="d-flex gap-2">
                {
                    props.options?.update &&
                    renderActionIcon(IconType.Pencil, Action.Update, row)
                }
                {
                    props.options?.duplicate &&
                    renderActionIcon(IconType.Duplicate, Action.Duplicate, row)
                }
                {
                    props.options?.delete &&
                    renderActionIcon(IconType.Trash, Action.Delete, row)
                }
            </div>
        );
    };

    const [ columns, setColumns ] = useState<Array<TableColumn>>([]);

    useEffect(() => {
        setColumns([
            ...(props.columns ?? []).map(col => {
                if (!col.renderCell) {
                    col.renderCell = (rowData: { [key: string]: TableValueType }) => {
                        const value = rowData[col.key];
                        return defaultRenderCell(value, col.key, col.subIndex, props.boldRow && props.boldRow(rowData), props.colorizedRow && props.colorizedRow(rowData));
                    };
                }
                return col;
            }),
            ...props.options?.update || props.options?.delete ?
                [ { label: intl.formatMessage({ id: 'actions' }), key: 'actions', renderCell: renderActionsButton } as TableColumn ] : []
        ]);
    }, [ props.columns, props.options, props.refreshColRender ]);

    useEffect(() => {
        if (props.staticData) {
            setIsLoading(props.staticData.isLoading);
        }
    }, [ props.staticData?.isLoading ]);

    const [ hidedColumns, setHidedColumns ] = useState<Array<string>>([]);
    const [ displayedColumns, setDisplayedColumns ] = useState<Array<TableColumn>>(columns);
    const [ isLoading, setIsLoading ] = useState<boolean>();

    useEffect(() => {
        if (props.selected) {
            setSelectedRows(props.selected);
        }
    }, [ props.selected ]);

    useEffect(() => {
        if (props.dynamicData) {
            setIsLoading(true);
            props.dynamicData.getData(pageIndex, pageSize, sorting, filters).then(res => {
                setCount(res.count);
                setData(res.results.map(el => {
                    if (props.dynamicData?.formatData) {
                        return props.dynamicData.formatData(el);
                    }
                    return { ...el } as { [key: string]: TableValueType };
                }));
                setIsLoading(false);
            });
        }
    }, [ pageIndex, pageSize, JSON.stringify(props.dynamicData?.refreshTrigger), sorting?.column, sorting?.order, JSON.stringify(filters) ]);

    useEffect(() => {
        if (props.staticData) {
            const sortingColumn = (sorting?.order && sorting?.column) ? columns.find(c => (c.dbKey ?? c.key) === sorting.column) : undefined;
            if (!sortingColumn || !sorting) {
                setData([ ...props.staticData.data ]);
            } else {
                const sortedData = [ ...props.staticData.data ].sort((rowA, rowB) => {
                    const values: Array<TableValueType> = [ rowA, rowB ].map(e => e[sorting.column]);
                    switch (sortingColumn.sortType) {
                        case 'date':
                            const [ timeA, timeB ] = values.map(elem => new Date(String(elem)).getTime());
                            return (timeA - timeB) * sorting.order;
                        default:
                            const [ valA, valB ] = values.map(elem => String(elem));
                            return valA.localeCompare(valB, intl.locale, { numeric: true }) * sorting.order;
                    }
                });
                setData([ ...sortedData ]);
            }
        }
    }, [ props.staticData, JSON.stringify(sorting) ]);

    const onClick = (row: { [key: string]: TableValueType }) => {
        const filteredRows = selectedRows.filter(r => JSON.stringify(r) !== JSON.stringify(row));
        const newRow = selectedRows.length > filteredRows.length ? [] : [ row ];
        const updatedSelected = props.multiSelect ? [ ...filteredRows, ...newRow ] : newRow;
        setSelectedRows(updatedSelected);

        props.onClick && props.onClick(row);
        props.onSelectChange && props.onSelectChange(updatedSelected);
    };

    const defaultRenderCell = (value: TableValueType, key: string, subIndex?: Array<number>, bold?: boolean, color?: string) => {
        return (
            <div>
                { (Array.isArray(value) ? [ ...value ] : [ value ]).map((d, index) => (
                    <div
                        style={{ color }}
                        key={`${key}-${index}`}
                        className={`
                            ${(subIndex && subIndex.includes(index)) ? 'sub-value' : ''}
                            ${bold ? 'fw-bold' : ''}
                        `}
                    >{d ?? emptySymbol}</div>
                )) }
            </div>
        );
    };

    useEffect(() => {
        const newVal = (props.selectAll && props.multiSelect) ? data : [];
        setSelectedRows((props.selectAll && props.multiSelect) ? data : []);
        props.onSelectChange && props.onSelectChange(newVal);
    }, [ props.selectAll ]);

    useEffect(() => {
        if (!props.multiSelect) {
            const newVal = selectedRows.slice(0, 1);
            setSelectedRows(newVal);
            props.onSelectChange && props.onSelectChange(newVal);
        }
    }, [ props.multiSelect ]);

    useEffect(() => {
        setDisplayedColumns(columns.filter(c => !hidedColumns.includes(c.key)));
    }, [ JSON.stringify(columns), hidedColumns ]);

    useEffect(() => {
        setPageIndex(0);
    }, [ pageSize, JSON.stringify(props.dynamicData?.refreshTrigger) ]);

    return (
        <div className="table-container position-relative mw-100 mh-100 d-flex flex-column">
            <TableHeader
                title={props.title}
                columns={displayedColumns}
                data={data}
                filters={filters}
                currentFilterKey={currentFilterKey}
                options={props.options}
                hidedColumns={hidedColumns}
                onAction={props.onAction}
                onHiderReset={() => setHidedColumns([])}
                onFiltersChange={f => {
                    setFilters(f);
                    setPageIndex(0);
                    setCurrentFilterKey(undefined);
                }}
            />
            <div className="overflow-auto">
                <table className="w-100">
                    <thead className="table-head position-sticky top-0">
                        <tr>
                            { displayedColumns.map(column =>
                                <th
                                    key={`th-${column.key}`}
                                    className="table-column-header"
                                >
                                    <TableColumnHeader
                                        column={column}
                                        allSortable={props.allSortable}
                                        allHidable={props.allHidable}
                                        sorting={sorting}
                                        filters={filters}
                                        currentFilterKey={currentFilterKey}
                                        onSortingChange={setSorting}
                                        onCurrentFilterKeyChange={setCurrentFilterKey}
                                        onFiltersChange={(f) => {
                                            setFilters(f);
                                            setPageIndex(0);
                                        }}
                                        onHideChange={h => {
                                            setHidedColumns([ ...hidedColumns, h ]);
                                        }}
                                    />
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="p-4">
                        {
                            isLoading ?
                                <tr>
                                    <td colSpan={displayedColumns.length}>
                                        <div className="w-100 d-flex justify-content-center my-2">
                                            <Loader />
                                        </div>
                                    </td>
                                </tr> :
                                data.length ?
                                    data.map((row, i) => (
                                        <tr
                                            style={{ background: props.backgroundRow && props.backgroundRow(row) }}
                                            key={`row-${i}`}
                                            className={`${selectedRows && selectedRows.map(r => JSON.stringify(r)).includes(JSON.stringify(row)) ? 'active' : ''}`}
                                            onClick={() => onClick(row)}
                                        >
                                            {
                                                displayedColumns.map(column =>
                                                    <td key={`cell-${column.key}`}>
                                                        {column.renderCell && column.renderCell(row, props.renderData)}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )) :
                                    <tr>
                                        <td
                                            colSpan={displayedColumns.length}
                                            className="m-5 text-center"
                                        >
                                            <div className="d-flex justify-content-center align-items-center gap-4 my-4">
                                                <Icon
                                                    iconType={IconType.EmptyFolder}
                                                    size={32}
                                                />
                                                <div>{ props.emptyMessage ?? intl.formatMessage({ id: 'empty.table' }) }</div>
                                            </div>
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                props.dynamicData && !!data.length &&
                <TableFooter
                    count={count}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    availablePageSizes={availablePageSizes}
                    onPageIndexChange={setPageIndex}
                    onPageSizeChange={setPageSize}
                />
            }
        </div>
    );
};
