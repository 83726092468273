import { ActionTranslation, GroupTranslation, MessageGroup, MessageProgram, MessageTargetType, TypeTranslation } from '../../../models/Message';
import { Card, CheckList, CheckListType, convertNumberToBinary, DatePicker, Table, TableColumn } from '@ceccli/design-system';
import { Terminal, TerminalDisplayType } from '../../../models/Terminal';

import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { useIntl } from 'react-intl';
import { useState } from 'react';

export const MessageProgramPage = () => {
    const intl = useIntl();
    const apiService = new ApiService();

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'ordinal.number' }), key: 'id' },
        { label: intl.formatMessage({ id: 'date.slots' }), key: 'dateSlot', dbKey: 'startDate' },
        { label: intl.formatMessage({ id: 'message.type' }), key: 'type' },
        { label: intl.formatMessage({ id: 'group' }), key: 'group' },
        { label: intl.formatMessage({ id: 'targets' }), key: 'targets' },
        { label: intl.formatMessage({ id: 'active.days' }), key: 'activeDays', renderCell: (row) => renderActiveDays(Number(row.activeDays)) },
        { label: intl.formatMessage({ id: 'time.slots' }), key: 'timeSlots' },
        { label: intl.formatMessage({ id: 'message.number' }), key: 'number' },
        { label: intl.formatMessage({ id: 'message.text' }), key: 'text' },
        { label: intl.formatMessage({ id: 'action' }), key: 'action' },
        { label: intl.formatMessage({ id: 'exploitation.date' }), key: 'actionDate', subIndex: [ 1 ] },
    ];

    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date>();
    const [ terminals, setTerminals ] = useState<Array<Terminal>>([]);

    const renderActiveDays = (val: number) => (
        <CheckList
            type={CheckListType.Weekday}
            active={convertNumberToBinary(val)}
        />
    );

    return (
        <PageFillUnderHeader
            header={
                <Card
                    title={intl.formatMessage({ id: 'message.program.title' })}
                    contentClass="p-4"
                >
                    <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-0">
                        <div className="col-12 col-lg-6 p-0 pe-lg-2">
                            <DatePicker
                                label={intl.formatMessage({ id: 'start.date' })}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                onChange={setStartDate}
                                options={{ reset: true }}
                            />
                        </div>
                        <div className="col-12 col-lg-6 p-0 ps-lg-2">
                            <DatePicker
                                label={intl.formatMessage({ id: 'end.date' })}
                                placeHolder={intl.formatMessage({ id: 'select.date' })}
                                onChange={setEndDate}
                                options={{ reset: true }}
                            />
                        </div>
                    </div>
                </Card>
            }
            content={
                <Table
                    columns={columns}
                    dynamicData={{
                        getData: async (pageIndex, pageSize, sorting) => {
                            let programs = await apiService.getTerminalMessagesProgram(pageIndex, pageSize, sorting, startDate, endDate);
                            programs = {
                                ...programs,
                                results: await Promise.all(programs.results.map(async r => {
                                    let updatedTargets = r.targets;
                                    switch (r.group) {
                                        case MessageGroup.Lines:
                                            updatedTargets = (await Promise.all(r.targets.map(t => apiService.getLine(Number(t)).then(res => res?.code ?? ''))));
                                            break;
                                        case MessageGroup.Panel:
                                            updatedTargets = await Promise.all(r.targets.map(async t => {
                                                const split = t.split(' - ');
                                                const id = Number(split[1]);
                                                let terminal = terminals.find(t => t.id === id);
                                                if (!terminal) {
                                                    terminal = await apiService.getTerminal(id);
                                                    if (terminal) {
                                                        setTerminals([ ...terminals, terminal ]);
                                                    }
                                                }
                                                const index = terminal?.index;
                                                const key = split[0] === MessageTargetType.Panel ?
                                                    TerminalDisplayType.PANEL :
                                                    TerminalDisplayType.SATELLITE;
                                                return `${key} - ${index}`;
                                            }));
                                            break;
                                        default:
                                            break;
                                    }
                                    return ({
                                        ...r,
                                        targets: updatedTargets.sort((a, b) => a.localeCompare(b, intl.locale, { numeric: true })),
                                    });
                                })),
                            };
                            return programs;
                        },
                        formatData: (data: MessageProgram) => ({
                            ...data,
                            dateSlot: [ intl.formatDate(data.startDate), intl.formatDate(data.endDate) ],
                            endDate: undefined,
                            startDate: undefined,
                            actionDate: [ intl.formatDate(data.actionDate), intl.formatTime(data.actionDate) ],
                            type: data.type ? intl.formatMessage({ id: TypeTranslation[data.type] }) : undefined,
                            group: data.group ? intl.formatMessage({ id: GroupTranslation[data.group] }) : undefined,
                            action: ActionTranslation[data.action] ? intl.formatMessage({ id: ActionTranslation[data.action] }) : undefined,
                            timeSlots: data.timeSlots.map(t => intl.formatTime(t)),
                        }),
                        refreshTrigger: [ startDate, endDate ],
                    }}
                    allSortable
                />
            }
        />
    );
};
