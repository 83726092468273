import './InterventionSettingsPage.scss';

import { Button, Input, Modal, Table, TableColumn, TableValueType, ToastModel, ToastType } from '@ceccli/design-system';

import { ApiService } from '../../../services/apiService';
import { InterventionParameter } from '../../../models/InterventionParameter';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { pushToast } from '../../../app/ToastStore';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';

interface InterventionParameterExtended extends InterventionParameter {
    displayId: string;
}

export const InterventionSettingsPage = () => {
    const apiService = new ApiService();
    const intl = useIntl();
    const maxLabelSize = 23;

    const dispatch = useDispatch();

    const [ currentChanges, setCurrentChanges ] = useState<{ [key: string]: string }>({});
    const [ savedChanges, setSavedChanges ] = useState<{ [key: string]: string }>({});
    const [ currentParam, setCurrentParam ] = useState<InterventionParameterExtended>();

    const renderLabelCol = (row: { [key: string]: TableValueType }, changes: {[key: string]: { [key: string]: string }}) => {
        const displayId = row.displayId?.toString() ?? '';
        const currentVal = changes.currentChanges[displayId] ?? changes.savedChanges[displayId] ?? row.label;
        return (
            <div className={'d-flex gap-3 align-items-center me-5'}>
                <Input
                    className={`w-100 ${row.subId ? '' : 'fw-bold'}`}
                    value={currentVal}
                    onChange={e => {
                        if (e.length > maxLabelSize) {
                            return;
                        }
                        setCurrentChanges({ ...changes.currentChanges, [displayId]: e });
                    }}
                />
                <div className="label-size me-3">
                    { currentVal.length } / {maxLabelSize}
                </div>
                <Button
                    text={intl.formatMessage({ id: 'update' })}
                    onClick={() => setCurrentParam({
                        id: Number(row.id),
                        subId: row.subId ? Number(row.subId) : undefined,
                        label: currentVal,
                        displayId,
                    })}
                    disabled={currentVal === (changes.savedChanges[displayId] ?? row.label)}
                />
            </div>
        );
    };

    const renderIdentifierCol = (row: { [key: string]: TableValueType }) => (
        <div className={`identifier-col ${row.subId ? 'ms-3' : 'fw-bold'}`}>
            {row.displayId}
        </div>
    );

    const columns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'identifier' }), key: 'displayId', dbKey: 'IdFct, IdSousFct', renderCell: (row) => renderIdentifierCol(row) },
        { label: intl.formatMessage({ id: 'label' }), key: 'label', renderCell: (row, d) => renderLabelCol(row, d as {[key: string]: { [key: string]: string }}) }
    ];

    return (
        <div className="intervention-settings-page h-100">
            {
                currentParam &&
                <Modal>
                    <div className="d-flex flex-column gap-3 align-items-center">
                        <div>{intl.formatMessage({ id: 'intervention.settings.confirmation' }, { labelId: currentParam?.displayId })}</div>
                        <div className="fw-bold">{currentParam.label}</div>
                        <div className="d-flex gap-3">
                            <Button
                                text={intl.formatMessage({ id: 'confirm' })}
                                onClick={async () => {
                                    let error = false;
                                    try {
                                        await apiService.updateInterventionParam(currentParam);
                                        delete currentChanges[currentParam.displayId];
                                        setSavedChanges({ ...savedChanges, [currentParam.displayId]: currentParam.label });
                                    } catch {
                                        error = true;
                                    }
                                    setCurrentParam(undefined);
                                    dispatch(pushToast(
                                        new ToastModel(
                                            intl.formatMessage({ id: `intervention.settings.${error ? 'error' : 'success'}` },
                                                { labelId: currentParam?.displayId }),
                                            error ? ToastType.Error : ToastType.Success,
                                        )
                                    ));
                                }}
                            />
                            <Button
                                text={intl.formatMessage({ id: 'cancel' })}
                                onClick={() => setCurrentParam(undefined)}
                            />
                        </div>
                    </div>
                </Modal>
            }
            <PageFillUnderHeader
                content={
                    <Table
                        title={intl.formatMessage({ id: 'intervention.settings.title' })}
                        columns={columns}
                        dynamicData={{
                            getData: apiService.getInterventionParams,
                            formatData: (data) => {
                                return ({
                                    ...data,
                                    displayId: data.subId ? `${data.id}.${data.subId}` : data.id
                                });
                            }
                        }}
                        renderData={{ currentChanges, savedChanges }}
                    />
                }
            />
        </div>
    );
};
