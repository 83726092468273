import { TriangleFill } from 'react-bootstrap-icons';

export type sortOrder = 1 | -1 | 0;

export interface SortIndicatorProps {
    order?: sortOrder,
    size?: number,
    onChange?: (order: sortOrder) => void,
}

export const SortIndicator = (props: SortIndicatorProps) => {
    const size = props.size ?? 12;
    const gapRatio = 0.15;
    const iconSize = `${100 - gapRatio * 100}%`;

    const updateOrder = () => {
        const next = (props.order ?? 0) + 1;
        const newOrder = next > 1 ?
            -1 : next === 0 ?
                0 : 1;
        if (props.onChange) {
            props.onChange(newOrder);
        }
    };

    return (
        <div
            onClick={() => updateOrder()}
            style={{ width: size, height: size, gap: size * gapRatio }}
            className={
                `sort-indicator interactive-icon
                ${props.order ? (props.order > 0) ? 'asc' : 'desc' : ''}`
            }
        >
            <TriangleFill className="top" size={iconSize} />
            <TriangleFill className="bot" size={iconSize} />
        </div>
    );
};
