import './Paginator.scss';

import { ColorVariable } from '../../../common/color';
import { ExpandArrow } from '../ExpandArrow/ExpandArrow';
import ReactPaginate from 'react-paginate';

export interface PaginatorProps {
    pageIndex: number;
    pageCount: number;
    pageRange?: number;
    marginRange?: number;
    onChange?: (pageIndex: number) => void;
}

export const Paginator = (props: PaginatorProps) => {
    const pageRange = props.pageRange ?? 3;
    const marginRange = props.marginRange ?? 1;

    const renderPreviousNext = (next?: boolean) => {
        return (
            <ExpandArrow size={12} noAnimation angle={(next ? -1 : 1) * 90} color={ColorVariable.TextDark} strokeSize={1} />
        );
    };

    return (
        props.pageCount ?
            <div data-testid="paginator">
                <ReactPaginate
                    forcePage={props.pageIndex}
                    pageCount={props.pageCount}
                    previousLabel={renderPreviousNext()}
                    nextLabel={renderPreviousNext(true)}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginRange}
                    onPageChange={(e) => (props.onChange ? props.onChange(e.selected) : undefined)}
                    containerClassName="m-0 p-0"
                />
            </div> :
            null
    );
};
