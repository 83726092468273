import { useContext, useEffect, useState } from 'react';

import MapContext from '../MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { StyleLike } from 'ol/style/Style';
import VectorSource from 'ol/source/Vector';

export interface VectorLayerProps {
    source: VectorSource,
    style?: StyleLike,
    zIndex?: number,
}

const VectorLayer = (props: VectorLayerProps) => {
    const map = useContext(MapContext);

    const [ vectorLayer, setVectorLayer ] = useState<OLVectorLayer<VectorSource>>();

    useEffect(() => {
        if (!map) { return; }
        const initVectorLayer = new OLVectorLayer({
            source: props.source,
            style: props.style
        });

        map.addLayer(initVectorLayer);

        if (props.zIndex) {
            initVectorLayer.setZIndex(props.zIndex);
        }

        setVectorLayer(initVectorLayer);

        return () => {
            if (map) {
                map.removeLayer(initVectorLayer);
                setVectorLayer(undefined);
            }
        };
    }, [ map ]);

    useEffect(() => {
        vectorLayer?.setSource(props.source);
    }, [ props.source ]);

    useEffect(() => {
        vectorLayer?.setStyle(props.style);
    }, [ props.style ]);

    useEffect(() => {
        if (props.zIndex) {
            vectorLayer?.setZIndex(props.zIndex);
        }
    }, [ props.zIndex ]);

    return null;
};
export default VectorLayer;
