import './PassengerInformationModal.scss';

import { Action, Button, Checkbox, CheckElementOptionType, CheckList, CheckListType, convertBinaryToNumber, convertNumberToBinary, DatePicker, Icon, IconType, Input, Modal, ModalConfirmation, RadioButtons, Select, Stepper, Text, TimePicker, ToastModel, ToastType, VariantType } from '@ceccli/design-system';
import { PassengerInformation, PassengerInformationKey, PassengerInformationTargetType, PassengerInformationType } from '../../models/PassengerInformation/PassengerInformation';
import { useEffect, useState } from 'react';

import { pushToast } from '../../app/ToastStore';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

export interface PassengerInformationModalProps {
    action: Action;
    data: PassengerInformation;
    vehicles: Array<CheckElementOptionType>;
    lines: Array<CheckElementOptionType>;
    stops: Array<CheckElementOptionType>;
    startExploitation: number;
    endExploitation: number;
    setData: (data: PassengerInformation) => void;
    onClose: (open: boolean) => void;
    onSave: () => void;
}

export const PassengerInformationModal: React.FunctionComponent<PassengerInformationModalProps> = (props: PassengerInformationModalProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const firstValues: Array<number> = [];
    const secondValues: Array<number> = [];
    const conversion = 60 * 1000;
    const startExploitation = props.startExploitation * conversion;
    const endExploitation = props.endExploitation * conversion;
    props.data.times.forEach((subArray: Array<string>) => {
        firstValues.push(Number(subArray[0]) * conversion);
        secondValues.push(Number(subArray[1]) * conversion);
    });
    const [ activeStep, setActiveStep ] = useState<number>(0);
    const [ activeDays, setActiveDays ] = useState<number>(props.data.activeDays ?? 0);
    const [ startTimes, setStartTimes ] = useState<Array<number>>(props.data.times.length ? firstValues : [ startExploitation ]);
    const [ endTimes, setEndTimes ] = useState<Array<number>>(props.data.times.length ? secondValues : [ endExploitation ]);
    const [ startDate, setStartDate ] = useState<Date>(props.data.startDate ?? undefined);
    const [ endDate, setEndDate ] = useState<Date>(props.data.endDate ?? undefined);
    const [ selectedTargeType, setSelectedTargetType ] = useState<PassengerInformationTargetType>(props.data.targetType as PassengerInformationTargetType);
    const [ openModalConfirmation, setOpenModalConfirmation ] = useState<boolean>(false);
    const [ selectedVehicles, setSelectedVehicles ] = useState<Array<number>>(props.data.targets.map(Number) ?? []);
    const [ selectedLines, setSelectedLines ] = useState<Array<number>>(props.data.targets.map(Number) ?? []);
    const [ selectedStops, setSelectedStops ] = useState<Array<number>>(props.data.targets.map(Number) ?? []);
    const [ selectAllVehicles, setSelectAllVehicles ] = useState<boolean>(false);
    const [ selectAllLines, setSelectAllLines ] = useState<boolean>(false);
    const [ selectAllStops, setSelectAllStops ] = useState<boolean>(false);
    const [ timeError, setTimeError ] = useState<boolean>(false);
    const [ isAllPark, setIsAllPark ] = useState<boolean>(props.data.targetType === PassengerInformationTargetType.Global);
    const [ initDate, setInitDate ] = useState<boolean>(false);
    const steps = 3;
    const maxTimeSlots = 3;
    const weekDays = 8;

    const optionsNature = [
        { value: PassengerInformationType.Perturbation, label: intl.formatMessage({ id: 'perturbation' }) },
        { value: PassengerInformationType.Commercial, label: intl.formatMessage({ id: 'commercial' }) },
    ];

    useEffect(() => {
        props.setData({
            ...props.data,
            targets: getTargets(),
            targetType: selectedTargeType,
        });
    }, [ selectedVehicles, selectedLines, selectedStops, selectedTargeType ]);

    const getTargets = (): Array<number> => {
        switch (selectedTargeType) {
            case PassengerInformationTargetType.Vehicles:
                return selectedVehicles;
            case PassengerInformationTargetType.Lines:
                return selectedLines;
            case PassengerInformationTargetType.Stops:
                return selectedStops;
            default:
                return selectedVehicles;
        }
    };

    const onNextStep = () => {
        const { data, setData } = props;
        switch (activeStep) {
            case 0:
                if (!activeDays || !startTimes.length || !endTimes.length || !data.informationType || (props.action === Action.Create && !initDate)) {
                    dispatch(pushToast(
                        new ToastModel(intl.formatMessage({ id: 'message.fill.all.information' }), ToastType.Error)
                    ));
                    return;
                }
                if (timeError) {
                    dispatch(pushToast(
                        new ToastModel(intl.formatMessage({ id: 'time.format.start.end' }), ToastType.Error)
                    ));
                    return;
                }
                setData({
                    ...data,
                    activeDays,
                    times: convertTimestampInTimes(),
                    startDate,
                    endDate,
                });
                break;
            case 1:
                if (activeStep === 1 && (!Object.values(data.information).length || !Object.values(data.information).filter(info => info !== '').length)) {
                    dispatch(pushToast(
                        new ToastModel(intl.formatMessage({ id: 'message.fill.one.information' }), ToastType.Error)
                    ));
                    return;
                }
                break;
            default:
                break;
        }
        if (activeStep < steps) {
            setActiveStep(activeStep + 1);
            return;
        }
    };

    const onPreviousStep = () => {
        if (activeStep) {
            setActiveStep(activeStep - 1);
        }
    };

    const onLastStep = () => {
        if (!selectedVehicles.length && !selectedLines.length && !selectedStops.length && !isAllPark) {
            dispatch(pushToast(new ToastModel(intl.formatMessage({ id: 'passenger.information.no.selection' }), ToastType.Warning)));
            return;
        }
        setOpenModalConfirmation(true);
    };

    const onChange = (value: string | Date, name?: string) => {
        if (name) {
            props.setData({
                ...props.data,
                ...{ [name]: value },
            });
        }
    };

    const onChangeInformation = (value: string, key: string) => {
        props.setData({
            ...props.data,
            information: {
                ...props.data.information,
                [key]: value
            },
        });
    };

    const convertTimestampInTimes = () => {
        return startTimes.map((_, i) => [ String(startTimes[i] / conversion), String(endTimes[i] / conversion) ]);
    };

    const addTime = () => {
        setStartTimes([
            ...startTimes,
            props.startExploitation * conversion,
        ]);
        setEndTimes([
            ...endTimes,
            props.endExploitation * conversion,
        ]);
    };

    const removeTime = (index: number) => {
        if (startTimes.length > 1) {
            setStartTimes(startTimes.filter((_, i) => i !== index));
            setEndTimes(endTimes.filter((_, i) => i !== index));
        }
    };

    const onClose = () => {
        props.onClose(true);
    };

    const onSave = () => {
        props.setData({
            ...props.data,
        });
        onClose();
        props.onSave();
        dispatch(pushToast(
            new ToastModel(intl.formatMessage({ id: 'message.success.action' },
                { object: intl.formatMessage({ id: 'passenger.information' }), action: intl.formatMessage({ id: `${props.action.toLowerCase()}d` }).toLowerCase() },
            ), ToastType.Success)
        ));
    };

    const onAllDaysChanged = () => {
        setActiveDays(convertBinaryToNumber(convertNumberToBinary(activeDays).filter(e => e).length === weekDays ? [] : Array.from({ length: weekDays }).map(_ => true)));
    };

    const getMinutes = (value: number): number => {
        const startExploitationMinute = startExploitation / conversion;
        const endExploitationMinute = endExploitation / conversion;
        value /= conversion;
        const late = 24 * 60;
        if (startExploitationMinute >= endExploitationMinute) {
            if (value <= endExploitationMinute) {
                return value + late;
            }
            if (value >= startExploitationMinute && value <= endExploitationMinute + late) {
                return value;
            }
        } else if (value > startExploitationMinute && value < endExploitationMinute) {
            return value;
        }
        return -1;
    };

    const checkValueTimes = (index: number, starts: Array<number>, ends: Array<number>) => {
        const start = getMinutes(starts[index]);
        const end = getMinutes(ends[index]);
        if (start !== 1 && end !== 0 && end > start) {
            setTimeError(false);
            return;
        }
        setTimeError(true);
    };

    const renderStep1 = () => {
        return (
            <div className="content">
                <div>
                    <Text variant={VariantType.H5}>{intl.formatMessage({ id: 'dates' })}</Text>
                    <DatePicker
                        label={intl.formatMessage({ id: 'start' })}
                        name="startDate"
                        defaultDate={(props.action !== Action.Create || (props.action === Action.Create && initDate && startDate)) ? startDate : undefined}
                        maxDate={(props.action === Action.Create && initDate) ? endDate : undefined}
                        onChange={(value) => { setStartDate(value as Date); setInitDate(true); }}
                    />
                    <DatePicker
                        label={intl.formatMessage({ id: 'end' })}
                        name="endDate"
                        defaultDate={(props.action !== Action.Create || (props.action === Action.Create && initDate && endDate)) ? endDate : undefined}
                        minDate={(props.action === Action.Create && initDate) ? startDate : undefined}
                        onChange={(value) => { setEndDate(value as Date); setInitDate(true); }}
                    />
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <Text variant={VariantType.H5}>{intl.formatMessage({ id: 'time.slots' })}</Text>
                        {
                            startTimes.length < maxTimeSlots &&
                            <Icon iconType={IconType.Plus} onClick={() => addTime()} />
                        }
                    </div>
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-around">
                            <Text variant={VariantType.Span}>{intl.formatMessage({ id: 'start' })}</Text>
                            <Text variant={VariantType.Span}>{intl.formatMessage({ id: 'end' })}</Text>
                        </div>
                        {
                            startTimes.length &&
                            startTimes.map((_, index) => (
                                index < maxTimeSlots &&
                                <div className="d-flex justify-content-around align-items-center gap-3">
                                    <TimePicker
                                        min={props.startExploitation}
                                        max={props.endExploitation}
                                        minutes={startTimes[index] / conversion}
                                        error={timeError}
                                        onChange={(value) => {
                                            const starts = [
                                                ...startTimes.slice(0, index),
                                                (value ?? 0) * conversion,
                                                ...startTimes.slice(index + 1)
                                            ];
                                            setStartTimes(starts);
                                            checkValueTimes(index, starts, endTimes);
                                        }}
                                    />
                                    <TimePicker
                                        min={props.startExploitation}
                                        max={props.endExploitation}
                                        minutes={endTimes[index] / conversion}
                                        error={timeError}
                                        onChange={(value) => {
                                            const ends = [
                                                ...endTimes.slice(0, index),
                                                (value ?? 0) * conversion,
                                                ...endTimes.slice(index + 1)
                                            ];
                                            setEndTimes(ends);
                                            checkValueTimes(index, startTimes, ends);
                                        }}
                                    />
                                    {
                                        startTimes.length > 1 &&
                                        <Icon iconType={IconType.Trash} onClick={() => removeTime(index)} />
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="days d-flex align-items-center gap-3">
                    <CheckList title={intl.formatMessage({ id: 'authorization.days' })} type={CheckListType.Weekday} active={convertNumberToBinary(activeDays)} onChange={(value) => setActiveDays(convertBinaryToNumber(value))} />
                    <Checkbox label={intl.formatMessage({ id: 'all.gendered' }, { masculine: true })} checked={convertNumberToBinary(activeDays).filter(e => e).length === weekDays} onChange={() => onAllDaysChanged()} />
                </div>
                <RadioButtons label={intl.formatMessage({ id: 'message.type' })} name="informationType" defaultValue={props.data.informationType} options={optionsNature} onChange={onChange} />
            </div>
        );
    };

    const renderStep2 = () => {
        return (
            <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column gap-2">
                    <Text variant={VariantType.H5}>{intl.formatMessage({ id: 'vehicles' })}</Text>
                    <div className="d-flex align-items-center gap-2">
                        <Input
                            label={intl.formatMessage({ id: 'desk.driver' })}
                            value={props.data.information[PassengerInformationKey.P]}
                            onChange={(value) => onChangeInformation(value, PassengerInformationKey.P)}
                        />
                        <Icon iconType={IconType.CrossCircle} onClick={() => onChangeInformation('', PassengerInformationKey.P)} />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <Input
                            label={intl.formatMessage({ id: 'passenger.text' })}
                            value={props.data.information[PassengerInformationKey.J]}
                            onChange={(value) => onChangeInformation(value, PassengerInformationKey.J)}
                        />
                        <Icon iconType={IconType.CrossCircle} onClick={() => onChangeInformation('', PassengerInformationKey.J)} />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <Input
                            label={intl.formatMessage({ id: 'passenger.hp' })}
                            value={props.data.information[PassengerInformationKey.VehS2]}
                            onChange={(value) => onChangeInformation(value, PassengerInformationKey.VehS2)}
                        />
                        <Icon iconType={IconType.CrossCircle} onClick={() => onChangeInformation('', PassengerInformationKey.VehS2)} />
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <Text variant={VariantType.H5}>{intl.formatMessage({ id: 'terminals' })}</Text>
                    <Input
                        label={intl.formatMessage({ id: 'passenger.text' })}
                    />
                    <Input
                        label={intl.formatMessage({ id: 'passenger.hp' })}
                    />
                </div>
            </div>
        );
    };

    const selectAllValue = (list: Array<CheckElementOptionType>, selectAllBroadcast: boolean, setSelectedBroadcast: React.Dispatch<React.SetStateAction<Array<number>>>) => {
        if (selectAllBroadcast) {
            setSelectedBroadcast([]);
        } else {
            setSelectedBroadcast(list.map(e => e.value) as Array<number>);
        }
    };

    const clearAllBroadcast = () => {
        setSelectedVehicles([]);
        setSelectedLines([]);
        setSelectedStops([]);
    };

    const renderSelectBroadcast = (
        label: string,
        placeHolder: string,
        list: Array<CheckElementOptionType>,
        selectedBroadcast: Array<number>,
        setSelectedBroadcast: React.Dispatch<React.SetStateAction<Array<number>>>,
        targetType: PassengerInformationTargetType,
        selectAllBroadcast: boolean,
        setSelectAllBroadcast: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        return (
            <div className="select-broadcast">
                <Select
                    label={intl.formatMessage({ id: label })}
                    placeHolder={intl.formatMessage({ id: placeHolder })}
                    options={list}
                    value={selectedTargeType === targetType ? list.filter(e => selectedBroadcast.includes(Number(e.value))) : []}
                    onChange={(value) => setSelectedBroadcast(value.map(e => e.value) as Array<number>)}
                    onClick={() => setSelectedTargetType(targetType)}
                    isDisabled={selectedTargeType !== targetType}
                    isClearable
                    isMulti
                />
                <div className="d-flex flex-column mt-3">
                    <Checkbox
                        label={intl.formatMessage({ id: 'active' })}
                        checked={selectedTargeType === targetType}
                        onChange={() => { onSelectBroadcast(targetType); clearAllBroadcast(); }}
                    />
                    <Checkbox
                        label={intl.formatMessage({ id: 'all.gendered' }, { masculine: true })}
                        checked={selectAllBroadcast}
                        onChange={() => { setSelectAllBroadcast(!selectAllBroadcast); selectAllValue(list, selectAllBroadcast, setSelectedBroadcast); }}
                        disabled={selectedTargeType !== targetType}
                    />
                </div>
            </div>
        );
    };

    const onSelectBroadcast = (targetType: PassengerInformationTargetType) => {
        setIsAllPark(false);
        setSelectedTargetType(targetType);
    };

    const onSelectAllPark = () => {
        setIsAllPark(!isAllPark);
        setSelectAllVehicles(false);
        setSelectAllVehicles(false);
        setSelectAllStops(false);
        setSelectedTargetType(PassengerInformationTargetType.Global);
    };

    const renderStep3 = () => {
        return (
            <div className="d-flex flex-column gap-4">
                {renderSelectBroadcast('vehicles', 'select.vehicle', props.vehicles, selectedVehicles, setSelectedVehicles, PassengerInformationTargetType.Vehicles, selectAllVehicles, setSelectAllVehicles)}
                {renderSelectBroadcast('lines', 'select.line', props.lines, selectedLines, setSelectedLines, PassengerInformationTargetType.Lines, selectAllLines, setSelectAllLines)}
                {renderSelectBroadcast('stops', 'select.stop', props.stops, selectedStops, setSelectedStops, PassengerInformationTargetType.Stops, selectAllStops, setSelectAllStops)}
                <Checkbox
                    label={intl.formatMessage({ id: 'park.all' })}
                    checked={isAllPark}
                    onChange={() => onSelectAllPark()}
                />
                { /* TODO: Implement terminals for Angoulem */}
            </div>
        );
    };

    const renderHandleStep = () => {
        switch (activeStep) {
            case 0:
                return renderStep1();
            case 1:
                return renderStep2();
            case 2:
                return renderStep3();
            default:
                return;
        }
    };

    const renderTitle = () => {
        switch (activeStep) {
            case 0:
                return intl.formatMessage({ id: 'passenger.information.title.first' });
            case 1:
                return intl.formatMessage({ id: 'passenger.information.title.second' });
            case 2:
                return intl.formatMessage({ id: 'passenger.information.title.third' });
            default:
                return;
        }
    };

    const renderButtonAction = () => {
        return (
            <div className={`d-flex ${!activeStep ? 'justify-content-end' : 'justify-content-between'}`}>
                {
                    [ 1, 2 ].includes(activeStep) &&
                    <Button text={intl.formatMessage({ id: 'back' })} onClick={() => onPreviousStep()} />
                }
                {
                    [ 0, 1 ].includes(activeStep) &&
                    <Button text={intl.formatMessage({ id: 'continue' })} onClick={() => onNextStep()} />
                }
                {
                    [ 2 ].includes(activeStep) &&
                    <Button text={intl.formatMessage({ id: props.action.toLowerCase() })} onClick={() => onLastStep()} />
                }
            </div>
        );
    };

    return (
        <>
            <Modal title={renderTitle()} onClose={onClose}>
                <div className="passenger-information-modal-container">
                    {
                        renderHandleStep()
                    }
                    <div className="my-3">
                        <Stepper activeStep={activeStep} steps={Array.from({ length: steps })} />
                        {
                            renderButtonAction()
                        }
                    </div>
                </div>
            </Modal>
            {
                openModalConfirmation &&
                <ModalConfirmation action={props.action} entity="Information" onClose={() => setOpenModalConfirmation(false)} onSave={onSave} />
            }
        </>
    );
};
