import './GeneralLayout.scss';

import { Icon, IconType, Menu, ResizableCard } from '@ceccli/design-system';
import { useDispatch, useSelector } from 'react-redux';

import Draggable from 'react-draggable';
import { logoutUser } from '../../../app/userStore';
import { PermissionKeys } from '../../../models/User';
import { PhonyIndicator } from '../../PhonyIndicator/PhonyIndicator';
import { PhonyTable } from '../../PhonyTable/PhonyTable';
import { ReactNode } from 'react';
import { RootState } from '../../../app/store';
import { routeGroups } from '../../../App';
import { setOpenDraggable } from '../../../app/phonyStore';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export interface GeneralLayoutProps {
    children: ReactNode,
    noMenu?: boolean,
}

export const GeneralLayout = (props: GeneralLayoutProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();

    const user = useSelector<RootState, boolean>(state => !!state.user.user);
    const userPermissionKeys = useSelector<RootState, Array<string>>(state => state.user.userPermissionKeys);
    const isPhonyOpen = useSelector<RootState, boolean>(state => state.phony.openDraggable);

    return (
        <div className="general-layout d-flex flex-column flex-md-row h-100">
            {
                !props.noMenu &&
                <Menu
                    routeGroups={
                        user ?
                            routeGroups.map(g => ({
                                ...g,
                                routes: g.routes.filter(r => !r.permissionKey || userPermissionKeys.includes(r.permissionKey))
                            })).filter(g => g.routes.length)
                            : []
                    }
                    onNavigation={(e) => {
                        if (typeof e === 'number') {
                            navigate(Number(e));
                        } else {
                            navigate(String(e));
                        }
                    }}
                    onLogout={() => dispatch(logoutUser())}
                    phony={
                        userPermissionKeys.includes(PermissionKeys.Phony) &&
                        <div className="p-2 p-md-3">
                            <PhonyIndicator />
                        </div>
                    }
                />
            }
            <div className="general-content w-100 position-relative">
                {props.children}
                {
                    isPhonyOpen &&
                    <div className="h-100 p-3">
                        <Draggable
                            defaultClassName="position-absolute"
                            bounds="parent"
                            cancel="strong"
                        >
                            <div className="phony-draggable">
                                <ResizableCard
                                    cardProps={{
                                        title:
                                            <div className="d-flex w-100 justify-content-between gap-5 pe-2">
                                                <div>{intl.formatMessage({ id: 'phony.ongoing' }).toUpperCase()}</div>
                                                <Icon
                                                    iconType={IconType.Phone}
                                                    onClick={() => {
                                                        dispatch(setOpenDraggable(false));
                                                        navigate('/phony');
                                                    }}
                                                />
                                            </div>,
                                        onClose: () => dispatch(setOpenDraggable(false)),
                                        children: <PhonyTable noTitle />,
                                    }}
                                />
                            </div>
                        </Draggable>
                    </div>
                }
            </div>
        </div>
    );
};
