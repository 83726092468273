export enum Color {
    Black = '#000000',
    BlackLight = '#121212',
    Blue = '#0059b2',
    BlueAccent = '#00bfff',
    BlueDark = '#0d233a',
    Blue1 = '#f7f9fd',
    Blue2 = '#ebf0fa',
    Blue3 = '#C5D4F0',
    Green = '#008000',
    GreenLight = '#4bb543',
    Grey = '#88929b',
    Grey1 = '#e2e2e2',
    Grey2 = '#f7f7f7',
    Grey3 = '#666666',
    Grey4 = '#687182',
    Grey5 = '#ebebeb',
    Orange = '#ff9100',
    Red = '#ff0000',
    RedDark = '#c70000',
    White = '#ffffff',
    Yellow = '#ffff00',

    OntimeColor = '#DDDDDD',
    SmallAdvanceColor = '#FFCCCC',
    LargeAdvanceColor = '#FF5050',
    SmallDelayColor = '#CCFFCC',
    LargeDelayColor = '#50FF50',
}

export enum ColorVariable {
    Primary = Color.Blue,
    Secondary = Color.White,
    DisabledColor = Color.Grey3,
    DisabledBackground = Color.Grey2,
    DisabledBorder = Color.Grey1,
    Earlier = Color.Red,
    Error = Color.Red,
    Success = Color.GreenLight,
    Late = Color.Green,

    AccentBackground = Color.Blue1,
    AccentText = Color.Black,

    TextDark = Color.Black,
    TextLight = Color.Grey4,

    HoverBackground = Color.Blue2,
    HoverText = Color.Black,

    SelectBackground = Color.Blue3,
    SelectText = Color.Black,

    BorderColor = Color.Grey5,

    Ontime = Color.OntimeColor,
    SmallAdvance = Color.SmallAdvanceColor,
    LargeAdvance = Color.LargeAdvanceColor,
    SmallDelay = Color.SmallDelayColor,
    LargeDelay = Color.LargeDelayColor,

    PhonyDistress = Color.Red,
    PhonyUrgent = Color.Orange,
    PhonySimple = Color.Yellow,
    PhonyOngoing = Color.BlueAccent,
}
