import './AssignmentPage.scss';

import { AssignmentEventData, AssignmentInfo, TestService } from '../../../services/assignmentsSocket.service';
import { Button, Card, ColorVariable, DatePicker, formatTimeFromMinutes, Icon, IconType, Input, Modal, Select, Table, TableColumn, TableValueType, Text, TextStyle, VariantType } from '@ceccli/design-system';
import { Journey, JourneyMode } from '../../../models/Journey';
import { MissionJourney, MissionType } from '../../../models/Mission';
import { ReactNode, useEffect, useState } from 'react';

import { Agent } from '../../../models/Agent';
import { ApiService } from '../../../services/apiService';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { RootState } from '../../../app/store';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Vehicle } from '../../../models/Vehicle';

export const AssignmentPage = () => {
    const apiService = new ApiService();
    const testService = new TestService();
    const intl = useIntl();

    const [ missionData, setMissionData ] = useState<Array<Object>>([]);
    const [ filledMissionData, setFilledMissionData ] = useState<Array<Object>>();
    const [ journeyData, setJourneyData ] = useState<Array<{ [key: string]: TableValueType }>>([]);

    const [ missions, setMissions ] = useState<Array<MissionJourney>>([]);
    const [ selectedMission, setSelectedMission ] = useState<MissionJourney>();

    const [ vehicles, setVehicles ] = useState<Array<Vehicle>>([]);
    const [ agents, setAgents ] = useState<Array<Agent>>([]);

    const [ selectedMissionId, setSelectedMissionId ] = useState<number>();

    const [ webSocket, setWebSocket ] = useState<WebSocket>();
    const [ selectedDate, setSelectedDate ] = useState<Date>(new Date());
    const [ assignmentData, setAssignmentData ] = useState<AssignmentEventData>(new AssignmentEventData());
    const authorization = useSelector<RootState, number | undefined>(state => state.user.user?.authorization);
    const assignmentList = useSelector<RootState, Array<AssignmentInfo>>(state => state.assignments.assignments);
    const currentInfo = assignmentList.find(i => i.missionId === assignmentData.missionId && i.duplicationIndex === assignmentData.duplicationIndex) ?? new AssignmentEventData();

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 7);

    const missionColumns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'service.vehicle' }), key: 'serviceVehicle', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'service.agent' }), key: 'serviceAgent' },
        { label: intl.formatMessage({ id: 'mission' }), key: 'missionLabel' },
        { label: intl.formatMessage({ id: 'start' }), key: 'start', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'end' }), key: 'end', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'crs' }), key: 'shift', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'vehicle' }), key: 'vehicle', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'agent' }), key: 'agent', subIndex: [ 1 ] },
    ];

    const journeyColumns: Array<TableColumn> = [
        { label: intl.formatMessage({ id: 'timings' }), key: 'timings', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'stops' }), key: 'stops', subIndex: [ 1 ] },
        { label: intl.formatMessage({ id: 'line' }), key: 'line', subIndex: [ 1 ] },
    ];

    useEffect(() => {
        let info = selectedMissionId ?
            assignmentList.find(i => i.missionId === selectedMissionId && i.duplicationIndex === assignmentData.duplicationIndex) :
            undefined;

        if (!info) {
            info = new AssignmentEventData();
        }

        setAssignmentData({
            ...assignmentData,
            vehicleId: info.vehicleId,
            agentId: info.agentId,
            missionId: selectedMissionId ?? info.missionId,
            startJourneyIndex: info.startJourneyIndex,
            endJourneyIndex: info.endJourneyIndex,
            shiftInMinutes: info.shiftInMinutes,
        });
    }, [ selectedMissionId, assignmentData.duplicationIndex, assignmentList ]);

    useEffect(() => {
        const time = selectedDate.getTime();
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const diffDays = Math.floor((time - currentDate.getTime()) / (1000 * 60 * 60 * 24));
        const ws = testService.initWebsocket(diffDays);
        setWebSocket(ws);

        setAssignmentData({ ...new AssignmentEventData(), dateIndex: diffDays });
        setSelectedMission(undefined);
        setSelectedMissionId(undefined);

        apiService.getMissionsCourses(time).then(res => {
            setMissions(res);
            const data = res.map(m => {
                const journeys = m.journeys.sort((a, b) => a.startTime - b.startTime);
                const firstJourney = journeys[0];
                const lastJourney = journeys[journeys.length - 1];

                return ({
                    id: m.id,
                    serviceAgent: m.serviceAgent,
                    serviceVehicle: [ m.serviceVehicle.toString(), intl.formatMessage({ id: `mission.type.${MissionType[m.type].toLowerCase()}` }).toUpperCase() ],
                    mission: m.missionNumber,
                    start: [ formatTimeFromMinutes(intl, firstJourney.startTime), firstJourney.startStop ],
                    end: [ formatTimeFromMinutes(intl, lastJourney.endTime), lastJourney.endStop ],
                    missionLabel: m.missionNumber.toString(),
                    journeys,
                });
            });

            setMissionData(data);
            setFilledMissionData(data.sort((a, b) => a.missionLabel.localeCompare(b.missionLabel, intl.locale, { numeric: true })));
            if (!data.find(d => d.id === selectedMissionId)) {
                setSelectedMissionId(undefined);
            }
        });

        apiService.getAllVehicles(undefined, undefined, time).then(res => setVehicles(res));
        apiService.getAllAgents(time, !authorization).then(res => setAgents(res));

        return () => {
            ws?.close();
            setWebSocket(undefined);
        };
    }, [ selectedDate ]);

    useEffect(() => {
        if (!filledMissionData?.length) {
            return;
        }

        // TO FIX: Check why other copy method still modify native array
        const copyArr = JSON.parse(JSON.stringify(missionData));
        const copyArr2 = JSON.parse(JSON.stringify(missionData));

        const duplications: Array<Object> = assignmentList
            .filter(assignment => assignment.duplicationIndex > 0)
            .map(assignment => getData(copyArr.find((d: any) => d.id === assignment.missionId), assignment));

        const others = copyArr2.map((item: any) => {
            const assignment = assignmentList.find(a => a.duplicationIndex === 0 && a.missionId === item.id);
            return assignment ? getData(item, assignment) : item;
        });

        setFilledMissionData([ ...others, ...duplications ].sort((a, b) => a.missionLabel.localeCompare(b.missionLabel, intl.locale, { numeric: true })));
    }, [ assignmentList, missionData, vehicles, agents ]);

    const getData = (data: {[key: string]: unknown}, info: AssignmentInfo) => {
        const vehicle = vehicles.find(v => v.id === info?.vehicleId);
        const agent = agents.find(a => a.id === info?.agentId);

        const agentInfo = [ agent?.code, agent?.lastName ].filter(a => a);
        const vehicleInfo = [ vehicle?.parkId.toString(), vehicle?.label ].filter(v => v);

        const shiftTime = info.shiftInMinutes ? `${info.shiftInMinutes} ${intl.formatMessage({ id: 'min' })}` : undefined;
        let shiftJourney = '';
        if (info.startJourneyIndex) {
            const mission = missions.find(m => m.id === data.id);
            const before = info.startJourneyIndex - 1;
            shiftJourney = `${before > 0 ? before : ''} [${(info.endJourneyIndex ?? 0) - before}] ${(mission?.journeys.length ?? 0) - (info.endJourneyIndex ?? 0)}`;
        }

        const vehicleService = data.serviceVehicle as Array<string>;
        if (info.duplicationIndex > 0) {
            vehicleService[1] = intl.formatMessage({ id: `mission.type.${MissionType[MissionType.DUPLICATION].toLowerCase()}` }).toUpperCase();
        }

        const journeys = data.journeys as Array<Journey>;
        const startJourney = journeys[info.startJourneyIndex ? (info.startJourneyIndex - 1) : 0];
        const endJourney = journeys[info.endJourneyIndex ? (info.endJourneyIndex - 1) : (journeys.length - 1)];
        const shift = info.shiftInMinutes ?? 0;
        const start = startJourney ? [ formatTimeFromMinutes(intl, startJourney.startTime + shift), startJourney.startStop ] : data.start;
        const end = endJourney ? [ formatTimeFromMinutes(intl, endJourney.endTime + shift), endJourney.endStop ] : data.end;

        return {
            ...data,
            serviceVehicle: vehicleService,
            missionLabel: `${data.mission}${(info.duplicationIndex ?? 0) > 0 ? ` (${info.duplicationIndex})` : ''}`,
            agent: agentInfo.length ? agentInfo : undefined,
            vehicle: vehicleInfo.length ? vehicleInfo : undefined,
            duplicationIndex: info.duplicationIndex,
            shift: [ shiftJourney, shiftTime ],
            start,
            end,
        };
    };

    useEffect(() => {
        if (selectedMissionId) {
            const mission = missions.find(m => m.id === selectedMissionId);
            const shift = assignmentData.shiftInMinutes ?? 0;
            setJourneyData(
                mission ?
                    mission.journeys.map(j => {
                        return ({
                            ...j,
                            timings: [ formatTimeFromMinutes(intl, j.startTime + shift), formatTimeFromMinutes(intl, j.endTime + shift) ],
                            stops: [ j.startStop, j.endStop ],
                            line: [ j.lineCode, JourneyMode[j.mode] ].filter(a => a)
                        });
                    }) :
                    []
            );
            setSelectedMission(mission);
        }
    }, [ selectedMissionId, assignmentData ]);

    const renderInfo = (icon: IconType, select: ReactNode, service?: number) => {
        return (
            <>
                <Icon
                    color={ColorVariable.Primary}
                    iconType={icon}
                    className="my-auto"
                />
                <Text textStyle={TextStyle.TableValue} className="my-auto">{service}</Text>
                <div>{select}</div>
            </>
        );
    };

    enum ActionType {
        Update = 'update',
        Dupicate = 'duplicate',
        Delete = 'remove',
        RemoveAgent = 'remove.agent',
        RemoveVehicle = 'remove.vehicle',
    }

    interface ActionInfo {
        type: ActionType,
        onClick: (ws: WebSocket, eventData: AssignmentEventData) => void,
        disabled: boolean,
        hided?: boolean,
    }

    const actions: Array<ActionInfo> = [
        {
            type: ActionType.Update,
            onClick: testService.modifyAssignment,
            disabled:
                !selectedMissionId ||
                (
                    currentInfo.agentId === assignmentData.agentId &&
                    currentInfo.vehicleId === assignmentData.vehicleId &&
                    currentInfo.startJourneyIndex === assignmentData.startJourneyIndex &&
                    currentInfo.endJourneyIndex === assignmentData.endJourneyIndex &&
                    currentInfo.shiftInMinutes === assignmentData.shiftInMinutes
                ),
            hided: !selectedMissionId,
        },
        {
            type: ActionType.Dupicate,
            onClick: testService.duplicateAssignment,
            disabled:
                !selectedMissionId ||
                (!!currentInfo.agentId && currentInfo.agentId === assignmentData.agentId) ||
                (!!currentInfo.vehicleId && currentInfo.vehicleId === assignmentData.vehicleId),
            hided: !selectedMissionId,
        },
        {
            type: ActionType.Delete,
            onClick: testService.removeAssignment,
            disabled:
                !selectedMissionId ||
                (!currentInfo.agentId && !currentInfo.vehicleId && currentInfo.duplicationIndex < 1),
            hided: !selectedMissionId,
        },
        {
            type: ActionType.RemoveAgent,
            onClick: testService.removeAgent,
            disabled: !assignmentData.agentId,
        },
        {
            type: ActionType.RemoveVehicle,
            onClick: testService.removeVehicle,
            disabled: !assignmentData.vehicleId,
        },
    ];

    const handleAssignmentEvent = (actionInfo: ActionInfo) => {
        if (webSocket) {
            actionInfo.onClick(webSocket, assignmentData);
        }
        setConfirmationAction(undefined);
    };

    const renderAssignmentSettings = () => {
        const vehicle = vehicles.find(v => v.id === assignmentData.vehicleId);
        const agent = agents.find(a => a.id === assignmentData.agentId);

        return (
            <Card
                contentClass="p-4 d-flex flex-column justify-content-between"
                title={intl.formatMessage({ id: 'assignment.settings' })}
                fullHeight
            >
                <>
                    <div className="overflow-auto h-100 px-2">
                        <div className="settings-header">
                            <div></div>
                            <Text variant={VariantType.Span} textStyle={TextStyle.TableLabel}>{intl.formatMessage({ id: 'service' })}</Text>
                            <Text variant={VariantType.Span} textStyle={TextStyle.TableLabel}>{intl.formatMessage({ id: 'assignment' })}</Text>
                            { renderInfo(
                                IconType.Bus,
                                <Select
                                    options={vehicles.map(v => { return { value: v.id.toString(), label: `${v.parkId} - ${v.label}` }; })}
                                    placeHolder={intl.formatMessage({ id: 'select.vehicle' })}
                                    className="my-2"
                                    value={vehicle ? [{ value: vehicle.id.toString(), label: `${vehicle.parkId} - ${vehicle.label}` }] : []}
                                    onChange={(e) => setAssignmentData({ ...assignmentData, vehicleId: e[0] ? Number(e[0].value) : 0 })}
                                    isClearable
                                />,
                                selectedMission?.serviceVehicle
                            )}
                            { renderInfo(
                                IconType.User,
                                <Select
                                    options={agents.map(a => { return { value: a.id.toString(), label: `${a.code} - ${a.lastName} ${a.firstName}` }; })}
                                    placeHolder={intl.formatMessage({ id: 'select.agent' })}
                                    value={agent ? [{ value: agent.id.toString(), label: `${agent.code} - ${agent.lastName} ${agent.firstName}` }] : []}
                                    onChange={(e) => setAssignmentData({ ...assignmentData, agentId: e[0] ? Number(e[0].value) : 0 })}
                                    isClearable
                                />,
                                selectedMission?.serviceAgent
                            )}
                        </div>
                        {
                            selectedMissionId &&
                            <Input
                                type="number"
                                label={intl.formatMessage({ id: 'time.shift' })}
                                min={-60}
                                max={60}
                                className="my-3"
                                value={assignmentData.shiftInMinutes}
                                onChange={(value) => {
                                    const num = Number(value);
                                    if (num >= -60 && num <= 60) {
                                        setAssignmentData({ ...assignmentData, shiftInMinutes: value ? Number(value) : undefined });
                                    }
                                }}
                            />
                        }
                        {
                            selectedMissionId &&
                            <div>
                                <Table
                                    columns={journeyColumns}
                                    staticData={{ data: journeyData }}
                                    onSelectChange={(selectedRows) => {
                                        let startIndex = -1;
                                        let endIndex = -1;
                                        if (selectedRows.length) {
                                            const indexes = selectedRows.map(r => journeyData.findIndex(d => d.id === r.id));
                                            startIndex = Math.min(...indexes);
                                            endIndex = Math.max(...indexes);
                                        }
                                        setAssignmentData({
                                            ...assignmentData,
                                            startJourneyIndex: startIndex > -1 ? startIndex + 1 : 0,
                                            endJourneyIndex: endIndex > -1 ? endIndex + 1 : 0
                                        });
                                    }}
                                    multiSelect
                                    selected={journeyData.slice(assignmentData.startJourneyIndex - 1, assignmentData.endJourneyIndex)}
                                />
                            </div>
                        }
                    </div>
                    <div className="d-flex justify-content-center gap-2 mt-3">
                        { actions.filter(a => !a.hided).map(a => (
                            <div key={a.type}>
                                <Button
                                    text={intl.formatMessage({ id: a.type })}
                                    onClick={() => setConfirmationAction(a)}
                                    disabled={a.disabled}
                                />
                            </div>
                        ))}
                    </div>
                </>
            </Card>
        );
    };

    const renderConfirmationModal = () => {
        const vehicle = vehicles.find(v => v.id === assignmentData.vehicleId);
        const agent = agents.find(a => a.id === assignmentData.agentId);

        const shiftTime = assignmentData.shiftInMinutes ? `${assignmentData.shiftInMinutes} ${intl.formatMessage({ id: 'min' })}` : undefined;
        const mission = missions.find(m => m.id === assignmentData.missionId);
        let shiftJourney = '';
        if (assignmentData.startJourneyIndex) {
            const before = assignmentData.startJourneyIndex - 1;
            shiftJourney = `${before > 0 ? before : ''} [${(assignmentData.endJourneyIndex ?? 0) - before}] ${(mission?.journeys.length ?? 0) - (assignmentData.endJourneyIndex ?? 0)}`;
        }
        const agentLabel = `${agent?.code} - ${agent?.lastName} ${agent?.firstName}`;
        const vehicleLabel = `${vehicle?.parkId} - ${vehicle?.label}`;
        const startJourney = mission?.journeys[assignmentData.startJourneyIndex ? (assignmentData.startJourneyIndex - 1) : 0];
        const endJourney = mission?.journeys[assignmentData.endJourneyIndex ? (assignmentData.endJourneyIndex - 1) : (mission.journeys.length - 1)];

        let content;
        let icon;
        let label;
        const duplicationIndex = confirmationAction?.type === ActionType.Dupicate ?
            Math.max(...assignmentList.filter(a => a.missionId === assignmentData.missionId).map(a => a.duplicationIndex), 0) + 1 :
            assignmentData.duplicationIndex;
        const shift = assignmentData.shiftInMinutes ?? 0;

        switch (confirmationAction?.type) {
            case ActionType.Update:
            case ActionType.Dupicate:
            case ActionType.Delete:
                content = (
                    <Table
                        columns={missionColumns}
                        staticData={{
                            data: [{
                                serviceVehicle: mission ? [
                                    mission.serviceVehicle.toString(),
                                    intl.formatMessage({ id: `mission.type.${MissionType[duplicationIndex > 0 ? MissionType.DUPLICATION : mission.type].toLowerCase()}` }).toUpperCase()
                                ] : undefined,
                                serviceAgent: mission?.serviceAgent,
                                agent: agent ? `${agent?.code} - ${agent?.lastName} ${agent?.firstName}` : undefined,
                                vehicle: vehicle ? `${vehicle?.parkId} - ${vehicle?.label}` : undefined,
                                shift: [ shiftJourney, shiftTime ],
                                missionLabel: `${mission?.missionNumber}${(duplicationIndex ?? 0) > 0 ? ` (${duplicationIndex})` : ''}`,
                                start: startJourney ? [ formatTimeFromMinutes(intl, startJourney.startTime + shift), startJourney.startStop ] : undefined,
                                end: endJourney ? [ formatTimeFromMinutes(intl, endJourney.endTime + shift), endJourney.endStop ] : undefined,
                            }]
                        }}
                    />
                );
                break;
            case ActionType.RemoveAgent:
                icon = IconType.User;
                label = agentLabel;
                break;
            case ActionType.RemoveVehicle:
                icon = IconType.Bus;
                label = vehicleLabel;
                break;
            default:
                break;
        }
        if (!content && icon && label) {
            content = (
                <div className="d-flex align-items-center gap-3 my-2">
                    <Icon iconType={icon} color={ColorVariable.Primary} />
                    <div>{label}</div>
                </div>
            );
        }

        let translationKey = `${confirmationAction?.type}.confirmation`;
        if (confirmationAction?.type === ActionType.Delete && assignmentData.duplicationIndex) {
            translationKey = `${translationKey}.duplication`;
        }

        return (
            <div className="d-flex flex-column gap-3 align-items-center">
                <div>{intl.formatMessage({ id: translationKey })}</div>
                { content }
                <div className="d-flex gap-3">
                    <Button
                        text={intl.formatMessage({ id: 'confirm' })}
                        onClick={() => confirmationAction && handleAssignmentEvent(confirmationAction)}
                    />
                    <Button
                        text={intl.formatMessage({ id: 'cancel' })}
                        onClick={() => setConfirmationAction(undefined)}
                    />
                </div>
            </div>
        );
    };

    const [ confirmationAction, setConfirmationAction ] = useState<ActionInfo>();

    return (
        <>
            {
                selectedMissionId &&
                <div className="d-block d-lg-none">
                    <Modal>
                        { renderAssignmentSettings() }
                    </Modal>
                </div>
            }
            {
                confirmationAction &&
                <Modal>
                    { renderConfirmationModal() }
                </Modal>
            }
            <PageFillUnderHeader
                header={
                    <Card
                        title={intl.formatMessage({ id: 'assignments.gesture' })}
                        contentClass="p-3"
                    >
                        <div className="col-3 mx-auto">
                            <DatePicker
                                defaultDate={selectedDate}
                                label={intl.formatMessage({ id: 'exploitation.date' })}
                                onChange={e => {
                                    if (e) {
                                        setSelectedDate(e);
                                    }
                                }}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        </div>
                    </Card>
                }
                content={
                    <div className="assignment-page d-flex h-100">
                        <div className={'col-12 col-lg-8 pe-2'}>
                            <Table
                                columns={missionColumns}
                                staticData={{
                                    data: filledMissionData as Array<{[key: string]: TableValueType}>,
                                    isLoading: !filledMissionData,
                                }}
                                onClick={(row) => {
                                    setSelectedMissionId(Number(row.id));
                                    setAssignmentData({
                                        ...assignmentData,
                                        duplicationIndex: Number(row.duplicationIndex ?? 0),
                                        shiftInMinutes: Number(row.shiftInMinutes ?? 0),
                                    });
                                }}
                                colorizedRow={(row) => (row.duplicationIndex ? String(ColorVariable.Primary) : undefined)}
                            />
                        </div>
                        <div className="col-4 ps-2 d-none d-lg-block">
                            { renderAssignmentSettings() }
                        </div>
                    </div>
                }
            />
        </>
    );
};
