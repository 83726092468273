import { addPhony, updatePhony } from '../app/phonyStore';
import { Phony, PhonyFlag } from '../models/Phony';
import { useDispatch, useSelector } from 'react-redux';

import { formatTimeFromSeconds } from '@ceccli/design-system';
import { RootState } from '../app/store';
import { SocketInitKey } from '../common/enum';
import { useIntl } from 'react-intl';

export enum PhonyMessageType {
    Action = '5',
    Info = '6',
    ActionA = '15',
    InfoA = '16',
}

export class PhonySocketService {
    intl = useIntl();
    dispatch = useDispatch();
    phonyInfos = useSelector<RootState, {[phonyId: number]: Phony}>(state => state.phony.phonyInfos);

    initWebsocket = () => {
        const ws = new WebSocket(String(process.env.REACT_APP_WEBSOCKET_URL));

        const handleMessageAction = (list: Array<string>) => {
            list.forEach(str => {
                const msgContent = str.split('|');

                this.dispatch(
                    addPhony({
                        id: Number(msgContent[0]),
                        content: {
                            startTime: formatTimeFromSeconds(this.intl, Number(msgContent[1])) ?? '',
                            priority: Number(msgContent[2]),
                            callType: Number(msgContent[3]),
                            hardwareType: Number(msgContent[4]),
                            hardwareKey: Number(msgContent[5]),
                            gssi: Number(msgContent[6]),
                            agentId: Number(msgContent[7]),
                            lineId: Number(msgContent[8]),
                            flag: msgContent[9].trim() as PhonyFlag,
                        },
                    })
                );
            });
        };

        const handleMessageInfo = (list: Array<string>) => {
            list.forEach(str => {
                const msgContent = str.split('|');
                const id = Number(msgContent[0]);

                this.dispatch(
                    updatePhony({
                        id,
                        content: {
                            updateTime: formatTimeFromSeconds(this.intl, Number(msgContent[1])) ?? '',
                            post: msgContent[2],
                            login: msgContent[3],
                            label: msgContent[4],
                            flag: msgContent[5] as PhonyFlag,
                        },
                    })
                );
            });
        };

        const handleMessageData = (data: string) => {
            const msgSplit = data.split('\n');
            const msgType = msgSplit[0];

            switch (msgType) {
                case PhonyMessageType.Action:
                case PhonyMessageType.ActionA:
                    handleMessageAction(msgSplit.slice(1));
                    break;
                case PhonyMessageType.Info:
                case PhonyMessageType.InfoA:
                    handleMessageInfo(msgSplit.slice(1));
                    break;
                default:
                    break;
            }
        };

        ws.onopen = () => {
            ws.send(SocketInitKey.Phony);
        };

        ws.onmessage = (msg) => {
            handleMessageData(msg.data);
        };

        ws.onerror = function(event) {
            console.log('Error on WebSocket', event);
        };

        return ws;
    };
}
