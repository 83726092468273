import './DeployedEmbeddedTables.scss';

import { ArrowButton, ColorVariable, Table, TableValueType } from '@ceccli/design-system';
import { useEffect, useState } from 'react';

import { ApiService } from '../../../services/apiService';
import { Application } from '../../../models/Application';
import { PageFillUnderHeader } from '../../layouts/PageFillUnderHeader';
import { useIntl } from 'react-intl';
import { VersionIdentifier } from '../../../models/Version';

export const DeployedEmbeddedTablesPage = () => {
    const intl = useIntl();
    const apiService = new ApiService();

    const [ applications, setApplications ] = useState<Array<Application>>();
    const [ selectedIndex, setSelectedIndex ] = useState<number>();
    const [ data, setData ] = useState<Array<{[key: string]: TableValueType}>>([]);

    useEffect(() => {
        apiService.getApplications().then(res => {
            setApplications(res);
            setSelectedIndex(res.length - 1);
            apiService.getVehiclesWithDeployedTable(undefined, undefined, undefined, res[0].dateKey);
        });
    }, []);

    const renderDate = (row: { [key: string]: TableValueType }) => {
        const index = Number(row.index);
        const max = Number(row.max);

        return (
            <div className="d-flex align-items-center gap-2">
                <ArrowButton
                    angle={90}
                    onClick={() => setSelectedIndex(index - 1)}
                    disabled={index <= 0}
                />
                <div>{row.date}</div>
                <ArrowButton
                    angle={-90}
                    onClick={() => setSelectedIndex(index + 1)}
                    disabled={index >= max}
                />
            </div>
        );
    };
    const columnsCommon = () => [
        {
            label: intl.formatMessage({ id: 'version.type' }, { type: intl.formatMessage({ id: 'config' }) }),
            key: 'versionConfig'
        },
        {
            label: intl.formatMessage({ id: 'version.type' }, { type: intl.formatMessage({ id: 'capability' }) }),
            key: 'versionCapability'
        },
        {
            label: intl.formatMessage({ id: 'version.type' }, { type: intl.formatMessage({ id: 'schedule' }) }),
            key: 'versionSchedule'
        },
        {
            label: intl.formatMessage({ id: 'version.type' }, { type: intl.formatMessage({ id: 'geography' }) }),
            key: 'versionGeography'
        },
        {
            label: intl.formatMessage({ id: 'version.type' }, { type: intl.formatMessage({ id: 'geographyPlus' }) }),
            key: 'versionGeographyPlus'
        },
    ];

    const columnsStatus = [
        { label: intl.formatMessage({ id: 'park.id' }), key: 'parkId' },
        { label: intl.formatMessage({ id: 'application.date' }), key: 'date' },
        ...columnsCommon(),
    ];
    const columnsVersion = [
        { label: intl.formatMessage({ id: 'application.date' }), key: 'date', renderCell: renderDate },
        ...columnsCommon(),
    ];

    useEffect(() => {
        if (!applications?.length || (!selectedIndex && selectedIndex !== 0)) {
            return;
        }

        setData([]);

        Promise.all(
            [
                apiService.getVersion(VersionIdentifier.Configuration),
                apiService.getVersion(VersionIdentifier.Capability),
                apiService.getVersion(VersionIdentifier.Schedule),
                apiService.getVersion(VersionIdentifier.Geography),
                apiService.getVersion(VersionIdentifier.GeographyPlus),
            ]
        ).then(([ config, capability, schedule, geography, geographyPlus ]) => {
            setData([{
                max: applications.length - 1,
                index: selectedIndex,
                date: intl.formatDate(applications[selectedIndex].startDate),
                versionConfig: config.value,
                versionCapability: capability.value,
                versionSchedule: schedule.value,
                versionGeography: geography.value,
                versionGeographyPlus: geographyPlus.value,
            }]);
        });
    }, [ applications, selectedIndex ]);

    return (
        <PageFillUnderHeader
            header={
                <div className="deployed-embedded-tables d-flex flex-column">
                    <div className="page-title big-text text-center text-uppercase mb-3">{intl.formatMessage({ id: 'deployed.embedded.tables' })}</div>
                    <Table
                        title={intl.formatMessage({ id: 'deployed.embedded.tables.version' })}
                        columns={columnsVersion}
                        staticData={{
                            data,
                            isLoading: !applications || !data.length
                        }}
                        refreshColRender={selectedIndex}
                    />
                </div>
            }
            content={
                applications && (selectedIndex || selectedIndex === 0) &&
                <Table
                    title={intl.formatMessage({ id: 'deployed.embedded.tables.status' })}
                    columns={columnsStatus}
                    dynamicData={{
                        getData: (pageIndex, pageSize, sorting) => apiService.getVehiclesWithDeployedTable(pageIndex, pageSize, sorting, applications[selectedIndex].dateKey),
                        formatData: (data) => {
                            let date;
                            let error;
                            if (data.dateKey) {
                                const dateKey = data.dateKey.toString();
                                date = intl.formatDate(
                                    new Date(
                                        Number(`20${dateKey.slice(0, 2)}`),
                                        Number(dateKey.slice(2, 4)) - 1,
                                        Number(dateKey.slice(4, 6)),
                                    )
                                );
                                error = dateKey < applications[selectedIndex].dateKey;
                            }
                            return ({
                                ...data,
                                date,
                                error,
                            });
                        },
                        refreshTrigger: [ selectedIndex ]
                    }}
                    refreshColRender={selectedIndex}
                    options={{ export: true }}
                    colorizedRow={(row) => (row.error ? ColorVariable.Error.toString() : undefined)}
                    allSortable
                />
            }
        />
    );
};
