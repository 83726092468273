import { useState } from 'react';

export interface TimePickerProps {
    minutes?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    error?: boolean;
    onError?: (error: boolean) => void
    onChange: (minutes?: number) => void;
}

export const TimePicker: React.FunctionComponent<TimePickerProps> = (props: TimePickerProps) => {
    const formatValue = (val: number) => Math.floor(val).toString().padStart(2, '0');
    const hours = props.minutes ? formatValue((props.minutes / 60) % 24) : undefined;
    const minutes = props.minutes ? formatValue(props.minutes % 60) : undefined;
    const min = props.min ?? 0;
    const max = props.max ?? 24 * 60;

    const isError = (val?: number) => {
        if (!val) {
            return false;
        }
        if (max > min) {
            return val > max || val < min;
        }
        return val > max && val < min;
    };

    const [ error, setError ] = useState<boolean>(isError(props.minutes));

    const onTimeChange = (str: string) => {
        const split = str.split(':').map(s => Number(s));
        let val = split[0] * 60 + split[1];
        setError(isError(val));
        if (min > max && val < min) {
            val += 24 * 60;
        }
        props.onChange(val);
    };

    return (
        <div className="time-picker">
            <input
                className={(error || props.error) ? 'error' : undefined}
                type="time"
                value={props.minutes ? `${hours}:${minutes}` : undefined}
                onChange={e => onTimeChange(e.target.value)}
                disabled={props.disabled}
            />
        </div>
    );
};
