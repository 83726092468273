import { ReactNode } from 'react';

export interface LayersProps {
    children: ReactNode;
}

const Layers = (props: LayersProps) => {
    return <div>{props.children}</div>;
};

export default Layers;
