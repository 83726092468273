import './RadioButtons.scss';

import React, { useEffect, useState } from 'react';
import { Text, VariantType } from '../Text/Text';

import { CheckElementOptionType } from '../../../common/type';

export interface RadioButtonsProps {
    options: Array<CheckElementOptionType>,
    defaultValue?: string;
    label?: string,
    name?: string;
    onChange: (value: string, name?: string) => void;
}

export const RadioButtons: React.FunctionComponent<RadioButtonsProps> = (props: RadioButtonsProps) => {
    const [ selected, setSelected ] = useState<string>(props.defaultValue ?? (props.options && props.options[0] && props.options[0].value.toString()));

    useEffect(() => {
        props.onChange(selected, props.name);
    }, [ selected ]);

    return (
        <div className="radio-buttons-container" data-testid="radio-buttons">
            {
                props.label &&
                <Text variant={VariantType.H5}>{props.label}</Text>
            }
            <div className="d-flex gap-4 radio-buttons">
                {
                    props.options.map(option => {
                        return (
                            <div
                                className="d-flex cursor-pointer"
                                onClick={() => setSelected(option.value.toString())}
                                key={option.value.toString()}
                            >
                                <input
                                    aria-labelledby="label"
                                    className="me-2 cursor-pointer"
                                    type="radio"
                                    name={props.name}
                                    value={option.value.toString()}
                                    checked={selected === option.value}
                                    readOnly
                                />
                                <label id="label">{option.label}</label>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};
