import assignmentsReducer from './assignmentsStore';
import { configureStore } from '@reduxjs/toolkit';
import cycleReducer from './cycleStore';
import drawableDataReducer from './drawableDataStore';
import mapDataReducer from './mapDataStore';
import passengerInformationReducer from './passengerInformationStore';
import phonyReducer from './phonyStore';
import saphirFunctionReducer from './saphirFunctionsStore';
import toastReducer from './ToastStore';
import userReducer from './userStore';

const store = configureStore({
    reducer: {
        mapData: mapDataReducer,
        drawableData: drawableDataReducer,
        passengerInformation: passengerInformationReducer,
        assignments: assignmentsReducer,
        cycles: cycleReducer,
        user: userReducer,
        toasts: toastReducer,
        saphirFunction: saphirFunctionReducer,
        phony: phonyReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
