import { fromLonLat } from 'ol/proj';
import { Icon } from 'ol/style';
import { Point } from 'ol/geom';

export class CartographyObject {
    id: number;
    type: CartographyKeys;
    label: string;
    position: [number, number];
    geometry: Point;
    key: string;
    icon?: Icon;
    positionLambert?: [number, number];

    constructor(id: number, type: CartographyKeys, label: string, position: [number, number], icon?: Icon, positionLambert?: [number, number]) {
        this.id = id;
        this.type = type;
        this.label = label;
        this.position = position;
        this.icon = icon;
        this.key = `${type}-${id}`;
        this.geometry = new Point(fromLonLat([ ...position ].reverse()));
        this.positionLambert = positionLambert;
    }
}

export enum CartographyKeys {
    Stop = 'stop',
    Vehicle = 'vehicle',
    Portable = 'portable',
    Terminal = 'terminal'
}
