import './Checkbox.scss';

import { Text, VariantType } from '../Text/Text';

import React from 'react';

export interface CheckboxProps {
    checked?: boolean;
    label?: string;
    title?: string;
    name?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    onChange?: (checked: boolean, name?: string) => void;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = (props: CheckboxProps) => {
    return (
        <div className="checkbox-container cursor-pointer" style={props.style} data-testid="checkbox">
            {
                props.title &&
                <Text variant={VariantType.H5} className="mt-4">{props.title}</Text>
            }
            <label className="d-flex align-items-center m-0">
                <input
                    type="checkbox"
                    name={props.name}
                    checked={props.checked}
                    onChange={() => {
                        props.onChange && props.onChange(!props.checked, props.name);
                    }}
                    disabled={props.disabled}
                />
                <span>{props.label}</span>
            </label>
        </div>
    );
};

