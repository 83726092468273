import React, { useState } from 'react';
import { Select, SelectProps } from '../Select/Select';

import { Button } from '../Button/Button';
import { CheckElementOptionType } from '../../../common/type';
import { useIntl } from 'react-intl';

export interface SelectButtonOptions {
    reset?: boolean,
    all?: boolean,
}
export interface SelectFiltersProps extends SelectProps {
    buttonOptions?: SelectButtonOptions,
    masculine?: boolean,
}

export const SelectFilter: React.FunctionComponent<SelectFiltersProps> = (props: SelectFiltersProps) => {
    const intl = useIntl();
    const [ value, setValue ] = useState<Array<CheckElementOptionType> | undefined>(props.value);

    return (
        <div className="d-flex align-items-end gap-1">
            <Select
                {...props}
                value={value}
                onChange={(e) => {
                    setValue(e);
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
                className="w-100"
            />
            {
                props.buttonOptions?.reset &&
                <Button
                    text={intl.formatMessage({ id: 'reset' })}
                    onClick={() => {
                        if (props.onChange) {
                            props.onChange(props.defaultValue ?? []);
                        }
                    }}
                />
            }
            {
                props.buttonOptions?.all &&
                <Button
                    text={intl.formatMessage({ id: 'all.gendered' }, { masculine: props.masculine })}
                    onClick={() => {
                        setValue([]);
                        if (props.onChange) {
                            props.onChange([]);
                        }
                    }}
                />
            }
        </div>
    );
};
