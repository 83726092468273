import './ResizableCard.scss';

import { Card, Icon, IconType } from '../../atoms';

import { CardProps } from '../../atoms/Card/Card';
import Draggable from 'react-draggable';
import { useState } from 'react';

export interface ResizableCardProps {
    cardProps: CardProps;
}

interface ResizerValue {
    x: number,
    y: number,
}

export const ResizableCard = (props: ResizableCardProps) => {
    const [ value, setValue ] = useState<ResizerValue>({ x: 1000, y: 1000 });
    const [ min ] = useState<ResizerValue>({ x: 0, y: 0 });
    const [ max ] = useState<ResizerValue>({ x: 1000, y: 1000 });

    const renderResizerDraggableBoth = (
        value: ResizerValue,
        min: ResizerValue,
        max: ResizerValue,
        onChange: (val: ResizerValue) => void,
    ) => {
        return (
            <Draggable
                axis="both"
                onDrag={(_, { x, y }) => {
                    onChange({
                        x: Math.min(Math.max(value.x + x, min.x), max.x),
                        y: Math.min(Math.max(value.y + y, min.y), max.y),
                    });
                }}
                position={{ x: 0, y: 0 }}
            >
                <div className="resize-icon">
                    <Icon iconType={IconType.Resize} size={16} />
                </div>
            </Draggable>
        );
    };

    return (
        <div
            className="resizable-card card-container overflow-auto"
            style={{ maxWidth: value.x, maxHeight: value.y }}
        >
            <Card { ...props.cardProps} />
            <strong className="resizable-drag">
                {
                    renderResizerDraggableBoth(
                        value,
                        min,
                        max,
                        setValue,
                    )
                }
            </strong>
        </div>
    );
};
