import { addInformation, deleteInformation, updateInformation } from '../app/passengerInformationStore';
import { Base, convertDecimalToBase, websocketFormat, websocketMessageFormat } from '@ceccli/design-system';
import { PassengerInformation, PassengerInformationTargetType, PassengerInformationType } from '../models/PassengerInformation/PassengerInformation';

import moment from 'moment';
import { SocketInitKey } from '../common/enum';
import { useDispatch } from 'react-redux';

export enum PassengerInformationEventCode {
    New = 'NMV',
    Modify = 'MMV',
    Remove = 'RMV',
}

export class PassengerInformationSocketService {
    dispatch = useDispatch();

    initWebsocket = () => {
        const ws = new WebSocket(String(process.env.REACT_APP_WEBSOCKET_URL));

        ws.onopen = () => {
            ws.send(SocketInitKey.PassengerInformation);
            ws.send('<EMC:0>');
        };

        ws.onmessage = (msg) => {
            const msgSplit = msg.data.split('\n');
            const info = msgSplit[1].split('|');
            const startDate = this.getDate(info[1]);
            const endDate = this.getDate(info[2]);
            const informationType = info[3];
            const activatedDays = Number(info[4]);
            const dateCount = Number(info[5]);
            const times = [];
            let targetTypeIndex = 5;

            if (!isNaN(dateCount)) {
                for (let i = 0; i < dateCount * 2; i += 2) {
                    times.push(info.slice(6 + i, 8 + i).map((t: string) => t));
                }
                targetTypeIndex = 6 + dateCount * 2;
            }

            const targetType = info[targetTypeIndex];
            let targetCount = 0;

            let targets: Array<number> = [];
            let lastTargetIndex = targetTypeIndex;
            switch (targetType) {
                case PassengerInformationTargetType.Global:
                    break;
                case PassengerInformationTargetType.Panels:
                    targetCount = Number(info[targetTypeIndex + 1]);
                    for (let i = 0; i < targetCount; i++) {
                        targets.push(info[targetTypeIndex + 3 + i * 2]);
                        lastTargetIndex = targetTypeIndex + 3 + i * 2;
                    }
                    break;
                case PassengerInformationTargetType.Vehicles:
                case PassengerInformationTargetType.Lines:
                case PassengerInformationTargetType.Stops:
                    targetCount = Number(info[targetTypeIndex + 1]);
                    targets = info.slice(targetTypeIndex + 2, targetTypeIndex + 2 + targetCount);
                    lastTargetIndex = targetTypeIndex + 2 + targetCount - 1;
                    break;
                default:
                    break;
            }

            const informations = Object.create({});
            let pcMessage;
            switch (informationType) {
                case PassengerInformationType.PC:
                    pcMessage = {
                        image: info[lastTargetIndex + 3],
                        fontWeight: info[lastTargetIndex + 4],
                        fontSize: info[lastTargetIndex + 5],
                        fontFamily: info[lastTargetIndex + 6],
                        text: info[lastTargetIndex + 7]
                    };
                    informations.PC = '';
                    break;
                default:
                    for (let i = lastTargetIndex + 1; i < info.length - 1; i += 2) {
                        informations[info[i]] = info[i + 1];
                    }
                    break;
            }

            const information = new PassengerInformation(
                info[0],
                startDate,
                endDate,
                informationType,
                times,
                activatedDays,
                targetType,
                targets,
                informations,
                pcMessage
            );

            if (informationType && targetType && informations) {
                this.dispatch(addInformation(information));
            }
        };
        return ws;
    };

    getDate = (info: string) => {
        return new Date(
            Number(`20${info.slice(0, 2)}`),
            Number(info.slice(2, 4)) - 1,
            Number(info.slice(4, 6)),
        );
    };

    createPassengerInformation = (ws: WebSocket, data: PassengerInformation) => {
        this.sendObjectEvent(ws, data, PassengerInformationEventCode.New);
    };

    updatePassengerInformation = (ws: WebSocket, data: PassengerInformation) => {
        this.sendObjectEvent(ws, data, PassengerInformationEventCode.Modify);
        this.dispatch(updateInformation(data));
    };

    deletePassengerInformation = (ws: WebSocket, data: PassengerInformation) => {
        this.sendEvent(ws, `${data.id};`, PassengerInformationEventCode.Remove);
        this.dispatch(deleteInformation(data.id));
    };

    convertInformation = (information: { [key: string]: string }) => {
        const keys = Object.keys(information);
        const values = Object.values(information);
        return keys.map((key, i) => `${key};${values[i].length}=${values[i]}`).join(';');
    };

    sendObjectEvent = (ws: WebSocket, data: PassengerInformation, code: PassengerInformationEventCode) => {
        const timesTargetType: Array<string> = [ data.targets.length ? data.targetType : PassengerInformationTargetType.Global ];
        if (data.targets.length) {
            timesTargetType.push(String(data.targets.length));
        }
        const event: Array<string | number> = [
            moment(data.startDate).format('DD/MM/YYYY'),
            moment(data.endDate).format('DD/MM/YYYY'),
            data.informationType,
            convertDecimalToBase(data.activeDays, Base.Hexa),
            data.times.length,
            data.times.map(time => time.join(';')).join(';'),
            timesTargetType.join(';'),
            websocketMessageFormat(data.targets),
            this.convertInformation(data.information),
        ];
        if (code === PassengerInformationEventCode.Modify) {
            event.unshift(data.id);
        }
        const message = websocketMessageFormat(event).replace(/;;/g, ';');
        this.sendEvent(ws, message, code);
    };

    sendEvent = (ws: WebSocket, message: string, code: PassengerInformationEventCode) => {
        ws.send(websocketFormat(code, message));
    };
}
